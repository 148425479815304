import React, { useEffect, useState } from "react"
import VehicleReport from "../components/reports/VehicleReport"
import { useStoreServices } from "../zustand/store"
import { useSearchParams } from "react-router-dom"

function AuthVehicleReportView() {
  const selectedService = useStoreServices((s) => s.selectedService)
  const [isWhat, setIsWhat] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setIsWhat(searchParams.get("what") ?? "in")
  }, [searchParams])

  return <VehicleReport service={selectedService} rc={selectedService.replacement_car_data} isWhat={isWhat} />
}

export default AuthVehicleReportView
