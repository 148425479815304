import React, { Fragment, useState, useEffect, useRef, useMemo } from "react"
import axios from "axios"
import dayjs from "dayjs"
import { apiUrl, config } from "../../library/constant"
import { useStore, useStoreCalendar, useStoreSchedule, useStoreWorkshops, useStoreSettings, useStoreReplacementCars, useStoreTypes } from "../../zustand/store"
import Gantt from "./Gantt"
import GanttWithPrice from "./GanttWithPrice"
import Input from "../../components/inputs/Input"

function Calendar({ className, selection, watcher, setValue, register, errors }) {

  const selectedWorkshop = document.getElementsByName('workshop')[0]
  const selectedWorkshopValue = selectedWorkshop ? selectedWorkshop.options[selectedWorkshop.selectedIndex]?.value : null
  const [choosenWorkshopId, setChoosenWorkshopId] = useState(null)
  const [showReasonField, setShowReasonField] = useState(watcher?.rc_selection_reason ? true : false)
  const [sortedWorkshops, setSortedWorkshops] = useState([])
  const replacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const selectedVehicleCategory = useStoreReplacementCars((s) => s.selectedVehicleCategory)
  const { calendarFrom, calendarTo } = useStoreCalendar()
  const { calendarItems, oldCalendarRows, calendarRows } = useStoreCalendar()
  const { workshops, setSelectedWorkshop } = useStoreWorkshops()
  const carsBrandTypes = useStoreTypes((state) => state.carsBrandTypes)

  const setSelectedFrom = useStoreSchedule((s) => s.setSelectedFrom)
  const setSelectedTo = useStoreSchedule((s) => s.setSelectedTo)
  const setSelectedId = useStoreSchedule((s) => s.setSelectedId)
  
  const [diplayAllRCWithoutSteering, setDiplayAllRCWithoutSteering] = useState(false)
  const [workshopArray, setWorkshopArray] = useState([])

  const [openWorkShopIndex, setOpenWorkShopIndex] = useState(0)

  const handleWorkShopClick = (index) => {
    if (openWorkShopIndex !== index) setOpenWorkShopIndex(index);
  };

  const onSelection = (params) => {
    const newDateFrom = dayjs(params.start).format("YYYY-MM-DD")
    const newDateTo = dayjs(params.end).format("YYYY-MM-DD")
    setSelectedFrom(newDateFrom)
    setSelectedTo(newDateTo)
    setSelectedId(params.id)
    setSelectedWorkshop(params.workshop);
  }

  // Function to count unique workshop
  const countUniqueWorkshopId = (obj) => {
    const uniqueNames = new Set();
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            uniqueNames.add(obj[key].workshop_id);
        }
    }
    return uniqueNames.size;
  }

  const parsingJSON = (json) => {
    try {
      return JSON.parse(json)
    } catch (error) {
      return []
    }
  }

  const uppercaseText = (text) => {
    // console.log("text", text)
    if (text) {
      return text?.toUpperCase()?.trim()
    } else {
      return text
    }
  }

  const hideAllAccordion = (workshops, rcRows) => {
    let isHidden = true
    for (let wsLength = 0; wsLength < workshops.length; wsLength++) {
      // if (workshop.id !== item.id) workshop.open = false;
      rcRows.filter(subItem => subItem.workshop_id === workshops[wsLength].workshopId)
      for (let priceLength = 0; priceLength < rcRows.length; priceLength++) {
        const rate = rcRows[priceLength]?.price.filter(item => item.mileage == watcher?.service_mileage).length > 0 ? rcRows[priceLength]?.price.find(item => item.mileage == watcher?.service_mileage) : ""
        if (rate) isHidden = false
      }
    }
    return isHidden
  }

  const hideWorkShopAccordion = (item) => {
    let isHidden = false
    // for (let priceLength = 0; priceLength < item.length; priceLength++) {
    //   const rate = item[priceLength]?.price.filter(item => item.mileage == watcher?.service_mileage).length > 0 ? item[priceLength]?.price.find(item => item.mileage == watcher?.service_mileage) : ""
    //   if (rate) isHidden = false
    // }
    return isHidden
  }

  const hideAccordion = (item) => {
    let isHidden = false
    // for (let priceLength = 0; priceLength < item.length; priceLength++) {
    //   const rate = item[priceLength]?.price.filter(item => item.mileage == watcher?.service_mileage).length > 0 ? item[priceLength]?.price.find(item => item.mileage == watcher?.service_mileage) : ""
    //   if (rate) isHidden = false
    // }
    return isHidden && 'hidden'
  }

  const stringToArray = (inputString) => {
    const delimiters = [',', '-', '_', ' ']
    // Create a regular expression from the delimiters
    const regex = new RegExp(`[${delimiters.join('\\')}]`, 'g')
    
    // Split the string using the regex
    return inputString.split(regex)
  }

  const isNumeric = (value) => {
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    if (typeof value === 'string') {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }
    return false;
  }

  const checkServiceMileage = (serviceMileageArray, currentServiceMileage) => {
    const parseServiceMileage = parsingJSON(serviceMileageArray)
    const serviceMileage = parseServiceMileage.filter(item => item?.mileage?.trim() === currentServiceMileage?.trim() && isNumeric(item?.price))
    return serviceMileage.length > 0
  }

  const getVehicleCategoryPrice = (selectedWorkshopName) => {
    let price = 0
    if (Array.isArray(selectedVehicleCategory) && selectedVehicleCategory.filter(vc => uppercaseText(vc.supplier_name) === uppercaseText(selectedWorkshopName) && uppercaseText(vc.model) === uppercaseText(watcher?.vehicle_category) && checkServiceMileage(vc.service_mileage, watcher?.service_mileage)).length > 0) {
      const selectedItem = selectedVehicleCategory.find(vc => uppercaseText(vc.supplier_name) === uppercaseText(selectedWorkshopName) && uppercaseText(vc.model) === uppercaseText(watcher?.vehicle_category) && checkServiceMileage(vc.service_mileage, watcher?.service_mileage))
      const selectedServiceMileage = parsingJSON(selectedItem.service_mileage)
      price = selectedServiceMileage.find(item => item.mileage === watcher?.service_mileage).price
    }
    return price
  }

  const checkReasonFieldByRates = (price, maxPrice, WorkshopsWithRates) => {
    if (WorkshopsWithRates.length === 1) return false
    // return if more than 1 results
    return price === maxPrice
  }

  useEffect(() => {
    if (watcher?.service_type == 1) {
      const workshopArrayValue = []

      // Filter Workshop by location
      let filteredWorkshops = workshops.filter(item => uppercaseText(item.location) === uppercaseText(watcher?.collection_state))

      for (let workshopLength = 0; workshopLength < filteredWorkshops.length; workshopLength++) {
        filteredWorkshops[workshopLength].workshopName = filteredWorkshops[workshopLength].name
        filteredWorkshops[workshopLength].price = getVehicleCategoryPrice(filteredWorkshops[workshopLength].name)
        filteredWorkshops[workshopLength].haveReasonField = false
        workshopArrayValue.push(filteredWorkshops[workshopLength].id)
      }

      // Find the highest price
      const maxPrice = Math.max(...filteredWorkshops.map(fruit => fruit.price))

      // Update the haveReasonField
      filteredWorkshops = filteredWorkshops.map(fworkshop => ({
        ...fworkshop,
        haveReasonField: fworkshop.price === 0 || checkReasonFieldByRates(fworkshop.price, maxPrice, filteredWorkshops.filter(item => item.price !== 0)),
      }));

      // Sort by price
      filteredWorkshops = [...filteredWorkshops].sort((a, b) => {
        if (a.price === 0) return 1;
        if (b.price === 0) return -1;
        return a.price - b.price;
      });

      setWorkshopArray(workshopArrayValue)
      setSortedWorkshops(filteredWorkshops)
    }
  
  }, [watcher?.service_type, watcher?.collection_state, watcher?.service_mileage, watcher?.collection_date, watcher?.collection_time, watcher?.brand, watcher?.vmodel, watcher?.workshop, watcher?.year, watcher?.vehicle_category])
  
  return (
    <div className="flex flex-col h-full transition-all duration-300 ease-in" id="calendar-schedule">
      {watcher && watcher?.service_type == 1 ? 
        <>
        {watcher.service_mileage && watcher.collection_date && watcher.collection_time && watcher.collection_state && watcher?.vehicle_category ? 
          <>
            {selectedWorkshopValue === "" ? 
              <div className="pt-3">
                {calendarRows.filter(cr => workshopArray.includes(cr.workshop_id)).length > 0 ? 
                  <Fragment>
                    <div className="pb-1">
                      <div className="font-bold">Workshops ({watcher.collection_state})</div>
                    </div>
                    {sortedWorkshops.length > 0 && 
                      <div className="pb-1 font-bold text-[#FF0000]">Service Steering is applied as per rates of maintenance ({watcher?.service_mileage} service)</div>
                    }
                    {sortedWorkshops.map((item, index) => {
                      return (
                          <Fragment key={index}>
                            <div key={index} className={`accordion-item border-b mb-3 ${hideAccordion(calendarRows.filter(subItem => subItem.workshop_id === item.workshopId))}`}>
                              <div
                                  className="accordion-item-title bg-gray-200 py-2 px-4 flex justify-between items-center cursor-pointer font-bold rounded"
                                  onClick={() => handleWorkShopClick(index)}
                              >
                                  <span className="text-sm">
                                    {item.workshopName}
                                    &nbsp;-&nbsp;
                                    RC({calendarRows.filter(subItem => subItem.workshop_id === item.id).length})
                                    &nbsp;
                                    {item.price === 0 ? 
                                      <>No Rate for {watcher?.vehicle_category}</>
                                    :
                                      <>RATE({item.price})</>
                                    }
                                  </span>
                                  {openWorkShopIndex === index ? <i className="fa-solid fa-chevron-up"/> : <i className="fa-solid fa-chevron-down"/>}
                              </div>
                              <div className={`accordion-item-content mt-[-25px] mx-3 h-[100%] opacity-100 ${openWorkShopIndex === index ? 'block' : 'hidden'}`} style={{ transition: 'max-height 0.3s ease, opacity 0.3s ease' }}>
                                <GanttWithPrice
                                  fromDate={calendarFrom}
                                  toDate={calendarTo}
                                  rowData={calendarRows.filter(subItem => subItem.workshop_id === item.id)}
                                  itemRowData={calendarItems}
                                  className={className}
                                  onSelection={onSelection}
                                  selection={choosenWorkshopId !== item.id ? false : selection}
                                  selectedWorkshop={item.workshopName}
                                  setChoosenWorkshopId={setChoosenWorkshopId}
                                  watcher={watcher}
                                  setValue={setValue}
                                  selectedRCRate={item.price}
                                  setShowReasonField={setShowReasonField}
                                  haveReasonField={item.haveReasonField}
                                  count={index}
                                />
                              </div>
                            </div>
                          </Fragment>
                        )
                      })
                    }
                  </Fragment>
                : 
                  <Fragment>
                        <div className="pt-1 font-bold text-[#FF0000]">
                          All workshops do not have RCs but have Service Steering rates. Tick the checkbox below to display all RCs without service steering!
                        </div>
                        <div className="pt-2">
                          <CheckBox setValue={setDiplayAllRCWithoutSteering} title="Display all RC without steering" />
                        </div>
                        {diplayAllRCWithoutSteering ? 
                          <Gantt
                            fromDate={calendarFrom}
                            toDate={calendarTo}
                            rowData={oldCalendarRows}
                            itemRowData={calendarItems}
                            className={className}
                            onSelection={onSelection}
                            selection={selection}
                            selectedWorkshop={selectedWorkshopValue}
                            setChoosenWorkshopId={setChoosenWorkshopId}
                            count={0}
                          />
                        :
                          <Fragment>
                            {sortedWorkshops.map((item, index) => {
                              return (
                                  <Fragment key={index}>
                                    <div key={index} className={`accordion-item border-b mt-3 mb-3 ${hideAccordion(calendarRows.filter(subItem => subItem.workshop_id === item.workshopId))}`}>
                                      <div
                                          className="accordion-item-title bg-gray-200 py-2 px-4 flex justify-between items-center cursor-pointer font-bold rounded"
                                          onClick={() => handleWorkShopClick(index)}
                                      >
                                          <span className="text-sm">
                                            {item.workshopName}
                                            &nbsp;-&nbsp;
                                            RC({calendarRows.filter(subItem => subItem.workshop_id === item.id).length})
                                            &nbsp;
                                            {item.price === 0 ? 
                                              <>No Rate for {watcher?.vehicle_category}</>
                                            :
                                              <>RATE({item.price})</>
                                            }
                                          </span>
                                          {openWorkShopIndex === index ? <i className="fa-solid fa-chevron-up"/> : <i className="fa-solid fa-chevron-down"/>}
                                      </div>
                                      <div className={`accordion-item-content mt-[-25px] mx-3 h-[100%] opacity-100 ${openWorkShopIndex === index ? 'block' : 'hidden'}`} style={{ transition: 'max-height 0.3s ease, opacity 0.3s ease' }}>
                                        <GanttWithPrice
                                          fromDate={calendarFrom}
                                          toDate={calendarTo}
                                          rowData={calendarRows.filter(subItem => subItem.workshop_id === item.id)}
                                          itemRowData={calendarItems}
                                          className={className}
                                          onSelection={onSelection}
                                          selection={choosenWorkshopId !== item.id ? false : selection}
                                          selectedWorkshop={item.workshopName}
                                          setChoosenWorkshopId={setChoosenWorkshopId}
                                          watcher={watcher}
                                          setValue={setValue}
                                          selectedRCRate={item.price}
                                          setShowReasonField={setShowReasonField}
                                          haveReasonField={item.haveReasonField}
                                          count={index}
                                        />
                                      </div>
                                    </div>
                                  </Fragment>
                                )
                              })
                            }
                          </Fragment>
                        }
                  </Fragment>
                }
              </div>
            : 
              <Fragment>
                <div>
                  {calendarRows.filter(cr => cr.workshop_id == selectedWorkshopValue).length > 0 ? 
                    <Fragment>
                      <div className="pt-2 font-bold text-[#FF0000]">Service Steering is applied as per rates of maintenance ({watcher?.service_mileage} service)</div>
                      <div className={`accordion-item border-b mb-3 pt-3 ${hideAccordion(calendarRows)}`}>
                        <div
                            className="accordion-item-title bg-gray-200 py-2 px-4 flex justify-between items-center cursor-pointer font-bold rounded"
                        >
                          <span className="text-sm">
                            {sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.workshopName} 
                            &nbsp;-&nbsp;
                            RC({calendarRows.length})
                            &nbsp;
                            {sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price === 0 ? 
                              <>No Rate for {watcher?.vehicle_category}</>
                            :
                              <>RATE({sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price})</>
                            }
                          </span>
                        </div>
                        <div className={`accordion-item-content mt-[-25px] mx-3 h-[100%] opacity-100`} style={{ transition: 'max-height 0.3s ease, opacity 0.3s ease' }}>
                          <GanttWithPrice
                            fromDate={calendarFrom}
                            toDate={calendarTo}
                            rowData={calendarRows}
                            itemRowData={calendarItems}
                            className={className}
                            onSelection={onSelection}
                            selection={selection}
                            selectedWorkshop={selectedWorkshopValue}
                            setChoosenWorkshopId={setChoosenWorkshopId}
                            watcher={watcher}
                            setValue={setValue}
                            selectedRCRate={sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price}
                            setShowReasonField={setShowReasonField}
                            haveReasonField={true}
                            count={0}
                          />
                        </div>
                      </div>
                    </Fragment>
                  : 
                      <Fragment>
                        <div className="pt-1 font-bold text-[#FF0000]">
                          All workshops do not have RCs but have Service Steering rates. Tick the checkbox below to display all RCs without service steering!
                        </div>
                        <div className="pt-2">
                          <CheckBox setValue={setDiplayAllRCWithoutSteering} title="Display all RC without steering" />
                        </div>
                        {diplayAllRCWithoutSteering ? 
                          <Gantt
                            fromDate={calendarFrom}
                            toDate={calendarTo}
                            rowData={oldCalendarRows}
                            itemRowData={calendarItems}
                            className={className}
                            onSelection={onSelection}
                            selection={selection}
                            selectedWorkshop={selectedWorkshopValue}
                            setChoosenWorkshopId={setChoosenWorkshopId}
                            count={0}
                          />
                        : 
                          <Fragment>
                            <div className={`accordion-item border-b mb-3 pt-3 ${hideAccordion(calendarRows)}`}>
                              <div
                                  className="accordion-item-title bg-gray-200 py-2 px-4 flex justify-between items-center cursor-pointer font-bold rounded"
                              >
                                <span className="text-sm">
                                  {sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.workshopName} 
                                  &nbsp;-&nbsp;
                                  RC({calendarRows.length})
                                  &nbsp;
                                  {sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price === 0 ? 
                                    <>No Rate for {watcher?.vehicle_category}</>
                                  :
                                    <>RATE({sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price})</>
                                  }
                                </span>
                              </div>
                              <div className={`accordion-item-content mt-[-25px] mx-3 h-[100%] opacity-100`} style={{ transition: 'max-height 0.3s ease, opacity 0.3s ease' }}>
                                <GanttWithPrice
                                  fromDate={calendarFrom}
                                  toDate={calendarTo}
                                  rowData={calendarRows}
                                  itemRowData={calendarItems}
                                  className={className}
                                  onSelection={onSelection}
                                  selection={selection}
                                  selectedWorkshop={selectedWorkshopValue}
                                  setChoosenWorkshopId={setChoosenWorkshopId}
                                  watcher={watcher}
                                  setValue={setValue}
                                  selectedRCRate={sortedWorkshops?.find(item => item.id == selectedWorkshopValue)?.price}
                                  setShowReasonField={setShowReasonField}
                                  haveReasonField={true}
                                  count={0}
                                />
                              </div>
                            </div>
                          </Fragment>
                        }
                      </Fragment>
                  }
                </div>
              </Fragment>
            }
          </>
        :
        <Fragment>
          {selectedVehicleCategory?.filter(vc => watcher?.service_mileage ? checkServiceMileage(vc.service_mileage, watcher?.service_mileage) : true).length === 0 ? 
            <Fragment>
              {oldCalendarRows.length > 0 ? 
                <Fragment>
                  <div className="pt-1 font-bold text-[#FF0000]">
                    All workshops have RCs but do not have rates. Tick the checkbox below to display all RCs without service steering!
                  </div>
                  <div className="pt-2">
                    <CheckBox setValue={setDiplayAllRCWithoutSteering} title="Display all RC without steering" />
                  </div>
                  {diplayAllRCWithoutSteering && 
                    <Gantt
                      fromDate={calendarFrom}
                      toDate={calendarTo}
                      rowData={oldCalendarRows}
                      itemRowData={calendarItems}
                      className={className}
                      onSelection={onSelection}
                      selection={selection}
                      selectedWorkshop={selectedWorkshopValue}
                      setChoosenWorkshopId={setChoosenWorkshopId}
                      count={0}
                    />
                  }
                </Fragment>
              :
                <div className="pt-1 font-bold text-[#FF0000]">
                  No replacement Car Available
                </div>
              }
            </Fragment>
          : 
            <div className="py-3 font-bold text-[#FF0000]">
              Please choose Service Mileage, Vehicle Category, Collection Date, Time and Location to view RC Scheduling
            </div>
          }
        </Fragment>
        }
          {showReasonField && 
            <div className="w-full form-control">
              <Input
                register={register}
                name="rc_selection_reason"
                label="Provide a reason for not choosing the workshop with the lowest service steering rate"
                placeholder="Reason"
                type="text"
                condition={{ required: true }}
                error={errors}
                message="Reason Required"
              />
            </div>
          }
        </>
      :
        <>
          {oldCalendarRows.length > 0 ? 
            <>
              <Gantt
                fromDate={calendarFrom}
                toDate={calendarTo}
                rowData={oldCalendarRows}
                itemRowData={calendarItems}
                className={className}
                onSelection={onSelection}
                selection={selection}
                selectedWorkshop={selectedWorkshopValue}
                setChoosenWorkshopId={setChoosenWorkshopId}
                count={0}
              />
            </>
          :
            <div className="pt-3 font-bold text-[#FF0000]">
              No replacement Car Available
            </div>
          }
        </>
      }
    </div>
  )
}

export default Calendar

const CheckBox = ({ title = "Title", setValue }) => {
  const [checked, setChecked] = useState(false)

  const handleCheck = () => {
    setChecked(!checked)
  }

  useEffect(() => setValue(checked), [checked])
  return (
    <div className="flex items-center ml-3" onClick={handleCheck}>
      <div className="flex items-center justify-center w-4 h-4 rounded ring ring-orange-600">
        {checked && <i className="mt-1 text-sm fa-solid fa-check"></i>}
      </div>
      <div className="flex ml-4 whitespace-nowrap">{title}</div>
    </div>
  )
}
