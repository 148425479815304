import React, { useState } from 'react'
import { toast } from "react-toastify"

const ServiceReportOrderByForm = ({ selectedData, setSelectedData, setIsModalSortVisible }) => {

    const [isFormSaving, setIsFormSaving] = useState(false)
    // Get all previous filters  
    const prevValues = []
    let prevValuesCount = 1
    for (const key in selectedData) {
        if (['ASC', 'DESC'].includes(selectedData[key].sort)) {
            prevValues.push({seq: prevValuesCount, field: selectedData[key].id.replace("Service Request Api Data.", ""), order: selectedData[key].sort})
            prevValuesCount++
        }
    }
    const [fields, setFields] = useState(prevValues.length > 0 ? prevValues : [{ seq: 1, field: "", order: "" }]);

    const handleAddFieldSet = () => {
        setFields([...fields, { seq: fields.length + 1, field: "", order: "" }])
    };
    
    const handleRemoveAllSave = () => {

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Saving here...
        // setSelectedData(sortChanges)
        // setIsModalSortVisible(false)
        toast.success("Successfully Saved.")
    }

    return (
        <div>
            <div className="pt-3 pb-7">
                <form onSubmit={handleSubmit}>
                    <div className="flex justify-end">
                        <button type="button" className="px-12 btn mr-3" onClick={() => handleRemoveAllSave()}>
                        Remove all and Save
                        </button>
                        <button type="submit" className="px-12 btn btn-success">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ServiceReportOrderByForm;