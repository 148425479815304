import React, { useMemo, useState } from "react"
import StatusCard from "../components/dashboard/StatusCard"

import PieChartComponent from "../components/dashboard/PieChartComponent"
import Card from "../components/Card"
import BarChartComponent from "../components/dashboard/BarChartComponent"
import AnimatedPage from "../components/AnimatedPage"
import Map from "../components/map/Map"
import { useStore, useStoreAlerts, useStoreSchedule, useStoreServices, useStoreServiceMileages } from "../zustand/store"
import { AlertsItem } from "../components/nav/AlertItem"
import ModalUni from "../components/modals/ModalUni"
import Tickets from "../components/dashboard/Tickets"
import { Link } from "react-router-dom"
import AlertsModal from "../components/dashboard/AlertsModal"
import DeliverWarning from "../components/dashboard/DeliverWarning"
import axios from "axios"
import { apiUrl, config } from "../library/constant"
import { useEffect } from "react"

function Dashboard() {
  const loggedUser = useStore((state) => state.loggedUser)
  const workshop = useStore((state) => state.workshop)
  const setServiceMileages = useStoreServiceMileages((state) => state.setServiceMileages)
  const userType = useStore((state) => state.userType)
  const alerts = useStoreAlerts((state) => state.alerts)
  const [isModalTicketsVisible, setIsModalTicketsVisible] = useState(false)
  const [isModalAlertsVisible, setIsModalAlertsVisible] = useState(false)
  const [viewWhat, setViewWhat] = useState("")
  const [deliverablesModal, setDeliverablesModal] = useState(false)
  const { schedules } = useStoreSchedule()
  const [workshopServices, setWorkshopServices] = useState([])
  const [modalTitle, setModalTitle] = useState("")

  const viewTickets = (second) => {
    setIsModalTicketsVisible(true)
    setViewWhat(second)
  }

  const services = useStoreServices((s) => s.services)

  useEffect(() => {
    ;(async () => {
      if (userType === 3 || userType === 4) {
        const res = await axios.get(apiUrl + `/services/workshop/${workshop}`, config())
        const services = res.data ?? []
        setWorkshopServices(services)
      }
    })()
  }, [userType, workshop])

  const filteredServices = useMemo(() => {
    const types = {
      new: 0,
      progress: 0,
      completed: 0,
      rejected: 0,
      all: 0,
      driver_todo: 0,
    }

    if (!services || services.length === 0) return types

    let newServices = services

    if (userType === 3 || userType === 4) {
      newServices = workshopServices
    }

    types.new = newServices.filter((item) => item.status_types === 1 && item).length
    types.progress = newServices.filter(
      (item) => (item.status_types === 2 || item.status_types === 5 || item.status_types === 6) && item
    ).length
    types.completed = newServices.filter(
      (item) => (item.status_types === 3 || item.status_types === 4) && item.status_sub_types !== 13 && item
    ).length
    types.rejected = newServices.filter((item) => item.status_sub_types === 13 && item).length

    let xxxServices = newServices.filter((item) => item.status_types !== 4 && item)
    if (userType !== 2) {
      xxxServices = xxxServices.filter((item) => item.status_sub_types !== 1)
    }
    // xxxServices = xxxServices.filter((item) => item.status_types !== 3 && item)
    // xxxServices = xxxServices.filter((item) => item.status_sub_types !== 7 && item)
    xxxServices = xxxServices.filter((obj) => !(obj.status_types === 1 && obj.assignedTo))
    xxxServices = xxxServices.filter((item) => item.status_sub_types !== 11)
    types.all = xxxServices.length

    types.driver_todo = newServices.filter(
      (item) =>
        (item.status_types === 1 ||
          item.status_sub_types === 9 ||
          item.status_sub_types === 10 ||
          item.status_sub_types === 15 ||
          item.status_sub_types === 17) &&
        item.assignedTo === loggedUser?.id &&
        item
    ).length

    return types
  }, [services, userType, workshopServices])

  const filteredSchedules = useMemo(() => {
    const removed10to11statuses = schedules.filter((item) => {
      if (userType === 4) {
        if (item?.service_data?.status_sub_types <= 9 && item.assignedTo === loggedUser?.id) {
          return item
        }
      } else {
        if (item?.service_data?.status_sub_types <= 9) {
          return item
        }
      }
    })

    const newSchedules = []

    for (let index = 0; index < removed10to11statuses.length; index++) {
      const schedule = removed10to11statuses[index]
      const sched_end_date = new Date(schedule.end)
      const now = new Date()
      if (sched_end_date <= now) {
        if (
          sched_end_date.getFullYear() === now.getFullYear() &&
          sched_end_date.getMonth() === now.getMonth() &&
          sched_end_date.getDate() === now.getDate()
        ) {
          console.log(schedule)

          const tomorrow = new Date(now)
          tomorrow.setDate(now.getDate() + 1)

          const schedule1 = schedule
          const schedule1_car = schedule1.replacement_car

          for (let index1 = 0; index1 < removed10to11statuses.length; index1++) {
            const schedule2 = removed10to11statuses[index1]
            const sched_start_date2 = new Date(schedule2.start)

            if (
              sched_start_date2.getFullYear() === tomorrow.getFullYear() &&
              sched_start_date2.getMonth() === tomorrow.getMonth() &&
              sched_start_date2.getDate() === tomorrow.getDate() &&
              schedule1_car === schedule2.replacement_car
            ) {
              if (userType === 4 || userType === 3) {
                if (schedule1?.service_data?.workshop === workshop) {
                  newSchedules.push({ date: new Date(schedule2.end), data: [schedule1, schedule2] })
                }
              } else {
                newSchedules.push({ date: new Date(schedule2.end), data: [schedule1, schedule2] })
              }
            }
          }
        } else {
          if (userType === 4 || userType === 3) {
            if (schedule?.service_data?.workshop === workshop) {
              newSchedules.push({ date: new Date(schedule.end), data: [schedule] })
            }
          } else {
            newSchedules.push({ date: new Date(schedule.end), data: [schedule] })
          }
        }
      } else {
        // newSchedules.push([schedule])
      }
    }
    const schedSorted = newSchedules.sort((a, b) => b.date - a.date).length

    const xServices = services
    const currentTime = new Date()
    const filteredArrayNewRequest = xServices.filter((obj) => {
      if (userType === 4) {
        if (obj.assignedTo === loggedUser?.id && obj.workshop === workshop) {
          const objDate = new Date(obj.updated_date)
          const timeDifference = currentTime.getTime() - objDate.getTime()
          const minutesDifference = Math.floor(timeDifference / (1000 * 60))
          if (minutesDifference > 30 && obj.status_types === 1) {
            return obj
          }
        }
      } else {
        const objDate = new Date(obj.updated_date)
        const timeDifference = currentTime.getTime() - objDate.getTime()
        const minutesDifference = Math.floor(timeDifference / (1000 * 60))
        if (minutesDifference > 30 && (obj.status_types === 1 || obj.status_sub_types === 10)) {
          return obj
        }
      }
    }).length

    return schedSorted + filteredArrayNewRequest // + filteredArrayOutDelivery
  }, [])

  return (
    <AnimatedPage withBanner={false}>
      <ModalUni isVisible={isModalTicketsVisible} setIsVisible={setIsModalTicketsVisible} size={7} title={modalTitle}>
        <Tickets viewWhat={viewWhat} />
      </ModalUni>

      <ModalUni
        isVisible={deliverablesModal}
        setIsVisible={setDeliverablesModal}
        size={7}
        title="Service Request Warnings"
      >
        <DeliverWarning />
      </ModalUni>

      <ModalUni isVisible={isModalAlertsVisible} setIsVisible={setIsModalAlertsVisible} size={5} title="Alerts">
        <AlertsModal />
      </ModalUni>
      <div className="mb-2 text-2xl font-black text-gray-800 md:text-4xl ">Dashboard</div>
      <div className="flex flex-col">
        {userType !== 4 ? (
          <>
            <div className="grid grid-flow-row grid-cols-1 gap-6 md:grid-cols-3">
              <div
                onClick={() => {
                  viewTickets("new")
                  setModalTitle("New Service Request")
                }}
              >
                <StatusCard
                  icon="cart-plus"
                  count={filteredServices.new}
                  className="border-[#00B1AF]"
                  name="New Service Request"
                />
              </div>

              <div
                onClick={() => {
                  viewTickets("progress")
                  setModalTitle("In Progress")
                }}
              >
                <StatusCard
                  icon="thumbs-up"
                  count={filteredServices.progress}
                  className="border-[#F06400]"
                  name="In Progress"
                />
              </div>

              <div
                onClick={() => {
                  viewTickets("completed")
                  setModalTitle("Completed")
                }}
              >
                <StatusCard
                  icon="truck-fast"
                  count={filteredServices.completed}
                  className="border-[#545656]"
                  name="Completed"
                />
              </div>
            </div>
            <div className="grid grid-flow-row grid-cols-1 gap-6 mt-6 md:grid-cols-3">
              <div
                onClick={() => {
                  viewTickets("all")
                  setModalTitle("Todo")
                }}
              >
                <StatusCard icon="circle-check" count={filteredServices.all} className="border-[#009A17]" name="Todo" />
              </div>

              <div onClick={() => setDeliverablesModal(true)}>
                <StatusCard
                  icon="truck-clock"
                  count={filteredSchedules}
                  className="border-[#db240c]"
                  name="Service Request Warnings"
                />
              </div>

              <div onClick={() => viewTickets("rejected")}>
                <StatusCard
                  icon="circle-check"
                  count={filteredServices.rejected}
                  className="border-red-600"
                  name="Rejected Services"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="grid grid-flow-row grid-cols-1 gap-6 mt-6 md:grid-cols-2">
            <div onClick={() => setDeliverablesModal(true)}>
              <StatusCard
                icon="truck-clock"
                count={filteredSchedules}
                className="border-[#db240c]"
                name="Service Request Warnings"
              />
            </div>

            <div
              onClick={() => {
                viewTickets("driver_todo")
                setModalTitle("Todo")
              }}
            >
              <StatusCard
                icon="circle-check"
                count={filteredServices.driver_todo}
                className="border-[#009A17]"
                name="Todo"
              />
            </div>
          </div>
        )}

        <div className="w-full mt-6 overflow-hidden shadow-md min-w-[100px] h-96 rounded-xl">
          <Map x={"workshop"} />
        </div>
        <div className="grid grid-flow-row grid-cols-1 gap-6 mt-6 md:grid-cols-2 lg:grid-cols-3">
          <Card className="p-4 overflow-hidden">
            <div className="flex items-start justify-between">
              <div className="text-xl">Service Request Overview</div>
              <Link to="/service_request">
                <div className="h-full mt-2 text-xs text-orange-600 md:text-base whitespace-nowrap">See All</div>
              </Link>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="overflow-hidden h-52 w-60">
                <PieChartComponent data={filteredServices} />
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-end w-full pb-3 mb-3 border-b border-gray-300">
                  <div className="mr-2 text-xl font-bold text-gray-800 lg:text-4xl">
                    {filteredServices.new +
                      filteredServices.progress +
                      filteredServices.completed +
                      filteredServices.all}
                  </div>
                  <div className="text-sm text-gray-400 lg:text-xl">Total Request</div>
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#00B1AF] rounded-full" />
                    {filteredServices.new} New Request
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#F06400] rounded-full" />
                    {filteredServices.progress} In Progress
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#db240c] rounded-full" />
                    {filteredServices.completed} Completed
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#009A17] rounded-full" />
                    {filteredServices.all} Todos
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="p-4 overflow-hidden">
            <div className="flex items-start justify-between">
              <div className="text-xl">Replacement Car Utilization</div>
              <Link to="/replacement_cars/view">
                <div className="h-full mt-2 text-xs text-orange-600 md:text-base whitespace-nowrap">See All</div>
              </Link>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="pt-4 mr-6 h-52 w-60">
                <BarChartComponent />
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-end w-full pb-3 mb-3 border-b border-gray-300">
                  <div className="mr-2 text-xl font-bold text-gray-800 lg:text-4xl">
                    {filteredServices.new +
                      filteredServices.progress +
                      filteredServices.completed +
                      filteredServices.all}
                  </div>
                  <div className="text-sm text-gray-400 lg:text-xl">Total Request</div>
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#004a5d] rounded-full" />
                    New Request
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#00b1af] rounded-full" />
                    Pending
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#545656] rounded-full" />
                    For Delivery
                  </div>
                  <div className="flex items-center">
                    <div className="w-2 h-2 mr-2 bg-[#009a17] rounded-full" />
                    Complete
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="p-4 overflow-hidden ">
            <div className="flex items-start justify-between">
              <div className="text-xl">Notifications</div>
              <a
                className="h-full mt-2 text-xs text-orange-600 md:text-base whitespace-nowrap"
                href="#"
                onClick={() => setIsModalAlertsVisible(true)}
              >
                See All
              </a>
            </div>
            <div className="h-[202px] overflow-y-auto">
              {alerts && alerts?.map((alert, index) => <AlertsItem key={index} item={alert} />)}
            </div>
          </Card>
        </div>
      </div>
    </AnimatedPage>
  )
}

export default Dashboard
