import GSTC from "gantt-schedule-timeline-calendar"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useStore, useStoreCalendar, useStoreSchedule } from "../../zustand/store"
import Select from "../inputs/Select"
import DateCalc from "date-calc"
import dayjs from "dayjs"

export const DateFilter = ({ cdate = null }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm()

  const [years, setYears] = useState(null)
  const [months, setMonths] = useState(null)
  const calendar = useStore((s) => s.calendar)
  const setSelectedFrom = useStoreSchedule((s) => s.setSelectedFrom)
  const setSelectedTo = useStoreSchedule((s) => s.setSelectedTo)
  const setSelectedId = useStoreSchedule((s) => s.setSelectedId)

  const { setCalendarFrom, setCalendarTo } = useStoreCalendar()

  useEffect(() => {
    const years = []
    const months = []
    for (let i = 2015; i <= 2024; i++) {
      years.push({ id: i, name: i })
    }
    setYears(years)

    // set months name with name and id
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    for (let i = 0; i <= 11; i++) {
      months.push({ id: i + 1, name: monthNames[i] })
    }
    setMonths(months)
  }, [])

  useEffect(() => {
    // use current date to set default value for year and month
    if (years && months) {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      setValue("year", year)
      setValue("month", month)
      setValue("days_filter", "4")
    }
  }, [setValue, years, months])

  const watchAllFields = watch()

  useEffect(() => {
    if (watchAllFields.year && watchAllFields.month && watchAllFields.days_filter) {
      if (watchAllFields.isNow) {
        let d = new DateCalc()
        // if (cdate) {
        //   d.now(dayjs(cdate).format("YYYY MM DD"))
        // }

        let numberOfDays = Number(watchAllFields.days_filter)
        let front = Math.round((1 / 3) * numberOfDays)
        let back = Math.round((2 / 3) * numberOfDays)

        // if (cdate) {
        //   front = 0
        //   back = numberOfDays
        // }

        let startDate
        // if (cdate) {
        //   startDate = d.now()
        // } else {
        startDate = d.before(front)
        // }
        const endDate = d.after(back)

        const startyear = parseInt(startDate.toString().substr(0, 4))
        const startmonth = parseInt(startDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
        const startday = parseInt(startDate.toString().substr(6, 2))
        const startDateParsed = new Date(startyear, startmonth, startday)

        const endyear = parseInt(endDate.toString().substr(0, 4))
        const endmonth = parseInt(endDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
        const endday = parseInt(endDate.toString().substr(6, 2))
        const endDateParsed = new Date(endyear, endmonth, endday)

        setCalendarFrom(startDateParsed)
        setCalendarTo(endDateParsed)
        console.log(startDate.toString(), "start", startyear, startmonth, startday, cdate)
        console.log(endDate.toString(), "end", endyear, endmonth, endday, cdate)
      } else {
        let d = new DateCalc()

        d.now(`${watchAllFields.year}${String(watchAllFields.month).padStart(2, "0")}01`)

        let numberOfDays = Number(watchAllFields.days_filter)

        const startDate = d.now()
        const endDate = d.after(numberOfDays)

        const startyear = parseInt(startDate.toString().substr(0, 4))
        const startmonth = parseInt(startDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
        const startday = parseInt(startDate.toString().substr(6, 2))
        const startDateParsed = new Date(startyear, startmonth, startday)

        const endyear = parseInt(endDate.toString().substr(0, 4))
        const endmonth = parseInt(endDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
        const endday = parseInt(endDate.toString().substr(6, 2))
        const endDateParsed = new Date(endyear, endmonth, endday)

        setCalendarFrom(startDateParsed)
        setCalendarTo(endDateParsed)
      }
    }
  }, [watchAllFields.year, watchAllFields.month, watchAllFields.days_filter, watchAllFields.isNow])

  // useEffect(() => {
  //   let unsubscribe
  //   if (calendar) {
  //     unsubscribe = calendar.subscribe("config.plugin.Selection", (options) => {
  //       if (options.enabled) {
  //         const selected = options.selected["chart-timeline-grid-row-cell"]
  //         if (selected.length !== 0) {
  //           console.log(selected)
  //           const selectedRow = options?.targetData?.row?.id

  //           if (selectedRow) {
  //             const selectedId = Number(selected[0].split("-")[1])
  //             setSelectedId(selectedId)

  //             const selectedFrom = selected[0].substr(selectedRow.length + 1)
  //             const selectedTo = selected[selected.length - 1].substr(selectedRow.length + 1)

  //             const newDateFrom = selectedFrom.substr(0, selectedFrom.length - "T00-00-00".length)
  //             const newDateTo = selectedTo.substr(0, selectedTo.length - "T00-00-00".length)

  //             setSelectedFrom(newDateFrom)
  //             setSelectedTo(newDateTo)
  //             console.log(newDateFrom, newDateTo)
  //           }
  //         }
  //       } else {
  //         // ...
  //       }
  //     })
  //   }
  //   return () => {
  //     if (unsubscribe) {
  //       unsubscribe()
  //     }
  //   }
  // }, [calendar])

  return (
    <div className="grid grid-cols-3 gap-4">
      {/* <div>Date Filters</div> */}
      <div className="">
        <Select
          register={register}
          name="days_filter"
          label="Days"
          placeholder="Days Filter"
          type="text"
          items={[
            {
              id: 4,
              name: "3 Days",
            },
            {
              id: 7,
              name: "1 Week",
            },
            {
              id: 14,
              name: "2 Weeks",
            },
            {
              id: 31,
              name: "1 Month",
            },
          ]}
          selectKey="name"
        />

        <CheckBox setValue={setValue} title="Current Date" />
      </div>

      {!watchAllFields.isNow && (
        <>
          <Select
            register={register}
            name="year"
            label="Year"
            placeholder="Select Year"
            type="text"
            items={years}
            selectKey="name"
          />
          <Select
            register={register}
            name="month"
            label="Month"
            placeholder="Select Month"
            type="text"
            items={months}
            selectKey="name"
          />
        </>
      )}
    </div>
  )
}

const CheckBox = ({ title = "Title", setValue }) => {
  const [checked, setChecked] = useState(true)

  const handleCheck = () => {
    setChecked(!checked)
  }

  useEffect(() => setValue("isNow", checked), [checked])
  return (
    <div className="flex items-center ml-3" onClick={handleCheck}>
      <div className="flex items-center justify-center w-4 h-4 rounded ring ring-orange-600">
        {checked && <i className="mt-1 text-sm fa-solid fa-check"></i>}
      </div>
      <div className="flex ml-4 whitespace-nowrap">{title}</div>
    </div>
  )
}
