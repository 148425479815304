import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify"

import Loading from "../Loading"
import ServiceReportOrderByForm from "./ServiceReportOrderByForm"

const ServiceReportOrderBy = ({ setIsModalSortVisible, selectedData, setSelectedData }) => {
    
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div className="flex flex-col gap-4 mt-4">
            {isLoading ? (
                <div className="flex justify-center py-7">
                    <Loading />
                </div>
            ) : (
                <div>
                    <ServiceReportOrderByForm  
                        selectedData={selectedData} 
                        setSelectedData={setSelectedData}
                        setIsModalSortVisible={setIsModalSortVisible} 
                    />
                </div>
            )}
        </div>
    );
};

export default ServiceReportOrderBy;