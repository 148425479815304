import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useRef } from "react"
import { apiUrl } from "../../library/constant"

const SinglePicture = ({ setValue, keyValue, value }) => {
  const pictureRef = useRef(null)
  const [image, setImage] = useState(null)

  const handleUploadPicture = () => {
    pictureRef.current.click()
  }

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      //   setImage(URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append("files", event.target.files[0])
      const res = await axios.post(apiUrl + "/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setValue(keyValue, res.data.filenames[0])
      setImage(apiUrl + "/public/" + res.data.filenames[0])
    }
  }

  useEffect(() => {
    if (value) {
      setImage(apiUrl + "/public/" + value)
    }
  }, [value])

  return (
    <div>
      <div className="flex justify-center w-full mt-6 avatar">
        <div className="relative w-32 rounded-full ring ring-[#F06400] ring-offset-base-100 ring-offset-2">
          <div
            className="absolute top-0 z-50 flex items-center justify-center w-full h-full transition-all ease-in-out opacity-0 cursor-pointer hover:bg-black/50 hover:opacity-100"
            onClick={handleUploadPicture}
          >
            <div className="text-white">Upload</div>
            <input
              type="file"
              ref={pictureRef}
              className="hidden"
              onChange={onImageChange}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          {image ? (
            <img src={image} />
          ) : (
            <div className="flex items-center justify-center w-full h-full bg-[#F06400]">
              <i className="text-6xl text-gray-200 fa-solid fa-user-bounty-hunter"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SinglePicture
