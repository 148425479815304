import React, { useEffect, useMemo, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from "react-leaflet"
import ResetViewControl from "@20tab/react-leaflet-resetview"
import { HashLoader } from "react-spinners"
import Loading from "../Loading"
import { useStoreMap, useStoreServices, useStoreWorkshops } from "../../zustand/store"
import Picker from "./Picker"
import L from "leaflet"

const Map = ({ x, workshop }) => {
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setShowMap(true)
    }, 1000)
  }, [])

  const DEFAULT_VIEWPORT = {
    center: [24.3734394, 54.5303054],
    zoom: 12,
  }

  return (
    <>
      {showMap ? (
        <MapContainer
          center={DEFAULT_VIEWPORT.center}
          zoom={DEFAULT_VIEWPORT.zoom}
          style={{ height: "100%", width: "100%" }}
          // dragging={false}
          doubleClickZoom={true}
          scrollWheelZoom={false}
          // attributionControl={false}
          // zoomControl={false}
        >
          {x === "search" && <Picker />}
          <SetMap />
          <TileLayer
            // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            // url="https://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}" //"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            url="https://mt1.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            // url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/toner/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/outdoors/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=n5rsLmQeY1Q1jpfNYv5k"
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}"
            // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}."
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <ResetViewControl title="Reset view" icon="url(./resetsmall.png)" />
          {/* <Marker position={[24.3734394, 54.5303054]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker> */}
          {x === "workshop" && <WorkshopsMarkers />}
          <WorkshopsMarker />
        </MapContainer>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <Loading />
        </div>
      )}
    </>
  )
}

function SetMap() {
  const map = useMap()
  const setMap = useStoreMap((s) => s.setMap)

  useEffect(() => {
    if (map) {
      setMap(map)
    }
  }, [map])
  return null
}

export default Map

const WorkshopsMarkers = (second) => {
  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const workshops = useStoreWorkshops((s) => s.workshops)

  const map = useMap()

  useEffect(() => {
    if (hoverCoordinates) {
      map.flyTo(hoverCoordinates)
    }
  })

  return (
    workshops &&
    workshops?.map((workshop) => {
      // console.log(workshop)
      const latlng = JSON.parse(workshop.coordinates)
      return (
        <Marker
          key={workshop.id}
          position={[latlng.lat, latlng.lng]}
          icon={L.divIcon({
            iconAnchor: [20, 20], // Adjust the anchor to center the icon
            popupAnchor: [5, -20], // Adjust the popup anchor if needed
            className: "text-xl text-white",
            html: `
            <div class="flex justify-center items-center p-6 w-6 h-6 bg-[#F06400]/50 rounded-full">
              <i class="fa-solid fa-warehouse-full text-white"></i>
            </div>
            `,
          })}
        >
          <Popup>
            <Poppi workshop={workshop} />
          </Popup>
        </Marker>
      )
    })
  )
}

const Poppi = ({ workshop }) => {
  const services = useStoreServices((s) => s.services)

  const filteredServices = useMemo(() => {
    const types = {
      todo: 0,
      progress: 0,
      delivery: 0,
      completed: 0,
    }

    if (!services || services.length === 0) return types

    types.todo = services.filter((item) => item.status_types === 1 && workshop.id === item.workshop && item).length
    types.progress = services.filter((item) => item.status_types === 2 && workshop.id === item.workshop && item).length
    types.delivery = services.filter((item) => {
      if (
        (item.status_sub_types === 8 && workshop.id === item.workshop) ||
        (item.status_sub_types === 9 && workshop.id === item.workshop)
      ) {
        return item
      }
    }).length
    types.completed = services.filter((item) => {
      if (
        (item.status_types === 3 && workshop.id === item.workshop) ||
        (item.status_types === 4 && workshop.id === item.workshop)
      ) {
        return item
      }
    }).length
    return types
  }, [services, workshop])

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="text-2xl text-blue-500">
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <div className="ml-2">
            <div className="font-bold">{workshop.name}</div>
            <div className="text-xs text-gray-500">{workshop.address}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div>New SR: {filteredServices.todo}</div>
        <div>Wating For Approval: {filteredServices.progress}</div>
        <div>For Delivery: {filteredServices.delivery}</div>
        <div>Completed: {filteredServices.completed}</div>
      </div>
    </div>
  )
}

const WorkshopsMarker = () => {
  const selectedService = useStoreServices((s) => s.selectedService)
  if (!selectedService?.workshop_data) {
    return <></>
  }

  const workshop = selectedService.workshop_data
  const latlng = JSON.parse(workshop.coordinates)

  return (
    <Marker
      position={[latlng.lat, latlng.lng]}
      icon={L.divIcon({
        className: "text-xl text-white",
        html: `
    <div class="flex justify-center items-center p-6 w-6 h-6 bg-[#F06400] rounded-full">
      <i class="fa-solid fa-warehouse-full text-white"></i>
    </div>
    `,
      })}
    >
      <Popup>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="text-2xl text-blue-500">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="ml-2">
                <div className="font-bold">{workshop.name}</div>
                <div className="text-xs text-gray-500">{workshop.address}</div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </Marker>
  )
}
