import axios from "axios"
import React, { useEffect, useState } from "react"
import { apiUrl, config } from "../library/constant"
import {
  useStore,
  useStoreAlerts,
  useStoreDrivers,
  useStoreReplacementCars,
  useStoreSchedule,
  useStoreServices,
  useStoreSettings,
  useStoreTypes,
  useStoreUsers,
  useStoreWorkshops,
} from "../zustand/store"
import SideBar from "./nav/SideBar"
import TopNav from "./nav/TopNav"

import { io } from "socket.io-client"
import LoadingAll from "./LoadingAll"

const DashboardContainer = ({ children }) => {
  const [isFetching, setFetching] = useState(true)
  const [progress, setProgress] = useState(0)

  const setWorkshops = useStoreWorkshops((state) => state.setWorkshops)
  const setDrivers = useStoreDrivers((s) => s.setDrivers)
  const setReplacementCars = useStoreReplacementCars((s) => s.setReplacementCars)
  const setServices = useStoreServices((s) => s.setServices)

  const setCarsBrandTypes = useStoreTypes((s) => s.setCarsBrandTypes)
  const setCarsCategoryTypes = useStoreTypes((s) => s.setCarsCategoryTypes)
  const setCarsBodyTypes = useStoreTypes((s) => s.setCarsBodyTypes)
  const setEmirateTypes = useStoreTypes((s) => s.setEmirateTypes)
  const setAuhCategoryTypes = useStoreTypes((s) => s.setAuhCategoryTypes)
  const setStatusSubTypes = useStoreTypes((s) => s.setStatusSubTypes)
  const setStatusTypes = useStoreTypes((s) => s.setStatusTypes)
  const setUserType = useStoreTypes((s) => s.setUserType)
  const setServiceTypes = useStoreTypes((s) => s.setServiceTypes)
  const setUsers = useStoreUsers((s) => s.setUsers)

  const setSettings = useStoreSettings((s) => s.setSettings)

  const setRunningRequestNo = useStore((s) => s.setRunningRequestNo)
  const loggedUser = useStore((s) => s.loggedUser)
  const setAlerts = useStoreAlerts((s) => s.setAlerts)
  const setSchedules = useStoreSchedule((s) => s.setSchedules)

  const token = useStore((state) => state.token)
  const setToken = useStore((state) => state.setToken)

  useEffect(() => {
    const fetchData = async () => {
      const userType = loggedUser?.user_type
      const workshop = loggedUser?.driver_data?.workshop

      const services = await axios.get(apiUrl + "/services", config(token))
      setServices(services.data)
      setProgress(10)

      const workshops = await axios.get(apiUrl + "/workshops", config(token))
      if (userType === 1 || userType === 2) {
        setWorkshops(workshops.data)
      } else {
        const nWorkshop = workshops.data.filter((item) => item.id === workshop && item)
        setWorkshops(nWorkshop)
      }
      setProgress(20)

      const drivers = await axios.get(apiUrl + "/drivers", config(token))
      if (userType === 1 || userType === 2) {
        setDrivers(drivers.data)
      } else {
        const nDrivers = drivers.data.filter((item) => item?.workshop === workshop && item)
        setDrivers(nDrivers)
      }
      setProgress(25)

      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config(token))
      setReplacementCars(replacement_cars.data)
      setProgress(30)

      const users = await axios.get(apiUrl + "/users", config(token))
      if (userType === 1 || userType === 2) {
        setUsers(users.data)
      } else {
        const nUsers = users.data.filter((item) => item.driver_data?.workshop === workshop && item)
        setUsers(nUsers)
      }

      const user_type = await axios.get(apiUrl + "/user_type", config(token))
      setUserType(user_type.data)
      setProgress(35)

      const emirate_types = await axios.get(apiUrl + "/emirate_types", config(token))
      setEmirateTypes(emirate_types.data)
      setProgress(40)

      const auh_category_types = await axios.get(apiUrl + "/auh_category_types", config(token))
      setAuhCategoryTypes(auh_category_types.data)
      setProgress(50)

      const cars_brand_types = await axios.get(apiUrl + "/cars_brand_types", config(token))
      setCarsBrandTypes(cars_brand_types.data)
      setProgress(55)

      const cars_category_types = await axios.get(apiUrl + "/cars_category_types", config(token))
      setCarsCategoryTypes(cars_category_types.data)
      setProgress(60)

      const cars_body_types = await axios.get(apiUrl + "/cars_body_types", config(token))
      setCarsBodyTypes(cars_body_types.data)
      setProgress(65)

      const status_types = await axios.get(apiUrl + "/status_types", config(token))
      setStatusTypes(status_types.data)
      setProgress(70)

      const status_sub_types = await axios.get(apiUrl + "/status_sub_types", config(token))
      setStatusSubTypes(status_sub_types.data)
      setProgress(75)

      const service_types = await axios.get(apiUrl + "/service_types", config(token))
      setServiceTypes(service_types.data)
      setProgress(80)

      const replacement_cars_schedules = await axios.get(apiUrl + "/replacement_cars_schedules", config(token))
      setSchedules(replacement_cars_schedules.data)
      setProgress(85)

      const alerts = await axios.get(apiUrl + "/alerts", config(token))
      setAlerts(alerts.data)
      setProgress(95)

      // const gtsc_refresh = await axios.get(apiUrl + "/gtsc_refresh", config(token))
      // console.log(gtsc_refresh.data)

      const settings = await axios.get(apiUrl + "/settings", config(token))
      setSettings(settings.data)
      setProgress(100)

      setFetching(false)
    }

    if (token) {
      fetchData()
    }
  }, [token])

  useEffect(() => {
    const fetchData = async () => {
      const running_request_no = await axios.post(apiUrl + "/running_request_no", { id: loggedUser.id }, config(token))
      setRunningRequestNo(running_request_no.data.request_no)
    }

    if (!loggedUser.id) {
      localStorage.removeItem("token")
      setToken(null)
      window.location.href = "/"
    }

    if (token && loggedUser) {
      fetchData()
    }
  }, [loggedUser, token])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Your Axios request here
        if (!isFetching) {
          const services = await axios.get(apiUrl + "/services", config(token))
          setServices(services.data)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    const intervalId = setInterval(fetchData, 3000)
    return () => clearInterval(intervalId)
  }, [isFetching])

  if (isFetching) {
    return <LoadingAll progress={progress} />
  }

  return (
    <div className="flex w-full">
      <SideBar />
      <div className="flex flex-col">
        <TopNav />

        <div className="bg-gray-100/50 w-[calc(100vw-56px)] md:w-[calc(100vw-96px)] h-full overflow-hidden">
          <div className="h-[calc(100vh-80px)] max-h-full overflow-x-auto lg:overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardContainer
