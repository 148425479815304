import axios from "axios"
import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { HashLoader } from "react-spinners"
import { apiUrl, config } from "../../library/constant"
import { useStoreAlerts, useStoreReplacementCars, useStore } from "../../zustand/store"
import ModalUni from "../modals/ModalUni"
import { dayjsx } from "../../library/functions"
import { toast } from "react-toastify"

const Table = ({ setModalOpen, searchText, categoryFilter }) => {
  const userType = useStore((state) => state.userType)
  const workshop = useStore((state) => state.workshop)
  const resReplacement_cars = useStoreReplacementCars((s) => s.replacementCars)
  const [replacementCars, setReplacementCars] = useState([])

  const alerts = useStoreAlerts((state) => state.alerts)
  const setIsWhat = useStoreReplacementCars((s) => s.setIsWhat)
  const setSelectedReplacementCar = useStoreReplacementCars((s) => s.setSelectedReplacementCar)
  const deleteReplacementCars = useStoreReplacementCars((s) => s.deleteReplacementCars)
  const selectedReplacementCar = useStoreReplacementCars((s) => s.selectedReplacementCar)
  const updateReplacementCars = useStoreReplacementCars((s) => s.updateReplacementCars)

  const [modalDelete, setModalDelete] = React.useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const bodyRows = 10

  const handleEdit = (item) => {
    setIsWhat("update")
    setSelectedReplacementCar(item)
    setModalOpen(true)
  }

  // create a funtion to handle delete
  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(apiUrl + "/replacement_cars/" + selectedReplacementCar.id, config())
      deleteReplacementCars(selectedReplacementCar)
      toast.success("Successfully Deleted a RC.")
    } catch (error) {
      toast.error(`Its seems they're record connected to this RC and will not continue deleting this RC.`)
    }
    setModalDelete(false)
  }

  const handleStatus = async (id, status) => {
    try {
      const { data } = await axios.put(
        apiUrl + "/replacement_cars/status",
        {
          id: id,
          status: status,
        },
        config()
      )
      updateReplacementCars(data)
      toast.success(`Successfully ${status === 1 ? "Activating" : "Deactivating"} ${data.reg_no}`)
    } catch (error) {
      toast.error(`Error ${status === 1 ? "Activating" : "Deactivating"}`)
    }
  }

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  const openDeleteModal = (item) => {
    setSelectedReplacementCar(item)
    setModalDelete(true)
  }

  useEffect(() => {
    const fetchPagination = async () => {
      let categoryParam = categoryFilter ? `&category=${categoryFilter}` : ''
      if (userType === 3 || userType === 4) {
        const res = await axios.get(
          apiUrl + "/replacement_cars/count?search=" + searchText + categoryParam + "&workshop=" + workshop,
          config()
        )
        let pages = parseInt((res.data - 1) / bodyRows + 1)
        setCount(res.data)
        setTotalPages(pages)
      } else {
        const res = await axios.get(apiUrl + "/replacement_cars/count?search=" + searchText + categoryParam, config())
        let pages = parseInt((res.data - 1) / bodyRows + 1)
        setCount(res.data)
        setTotalPages(pages)
      }
    }
    fetchPagination()
  }, [alerts, resReplacement_cars, searchText, categoryFilter, userType, workshop])

  useEffect(() => {
    const fetchData = async () => {
      let categoryParam = categoryFilter ? `&category=${categoryFilter}` : ''
      if (userType === 3 || userType === 4) {
        const res = await axios.get(
          apiUrl +
            `/replacement_cars/workshop/${workshop}?page=` +
            currentPage +
            "&count=" +
            bodyRows +
            "&search=" +
            searchText +
            categoryParam,
          config()
        )
        setReplacementCars(res.data)
        setIsLoading(false)
      } else {
        const res = await axios.get(
          apiUrl + "/replacement_cars?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText + categoryParam,
          config()
        )
        setReplacementCars(res.data)
        setIsLoading(false)
      }
    }
    fetchData()
  }, [currentPage, alerts, resReplacement_cars, searchText, categoryFilter, userType, workshop])

  return (
    <div className="w-full mt-6">
      {/* <div className="w-full overflow-x-auto overflow-y-scroll max-h-96 scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100"> */}
      {/* <table className="w-full mt-6 overflow-hidden rounded-t-lg table-auto"></table> */}
      <ModalUni isVisible={modalDelete} setIsVisible={setModalDelete} title="Delete" size={2}>
        <div className="flex flex-col w-full mt-4">
          Are you sure you want to delete this item? <br />
          <div className="font-bold">{selectedReplacementCar?.reg_no}</div>
        </div>
        <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
          <div className="px-12 btn" onClick={() => setModalDelete(false)}>
            No
          </div>
          <button className="px-12 btn" onClick={() => handleDelete()}>
            Yes
          </button>
        </div>
      </ModalUni>
      {isLoading ? 
        <div className="mt-10">
          <center>
            <HashLoader color="#f97316" loading={true} size={80}/>
          </center>
        </div>
      :
        <>
          <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg ">
            <table className="w-full overflow-hidden bg-white rounded-t-lg table-auto whitespace-nowrap">
              <thead className="sticky top-0">
                <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
                  <th className="px-3 py-4 text-left">RC NUMBER</th>
                  <th className="px-3 py-4 text-left">PLATE NO..</th>
                  <th className="px-3 py-4 text-left">EMIRATE</th>
                  <th className="px-3 py-4 text-left">MAKE</th>
                  <th className="px-3 py-4 text-left">MODEL</th>
                  <th className="px-3 py-4 text-left">YEAR</th>
                  <th className="px-3 py-4 text-left">COLOR</th>
                  <th className="px-3 py-4 text-left">WORKSHOP</th>
                  <th className="px-3 py-4 text-left">WORKSHOP LOCATION</th>
                  <th className="px-3 py-4 text-left">CATEGORY</th>
                  <th className="px-3 py-4 text-left">LOCK</th>
                  <th className="px-3 py-4 text-left">DAILY_RATE</th>
                  <th className="px-3 py-4 text-left">ADDED</th>
                  <th className="px-3 py-4 text-left">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {replacementCars.map((item, key) => {
                  return (
                    <TableRow
                      key={key}
                      item={item}
                      setModalOpen={setModalOpen}
                      openDeleteModal={openDeleteModal}
                      handleEdit={() => handleEdit(item)}
                      handleStatus={handleStatus}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
            <div>
              Showing {replacementCars?.length ?? 0} of {count} total record
            </div>
            <div className="flex items-center mt-2 space-x-4">
              <div onClick={prevPage} className="cursor-pointer">
                Previous
              </div>
              <div>
                {currentPage}/{totalPages}
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                Next
              </div>
            </div>
          </div>
        </>
      }
      
    </div>
  )
}

export default Table

const TableRow = ({ item, setModalOpen, openDeleteModal, handleEdit, handleStatus }) => {

  const parsingJSON = (json) => {
    try {
      return JSON.parse(json)
    } catch (error) {
      return []
    }
  }

  return (
    <tr>
      <td className="py-4 pl-3">
        <div className="flex items-center">
          <div className={`w-3 h-3 mr-1 rounded-full ${item.status ? "bg-green-500" : "bg-red-500"}`}></div>
          <div>{item.reg_no}</div>
        </div>
      </td>
      <td className="py-4 pl-3">{item?.plate}</td>
      <td className="py-4 pl-3">{item?.emirate_data?.name}</td>
      <td className="py-4 pl-3">{item?.brand_data?.name}</td>
      <td className="py-4 pl-3">{item?.model}</td>
      <td className="py-4 pl-3">{item?.year}</td>
      <td className="py-4 pl-3">{item?.color}</td>
      <td className="py-4 pl-3">{item?.workshop_data?.name}</td>
      <td className="py-4 pl-3">{item?.workshop_data?.location}</td>
      <td className="py-4 pl-3">{item?.category_data?.name}</td>
      <td className="py-4 pl-3">{parsingJSON(item?.locked_to).length > 0 ? `Y` : ``}</td>
      <td className="py-4 pl-3">{item?.daily_rate}</td>
      <td className="py-4 pl-3">{dayjsx(item.added_date).format("MMM D, YYYY HH:mm")}</td>
      <td className="flex items-center py-4 pl-3 text-blue-400">
        <div onClick={handleEdit} className="mr-4 cursor-pointer">
          <i className="ml-1 fa-solid fa-pen-to-square" title="Edit"></i>
        </div>
        <div onClick={() => openDeleteModal(item)} className="mr-4 text-red-500 cursor-pointer">
          <i className="ml-1 fa-solid fa-trash-alt" title="Delete"></i>
        </div>
        {item.status ? (
          <div className="text-red-500 cursor-pointer" onClick={() => handleStatus(item.id, 0)}>
            <i className="ml-1 fa-solid fa-key" title="Deactivate"></i>
          </div>
        ) : (
          <div className="text-green-500 cursor-pointer" onClick={() => handleStatus(item.id, 1)}>
            <i className="ml-1 fa-solid fa-key" title="Activate"></i>
          </div>
        )}
      </td>
    </tr>
  )
}
