import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useEffect } from "react"
import ReactTooltip from "react-tooltip"
import { useStoreServices } from "../../zustand/store"

const Gantt = ({
  fromDate,
  toDate,
  rowData,
  itemRowData,
  headerRowHeight = 44,
  rowHeight = 80,
  headerRowWidth = 120,
  tableHeight = 700,
  onSelection,
  selection = false,
  className,
  selectedWorkshop,
  setChoosenWorkshopId,
  count
}) => {
  // const headerRowHeight = 44
  // const rowHeight = 80
  // const tableHeight = 700
  const datesFromTo = getDatesBetween(fromDate, toDate)

  const [firstDiv, setFirstDiv] = useState({ id: "", date: "", rowId: 0 })
  const [secondDiv, setSecondDiv] = useState({ id: "", date: "", rowId: 0 })

  const [position1, setPosition1] = useState({ x: 0, y: 0 })
  const [position2, setPosition2] = useState({ x: 0, y: 0, w: 0, h: 0 })

  const [container, setContainer] = useState({ x: 0, y: 0, sx: 0, sy: 0 })

  const [procItemRow, setProcItemRow] = useState([])

  const { selectedService } = useStoreServices()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [fromDate, toDate, rowData, itemRowData])

  useEffect(() => {
    if (firstDiv.id && secondDiv.id && onSelection && selection) {
      if (secondDiv.date < firstDiv.date) {
        onSelection({
          start: secondDiv.date,
          end: firstDiv.date,
          id: firstDiv.rowId,
          workshop: selectedWorkshop
        })
      } else {
        onSelection({
          start: firstDiv.date,
          end: secondDiv.date,
          id: firstDiv.rowId,
          workshop: selectedWorkshop
        })
      }
    }
  }, [firstDiv, secondDiv, onSelection])

  useEffect(() => {
    setPosition1({ x: 0, y: 0 })
    setPosition2({ x: 0, y: 0, w: 0, h: 0 })
  }, [firstDiv])

  useEffect(() => {
    if (firstDiv.id && secondDiv.id) {
      const firstDivElement = document.getElementById(firstDiv.id)
      const secordDivElement = document.getElementById(secondDiv.id)

      const containerElement = document.getElementById(`ejs-gantt-container-${count}`)
      const rect = containerElement?.getBoundingClientRect()
      setContainer({
        x: rect?.left ?? 0,
        y: rect?.top ?? 0,
        sx: containerElement?.scrollLeft ?? 0,
        sy: containerElement?.scrollTop ?? 0,
      })

      if (firstDivElement && secordDivElement) {
        if (secondDiv.date < firstDiv.date) {
          const { left: left1, top: top1 } = secordDivElement.getBoundingClientRect()
          const { left: left2, top: top2 } = firstDivElement.getBoundingClientRect()
          const width = firstDivElement.offsetWidth
          const height = firstDivElement.offsetHeight
          setPosition1({ x: left1, y: top2 })
          setPosition2({ x: left2, y: top1, w: width, h: height })
        } else {
          const { left: left1, top: top1 } = firstDivElement.getBoundingClientRect()
          const { left: left2, top: top2 } = secordDivElement.getBoundingClientRect()
          const width = secordDivElement.offsetWidth
          const height = secordDivElement.offsetHeight
          setPosition1({ x: left1, y: top1 })
          setPosition2({ x: left2, y: top2, w: width, h: height })
        }
      }
    }
    if (secondDiv.id === "") {
      setPosition2({ x: 0, y: 0, w: 0, h: 0 })
    }
  }, [secondDiv])

  useEffect(() => {
    if (itemRowData && itemRowData.length !== 0) {
      const containerElement = document.getElementById(`ejs-gantt-container-${count}`)
      const rect = containerElement?.getBoundingClientRect()

      const processed = itemRowData.map(({ id, row_id, start, end, item }, key) => {
        const startDate = dayjs(start).format("MMMDD")
        const firstDivId = `${row_id}-${startDate}`
        const endDate = dayjs(end).format("MMMDD")
        const secondDivId = `${row_id}-${endDate}`
        const firstDivElement = document.getElementById(firstDivId)
        const secordDivElement = document.getElementById(secondDivId)

        if (firstDivElement && secordDivElement) {
          const { left: left1, top: top1 } = firstDivElement.getBoundingClientRect()
          const { left: left2, top: top2 } = secordDivElement.getBoundingClientRect()
          const width = secordDivElement.offsetWidth
          const height = secordDivElement.offsetHeight
          return {
            id,
            item,
            pos1: { x: left1, y: top2 },
            pos2: { x: left2, y: top1, w: width, h: height },
            container: {
              x: rect?.left ?? 0,
              y: rect?.top ?? 0,
              sx: containerElement?.scrollLeft ?? 0,
              sy: containerElement?.scrollTop ?? 0,
            },
          }
        } else {
          return
        }
      })

      const filteredX = processed.filter((item) => item)
      setProcItemRow(filteredX)
    }
  }, [itemRowData, fromDate, toDate])

  function isCurrentDate(datetimeToCheck) {
    const currentDate = new Date()
    return (
      datetimeToCheck.getFullYear() === currentDate.getFullYear() &&
      datetimeToCheck.getMonth() === currentDate.getMonth() &&
      datetimeToCheck.getDate() === currentDate.getDate()
    )
  }

  function getDatesBetween(startDate, endDate) {
    const dates = []
    let currentDate = new Date(startDate)

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  return (
    <>
      <div
        className={`overflow-x-scroll mt-2 flex relative select-none border-t ${className}`}
        id={`ejs-gantt-container-${count}`}
        style={{ maxHeight: `${tableHeight}px` }}
      >
        {selection && secondDiv && (
          <>
            <div
              className={`absolute bg-orange-500 z-50 pointer-events-none rounded-lg flex justify-center items-center text-white`}
              style={{
                top: `${position1.y - container.y + container.sy}px`,
                left: `${position1.x - container.x + container.sx}px`,
                width: `${Math.abs(position2.x - position1.x + position2.w)}px`,
                height: `${rowHeight}px`,
              }}
            >
              {dayjs(firstDiv.date).format("MMM DD") === dayjs(secondDiv.date).format("MMM DD") ? (
                <>{dayjs(secondDiv.date).format("MMM DD")}</>
              ) : (
                <>
                  {secondDiv.date < firstDiv.date ? (
                    <>
                      {dayjs(secondDiv.date).format("MMM DD")} - {dayjs(firstDiv.date).format("MMM DD")}
                    </>
                  ) : (
                    <>
                      {dayjs(firstDiv.date).format("MMM DD")} - {dayjs(secondDiv.date).format("MMM DD")}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {procItemRow.map((item, key) => (
          <React.Fragment key={key}>
            <div
              data-tip={true}
              data-for={`${key}-gantt-tooltip`}
              className={`absolute ${
                selectedService?.replacement_car_schedule?.id?.toString() === item?.id?.toString()
                  ? "bg-orange-500"
                  : "bg-cyan-500"
              } ${
                selection ? "pointer-events-none" : ""
              } z-40 rounded-lg border-white border overflow-x-hidden overflow-y-hidden scrollbar-thin scrollbar-thumb-[#ffffff] scrollbar-track-gray-100`}
              style={{
                top: `${item.pos1.y - item.container.y + item.container.sy}px`,
                left: `${item.pos1.x - item.container.x + item.container.sx}px`,
                width: `${Math.abs(item.pos2.x - item.pos1.x + item.pos2.w)}px`,
                height: `${rowHeight}px`,
              }}
            >
              {item.item}
            </div>
            <ReactTooltip id={`${key}-gantt-tooltip`}>{item.item}</ReactTooltip>
          </React.Fragment>
        ))}

        <table className="relative">
          <thead>
            <tr className="">
              <th className="!sticky top-0 bg-white p-0 z-50">
                <div className="border px-4 py-2 whitespace-nowrap" style={{ height: `${headerRowHeight}px` }}>
                  Replacement Vehicles
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rowData?.map((item, key) => {
              return (
                <tr key={key} className="border" style={{ height: rowHeight }}>
                  <td className="" style={{ height: rowHeight }}>
                    <div className="h-full w-full overflow-y-hidden" style={{ height: rowHeight - 10 }}>
                      {item.itemWithWorkshop}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <table className="relative overflow-x-scroll">
          <thead>
            <tr className="">
              {datesFromTo.map((date, key) => {
                const formattedDate = dayjs(date).format("MMM DD")
                const isNow = isCurrentDate(date)
                return (
                  <th className="!sticky top-0 bg-white p-0 z-50" key={key}>
                    <div
                      className={`border px-4 py-2 whitespace-nowrap ${isNow ? "bg-gray-300" : ""}`}
                      style={{ height: `${headerRowHeight}px`, width: `${headerRowWidth}px` }}
                    >
                      {formattedDate}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rowData?.map((item, key) => {
              return (
                <tr key={key} className="border px-4 py-2">
                  {datesFromTo.map((date, defkey) => {
                    const formattedDate = dayjs(date).format("MMMDD")
                    const id = `${item.id}-${formattedDate}`
                    const isNow = isCurrentDate(date)
                    const params = {
                      isNow,
                      rowHeight,
                      headerRowWidth,
                      formattedDate,
                      id,
                      rowId: item.id,
                      setFirstDiv,
                      setSecondDiv,
                      date,
                      workshopId: item.workshop_id
                    }
                    return <TableData {...params} key={defkey} setChoosenWorkshopId={setChoosenWorkshopId} />
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const TableData = ({ isNow, rowHeight, formattedDate, id, rowId, setFirstDiv, setSecondDiv, date, headerRowWidth, workshopId, setChoosenWorkshopId }) => {
  const onMouseDown = (e) => {
    setFirstDiv({ id, date, rowId })
    setSecondDiv("")
  }

  const onMouseUp = (e) => {
    setSecondDiv({ id, date, rowId })
    setChoosenWorkshopId(workshopId);
    // alert(`ID:${id} DATE:${date} ROW_ID:${rowId}`);  
  }
  
  return (
    <td
      id={id}
      className={`border px-4 py-2 whitespace-nowrap hover:bg-gray-200 ${isNow ? "bg-gray-300" : ""}`}
      style={{ width: `${headerRowWidth}px`, height: rowHeight }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {/* {formattedDate} */}
      {/* {rowId} */}
    </td>
  )
}

export default Gantt
