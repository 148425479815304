import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import {
  useStore,
  useStoreMap,
  useStoreReplacementCars,
  useStoreSchedule,
  useStoreServices,
  useStoreTypes,
  useStoreWorkshops,
  useStoreServiceMileages
} from "../../zustand/store"
import Input from "../inputs/Input"
import Select from "../inputs/Select"
import SelectMap from "../inputs/SelectMap"
import TextArea from "../inputs/TextArea"
import Map from "../map/Map"
import ModalUni from "../modals/ModalUni"
import CarItem from "./CarItem"
import { motion } from "framer-motion"
import ReplacementSchedules from "./ReplacementSchedules"
import { ClientDetails } from "./forms/ClientDetails"
import ServiceDetails from "./forms/ServiceDetails"
import CollectionDetails from "./forms/CollectionDetails"
import ReplacementCarDetails from "./forms/ReplacementCarDetails"
import VehicleReport from "./forms/VehicleReport"
import { fetchAdress } from "../../library/functions"
import Attachments from "./forms/Attachments"

const variants = {
  open: { opacity: 1, height: "100%" },
  closed: { opacity: 1, height: "0px" },
}

const FormCreate = ({ setVisibleModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    unregister,
  } = useForm()

  const runningRequestNo = useStore((state) => state.runningRequestNo)
  const loggedUser = useStore((state) => state.loggedUser)

  // create a function that will pad a number with leading zeros
  const pad = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
  }
  const year = new Date().getFullYear().toString().slice(-2)
  const [requestNo, setRequestNo] = useState(
    "SR" +
      pad(loggedUser.id, 2) +
      "-" +
      pad(new Date().getMonth(), 2) +
      pad(new Date().getDate(), 2) +
      year +
      "-" +
      pad(runningRequestNo + 1, 4)
  )

  const token = useStore((state) => state.token)

  const emirateTypes = useStoreTypes((state) => state.emirateTypes)
  const auhCategoryTypes = useStoreTypes((state) => state.auhCategoryTypes)
  const carsCategoryTypes = useStoreTypes((state) => state.carsCategoryTypes)

  const serviceTypes = useStoreTypes((state) => state.serviceTypes)

  const isWhat = useStoreServices((state) => state.isWhat)
  const addService = useStoreServices((state) => state.addService)
  const setRunningRequestNo = useStore((s) => s.setRunningRequestNo)
  const addEmirateSub = useStoreTypes((s) => s.addAuhCategoryTypes)

  const storeReplacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const setStoreReplacementCars = useStoreReplacementCars((s) => s.setReplacementCars)
  const [replacementCars, setReplacementCars] = useState(null)

  const watcher = watch()

  const [modalAddVisible, setModalAddVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const selectedFrom = useStoreSchedule((s) => s.selectedFrom)
  const selectedTo = useStoreSchedule((s) => s.selectedTo)
  const setSelectedFrom = useStoreSchedule((s) => s.setSelectedFrom)
  const setSelectedTo = useStoreSchedule((s) => s.setSelectedTo)

  const selectedId = useStoreSchedule((s) => s.selectedId)
  const setSelectedId = useStoreSchedule((s) => s.setSelectedId)
  const [selectedRC, setSelectedRC] = useState(null)

  const isNumeric = (value) => {
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    if (typeof value === 'string') {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }
    return false;
  }

  const onSubmit = async (submitData) => {
    setModalLoading(true)
    let newDateTime = null
    if (submitData.collection_date && submitData.collection_time) {
      newDateTime = new Date(submitData.collection_date + " " + submitData.collection_time)
    }
    if (isWhat === "insert") {
      if (!selectedId && submitData.rc_needed) {
        toast.error("Please select a replacement car")
        setModalLoading(false)
        return
      }

      let workshop = null

      if (submitData.rc_needed) {
        workshop = selectedRC?.workshop ? Number(selectedRC?.workshop) : Number(watcher?.selWorkshop)
      } else {
        workshop = submitData?.workshop ? Number(submitData.workshop) : Number(watcher?.selWorkshop)
      }

      const newSubmitData = {
        ...submitData,
        request_no: requestNo,
        collection_datetime: newDateTime,
        client: null,
        replacement_car: selectedId ? Number(selectedId) : null,
        auh_category: submitData.auh_category ? Number(submitData.auh_category) : null,
        workshop: workshop,
        collection_needed: watcher.collection_needed ? true : false,
        rc_needed: watcher.rc_needed ? true : false,
        brand: isNumeric(watcher.brand) ? Number(watcher.brand) : 0,
      }
      const { data, status } = await axios.post(apiUrl + "/services", newSubmitData, config())

      let replacement_car_schedule = null

      if (selectedFrom && selectedTo && selectedId) {
        // Create Schedule
        const submitData2 = {
          start: new Date(selectedFrom).toISOString(),
          end: new Date(selectedTo).toISOString(),
          replacement_car: Number(selectedId),
          service: data.id,
        }

        // console.log(submitData2)
        const { data2, status2 } = await axios.post(apiUrl + "/replacement_cars_schedules", submitData2, config())
        replacement_car_schedule = data2
        // console.log(data2, status2)
        const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
        // console.log(replacement_cars.data)
        setStoreReplacementCars(replacement_cars.data)

        setSelectedId(null)
        setSelectedFrom(null)
        setSelectedTo(null)
      }

      if (status === 200) {
        // console.log("data", data)

        toast.success("Services Successfully added...")

        let xData = {
          ...data,
          replacement_car_schedule: replacement_car_schedule,
        }

        addService(xData)
        setVisibleModal(false)

        const running_request_no = await axios.post(
          apiUrl + "/running_request_no",
          { id: loggedUser.id },
          config(token)
        )
        setRunningRequestNo(running_request_no.data.request_no)
        setModalLoading(false)
      }
    }
  }

  const btnCancel = () => {
    setVisibleModal(false)
  }

  useEffect(() => {
    setSelectedId(null)
    setSelectedFrom(null)
    setSelectedTo(null)
    if (storeReplacementCars) {
      setReplacementCars(storeReplacementCars)
    }
  }, [storeReplacementCars])

  useEffect(() => {
    const category = watcher.auh_category
    if (category === "add") {
      if (Number(watcher.emirate) !== 0) {
        setModalAddVisible(true)
      } else if (Number(watcher.emirate) === 0) {
        toast.warning("Please select Emirate first.")
        setValue("auh_category", "")
      }
    }
  }, [watcher.auh_category])

  const [addCategoryName, setAddCategoryName] = useState("")
  const [addCategoryActual, setAddCategoryActual] = useState("")

  const handleSubmitCategory = async (second) => {
    const data = {
      emirate: Number(watcher.emirate),
      name: addCategoryName,
      actual: addCategoryActual,
    }
    try {
      const res = await axios.post(apiUrl + "/auh_category_types", data, config())
      addEmirateSub(res.data)
      toast.success("Successfully Category Added.")
      setValue("auh_category", res.data.id)
      setModalAddVisible(false)
    } catch (error) {
      toast.success("Error Adding Category.")
    }
  }

  const filteredCategory = useMemo(() => {
    return auhCategoryTypes.filter((item) => item.emirate === Number(watcher.emirate) && item)
  }, [watcher.emirate, auhCategoryTypes])

  const filteredReplacementCars = useMemo(() => {
    if (replacementCars) {
      let repCars = [...storeReplacementCars]
      repCars = repCars.sort((a, b) => a?.utilization - b?.utilization)

      if (watcher.workshop) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.workshop) === element.workshop) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watcher.brand) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.brand) === element.brand) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      // if (watcher?.vmodel) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (watcher?.vmodel?.split(/[\s,._-]+/)[0]?.toUpperCase()?.trim() === element.model?.split(/[\s,._-]+/)[0]?.toUpperCase()?.trim()) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      if (watcher.replacement_car_category) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.replacement_car_category) === Number(element.category)) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      // if (watcher.emirate) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (Number(watcher.emirate) === element.emirate) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      if (watcher.year) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (watcher.year === element.year) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watcher.client_no) {
        let tempCars = []
        for (let index = 0; index < storeReplacementCars.length; index++) {
          const element = storeReplacementCars[index]
          if (element?.locked_to?.includes(String(watcher.client_no))) {
            tempCars.push(element)
          }
        }
        // if (tempCars.length !== 0) {
        let ntempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          try {
            const lockkss = JSON.parse(element?.locked_to)

            if (lockkss.length === 0) {
              ntempCars.push(element)
            }
          } catch (error) {
            ntempCars.push(element)
          }
        }

        repCars = [...tempCars, ...ntempCars]
        // }
      }

      return repCars
    } else {
      return []
    }
  }, [
    replacementCars,
    storeReplacementCars,
    watcher.workshop,
    watcher.brand,
    watcher.replacement_car_category,
    watcher.year,
    watcher.client_no,
    // watcher.vmodel
  ])

  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const setAddressLoading = useStoreMap((s) => s.setAddressLoading)
  const setServiceMileages = useStoreServiceMileages((state) => state.setServiceMileages)
  useEffect(() => {
    if (hoverCoordinates) {
      const fetsss = async () => {
        setAddressLoading(true)
        const data = await fetchAdress(`${hoverCoordinates.lng},${hoverCoordinates.lat}`, {
          lat: hoverCoordinates.lat,
          lng: hoverCoordinates.lng,
        })
        if (data?.features.length !== 0) {
          // console.log(data)
          const latlng = {
            lat: data.features[0].center[1],
            lng: data.features[0].center[0],
          }
          setValue("collection_location", data.features[0].place_name_en)
          setValue("collection_coordinates", JSON.stringify(latlng))
          setSelectedAddress({ label: data.features[0].place_name_en, value: { place_id: data.features[0].place_id } })
        }
        setAddressLoading(false)
      }
      const getMapCountry = async () => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${hoverCoordinates.lat}&lon=${hoverCoordinates.lng}`
          );
          const address = response.data.address
          console.log("address", address)
          // if (address.country_code === 'ae') 
          setValue("collection_state", address && address.state ? address.state.replace(" Emirate", "") : null)
          setValue("collection_country", address ? address?.country : null)
          setValue("vehicle_category", '')
        } catch (error) {
          console.error('Error fetching geocoding data:', error)
        }
      }

      fetsss()
      getMapCountry()
    }

    const getServiceMileages = async () => {
      const serviceMileages = await axios.get(apiUrl + "/service_mileage", config())
      setServiceMileages(serviceMileages.data ?? [])
    }

    getServiceMileages()

  }, [hoverCoordinates, setSelectedAddress])

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setValue("selectedFrom", selectedFrom)
      setValue("selectedTo", selectedTo)
    }
  }, [selectedFrom, selectedTo, setValue])

  useEffect(() => {
    if (replacementCars && selectedId) {
      for (let index = 0; index < replacementCars.length; index++) {
        const replacementCar = replacementCars[index]
        if (replacementCar.id === selectedId) {
          setSelectedRC(replacementCar)
        }
      }
    }
  }, [replacementCars, selectedId])

  useEffect(() => {
    setValue("rc_needed", watcher.collection_needed)
  }, [watcher.collection_needed, setValue, isWhat])

  useEffect(() => {
    return () => {
      unregister("workshop")
    }
  }, [watcher.rc_needed])

  return (
    <div>
      <ModalUni
        isVisible={modalLoading}
        setIsVisible={() => {
          return
        }}
        size={2}
        title={"Submitting Data"}
        hasClose={false}
      ></ModalUni>
      <ModalUni isVisible={modalAddVisible} setIsVisible={setModalAddVisible} size={3} title={"Add Emirate Category"}>
        <div>
          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Category Name</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. R6"
              className="w-full input input-bordered"
              value={addCategoryName}
              onChange={(e) => {
                setAddCategoryName(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Actual</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. 16"
              className="w-full input input-bordered"
              value={addCategoryActual}
              onChange={(e) => {
                setAddCategoryActual(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className="flex justify-end">
            <div className="px-12 btn" onClick={handleSubmitCategory}>
              Submit
            </div>
          </div>
        </div>
      </ModalUni>

      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <ServiceDetails
          errors={errors}
          filteredCategory={filteredCategory}
          register={register}
          requestNo={requestNo}
          setSelectedFrom={setSelectedFrom}
          setSelectedId={setSelectedId}
          setSelectedTo={setSelectedTo}
          setValue={setValue}
          watcher={watcher}
        />

        {watcher.collection_needed ? (
          <CollectionDetails register={register} errors={errors} watcher={watcher} setValue={setValue} />
        ) : (
          <></>
        )}

        {watcher.collection_needed && watcher.rc_needed ? (
          <ReplacementCarDetails
            register={register}
            errors={errors}
            watcher={watcher}
            setValue={setValue}
            filteredReplacementCars={filteredReplacementCars}
            selectedRC={selectedRC}
          />
        ) : (
          <></>
        )}

        <Attachments setValue={setValue} watcher={watcher} />

        {/* <VehicleReport setValue={setValue} /> */}

        <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
          <button className="px-12 btn" type="button" onClick={btnCancel}>
            Cancel
          </button>
          <button className="px-12 btn" type="submit">
            {isWhat === "insert" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormCreate
