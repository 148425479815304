import React, { useState, useEffect } from "react"
import axios from "axios"
import { HashLoader } from "react-spinners"

import { apiUrl, config } from "../../library/constant"
import { capitalizeWords } from "../../library/functions"
import { useStoreAlerts, useStoreUsers, useStoreWorkshops } from "../../zustand/store"

const tableHeaders = ["User ID", "", "Name/Email", "Username", "Phone No.", "Account Type", "Workshop", "Actions"]

const UsersTable = ({ setModal, setSelectedUser, searchText }) => {
  const storeUsers = useStoreUsers((s) => s.users)
  const alerts = useStoreAlerts((s) => s.alerts)
  const workshops = useStoreWorkshops((s) => s.workshops)

  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const bodyRows = 10

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  useEffect(() => {
    const fetchPagination = async () => {
      const res = await axios.get(apiUrl + "/users/count?search=" + searchText, config())
      let pages = parseInt((res.data - 1) / bodyRows + 1)
      setCount(res.data)
      setTotalPages(pages)
    }
    fetchPagination()
  }, [alerts, storeUsers, searchText])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        apiUrl + "/users?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
        config()
      )
      setUsers(res.data)
      setIsLoading(false)
    }
    fetchData()
  }, [currentPage, alerts, storeUsers, searchText])

  const getWorkshop = (Id) => {
    if (workshops) {
      const finded = workshops.find((obj) => obj.id === Id)
      if (finded) {
        return finded.name.toLowerCase()
      }
      return ""
    }
    return ""
  }

  return (
    <>
      {isLoading ? 
        <div className="mt-10">
          <center>
            <HashLoader color="#f97316" loading={true} size={80}/>
          </center>
        </div>
      :
        <div>
          <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg">
            <table className="w-full overflow-hidden text-left text-gray-500 rounded-md dark:text-gray-400">
              <thead className="sticky top-0">
                <tr className="text-sm text-[#F06400] uppercase ring ring-[#F06400]">
                  {tableHeaders.map((header, index) => (
                    <th key={index} scope="col" className="px-6 py-3">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={index} className="text-gray-900 bg-white border-b">
                      <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                        {user.id}
                      </th>
                      <td className="px-6 py-4">
                        <Picture src={user?.profile_picture} />
                      </td>
                      <td className="px-6 py-4">
                        {user?.name ?? capitalizeWords(user?.name)}
                        <br />
                        <div className="bg-orange-500 badge">{user?.email}</div>
                      </td>
                      <td className="px-6 py-4">{user?.username}</td>
                      <td className="px-6 py-4">{user?.phone_no ?? "No phone number"}</td>
                      <td className="px-6 py-4 capitalize">{user?.user_type_data?.name ?? "404"}</td>
                      <td className="px-6 py-4 capitalize">{getWorkshop(user?.driver_data?.workshop)}</td>
                      <td className="px-6 py-4 space-x-2">
                        <button
                          className="space-x-2 text-blue-500"
                          onClick={() => {
                            setSelectedUser(user)
                            setModal({ type: "edit", visible: true })
                          }}
                        >
                          <i className="fa-solid fa-pencil"></i>
                          <span>Edit</span>
                        </button>
                        <button className="space-x-2 text-red-500">
                          <i className="fa-solid fa-trash"></i>
                          <span>Delete</span>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
            <div>
              Showing 1 to {bodyRows} of {count} entries
            </div>
            <div className="flex items-center mt-2 space-x-4">
              <div onClick={prevPage} className="cursor-pointer">
                Previous
              </div>
              <div>
                {currentPage}/{totalPages}
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                Next
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const Picture = ({ src }) => (
  <div className="avatar">
    <div className="w-8 rounded">
      {src ? <img src={apiUrl + "/public/" + src} alt="vsrm" /> : <i className="text-3xl far fa-user" />}
    </div>
  </div>
)

export default UsersTable
