import React, { useState, useMemo } from "react"
import { Menu, Transition } from "@headlessui/react"
import { useStore } from "../../zustand/store"
import { apiUrl, config } from "../../library/constant"
import ModalUni from "../modals/ModalUni"
import { useForm } from "react-hook-form"
import Input from "../inputs/Input"
import axios from "axios"
import { toast } from "react-toastify"
import {Img} from 'react-image'

const MenuComponent = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    register,
    watch,
  } = useForm()

  const watcher = watch()

  const setToken = useStore((state) => state.setToken)
  const loggedUser = useStore((state) => state.loggedUser)
  const [changePass, setChangePass] = useState(false)

  const [passwordStatus, setPasswordStatus] = useState("")

  const onSubmit = async (params) => {
    // Your form submission logic goes here
    try {
      console.log(params)
      const { data, status } = await axios.post(
        apiUrl + "/change_password",
        { ...params, username: loggedUser.username },
        config()
      )
      console.log(data, status)
      if (status === 200) {
        toast.success("Password Changed Successfully!!!")
        setChangePass(false)
        setPasswordStatus("")
      } else {
        setPasswordStatus("Invalid Current Password")
      }
    } catch (error) {
      setPasswordStatus("Invalid Current Password")
    }
  }

  const isSubmitEnabled = useMemo(() => {
    if (!/(?=.*[A-Z])/.test(watcher?.password)) {
      return false
    }
    if (!/(?=.*\d)/.test(watcher?.password)) {
      return false
    }
    if (watcher?.password?.length < 12) {
      return false
    }
    if (watcher?.password_repeat === "") {
      return false
    }
    if (watcher?.password_repeat !== watcher?.password) {
      return false
    }
    if (watcher?.current_password === "") {
      return false
    }
    return true
  }, [watcher])

  // border-2 border-[#F06400]
  return (
    <div className="flex items-center justify-center z-[1000]">
      <div className="relative inline-block text-left">
        <ModalUni isVisible={changePass} setIsVisible={setChangePass} size={4} title={"Change Password"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              name="current_password"
              label="Current Password"
              placeholder={"Enter password"}
              type="password"
              condition={{ required: true }}
              error={errors}
              message="Password Required"
            />

            <Input
              register={register}
              name="password"
              label="New Password"
              placeholder={"Enter password"}
              type="password"
              condition={{ required: true }}
              error={errors}
              message={""}
            />
            <div className="flex flex-col">
              {!/(?=.*[A-Z])/.test(watcher?.password) ? (
                <span className="text-red-500 label-text-alt">
                  <i className="fa-solid fa-circle-xmark"></i> must contain at least 1 capital letter
                </span>
              ) : (
                <span className="text-green-500 label-text-alt">
                  <i className="fa-solid fa-circle-check"></i> must contain at least 1 capital letter
                </span>
              )}

              {!/(?=.*\d)/.test(watcher?.password) ? (
                <span className="text-red-500 label-text-alt">
                  <i className="fa-solid fa-circle-xmark"></i> must contain at least 1 number
                </span>
              ) : (
                <span className="text-green-500 label-text-alt">
                  <i className="fa-solid fa-circle-check"></i> must contain at least 1 number
                </span>
              )}

              {(watcher?.password?.length ?? 0) < 12 ? (
                <span className="text-red-500 label-text-alt">
                  <i className="fa-solid fa-circle-xmark"></i> must be at least 12 characters long
                </span>
              ) : (
                <span className="text-green-500 label-text-alt">
                  <i className="fa-solid fa-circle-check"></i> must be at least 12 characters long
                </span>
              )}
            </div>

            <Input
              register={register}
              name="password_repeat"
              label="Repeat Password"
              placeholder={"Enter password"}
              type="password"
              condition={{ required: true }}
              error={errors}
              message={""}
            />
            {watcher?.password_repeat === "" && (
              <label className="pt-0 label">
                <span className="label-text-alt"></span>
                <span className="italic text-red-500 label-text-alt">Confirm Password Required!</span>
              </label>
            )}

            {watcher?.password_repeat !== watcher?.password && (
              <label className="pt-0 label">
                <span className="label-text-alt"></span>
                <span className="italic text-red-500 label-text-alt">Password not match!</span>
              </label>
            )}

            <button type="submit" className="btn" disabled={!isSubmitEnabled}>
              Submit
            </button>

            {passwordStatus && (
              <label className="pt-0 label">
                <span className="text-red-500 label-text-alt text-sm">{passwordStatus}</span>
              </label>
            )}
          </form>
        </ModalUni>
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center justify-center rounded-full bg-gray-100 border-2 border-gray-200">
                <div className="flex py-1 pr-3 pl-1 gap-2 items-center">
                  {loggedUser?.profile_picture ? (
                    <div className="avatar">
                      <div className="w-12 rounded-full">
                      <Img
                        src={[`${apiUrl}/public/${loggedUser?.profile_picture}`, `${apiUrl}/public/default_user_photo.jpg`]}
                        alt="vsrm-img"
                      />
                        {/* <img 
                          src={`${apiUrl}/public/${loggedUser?.profile_picture}`} 
                          alt="vsrm-img" /> */}
                      </div>
                    </div>
                  ) : (
                    <i className="fa-solid fa-user-tie"></i>
                  )}

                  <div className="text-sm">Welcome! {loggedUser.name}</div>
                  <i className="fa-solid fa-sort-down ml-1 mb-1"></i>
                </div>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                >
                  <div className="px-4 py-3">
                    <p className="text-sm leading-5">Signed in as</p>
                    <p className="text-sm font-medium leading-5 text-gray-900 truncate">{loggedUser?.email}</p>
                  </div>

                  <div className="py-1">
                    <Menu.Item
                      as="span"
                      className="flex items-center w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-pointer"
                      onClick={() => setChangePass(true)}
                    >
                      <i className="fa-solid fa-key mr-2"></i>
                      Change Password
                    </Menu.Item>

                    <Menu.Item
                      as="span"
                      disabled
                      className="flex items-center w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 opacity-50 cursor-not-allowed"
                    >
                      <i className="fa-solid fa-headset mr-2"></i>
                      Support (soon)
                    </Menu.Item>

                    <Menu.Item
                      as="span"
                      disabled
                      className="flex items-center w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 opacity-50 cursor-not-allowed"
                    >
                      <i className="fa-regular fa-file-certificate mr-2"></i>
                      License (soon)
                    </Menu.Item>
                  </div>

                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#sign-out"
                          className={`${
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex items-center w-full px-4 py-2 text-sm leading-5 text-left`}
                          onClick={() => {
                            console.log("sign out")
                            localStorage.removeItem("token")
                            setToken(null)
                            window.location.href = "/"
                          }}
                        >
                          <i className="fa-solid fa-right-from-bracket mr-2"></i>
                          Sign out
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}

export default MenuComponent
