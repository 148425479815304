import React, { useMemo } from "react"
import { apiUrl } from "../../library/constant"
import { useStoreReplacementCars, useStoreWorkshops } from "../../zustand/store"

const Cars = () => {
  const { selectedWorkshop } = useStoreWorkshops()
  const { replacementCars: storeReplacementCar } = useStoreReplacementCars()

  const replacementCars = useMemo(() => {
    return storeReplacementCar.filter((item) => item.workshop === selectedWorkshop.id)
  }, [selectedWorkshop])

  return (
    <div className="mt-4">
      <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg max-h-96">
        <table className="w-full overflow-hidden bg-white rounded-t-lg table-auto whitespace-nowrap">
          <thead className="sticky top-0">
            <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
              <th className="px-3 py-4 text-left">RC NUMBER</th>
              <th className="px-3 py-4 text-left">PLATE NO..</th>
              <th className="px-3 py-4 text-left">MAKE</th>
              <th className="px-3 py-4 text-left">MODEL</th>
              <th className="px-3 py-4 text-left">YEAR</th>
              <th className="px-3 py-4 text-left">COLOR</th>
              <th className="px-3 py-4 text-left">WORKSHOP</th>
              <th className="px-3 py-4 text-left">CATEGORY</th>
            </tr>
          </thead>
          <tbody>
            {replacementCars.map((item, key) => {
              return <TableRow key={key} item={item} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Cars

const TableRow = ({ item }) => {
  return (
    <tr>
      <td className="py-4 pl-3">{item.reg_no}</td>
      <td className="py-4 pl-3">{item.plate}</td>
      <td className="py-4 pl-3">{item.brand_data.name}</td>
      <td className="py-4 pl-3">{item.model}</td>
      <td className="py-4 pl-3">{item.year}</td>
      <td className="py-4 pl-3">{item.color}</td>
      <td className="py-4 pl-3">{item.workshop_data.name}</td>
      <td className="py-4 pl-3">{item.category_data.name}</td>
    </tr>
  )
}
