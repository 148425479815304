import React from "react"
import { apiUrl } from "../../library/constant"
import Card from "../Card"
import MenuComponent from "./MenuComponent"

const DriverCard = ({ item, setIsAddDriverOpen }) => {
  return (
    <Card className="relative flex p-4">
      <div className="mr-4">
        <div className="avatar">
          <div className="w-24 rounded-full ring ring-[#F06400] ring-offset-base-100 ring-offset-2">
            {item?.pic ? (
              <img src={apiUrl + "/public/" + item?.pic} />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-[#F06400]">
                <i className="text-6xl text-gray-200 fa-solid fa-user-bounty-hunter"></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex flex-col mt-3">
          <div className="text-xl font-semibold capitalize">{item?.name}</div>
          <div className="flex items-center italic text-gray-400">
            <i className="mr-2 fa-solid fa-envelope"></i>
            {item?.email}
          </div>
          <div className="flex items-center text-gray-400">
            <i className="mr-2 fa-solid fa-phone"></i>
            {item?.contact}
          </div>
        </div>
        <div className="flex items-center justify-center w-5 h-4 cursor-pointer">
          {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}
          <MenuComponent item={item} setIsAddDriverOpen={setIsAddDriverOpen} />
        </div>
      </div>
    </Card>
  )
}

export default DriverCard

export const NewDriverCard = ({ item, setIsAddDriverOpen, isLarge = false, isFlexCol = true }) => {
  return (
    <div className={`grid grid-cols-3 gap-4 ${isFlexCol ? "flex-col" : ""} `}>
      <img
        src={apiUrl + "/public/" + item?.pic}
        alt="Driver Splash"
        className={`object-cover w-full rounded-md shadow-lg ${isLarge ? "h-48 w-72" : "h-72"}`}
      />
      <div className="flex flex-col col-span-2">
        <div className="flex justify-between">
          <p className="text-xl font-semibold text-petrol">{item?.name}</p>
          {/* <MenuComponent item={item} setIsAddDriverOpen={setIsAddDriverOpen} /> */}
        </div>
        <p className="text-[#F06400]">{item?.workshop_data?.name}</p>
        <div className="flex flex-col justify-between mt-2">
          <div className="flex items-center gap-2">
            <i className="mr-2 fa-solid fa-phone text-aqua"></i>
            <p className="text-petrol">{item?.contact}</p>
          </div>
          <div className="flex items-center gap-2">
            <i className="mr-2 fa-solid fa-envelope text-aqua"></i>
            <p className="text-right text-petrol">{item?.email}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
