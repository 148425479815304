import React, { useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { ConvertToJson } from "../../library/functions"
import { apiUrl } from "../../library/constant"
import { DetailField } from "../service_request/FormView"
import CarIntent from "../service_request/CarIntent"
import dayjs from "dayjs"
import Html2Pdf from "js-html2pdf"
import { useSearchParams } from "react-router-dom"

function VehicleReport({ service, rc, isWhat }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => componentRef.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName("html")[0]
        // console.log(html)
        const exporter = new Html2Pdf(html)
        await exporter.getPdf(true)
      }
    },
  })

  const returnVals = (param1, param2, param3) => {
    try {
      const parsed = JSON.parse(param1)
      console.log(`${param2}_${param3}`, parsed)
      return parsed[`${param2}_${param3}`]
    } catch (error) {
      return "No Info"
    }
  }

  const changeParams = (what) => {
    const encryted_data = searchParams.get("f")
    setSearchParams({ what: what, f: encryted_data })
  }

  return (
    <div ref={componentRef} className="flex flex-col p-6 card print:bg-white print:text-black print:mt-1">
      {service?.delivery_signature ? (
        <div className="flex gap-2">
          <div className="btn btn-secondary print:hidden flex-1" onClick={() => changeParams("in")}>
            <i className="fa-solid fa-print" />
            View Collection Report
          </div>
          <div className="btn btn-primary print:hidden flex-1" onClick={() => changeParams("out")}>
            <i className="fa-solid fa-print" />
            View Delivery Report
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="flex mt-2">
        <div className="btn btn-accent print:hidden flex-1" onClick={handlePrint}>
          <i className="fa-solid fa-print" />
          Print
        </div>
      </div>

      <div className="text-3xl text-center mb-4 font-black mt-6">
        {isWhat === "in" ? "Vehicle Collection Report" : "Vehicle Delivery Report"}
      </div>

      <div className="flex print:text-black">
        <div className="mr-2">Date Request:</div>
        <div className="font-bold">{dayjs(service?.added_date).format("MMMM D, YYYY - HH:mm")}</div>
      </div>
      <div className="flex text-3xl print:text-xl print:text-black">
        <div className="mr-2">Control No:</div>
        <div className="flex font-bold">{service?.request_no}</div>
      </div>

      <div className="grid grid-flow-row grid-cols-3 mt-2">
        <div>
          <div>Driver Name:</div>
          <div className="font-bold">{service?.driver_name}</div>
        </div>
        <div>
          <div>Driver Contact:</div>
          <div className="font-bold">{service?.driver_contact}</div>
        </div>
        <div>
          <div>Driver Email:</div>
          <div className="font-bold">{service?.driver_email}</div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <p className="text-lg">Leased Car Details: </p>
        <div className="font-bold">
          {rc ? (
            <CarItem
              display={true}
              item={
                service?.client_api_data && {
                  emirate_data: {
                    name: service?.emirate_data?.name,
                  },
                  plate: JSON.parse(service?.client_api_data)?.PLATE_NO,
                  brand_data: {
                    name: JSON.parse(service?.client_api_data)?.MAKE,
                  },
                  color: JSON.parse(service?.client_api_data)?.COLOR,
                  model: JSON.parse(service?.client_api_data)?.MODEL,
                  workshop_data: {
                    name: service?.workshop_data?.name,
                  },
                  year: JSON.parse(service?.client_api_data)?.YEAR_MODEL,
                }
              }
              onClick={() => {}}
              selectedID={0}
            />
          ) : (
            "No Assigned Replacement Car"
          )}
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <p className="text-lg">Car Replacement Details: </p>
        <div className="font-bold">
          {rc ? <CarItem display={true} item={rc} onClick={() => {}} selectedID={0} /> : "No Assigned Replacement Car"}
        </div>
      </div>

      {/* <VehicleReportView
        lease_vehicle={service.lease_vehicle}
        lease_vehicle_in={service.lease_vehicle_in}
        lease_vehicle_out={service.lease_vehicle_out}
        replacement_vehicle={service.replacement_vehicle}
        replacement_vehicle_in={service.replacement_vehicle_in}
        replacement_vehicle_out={service.replacement_vehicle_out}
        rc_needed={service?.rc_needed}
        collection_needed={service?.collection_needed}
      /> */}

      <div className="grid grid-flow-row grid-cols-4 mt-4">
        <div className="col-span-4 border p-2 text-xl font-bold">
          A. Leased Vehicle - {isWhat === "in" ? "In" : "Out"}
        </div>
        <div className="border p-2">
          Date {isWhat === "in" ? "In" : "Out"}:{" "}
          {returnVals(service.lease_vehicle, "date", isWhat === "in" ? "in" : "out")}
        </div>
        <div className="border p-2">
          Time {isWhat === "in" ? "In" : "Out"}:
          {returnVals(service.lease_vehicle, "time", isWhat === "in" ? "in" : "out")}
        </div>
        <div className="border p-2">
          Km {isWhat === "in" ? "In" : "Out"}:{returnVals(service.lease_vehicle, "km", isWhat === "in" ? "in" : "out")}
        </div>
        <div className="border p-2">
          Fuel {isWhat === "in" ? "In" : "Out"}:
          {returnVals(service.lease_vehicle, "fuel", isWhat === "in" ? "in" : "out")}
        </div>
      </div>

      <div className="grid grid-flow-row grid-cols-2 ">
        <div>
          <CarIntent
            className="border"
            // title={``}
            name={`Vehicle ${isWhat === "in" ? "In" : "Out"}`}
            id={`lease_vehicle_${isWhat === "in" ? "in" : "out"}`}
            data={isWhat === "in" ? service.lease_vehicle_in : service.lease_vehicle_out}
            disabled={true}
          />
        </div>
        <div className="grid grid-flow-row grid-cols-2">
          {ConvertToJson(isWhat === "in" ? service.lease_vehicle_in : service.lease_vehicle_out) &&
            Object.keys(ConvertToJson(isWhat === "in" ? service.lease_vehicle_in : service.lease_vehicle_out)).map(
              (key) => {
                const carPart = ConvertToJson(isWhat === "in" ? service.lease_vehicle_in : service.lease_vehicle_out)[
                  key
                ]

                if (carPart?.car_part_id) {
                  // console.log({
                  //   key,
                  //   value: carPart,
                  // })

                  return <CarParts data={carPart} key={key} />
                }
              }
            )}
        </div>
      </div>

      {service.rc_needed && (
        <>
          <div className="grid grid-flow-row grid-cols-4 mt-4">
            <div className="col-span-4 border p-2 text-xl font-bold">
              B. Replacement Vehicle - {isWhat === "in" ? "Out" : "In"}
            </div>
            <div className="border p-2">
              Date {isWhat === "in" ? "Out" : "In"}:
              {returnVals(service.replacement_vehicle, "date", isWhat === "in" ? "out" : "in")}
            </div>
            <div className="border p-2">
              Time {isWhat === "in" ? "Out" : "In"}:
              {returnVals(service.replacement_vehicle, "time", isWhat === "in" ? "out" : "in")}
            </div>
            <div className="border p-2">
              Km {isWhat === "in" ? "Out" : "In"}:
              {returnVals(service.replacement_vehicle, "km", isWhat === "in" ? "out" : "in")}
            </div>
            <div className="border p-2">
              Fuel {isWhat === "in" ? "Out" : "In"}:
              {returnVals(service.replacement_vehicle, "fuel", isWhat === "in" ? "out" : "in")}
            </div>
          </div>
          <div className="grid grid-flow-row grid-cols-2 ">
            <div>
              <CarIntent
                className="border"
                // title={`Replacement Vehicle ${isWhat === "in" ? "Out" : "In"}`}
                name={`Vehicle ${isWhat === "in" ? "Out" : "In"}`}
                id={`replacement_vehicle__${isWhat === "in" ? "out" : "in"}`}
                data={isWhat === "in" ? service.replacement_vehicle_out : service.replacement_vehicle_in}
                disabled={true}
              />
            </div>
            <div className="grid grid-flow-row grid-cols-2">
              {ConvertToJson(isWhat === "in" ? service.replacement_vehicle_out : service.replacement_vehicle_in) &&
                Object.keys(
                  ConvertToJson(isWhat === "in" ? service.replacement_vehicle_out : service.replacement_vehicle_in)
                ).map((key) => {
                  const carPart = ConvertToJson(
                    isWhat === "in" ? service.replacement_vehicle_out : service.replacement_vehicle_in
                  )[key]

                  if (carPart?.car_part_id) {
                    // console.log({
                    //   key,
                    //   value: carPart,
                    // })

                    return <CarParts data={carPart} key={key} />
                  }
                })}
            </div>
          </div>
        </>
      )}

      <div className="h-96 mt-6 px-4">
        <div className="flex">
          <div className="mr-2">Driver Name:</div>
          <div className="font-bold">{service.driver_name}</div>
        </div>
        <div className="flex">
          <div className="w-80">
            {isWhat === "in" ? (
              <DetailField
                label="Signature"
                value={service?.collection_signature ?? "No details provided"}
                type="image"
              />
            ) : (
              <DetailField
                label="Signature"
                value={service?.delivery_signature ?? "No details provided"}
                type="image"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleReport

const CarParts = ({ data }) => {
  // console.log(data)
  return (
    <div className="grid grid-flow-row gap-4 border p-4">
      <div className="text-lg font-bold">Car Part: {data?.car_part_name}</div>
      <DetailField label={"Car Part Description"} value={data?.car_part_description ?? "No Details Provided"} />
      <div>
        <div className="text-sm">Car Part Pictures :</div>
        <div className="grid grid-flow-row grid-cols-2">
          {data?.car_pics?.map((item, key) => (
            <img src={apiUrl + "/public/" + item} key={key} alt="vsrm-image" />
          ))}
        </div>
      </div>
    </div>
  )
}

const CarItem = ({ display = false, selectedID, item, onClick }) => {
  const isSelected = selectedID === item.id

  return (
    <div
      className={`flex w-full h-24 overflow-hidden bg-white rounded-lg ${
        display ? "shadow-md border" : `hover:bg-orange-300 ${isSelected && "ring-4 ring-[#F06400]"}`
      }  `}
      onClick={() => {
        if (!display) {
          onClick(item.id)
        }
      }}
    >
      <img src={item.pic ? apiUrl + "/public/" + item.pic : "/cars/ferrari.png"} alt="car" className="w-36" />
      <div className="flex flex-col justify-between w-full px-4 py-2">
        <div className="text-lg font-bold">
          {/* {item?.reg_no} */}
          {item?.emirate_data?.name}-{item?.plate}
        </div>
        <div className="flex gap-4">
          <div>
            <div className="whitespace-nowrap">Make: {item?.brand_data.name}</div>
            <div className="whitespace-nowrap">Color: {item?.color}</div>
          </div>

          <div>
            <div className="whitespace-nowrap">Model: {item?.model}</div>
            <div className="whitespace-nowrap">Workshop: {item?.workshop_data.name}</div>
          </div>
          <div className="mr-28">
            <div className="whitespace-nowrap">Year: {item?.year}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
