import { useRef, useState } from "react"
import { Stepper } from "@mantine/core"
import { useForm } from "react-hook-form"
import SelectMap from "../../inputs/SelectMap"
import Map from "../../map/Map"
import SignatureCanvas from "react-signature-canvas"
import MantineDropzone from "./Dropzone"
import { useEffect } from "react"
import { useStore, useStoreDrivers, useStoreServices } from "../../../zustand/store"
import { NewDriverCard } from "../../drivers/DriverCard"
import Files from "../../inputs/Files"
import { urltoFile } from "../../../library/functions"
import { apiUrl, config } from "../../../library/constant"
import axios from "axios"
import CarIntent from "../CarIntent"
import dayjs from "dayjs"
import TextArea from "../../inputs/TextArea"
import Input from "../../inputs/Input"
import { toast } from "react-toastify"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const TransferLeaseVehicle = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const watcher = watch()

  const [active, setActive] = useState(0)
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current))
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))

  const loggedUser = useStore((s) => s.loggedUser)
  const drivers = useStoreDrivers((s) => s.drivers)
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const [currentDriver, setCurrentDriver] = useState({})

  const [signaturePoints, setSignaturePoints] = useState([])
  const [signUrl, setSignUrl] = useState("")

  const [leasedVehicleIn, setLeasedVehicleIn] = useState("{}")

  const [histID, setHistID] = useState(0)

  useEffect(() => {
    // console.log(loggedUser)
    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index]
      if (loggedUser.driver === driver.id) {
        setCurrentDriver(driver)
        break
      }
    }
  }, [selectedService, drivers])

  const finish = useCbOnce(async () => {
    const file = await urltoFile(signUrl, "signature.png", "png")
    console.log(file)
    const formData = new FormData()
    formData.append("files", file)
    const res = await axios.post(apiUrl + "/upload", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })

    const currentDriverId = currentDriver.id
    const uploadedFilename = res.data.filenames[0]
    // const uploadedPictures = uploadFiles ? uploadFiles.map((item) => item.filename) : []

    let lease_vehicle_data
    try {
      lease_vehicle_data = JSON.parse(selectedService.lease_vehicle)
    } catch (error) {
      lease_vehicle_data = {}
    }

    let replacement_vehicle_data
    try {
      replacement_vehicle_data = JSON.parse(selectedService.replacement_vehicle)
    } catch (error) {
      replacement_vehicle_data = {}
    }

    const lease_vehicle = {
      ...lease_vehicle_data,
      date_in: watcher.date_in,
      time_in: watcher.time_in,
      km_in: watcher.km_in,
      fuel_in: watcher.fuel_in,
      description_in: watcher.description_in,
    }

    let replacement_vehicle = {}
    if (selectedService?.rc_needed) {
      replacement_vehicle = {
        ...replacement_vehicle_data,
        date_out: watcher.date_out,
        time_out: watcher.time_out,
        km_out: watcher.km_out,
        fuel_out: watcher.fuel_out,
        description_out: watcher.description_out,
      }
    }

    const xLeasedVehicleIn = JSON.parse(leasedVehicleIn)
    const newLeasedVehicleIn = { ...xLeasedVehicleIn, description: watcher.description_in }

    let submitData = {
      id: selectedService.id,
      collection_driver: Number(currentDriverId),
      collection_signature: uploadedFilename,
      lease_vehicle: JSON.stringify(lease_vehicle),
      lease_vehicle_out: JSON.stringify(newLeasedVehicleIn),
      workshop: histID,
    }

    console.log(submitData)

    const response = await axios.post(apiUrl + "/services/transfer_delivery", submitData, config())
    console.log(response.data)

    setSelectedService(response.data)
    updateService(response.data)
    setIsVisible(false)
  })

  useEffect(() => {
    const newDateTime = new Date()
    setValue("time_in", dayjs(newDateTime).format("HH:mm"))
    setValue("date_in", dayjs(newDateTime).format("YYYY-MM-DD"))
    setValue("time_out", dayjs(newDateTime).format("HH:mm"))
    setValue("date_out", dayjs(newDateTime).format("YYYY-MM-DD"))

    setLeasedVehicleIn(selectedService?.lease_vehicle_in)

    const link = apiUrl + "/workshop_history/" + selectedService.id
    axios
      .get(link, config())
      .then((res) => {
        if (res.data.length !== 0) {
          setHistID(res.data[0].id)
          console.log("TLV", res.data[0].id)
        }
      })
      .catch((err) => {
        toast.error("Problem fetching History")
      })
  }, [])

  return (
    <div className="px-4 space-y-4">
      <div className="">
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Driver Confirmation" radius={"sm"} description="First Step">
            <div className="flex justify-center mt-6">
              {currentDriver?.id && <NewDriverCard item={currentDriver} isLarge={true} isFlexCol={false} />}
            </div>
          </Stepper.Step>

          {/* Leased Vehicle In */}
          <Stepper.Step label="Vehicle Report" radius={"sm"} description={"Second Step"}>
            <CarIntent
              className="border-b"
              title={"Lease Vehicle Out"}
              name="Vehicle Out"
              id="lease_vehicle_in"
              xSetValue={setLeasedVehicleIn}
              data={leasedVehicleIn ?? null}
              isSetValue={false}
            />
            <form className="grid grid-flow-row grid-cols-3 gap-x-4">
              <Input
                register={register}
                name="date_out"
                label="Date Out"
                placeholder="Date Out"
                type="date"
                error={errors}
                disabled
              />

              <Input
                register={register}
                name="time_out"
                label="Time Out"
                placeholder="Time Out"
                type="time"
                error={errors}
                disabled
              />
              <div className="flex gap-x-4">
                <Input
                  register={register}
                  name="km_out"
                  label="Km Out"
                  placeholder="Km Out"
                  type="text"
                  error={errors}
                />
                <Input
                  register={register}
                  name="fuel_out"
                  label="Fuel Out"
                  placeholder="Fuel Out"
                  type="text"
                  error={errors}
                />
              </div>
            </form>
            <div>
              <TextArea
                register={register}
                name="description_in"
                label="Description"
                placeholder={"Description"}
                type="text"
                error={errors}
                className="min-h-[140px]"
              />
            </div>
          </Stepper.Step>

          <Stepper.Step label="E-Signature of Workshop Manager" radius={"sm"} description="Final Step">
            <Signature value={signaturePoints} setValue={setSignaturePoints} setSignUrl={setSignUrl} />
          </Stepper.Step>
        </Stepper>
      </div>

      <div className="flex items-center justify-end w-full gap-2">
        {active != 0 && (
          <button
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => prevStep()}
          >
            Back
          </button>
        )}

        {active < 2 ? (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => nextStep()}
          >
            Next
          </button>
        ) : (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => finish()}
          >
            Finish
          </button>
        )}
      </div>
    </div>
  )
}

export default TransferLeaseVehicle

const Signature = ({ value, setValue, setSignUrl }) => {
  const sigCanvas = useRef()

  useEffect(() => {
    if (sigCanvas.current || value) {
      sigCanvas.current.fromData(value)
    }
  }, [sigCanvas, value])

  return (
    <div className="relative w-full space-y-2 border">
      {/* TODO: convert signature to image, idk how to do this sir whaha */}
      <button className="absolute px-3 py-2 border rounded-md top-2 right-2" onClick={() => sigCanvas.current.clear()}>
        Clear
      </button>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 800, height: 400, className: "" }}
        ref={sigCanvas}
        onEnd={(e) => {
          const dataPoints = sigCanvas.current.toData()
          setValue(dataPoints)
          setSignUrl(sigCanvas.current.toDataURL("png"))
        }}
      />
    </div>
  )
}
