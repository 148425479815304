import React from "react"
import { useStoreAlerts } from "../../zustand/store"
import { AlertsItem } from "../nav/AlertItem"

const AlertsModal = () => {
  const alerts = useStoreAlerts((s) => s.alerts)
  return (
    <div className="flex flex-col gap-4 mt-4">
      {alerts &&
        alerts?.map((item, index) => (
          <div key={index}>
            <AlertsItem item={item} />
          </div>
        ))}
    </div>
  )
}

export default AlertsModal
