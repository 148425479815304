import React from "react"
import { useStoreServices } from "../../../zustand/store"
import { useState } from "react"
import { useEffect } from "react"
import { apiUrl, config } from "../../../library/constant"
import axios from "axios"
import { toast } from "react-toastify"
import dayjs from "dayjs"

const TransferTicketHistory = () => {
  const selectedService = useStoreServices((s) => s.selectedService)

  const [history, setHistory] = useState([])

  useEffect(() => {
    // axios.get(apiUrl + "/workshop_history/" + selectedService.id, config())
    const link = apiUrl + "/ticket_history/" + selectedService.id
    console.log(config())
    axios
      .get(link, config())
      .then((res) => {
        console.log(res.data)
        if (res?.data?.length) {
          setHistory(res.data)
        } else {
          setHistory([])
        }
      })
      .catch((err) => {
        toast.error("Problem fetching History")
      })
  }, [])

  return (
    <div className="grid grid-flow-row gap-2">
      {history.length !== 0
        ? history.map((item, index) => {
            return <HistoryTag data={item} key={index} />
          })
        : "No History Found"}
    </div>
  )
}

export default TransferTicketHistory

const HistoryTag = ({ data }) => {
  return (
    <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md shadow-md">
      <div className={`font-semibold grid grid-flow-col grid-cols-3 w-full`}>
        <div>{data?.from_user_data?.name}</div>
        <div className="text-center ">
          <i className=" fa-solid fa-right-long"></i>
        </div>
        <div className="text-right">{data?.to_user_Data?.name}</div>
      </div>
      <div className="text-sm">Transferred by: {data?.added_by_data?.name}</div>
      <div className="flex justify-between text-sm">
        <div>{dayjs(data.added_date).format("MMM D, YYYY")}</div>
        <div>{dayjs(data.added_date).format("HH:mm")}</div>
      </div>
    </div>
  )
}
