import React, { useMemo } from "react"
import { useStoreDrivers, useStoreWorkshops } from "../../zustand/store"
import { NewDriverCard } from "../drivers/DriverCard"

const Drivers = () => {
  const { selectedWorkshop } = useStoreWorkshops()
  const { drivers: storeDrivers } = useStoreDrivers()

  const drivers = useMemo(() => {
    return storeDrivers.filter((item) => item.workshop === selectedWorkshop.id)
  }, [selectedWorkshop])

  return (
    <div className="mt-4">
      {drivers?.map((item, key) => {
        console.log(item)
        return <NewDriverCard item={item} isLarge={true} isFlexCol={false} />
      })}
    </div>
  )
}

export default Drivers
