import React, { useState, useEffect } from "react"

function DebouceDropdown({ label, item, onTextChange }) {
  const [text, setText] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => {
      onTextChange(text.toUpperCase())
    }, 500) // debounce time in milliseconds

    return () => {
      clearTimeout(timer)
    }
  }, [text, onTextChange])

  const handleChange = (event) => {
    setText(event.target.value)
  }

  return (
    <label className="px-4 py-3 text-lg text-gray-400 bg-white rounded-md shadow-md group">
      <select
        className="bg-transparent focus:outline-none group-focus-within:text-black pr-5"
        value={text}
        onChange={handleChange}
        onClick={handleChange}
      >
        <option value="0">Select All {label}</option>
        {item.map((item, index) => {
            return (
                <option key={index} value={item.id}>{item.name}</option>
            )
        })}
      </select>
    </label>
  )
}

export default DebouceDropdown
