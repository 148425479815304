import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { capitalizeWords } from "../../library/functions"
import { useStoreDrivers, useStoreUsers, useStoreWorkshops } from "../../zustand/store"
import Input from "../inputs/Input"
import Select from "../inputs/Select"
import SinglePicture from "../inputs/SinglePicture"
import InputField from "./InputField"

const Form = ({ selectedUser, type, setVisible }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const watcher = watch()

  useEffect(() => {
    console.log(selectedUser)
    if (type === "edit") {
      const nsu = { ...selectedUser, password: "", workshop: selectedUser?.driver_data?.workshop ?? null }
      reset(nsu)
    } else {
      reset({})
    }
  }, [type, reset, selectedUser])

  const addDriver = useStoreDrivers((s) => s.addDriver)
  const updateDriver = useStoreDrivers((s) => s.updateDriver)
  const addUser = useStoreUsers((s) => s.addUser)
  const updateUser = useStoreUsers((s) => s.updateUser)

  const onSubmit = handleSubmit(async (param) => {
    const name = capitalizeWords(
      param?.name_last?.trim() + ", " + param?.name_first?.trim() + " " + param?.name_middle?.trim()
    )
    console.log(param)
    if (type === "create") {
      let driver = null
      if (Number(param.user_type) === 4 || Number(param.user_type) === 3 || Number(param.user_type) === 1) {
        const newParams = {
          name: name,
          address: param.address,
          contact: param.phone_no,
          email: param.email,
          workshop: Number(param.user_type) !== 1 ? Number(param.workshop) : null,
          pic: param.profile_picture,
        }
        const { data, status } = await axios.post(apiUrl + "/drivers", newParams, config())
        if (status === 200) {
          toast.success("Driver Successfully Added...")
          addDriver(data)
          driver = data.id
        } else {
          toast.error("Somethings not right uploading data to drivers. Please check your Form.")
        }
      }

      const newUserParams = { ...param, driver, name }
      const { data, status } = await axios.post(apiUrl + "/users", newUserParams, config())
      console.log(data)
      if (status === 200) {
        toast.success("User Successfully Added...")
        addUser(data)
        setVisible(false)
      } else {
        toast.error("Somethings not right uploading data to Users. Please check your Form.")
      }
    } else if (type === "edit") {
      console.log("UserEdit", Number(param.workshop), Number(param.user_type))
      let driver = null
      if (selectedUser.driver) {
        const newParams = {
          id: selectedUser.driver,
          name: name,
          address: param.address,
          contact: param.phone_no,
          email: param.email,
          workshop: param.workshop ? Number(param.workshop) : null,
          pic: param.profile_picture,
        }
        const { data, status } = await axios.put(apiUrl + "/drivers", newParams, config())
        if (status === 200) {
          // toast.success("Driver Successfully Updated...")
          console.log(data)
          updateDriver(data)
          driver = data.id
        } else {
          toast.error("Somethings not right uploading data to drivers. Please check your Form.")
        }
      } else {
        if (Number(param.user_type) === 4 || Number(param.user_type) === 3 || Number(param.user_type) === 1) {
          const newParams = {
            name: name,
            address: param.address,
            contact: param.phone_no,
            email: param.email,
            workshop: Number(param.user_type) !== 1 ? Number(param.workshop) : null,
            pic: param.profile_picture,
          }
          const { data, status } = await axios.post(apiUrl + "/drivers", newParams, config())
          if (status === 200) {
            // toast.success("Driver Successfully Added...")
            addDriver(data)
            driver = data.id
          } else {
            toast.error("Somethings not right uploading data to drivers. Please check your Form.")
          }
        }
      }
      let newUserParams = { ...param, name }
      if (driver) {
        newUserParams = { ...newUserParams, driver }
      }
      const { data, status } = await axios.put(apiUrl + "/users", newUserParams, config())
      if (status === 200) {
        toast.success("User Successfully Updated...")
        console.log(data)
        updateUser(data)
        // setVisible(false)
      } else {
        toast.error("Somethings not right uploading data to Users. Please check your Form.")
      }
    }
  })

  const options = [
    { id: 1, label: "Admin" },
    { id: 2, label: "Agent" },
    { id: 3, label: "Manager" },
    { id: 4, label: "Driver" },
    { id: 5, label: "Agency" },
  ]
  const workshops = useStoreWorkshops((s) => s.workshops)
  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      <SinglePicture keyValue="profile_picture" setValue={setValue} value={watcher.profile_picture} />
      <h3 className="text-xl font-semibold">Personal Information</h3>
      <div className="flex items-stretch gap-2">
        <InputField name="name_first" label="First Name" register={register} />
        <InputField name="name_middle" label="Middle Name" register={register} />
        <InputField name="name_last" label="Last Name" register={register} />
      </div>
      <div className="flex gap-2">
        <InputField name="dob" label="Birthday" register={register} />
        <InputField name="gender" label="Gender" register={register} />
        <InputField name="phone_no" label="Phone Number" register={register} />
      </div>
      <h3 className="text-xl font-semibold">Location Information</h3>

      <div className="flex gap-2">
        <InputField name="address" label="Address" register={register} />
        <InputField name="home_no" label="Home Number" register={register} />
      </div>
      <h3 className="text-xl font-semibold">User Information</h3>
      <div className="flex gap-2">
        {/* <InputField name="email" label="Email" register={register} type="email" />
        <InputField name="username" label="Username" register={register} /> */}

        <Input
          register={register}
          name="email"
          label="Email"
          placeholder="Email"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Email Required"
        />

        <Input
          register={register}
          name="username"
          label="Username"
          placeholder="Username"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Username Required"
        />
      </div>
      <div className="flex gap-2">
        {/* <InputField name="password" label="Password" register={register} type="password" /> */}
        <Input
          register={register}
          name="password"
          label="Password"
          placeholder={type === "create" ? "Enter password" : "Type to change the current password"}
          type="password"
          condition={{ required: type === "create" ? true : false }}
          error={errors}
          message="Password Required"
          capitalize={false}
        />
        <Select
          register={register}
          name="user_type"
          label="User Type"
          placeholder=""
          condition={{ required: true }}
          error={errors}
          message="User Type Required"
          items={options}
          selectKey="label"
        />
      </div>

      {Number(watcher.user_type) === 3 || Number(watcher.user_type) === 4 || Number(watcher.user_type) === 5 ? (
        <div className="flex gap-2">
          <Select
            register={register}
            name="workshop"
            label="Workshop"
            placeholder="Select Workshop"
            condition={{ required: true }}
            error={errors}
            message="Workshop Required"
            items={workshops}
            selectKey="name"
          />
        </div>
      ) : (
        <div></div>
      )}
      <button
        type="submit"
        className="self-end px-6 py-2 text-lg font-semibold text-white bg-[#F06400] rounded-md w-fit "
      >
        {type === "create" ? "Submit" : "Update"}
      </button>
    </form>
  )
}

export default Form
