import React, { useEffect } from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { variants } from "../../../library/constant"
import Files from "../../inputs/Files"
import { useMemo } from "react"
import { DetailField } from "../FormView"
import FilesAll from "../../inputs/FilesAll"

const Attachments = ({ setValue, watcher }) => {
  const [formVisible, setFormVisible] = useState(false)
  const [files, setFiles] = useState(null)
  const [uploadFiles, setUploadFiles] = useState(null)

  useEffect(() => {
    const attachmentsFiles = uploadFiles
      ? uploadFiles.map((item, key) => ({ filename: item.filename, name: files[key]?.file?.name }))
      : []
    setValue("attachments", JSON.stringify(attachmentsFiles))
  }, [uploadFiles, files])

  const attachments = useMemo(() => {
    let att = []
    try {
      att = JSON.parse(watcher?.attachments)
    } catch (error) {}
    return att
  }, [watcher?.attachments])

  return (
    <div>
      <div className="flex flex-col mt-6">
        <div
          className="flex justify-between w-full text-xl font-semibold cursor-pointer"
          onClick={() => setFormVisible(!formVisible)}
        >
          <div className="flex items-center gap-4">
            <div className="text-[#f06400]">Attachments</div>
          </div>
          <i className="mx-4 fa-solid fa-chevron-down"></i>
        </div>
        <div className="w-full h-2 border-b"></div>
      </div>
      <motion.div animate={formVisible ? "open" : "closed"} variants={variants} className="h-0 overflow-hidden">
        <div className="flex">
          <FilesAll
            files={files}
            setFiles={setFiles}
            isWhat={"insert"}
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            accept="*"
          />
        </div>

        <div>
          <DetailField label="Attachments" value={attachments ?? []} type="files-object" />
        </div>
      </motion.div>
    </div>
  )
}

export default Attachments
