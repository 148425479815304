import { useRef } from "react"
import { Button, Group } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"

const MantineDropzone = () => {
  const openRef = useRef()

  return (
    <>
      <Dropzone openRef={openRef} classNames={{ root: "border-none bg-neutral-500" }}>
        <div className="border-2 flex justify-center items-center border-dashed border-[#F06400] p-4 rounded-md">
          <div className="w-[60%] text-center my-20">
            <i className="fa-regular fa-photo-film text-3xl text-[#F06400]"></i>
            <p className="font-semibold text-lg">
              Drag & Drop <span className="text-[#F06400]">images</span>
            </p>
            <div className="">
              or <button className="text-[#F06400]">browse files</button> on your computer
            </div>
          </div>
        </div>
      </Dropzone>
    </>
  )
}

export default MantineDropzone
