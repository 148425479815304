import React, { useEffect, useState } from "react"
import { useStore, useStoreServices } from "../../zustand/store"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import * as dayjs from "dayjs"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import Loading from "../Loading"
import { toast } from "react-toastify"
import { getMessage, getMessagedItem } from "../../library/functions"
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

const Tickets = ({ viewWhat }) => {
  const loggedUser = useStore((state) => state.loggedUser)
  const services = useStoreServices((s) => s.services)
  const workshop = useStore((state) => state.workshop)
  const userType = useStore((state) => state.userType)
  const [workshopServices, setWorkshopServices] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [selected, setSelected] = useState(0)
  const [total, setTotal] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (userType === 3 || userType === 4) {
        setIsLoading(true)
        const res = await axios.get(apiUrl + `/services/workshop/${workshop}`, config())
        const services = res.data ?? []
        setWorkshopServices(services)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })()
  }, [userType, workshop])

  const filteredServices = useMemo(() => {
    let newServices = services

    if (userType === 3 || userType === 4) {
      newServices = workshopServices
    }

    if (viewWhat === "new") {
      newServices = newServices.filter((item) => item.status_types === 1 && item)
    } else if (viewWhat === "progress") {
      newServices = newServices.filter(
        (item) => (item.status_types === 2 || item.status_types === 5 || item.status_types === 6) && item
      )
    } else if (viewWhat === "completed") {
      newServices = newServices.filter(
        (item) => (item.status_types === 3 || item.status_types === 4) && item.status_sub_types !== 13 && item
      )
    } else if (viewWhat === "rejected") {
      newServices = newServices.filter((item) => item.status_sub_types === 13 && item)
    } else if (viewWhat === "all") {
      if (userType !== 2) {
        newServices = newServices.filter((item) => item.status_sub_types !== 1)
      }
      // newServices = newServices.filter((item) => item.status_types !== 3 && item)
      newServices = newServices.filter((item) => item.status_types !== 4 && item)
      // newServices = newServices.filter((item) => item.status_sub_types !== 7 && item)
      newServices = newServices.filter((obj) => !(obj.status_types === 1 && obj.assignedTo))
      newServices = newServices.filter((item) => item.status_sub_types !== 11)
    } else if (viewWhat === "driver_todo") {
      newServices = newServices.filter(
        (item) =>
          (item.status_types === 1 ||
            item.status_sub_types === 9 ||
            item.status_sub_types === 10 ||
            item.status_sub_types === 15 ||
            item.status_sub_types === 17) &&
          item.assignedTo === loggedUser?.id &&
          item
      )
    }

    setTotal(newServices)

    if (selected === 12) {
      newServices = newServices.filter((item) => item.collection_needed === true && item)
    } else if (selected === 19) {
      newServices = newServices.filter((item) => item.collection_needed === false && item)
    }

    if (selected !== 0 && selected < 30) {
      newServices = newServices.filter((item) => item.status_sub_types === selected && item)
    } else if (selected > 30) {
      newServices = newServices.filter((item) =>
        getMessagedItem(item.status_sub_types, item.collection_needed, !Boolean(item.assignedTo), item, selected)
      )
    }
    return newServices
  }, [services, userType, viewWhat, workshopServices, selected])

  const selectItem = (id) => {
    setSelected(id)
  }

  const Count = ({ count }) => (
    <div
      className={`badge badge-warning font-bold ml-2  ${
        count !== 0 ? "bg-red-600 text-white" : "bg-gray-600 text-white"
      }`}
    >
      {count}
    </div>
  )

  return (
    <div className="flex flex-col gap-4 mt-4">
      {isLoading ? (
        <div className="">
          <Loading />
          {/* <span className="loading loading-infinity loading-lg"></span> */}
        </div>
      ) : (
        <>
          {viewWhat === "new" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => selectItem(0)}>
                All
                <Count count={total?.length} />
              </div>
              <div className={`tab tab-lifted ${selected === 1 ? "tab-active" : ""}`} onClick={() => selectItem(1)}>
                New
                <Count count={total?.filter((item) => item.status_sub_types === 1 && item).length} />
              </div>
              {/* <div className={`tab tab-lifted ${selected === 2 ? "tab-active" : ""}`} onClick={() => selectItem(2)}>
                Todo{" "}
                <Count count={total?.filter((item) => item.status_sub_types === 2 && item).length} />
              </div> */}
              <div className={`tab tab-lifted ${selected === 3 ? "tab-active" : ""}`} onClick={() => selectItem(3)}>
                Service Scheduled <Count count={total?.filter((item) => item.status_sub_types === 3 && item).length} />
              </div>
            </div>
          )}
          {viewWhat === "progress" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => selectItem(0)}>
                All
                <Count count={total?.length} />
              </div>
              <div className={`tab tab-lifted ${selected === 4 ? "tab-active" : ""}`} onClick={() => selectItem(4)}>
                Collected <Count count={total.filter((item) => item.status_sub_types === 4 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 5 ? "tab-active" : ""}`} onClick={() => selectItem(5)}>
                Received <Count count={total.filter((item) => item.status_sub_types === 5 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 6 ? "tab-active" : ""}`} onClick={() => selectItem(6)}>
                For Assessment
                <Count count={total.filter((item) => item.status_sub_types === 6 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 7 ? "tab-active" : ""}`} onClick={() => selectItem(7)}>
                For Approval
                <Count count={total.filter((item) => item.status_sub_types === 7 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 8 ? "tab-active" : ""}`} onClick={() => selectItem(8)}>
                Work In Progress
                <Count count={total.filter((item) => item.status_sub_types === 8 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 9 ? "tab-active" : ""}`} onClick={() => selectItem(9)}>
                Ready for Delivery
                <Count count={total.filter((item) => item.status_sub_types === 9 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 10 ? "tab-active" : ""}`} onClick={() => selectItem(10)}>
                Out for Delivery
                <Count count={total.filter((item) => item.status_sub_types === 10 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 15 ? "tab-active" : ""}`} onClick={() => selectItem(15)}>
                Transfer Scheduled
                <Count count={total.filter((item) => item.status_sub_types === 15 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 16 ? "tab-active" : ""}`} onClick={() => selectItem(16)}>
                Transfer Delivered
                <Count count={total.filter((item) => item.status_sub_types === 16 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 17 ? "tab-active" : ""}`} onClick={() => selectItem(17)}>
                Change RC Scheduled
                <Count count={total.filter((item) => item.status_sub_types === 17 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 18 ? "tab-active" : ""}`} onClick={() => selectItem(18)}>
                RC Collected
                <Count count={total.filter((item) => item.status_sub_types === 18 && item).length} />
              </div>
            </div>
          )}
          {viewWhat === "completed" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => selectItem(0)}>
                All
                <Count count={total.length} />
              </div>
              <div className={`tab tab-lifted ${selected === 11 ? "tab-active" : ""}`} onClick={() => selectItem(11)}>
                Cancelled
                <Count count={total.filter((item) => item.status_sub_types === 11 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 12 ? "tab-active" : ""}`} onClick={() => selectItem(12)}>
                Delivered
                <Count
                  count={
                    total.filter((item) => item.status_sub_types === 12 && item.collection_needed === true && item)
                      .length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 19 ? "tab-active" : ""}`} onClick={() => selectItem(19)}>
                Done
                <Count
                  count={
                    total.filter((item) => item.status_sub_types === 12 && item.collection_needed === false && item)
                      .length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 14 ? "tab-active" : ""}`} onClick={() => selectItem(14)}>
                Closed
                <Count count={total.filter((item) => item.status_sub_types === 14 && item).length} />
              </div>
            </div>
          )}
          {viewWhat === "rejected" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 13 ? "tab-active" : ""}`} onClick={() => selectItem(13)}>
                Rejected
                <Count count={total.length} />
              </div>
            </div>
          )}

          {viewWhat === "all" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => selectItem(0)}>
                All
                <Count count={total?.length} />
              </div>
              {userType === 2 && (
                <div className={`tab tab-lifted ${selected === 31 ? "tab-active" : ""}`} onClick={() => selectItem(31)}>
                  Awaiting For Ticket Action
                  <Count
                    count={
                      total?.filter((item) =>
                        getMessagedItem(
                          item.status_sub_types,
                          item.collection_needed,
                          !Boolean(item.assignedTo),
                          item,
                          31
                        )
                      ).length
                    }
                  />
                </div>
              )}

              <div className={`tab tab-lifted ${selected === 32 ? "tab-active" : ""}`} onClick={() => selectItem(32)}>
                Assign to Driver
                <Count
                  count={
                    total?.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        32
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 33 ? "tab-active" : ""}`} onClick={() => selectItem(33)}>
                Collect Vehicle
                <Count
                  count={
                    total?.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        33
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 34 ? "tab-active" : ""}`} onClick={() => selectItem(34)}>
                Receive Vehicle
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        34
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 35 ? "tab-active" : ""}`} onClick={() => selectItem(35)}>
                Set For Assessment
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        35
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 36 ? "tab-active" : ""}`} onClick={() => selectItem(36)}>
                Assess Vehicle
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        36
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 37 ? "tab-active" : ""}`} onClick={() => selectItem(37)}>
                Approve Job Order
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        37
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 38 ? "tab-active" : ""}`} onClick={() => selectItem(38)}>
                Approve Request
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        38
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 39 ? "tab-active" : ""}`} onClick={() => selectItem(39)}>
                Complete Job Order
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        39
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 40 ? "tab-active" : ""}`} onClick={() => selectItem(40)}>
                Complete Request
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        40
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 41 ? "tab-active" : ""}`} onClick={() => selectItem(41)}>
                Assign to Driver
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        41
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 42 ? "tab-active" : ""}`} onClick={() => selectItem(42)}>
                Deliver Vehicle
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        42
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 43 ? "tab-active" : ""}`} onClick={() => selectItem(43)}>
                Awaiting For Client Decision
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        43
                      )
                    ).length
                  }
                />
              </div>
              {/* <div className={`tab tab-lifted ${selected === 44 ? "tab-active" : ""}`} onClick={() => selectItem(44)}>
                Close Ticket
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        44
                      )
                    ).length
                  }
                />
              </div> */}
              {/* <div className={`tab tab-lifted ${selected === 45 ? "tab-active" : ""}`} onClick={() => selectItem(45)}>
                Rejected
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        45
                      )
                    ).length
                  }
                />
              </div> */}
              <div className={`tab tab-lifted ${selected === 46 ? "tab-active" : ""}`} onClick={() => selectItem(46)}>
                Collect RC from Client
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        46
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 47 ? "tab-active" : ""}`} onClick={() => selectItem(47)}>
                Receive Replaced RC
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        47
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 48 ? "tab-active" : ""}`} onClick={() => selectItem(48)}>
                Transfer Leased Vehicle
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        48
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 49 ? "tab-active" : ""}`} onClick={() => selectItem(49)}>
                Recieve Transfered Leased Vehicle
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        49
                      )
                    ).length
                  }
                />
              </div>
              <div className={`tab tab-lifted ${selected === 50 ? "tab-active" : ""}`} onClick={() => selectItem(50)}>
                Close Ticket
                <Count
                  count={
                    total.filter((item) =>
                      getMessagedItem(
                        item.status_sub_types,
                        item.collection_needed,
                        !Boolean(item.assignedTo),
                        item,
                        50
                      )
                    ).length
                  }
                />
              </div>
            </div>
          )}

          {viewWhat === "driver_todo" && (
            <div className="tabs">
              <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => selectItem(0)}>
                All
                <Count count={total?.length} />
              </div>
              {/* <div className={`tab tab-lifted ${selected === 1 ? "tab-active" : ""}`} onClick={() => selectItem(1)}>
                New
                <Count count={total?.filter((item) => item.status_sub_types === 1 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 2 ? "tab-active" : ""}`} onClick={() => selectItem(2)}>
                Todo
                <Count count={total?.filter((item) => item.status_sub_types === 2 && item).length} />
              </div> */}
              <div className={`tab tab-lifted ${selected === 3 ? "tab-active" : ""}`} onClick={() => selectItem(10)}>
                Awaiting For Client Decision
                <Count count={total?.filter((item) => item.status_sub_types === 10 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 3 ? "tab-active" : ""}`} onClick={() => selectItem(3)}>
                Service Scheduled
                <Count count={total?.filter((item) => item.status_sub_types === 3 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 9 ? "tab-active" : ""}`} onClick={() => selectItem(9)}>
                Ready for Delivery
                <Count count={total.filter((item) => item.status_sub_types === 9 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 15 ? "tab-active" : ""}`} onClick={() => selectItem(15)}>
                Transfer Scheduled
                <Count count={total.filter((item) => item.status_sub_types === 15 && item).length} />
              </div>
              <div className={`tab tab-lifted ${selected === 17 ? "tab-active" : ""}`} onClick={() => selectItem(17)}>
                Change RC Scheduled
                <Count count={total.filter((item) => item.status_sub_types === 17 && item).length} />
              </div>
            </div>
          )}

          {services && filteredServices.length !== 0
            ? filteredServices.map((item, key) => {
                if (viewWhat === "all" || viewWhat === "driver_todo") {
                  return (
                    <Card
                      item={item}
                      key={key}
                      message={getMessage(item.status_sub_types, item.collection_needed, !Boolean(item.assignedTo))}
                    />
                  )
                } else {
                  return <Card item={item} key={key} />
                }
              })
            : "No Tickets Found"}
        </>
      )}
    </div>
  )
}

export default Tickets

const Card = ({ item, message = "" }) => {
  const userType = useStore((state) => state.userType)
  const loggedUser = useStore((state) => state.loggedUser)
  let [searchParams, setSearchParams] = useSearchParams()
  const viewTicket = (req_no) => {
    if (userType === 4 && item?.assignedTo !== loggedUser?.id) {
      toast.warning("You dont have Access to this Ticket")
    } else {
      console.log(req_no)
      setSearchParams({ service_number: req_no })
    }
  }
  const client_data = item?.client_api_data ? JSON.parse(item?.client_api_data) : {}
  return (
    <div
      className="flex justify-between py-4 rounded-lg shadow-lg cursor-pointer transition-all ease-in hover:translate-x-3"
      onClick={() => viewTicket(item.request_no)}
    >
      <div className="ml-4 ">
        <div className="text-lg">{item.request_no}</div>
        <div className="text-sm">
          {client_data?.PLATE_NO ? client_data?.PLATE_NO + " - " : ""}
          {client_data?.MAKE ? client_data?.MAKE + " - " : ""}
          {client_data?.MODEL ? client_data?.MODEL + " - " : ""}
          {dayjs(item.added_date).fromNow("s")} ago
        </div>
        <div className="flex text-sm">
          {message ? (
            <>
              <div className="">Service Action:</div>
              <div className="ml-2 text-[#F06400] font-bold">{message}</div>{" "}
            </>
          ) : (
            <>
              <div className="">Sub Status:</div>
              <div className="ml-2 text-[#F06400] font-bold">{item?.status_sub_types_data?.name}</div>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-4">
          {/* <div className="flex text-xs text-[#F06400]">
            <div className="p-2 bg-green-500 rounded-full"></div>
            <div className="ml-2">{item?.status_types_data?.name}</div>
          </div> */}
          <div className="flex flex-col mt-2 text-xs text-[#F06400]">
            {/* <div className="p-2 bg-[#F06400] rounded-full"></div> */}

            {/* <div className="ml-2">{item?.status_sub_types_data?.name}</div> */}
          </div>
        </div>
        <div>
          <i className="mx-4 fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
  )
}
