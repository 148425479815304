import React from "react"

const Select = ({
  register,
  name,
  condition = {},
  type = "text",
  placeholder,
  error,
  message = "No Error Message",
  label = "",
  className,
  items,
  selectKey,
  hasAddItem = false,
  disabled = false,
  hasColoring = false,
  colorKey = "",
  onClick = () => false,
  onChange = () => false,
  showPlaceholder = true,
  selectRef
}) => {

  const customRef = selectRef ? {ref: selectRef}: {}
  return (
    <div className={"w-full form-control " + className}>
      {label && (
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
      )}

      <select
        {...register(name, condition)}
        {...customRef}
        placeholder={placeholder}
        className="w-full select select-bordered"
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
      >
        {showPlaceholder ? <option value="">{placeholder}</option> : ""}

        {items?.map((item, key) => {
          return (
            <option
              value={item.id}
              key={key}
              className={`${hasColoring ? `${item[colorKey] ? "text-[#db240c]" : ""}` : ""}`}
            >
              {item[selectKey]}
            </option>
          )
        })}
        {hasAddItem && (
          <option value="add">
            Add New
            {/* <div className="flex justify-between">
              <div>Add New</div>
              <i className="fa-solid fa-circle-plus"></i>
            </div> */}
          </option>
        )}
      </select>
      <label className="pt-0 label">
        <span className="label-text-alt"></span>
        {error && error[name] && <span className="italic text-red-500 label-text-alt">{message}</span>}
        {/* <span className="label-text-alt">Alt label</span> */}
      </label>
    </div>
  )
}

export default Select
