import React from "react"
import { useStoreServices, useStoreUsers } from "../../../zustand/store"
import { useForm } from "react-hook-form"
import axios from "axios"
import { apiUrl, config } from "../../../library/constant"
import { toast } from "react-toastify"
import { useMemo } from "react"
import { useEffect } from "react"
import Select from "../../inputs/Select"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const TransferTicket = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)
  const users = useStoreUsers((s) => s.users)

  const onSubmit = useCbOnce((data) => {
    const added_by = Number(data.added_by)
    const submitData = {
      id: selectedService.id,
      added_by: added_by,
    }

    axios.post(apiUrl + "/services/transfer_ticket", submitData, config()).then((res) => {
      setSelectedService(res.data)
      updateService(res.data)
      toast.info("Successfully Transferred to New User")
    })

    setIsVisible(false)
  })

  const filteredUsers = useMemo(() => {
    const newUsers = users.filter(
      (item) => item.id !== selectedService?.added_by && (item.user_type === 2 || item.user_type === 1)
    )
    console.log(newUsers)
    return newUsers
  }, [users, selectedService])

  useEffect(() => {
    console.log(filteredUsers)
  }, [filteredUsers])

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="flex mb-2">
          <div className="mr-2">Current User: </div>
          <div className="font-bold">{selectedService?.added_by_data?.name}</div>
        </div>

        <Select
          register={register}
          name="added_by"
          label="Users"
          placeholder="Select User"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="User Required"
          items={filteredUsers}
          selectKey="name"
          hasColoring={true}
        />

        <div className="flex items-center justify-end w-full gap-2">
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="button"
            onClick={() => setIsVisible(false)}
          >
            cancel
          </button>
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default TransferTicket
