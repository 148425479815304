const timeVals = [
  {
    id: "8:00",
    name: "8:00 AM - 8:30 AM",
  },
  {
    id: "8:30",
    name: "8:30 AM - 9:00 AM",
  },
  {
    id: "9:00",
    name: "9:00 AM - 9:30 AM",
  },
  {
    id: "9:30",
    name: "9:30 AM - 10:00 AM",
  },
  {
    id: "10:00",
    name: "10:00 AM - 10:30 AM",
  },
  {
    id: "10:30",
    name: "10:30 AM - 11:00 AM",
  },
  {
    id: "11:00",
    name: "11:00 AM - 11:30 AM",
  },
  {
    id: "11:30",
    name: "11:30 AM - 12:00 PM",
  },
  {
    id: "12:00",
    name: "12:00 PM - 12:30 PM",
  },
  {
    id: "12:30",
    name: "12:30 PM - 1:00 PM",
  },
  {
    id: "13:00",
    name: "1:00 PM - 1:30 PM",
  },
  {
    id: "13:30",
    name: "1:30 PM - 2:00 PM",
  },
  {
    id: "14:00",
    name: "2:00 PM - 2:30 PM",
  },
  {
    id: "14:30",
    name: "2:30 PM - 3:00 PM",
  },
  {
    id: "15:00",
    name: "3:00 PM - 3:30 PM",
  },
  {
    id: "15:30",
    name: "3:30 PM - 4:00 PM",
  },
  {
    id: "16:00",
    name: "4:00 PM - 4:30 PM",
  },
  {
    id: "16:30",
    name: "4:30 PM - 5:00 PM",
  },
]

export function customRoundOffTime(inputTime) {
  const [hour, minute] = inputTime.split(":")
  let roundedMinute

  if (minute >= 1 && minute <= 29) {
    roundedMinute = "00"
  } else if (minute >= 31 && minute <= 59) {
    roundedMinute = "30"
  } else {
    roundedMinute = minute
  }

  return `${hour}:${roundedMinute}`
}

export function getTimeRange(newInput) {
  const input = customRoundOffTime(newInput)
  console.log(input)

  const result = timeVals.find((timeSlot) => timeSlot.id === input)

  if (result) {
    return result.name
  } else {
    return "Input is not inside a time range"
  }
}
