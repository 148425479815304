import GSTC from "gantt-schedule-timeline-calendar"
import React, { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import { HashLoader } from "react-spinners"
import { apiUrl, config } from "../../library/constant"
import { useStore, useStoreCalendar, useStoreServices } from "../../zustand/store"
import Calendar from "../calendar/Calendar"
import { DateFilter } from "../schedules/DateFilter"
import { chartData, rowGTSCDataProcessor } from "../schedules/Filters"

const ReplacementSchedules = ({ car, cdate = null, watcher, setValue, register, errors, clientNo = "" }) => {

  const [loading, setLoading] = useState(false);

  const service = useStoreServices((s) => s.selectedService)
  const { setCalendarItems, setCalendarRows, setOldCalendarRows } = useStoreCalendar()
  // const calendar = useStore((s) => s.calendar)

  const uppercaseText = (text) => {
    // console.log("text", text)
    if (text) {
      return text?.toUpperCase()?.trim()
    } else {
      return text
    }
  }

  useEffect(() => {
    setLoading(true)
    if (car) {
      let repCars = car

      // console.log("repCars", repCars)

      // calendar.update("config.chart.items", (s) => {
      //   const newRows = GSTC.api.fromArray([])
      //   return newRows
      // })
      // calendar.update("config.list.rows", (s) => {
      //   const newRows = GSTC.api.fromArray(rowGTSCDataProcessor(repCars))
      //   return newRows
      // })

      // remove all no price
      // repCars = repCars.filter(item => item.price != "")

      // filter by model
      // repCars = repCars.filter(item => uppercaseText(item.model) === uppercaseText(watcher?.vehicle_category))

      // filter by location
      repCars = repCars.filter(item => uppercaseText(item.location) === uppercaseText(watcher?.collection_state))

      setOldCalendarRows(rowGTSCDataProcessor(repCars))
      setCalendarRows(rowGTSCDataProcessor(repCars))


      let ganttSched = []
      for (let index = 0; index < repCars.length; index++) {
        const repCar = repCars[index]
        if (repCar.schedules.length !== 0) {
          // console.log("repCar.schedules", repCar.schedules)
          let schedules = repCar.schedules
          for (let index = 0; index < schedules.length; index++) {
            const sched = schedules[index]
            const newGanttItem = {
              id: sched.id.toString(),
              row_id: sched.replacement_car,
              start: new Date(sched.start),
              end: new Date(sched.end),
              item: chartData(repCar, sched.service_data ?? null),
            }
            ganttSched.push(newGanttItem)
          }
        }
      }

      if (ganttSched.length !== 0) {
        setCalendarItems(ganttSched)
      }
    }

    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [car, watcher?.service_mileage, watcher?.collection_state, watcher?.collection_country, watcher?.vehicle_category])

  return (
    <div className="mt-2">
      <div className="">
        <DateFilter cdate={cdate} clientNo={clientNo} />
        {loading ? 
          <div className={`visible py-[100px]`}>
              <center>
                <HashLoader color="#f97316" loading={true} size={100}/>
              </center>
          </div>
        : 
          <Calendar selection={true} watcher={watcher} setValue={setValue} register={register} errors={errors} />
        }
      </div>
    </div>
  )
}

export default ReplacementSchedules
