import React from "react"
import { Menu, Transition } from "@headlessui/react"
import { useStore, useStoreAlerts } from "../../zustand/store"
import { useEffect } from "react"
import { AlertsItem } from "./AlertItem"

const AlertsComponent = () => {
  const alerts = useStoreAlerts((s) => s.alerts)
  const addAlert = useStoreAlerts((s) => s.addAlert)
  const socket = useStore((s) => s.socket)

  useEffect(() => {
    if (socket) {
      socket.on("notification-item", (data) => {
        addAlert(data)
        console.log(data)
      })
    }
    return () => {
      if (socket) {
        socket.off("notification-item")
      }
    }
  }, [socket])

  return (
    <div className="flex items-center justify-center z-[1000]">
      <div className="relative inline-block text-left">
        <Menu>
          {({ open }) => (
            <>
              {/* <Menu.Button className="flex items-center justify-center w-12 h-12 border-2 border-[#F06400] rounded-full">
                                <i className="fa-solid fa-user-tie"></i>
                            </Menu.Button> */}

              <Menu.Button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                <i className="fa-solid fa-bell"></i>
                <div className="absolute right-0 bottom-3">
                  <div className="w-3 h-3 bg-[#F06400] rounded-full animate-pulse"></div>
                </div>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 w-64 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none md:w-96"
                >
                  <div className="flex items-center ml-6">
                    <div>
                      <i className="fa-solid fa-bell"></i>
                    </div>
                    <div className="px-4 py-3">
                      <p className="text-sm leading-5">Notifications</p>
                      {/* <p className="text-sm font-medium leading-5 text-gray-900 truncate">{alerts?.length} Recieved</p> */}
                    </div>
                  </div>

                  <div className="overflow-y-auto divide-y divide-gray-100 h-[400px]">
                    {alerts &&
                      alerts?.map((item, index) => (
                        <div key={index}>
                          <AlertsItem item={item} />
                        </div>
                      ))}
                  </div>

                  {/* <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#sign-out"
                          className={`${
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          View All
                        </a>
                      )}
                    </Menu.Item>
                  </div> */}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}

export default AlertsComponent
