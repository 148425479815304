import React, { useState } from "react"
import AnimatedPage from "../components/AnimatedPage"
import DebouceSearchInput from "../components/inputs/DebouceSearchInput"
import Table from "../components/audit_logs/Table"
import { useStore } from "../zustand/store"
import NoAccess from "./NoAccess"

const AuditLogs = () => {
  const privileges = useStore((state) => state.privileges)
  const [searchText, setSearchText] = useState("")

  if (!Boolean(privileges[14])) {
    return <NoAccess />
  }
  return (
    <AnimatedPage title="Audit Logs">
      <div className="flex flex-col">
        <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
          <div className="flex gap-4">
            <DebouceSearchInput onTextChange={setSearchText} />
          </div>
        </div>
        <Table searchText={searchText}/>
      </div>
    </AnimatedPage>
  )
}

export default AuditLogs
