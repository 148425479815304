import React from "react"

const InputField = ({ name, register, label, type = "text" }) => {
  return (
    <label className="flex flex-col w-full gap-3">
      <span>{label}</span>
      <input
        type={type}
        {...register(name)}
        className="border-b-2 border-gray-200 focus:outline-none focus:border-b-[#F06400] input input-bordered"
      />
    </label>
  )
}

export default InputField
