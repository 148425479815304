import React from "react"
import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"

const ExcelDownload = ({ excelData, fileName, isIcon = false }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div className="px-4 py-2 text-white rounded-md btn btn-success" title="Export Excel" onClick={exportToExcel}>
      {isIcon ? <i className="fa-solid fa-file-excel text-lg"/> : `Download Excel`}
    </div>
  )
}

export default ExcelDownload
