import axios from "axios"
import DateCalc from "date-calc"
import GSTC from "gantt-schedule-timeline-calendar"
import React, { useEffect } from "react"
import { useMemo } from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../../library/constant"
import {
  useStore,
  useStoreCalendar,
  useStoreReplacementCars,
  useStoreSchedule,
  useStoreServices,
} from "../../../zustand/store"
import ReplacementCarDetails from "../forms/ReplacementCarDetails"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const ChangeRC = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const [selectedRC, setSelectedRC] = useState(null)
  const watcher = watch()
  const { replacementCars: storeReplacementCars, setReplacementCars: setStoreReplacementCars } =
    useStoreReplacementCars()
  const selectedFrom = useStoreSchedule((s) => s.selectedFrom)
  const selectedTo = useStoreSchedule((s) => s.selectedTo)
  const setSelectedFrom = useStoreSchedule((s) => s.setSelectedFrom)
  const setSelectedTo = useStoreSchedule((s) => s.setSelectedTo)
  const selectedId = useStoreSchedule((s) => s.selectedId)
  const setSelectedId = useStoreSchedule((s) => s.setSelectedId)

  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const { setCalendarFrom, setCalendarTo } = useStoreCalendar()

  const filteredReplacementCars = useMemo(() => {
    if (storeReplacementCars) {
      let repCars = [...storeReplacementCars]
      repCars = repCars.sort((a, b) => a?.utilization - b?.utilization)

      if (watcher.workshop) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.workshop) === element.workshop) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }
      if (watcher.brand) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.brand) === element.brand) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watcher.replacement_car_category) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.replacement_car_category) === Number(element.category)) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watcher.year) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (watcher.year === element.year) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watcher.client_no) {
        let tempCars = []
        for (let index = 0; index < storeReplacementCars.length; index++) {
          const element = storeReplacementCars[index]
          if (element?.locked_to?.includes(String(watcher.client_no))) {
            tempCars.push(element)
          }
        }
        if (tempCars.length !== 0) {
          repCars = tempCars
        }
      }
      console.log("reloading Replacement Cars")
      return repCars
    } else {
      return []
    }
  }, [
    storeReplacementCars,
    watcher.workshop,
    watcher.brand,
    watcher.replacement_car_category,
    watcher.year,
    watcher.client_no,
  ])

  useEffect(() => {
    if (storeReplacementCars && selectedId) {
      for (let index = 0; index < storeReplacementCars.length; index++) {
        const replacementCar = storeReplacementCars[index]
        if (replacementCar.id === selectedId) {
          setSelectedRC(replacementCar)
        }
      }
    }
  }, [storeReplacementCars, selectedId])

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setValue("selectedFrom", selectedFrom)
      setValue("selectedTo", selectedTo)
    }
  }, [selectedFrom, selectedTo, setValue])

  useEffect(() => {
    setSelectedId(null)
    setSelectedFrom(null)
    setSelectedTo(null)
  }, [])

  useEffect(() => {
    let d = new DateCalc()

    d.now()

    let numberOfDays = 30

    const startDate = d.now()
    const endDate = d.after(numberOfDays)

    const startyear = parseInt(startDate.toString().substr(0, 4))
    const startmonth = parseInt(startDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
    const startday = parseInt(startDate.toString().substr(6, 2))
    const startDateParsed = new Date(startyear, startmonth, startday)

    const endyear = parseInt(endDate.toString().substr(0, 4))
    const endmonth = parseInt(endDate.toString().substr(4, 2)) - 1 // Months are zero-based (0-11)
    const endday = parseInt(endDate.toString().substr(6, 2))
    const endDateParsed = new Date(endyear, endmonth, endday)

    setCalendarFrom(startDateParsed)
    setCalendarTo(endDateParsed)
  }, [])

  useEffect(() => {
    const rc = selectedService.replacement_car_data
    if (rc) {
      setValue("replacement_car_category", rc.category)
      setValue("workshop", rc.workshop)
    }
  }, [selectedService])

  const onSubmit = useCbOnce(async () => {
    console.log(selectedService?.replacement_car_schedule?.id)

    if (!selectedId) {
      toast.info("No Changes Made.")
      setIsVisible(false)
    }

    console.log("rcid", selectedId)
    const submitData1 = {
      id: selectedService.id,
      replacement_car: selectedId,
      replacement_vehicle: selectedService.replacement_vehicle,
      replacement_vehicle_in: selectedService.replacement_vehicle_in ?? selectedService.replacement_vehicle_out,
      replacement_vehicle_out: selectedService.replacement_vehicle_out,
      collection_driver: Number(selectedService.collection_driver),
    }
    const { data, status } = await axios.post(apiUrl + "/services/change_rc", submitData1, config())

    let replacement_car_schedule = null

    if (selectedFrom && selectedTo && selectedId && selectedService?.replacement_car_schedule?.id) {
      // Update Schedule
      const submitData = {
        id: selectedService.replacement_car_schedule.id,
        start: new Date(selectedFrom).toISOString(),
        end: new Date(selectedTo).toISOString(),
        replacement_car: Number(selectedId),
        service: selectedService.id,
      }
      console.log(submitData)

      const { data: data2 } = await axios.put(apiUrl + "/replacement_cars_schedules", submitData, config())
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)
      replacement_car_schedule = data2

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    } else if (selectedFrom && selectedTo && selectedId && !selectedService?.replacement_car_schedule?.id) {
      // Create Schedule
      const submitData = {
        start: new Date(selectedFrom).toISOString(),
        end: new Date(selectedTo).toISOString(),
        replacement_car: Number(selectedId),
        service: selectedService.id,
      }
      console.log(submitData)

      const { data: data2 } = await axios.post(apiUrl + "/replacement_cars_schedules", submitData, config())
      replacement_car_schedule = data2
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    }

    if (status === 200) {
      toast.success("Services Successfully Updated...")
      if (replacement_car_schedule) {
        let xData = {
          ...data,
          replacement_car_schedule: replacement_car_schedule,
        }
        setSelectedService(xData)
      } else {
        setSelectedService(data)
      }

      updateService(data)
      setIsVisible(false)
    }
  })

  const removeReplacementCar = async () => {
    if (selectedService?.replacement_car_schedule?.id) {
      await axios.delete(
        apiUrl + "/replacement_cars_schedules/" + selectedService.replacement_car_schedule.id,
        config()
      )
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <ReplacementCarDetails
          hasDrop={false}
          register={register}
          errors={errors}
          watcher={watcher}
          setValue={setValue}
          filteredReplacementCars={filteredReplacementCars}
          selectedRC={selectedRC}
        />
        <div className="flex items-center justify-end w-full gap-2 mt-4">
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="button"
            onClick={() => setIsVisible(false)}
          >
            cancel
          </button>
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChangeRC
