import React from "react"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import Card from "../Card"

const reqBody = `{
  "key": "6OH5DrzdazMKEOM1g0xqK3dOFRc9gs",
  "agent_email": "email@example.com",
  "emirate": "AUH",
  "plate": "string",
  "emirate_category": "R4",
  "service_type": "Regular Maintenance",
  "mileage": "string",
  "driver_name": "string",
  "driver_contact": "string",
  "driver_email": "string",
  "request_details": "string"
}`

const CreateAPI = () => {
  const jwtToken = config()
  const copyJWT = (toCopy) => {
    navigator.clipboard.writeText(toCopy)
    toast.info("Copied to Clipboard")
  }
  return (
    <Card>
      <div className="p-6">
        <div className="text-2xl">Create Service Request API</div>
        {/* 
        <div>
          <div className="mt-2 mb-2 font-bold">Authorization Token</div>
          <div className="flex items-center min-w-full px-4 py-2 bg-gray-300 rounded-md">
            <div className="overflow-hidden text-lg whitespace-nowrap text-ellipsis">
              {jwtToken.headers.Authorization}
            </div>
            <div
              className="px-3 py-1 text-2xl bg-white rounded-md cursor-pointer hover:bg-gray-600 hover:text-gray-300"
              onClick={() => copyJWT(jwtToken.headers.Authorization)}
            >
              <i className="fa-solid fa-clipboard"></i>
            </div>
          </div>
        </div>
        */}

        <div className="mt-4 mb-2 font-bold">API URL</div>
        <div className="flex items-center justify-between min-w-full px-4 py-2 mt-2 bg-green-100 rounded-md ring ring-green-400">
          <div className="flex">
            <div className="px-3 py-1 bg-green-400 rounded-md cursor-pointer">POST</div>
            <div className="ml-4">{apiUrl}/services/api</div>
          </div>

          <div
            className="px-3 py-1 text-2xl bg-white rounded-md cursor-pointer hover:bg-gray-600 hover:text-gray-300"
            onClick={() => copyJWT(`${apiUrl}/services/api`)}
          >
            <i className="fa-solid fa-clipboard"></i>
          </div>
        </div>
        <div className="mt-4 mb-2 font-bold">Request Body</div>
        <div className="relative">
          <div
            className="absolute top-4 right-4 z-[10000] px-3 py-1 text-2xl bg-white rounded-md cursor-pointer hover:bg-gray-600 hover:text-gray-300"
            onClick={() => copyJWT(reqBody)}
          >
            <i className="fa-solid fa-clipboard"></i>
          </div>
          <JsonBody />
        </div>
      </div>
    </Card>
  )
}

export default CreateAPI

const JsonBody = (second) => (
  <div className="rounded-md highlight-code">
    <pre
      className="body-param__example microlight"
      style={{ display: "block", overflowX: "auto", padding: "0.5em", background: "rgb(51, 51, 51)", color: "white" }}
    >
      <code className="language-json" style={{ whiteSpace: "pre" }}>
        <span>
          {"{"}
          {"\n"}
        </span>
        <span>{"  "}</span>
        <span className="hljs-attr">"key"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"6OH5DrzdazMKEOM1g0xqK3dOFRc9gs"</span>
        <span className="text-red-500">{" *Required: this key is required to insert your data."}</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"agent_email"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"email@example.com"</span>
        <span className="text-red-500">{" *Required: this string needs to exist inside users table"}</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"emirate"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"AUH"</span>
        <span className="text-red-500">{" *Required: this string needs to exist inside emirate_types table"}</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"plate"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"emirate_category"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"R4"</span>
        <span className="text-red-500">
          {" *Required: this string is actual field needs to exist inside auh_category table"}
        </span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"service_type"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"Regular Maintenance"</span>
        <span className="text-red-500">{" *Required: this string needs to exist inside service_types table"}</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"mileage"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"driver_name"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"driver_contact"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"driver_email"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>,{"\n"}</span>
        <span>{"  "}</span>
        <span className="hljs-attr">"request_details"</span>
        <span>: </span>
        <span style={{ color: "rgb(162, 252, 162)" }}>"string"</span>
        <span>{"\n"}</span>
        {"}"}
      </code>
    </pre>
  </div>
)
