import { motion } from "framer-motion"
import Input from "../../inputs/Input"
import { useEffect, useState } from "react"
import { variants } from "../../../library/constant"
import CarIntent from "../CarIntent"
import { useForm } from "react-hook-form"

const VehicleReport = ({
  setValue,
  lease_vehicle_in = null,
  lease_vehicle_out = null,
  replacement_vehicle_out = null,
  replacement_vehicle_in = null,
  lease_vehicle = null,
  replacement_vehicle = null,
  watcher,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <div className="flex flex-col mt-6">
        <div
          className="flex justify-between w-full text-xl font-semibold cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center gap-4">
            <div>Vehicle Report</div>
          </div>
          <i className="mx-4 fa-solid fa-chevron-down"></i>
        </div>
        <div className="w-full h-2 border-b"></div>
      </div>

      <motion.div animate={isOpen ? "open" : "closed"} variants={variants} className="h-0 overflow-hidden">
        <div className="px-6 mt-4">
          <div className="grid grid-cols-2 ">
            <CarIntent
              className="border-t border-l border-r"
              title="Leased Vehicle In"
              name="Vehicle In"
              id="lease_vehicle_in"
              xSetValue={setValue}
              data={lease_vehicle_in}
            />
            <CarIntent
              className="border-t border-r"
              title="Leased Vehicle Out"
              name="Vehicle Out"
              id="lease_vehicle_out"
              xSetValue={setValue}
              data={lease_vehicle_out}
            />
            {watcher.collection_needed && watcher.rc_needed ? (
              <>
                <CarIntent
                  className="border-t border-b border-l border-r"
                  title="Replacement Vehicle Out"
                  name="Vehicle Out"
                  id="replacement_vehicle_out"
                  xSetValue={setValue}
                  data={replacement_vehicle_out}
                />
                <CarIntent
                  className="border-t border-b border-r"
                  title="Replacement Vehicle In"
                  name="Vehicle In"
                  id="replacement_vehicle_in"
                  xSetValue={setValue}
                  data={replacement_vehicle_in}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className="mt-4 text-lg font-bold">Leased Vehicle - IN</div>
            <LVInputs id="lease_vehicle" xSetValue={setValue} data={lease_vehicle} />
          </div>
          {watcher.collection_needed && watcher.rc_needed ? (
            <>
              <div>
                <div className="mt-4 text-lg font-bold">Replacement Vehicle - OUT</div>
                <LVInputs id="replacement_vehicle" xSetValue={setValue} isReverse={true} data={replacement_vehicle} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </motion.div>
    </div>
  )
}

export default VehicleReport

export const LVInputs = ({ xSetValue, id, isReverse = false, data = null }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const watcher = watch()

  useEffect(() => {
    xSetValue(id, JSON.stringify(watcher))
  }, [
    watcher.fleet_no,
    watcher.type,
    watcher.reg_no,
    watcher.date_in,
    watcher.time_in,
    watcher.km_in,
    watcher.fuel_in,
    watcher.date_out,
    watcher.time_out,
    watcher.km_out,
    watcher.fuel_out,
  ])

  useEffect(() => {
    if (data) {
      const x = JSON.parse(data)
      console.log(x)
      setValue("fleet_no", x.fleet_no)
      setValue("type", x.type)
      setValue("reg_no", x.reg_no)
      setValue("date_in", x.date_in)
      setValue("time_in", x.time_in)
      setValue("km_in", x.km_in)
      setValue("fuel_in", x.fuel_in)
      setValue("date_out", x.date_out)
      setValue("time_out", x.time_out)
      setValue("km_out", x.km_out)
      setValue("fuel_out", x.fuel_out)
    }
  }, [data])

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-x-4">
      {/* <Input register={register} name="fleet_no" label="Fleet No." placeholder="Fleet No." type="text" error={errors} />
      <Input register={register} name="type" label="Type" placeholder="Type" type="text" error={errors} />
      <Input register={register} name="reg_no" label="Reg No." placeholder="Reg No." type="text" error={errors} /> */}
      <div className={`${isReverse ? "flex-col-reverse" : "flex-col"} flex col-span-3`}>
        {/* IN */}
        <div className="grid grid-flow-row grid-cols-3 gap-x-4">
          <Input register={register} name="date_in" label="Date In" placeholder="Date In" type="date" error={errors} />
          <Input register={register} name="time_in" label="Time In" placeholder="Time In" type="time" error={errors} />
          <div className="flex gap-x-4">
            <Input register={register} name="km_in" label="Km In" placeholder="Km In" type="text" error={errors} />
            <Input
              register={register}
              name="fuel_in"
              label="Fuel In"
              placeholder="Fuel In"
              type="text"
              error={errors}
            />
          </div>
        </div>

        {/* OUT */}
        <div className="grid grid-flow-row grid-cols-3 gap-x-4">
          <Input
            register={register}
            name="date_out"
            label="Date Out"
            placeholder="Date Out"
            type="date"
            error={errors}
          />
          <Input
            register={register}
            name="time_out"
            label="Time Out"
            placeholder="Time Out"
            type="time"
            error={errors}
          />
          <div className="flex gap-x-4">
            <Input register={register} name="km_out" label="Km Out" placeholder="Km Out" type="text" error={errors} />
            <Input
              register={register}
              name="fuel_out"
              label="Fuel Out"
              placeholder="Fuel Out"
              type="text"
              error={errors}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

// const RVInputs = ({ register, errors }) => {
//   return (
//     <div className="grid grid-flow-row grid-cols-3 gap-x-4">
//       <Input
//         register={register}
//         name="rv_fleet_no"
//         label="Fleet No."
//         placeholder="Fleet No."
//         type="text"
//         error={errors}
//       />
//       <Input register={register} name="rv_type" label="Type" placeholder="Type" type="text" error={errors} />
//       <Input register={register} name="rv_reg_no" label="Reg No." placeholder="Reg No." type="text" error={errors} />

//       <Input
//         register={register}
//         name="rv_date_out"
//         label="Date Out"
//         placeholder="Date Out"
//         type="date"
//         error={errors}
//       />
//       <Input
//         register={register}
//         name="rv_time_out"
//         label="Time Out"
//         placeholder="Time Out"
//         type="time"
//         error={errors}
//       />
//       <div className="flex gap-x-4">
//         <Input register={register} name="rv_km_out" label="Km Out" placeholder="Km Out" type="text" error={errors} />
//         <Input
//           register={register}
//           name="rv_fuel_out"
//           label="Fuel Out"
//           placeholder="Fuel Out"
//           type="text"
//           error={errors}
//         />
//       </div>
//       <Input register={register} name="rv_date_in" label="Date In" placeholder="Date In" type="date" error={errors} />
//       <Input register={register} name="rv_time_in" label="Time In" placeholder="Time In" type="time" error={errors} />
//       <div className="flex gap-x-4">
//         <Input register={register} name="rv_km_in" label="Km In" placeholder="Km In" type="text" error={errors} />
//         <Input register={register} name="rv_fuel_in" label="Fuel In" placeholder="Fuel In" type="text" error={errors} />
//       </div>
//     </div>
//   )
// }
