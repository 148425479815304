import create from "zustand"
import { customSort } from "../library/functions"

const useStore = create((set) => ({
  token: null,
  setToken: (state) => set({ token: state }),
  loggedUser: null,
  userType: 0,
  privileges: [],
  workshop: 0,
  setLoggedUser: (state) => {
    // console.log(state)
    return set({
      loggedUser: state,
      userType: state?.user_type ?? 0,
      workshop: state?.driver_data?.workshop ?? 0,
      privileges: state?.user_type_data?.privileges ?? [],
    })
  },
  runningRequestNo: 1,
  setRunningRequestNo: (state) => set({ runningRequestNo: state }),
  socket: null,
  setSocket: (state) => set({ socket: state }),
  isVehicleModalOpen: false,
  setVehicleModalOpen: (state) => set({ isVehicleModalOpen: state }),
}))

const useStoreCalendar = create((set) => ({
  oldCalendarRows: [],
  calendarRows: [],
  oldCalendarItems: [],
  calendarItems: [],
  setOldCalendarRows: (state) => set({ oldCalendarRows: state }),
  setCalendarRows: (state) => set({ calendarRows: state }),
  setOldCalendarItems: (state) => set({ oldCalendarItems: state }),
  setCalendarItems: (state) => set({ calendarItems: state }),
  calendarFrom: new Date(),
  calendarTo: new Date(),
  setCalendarFrom: (state) => set({ calendarFrom: state }),
  setCalendarTo: (state) => set({ calendarTo: state }),
}))

const useStoreAlerts = create((set) => ({
  alerts: [],
  setAlerts: (payload) => set({ alerts: payload }),
  addAlert: (payload) =>
    set((state) => {
      const newArray = [payload].concat(state.alerts)
      return { alerts: newArray }
    }),
}))

const useStoreServiceMileages = create((set) => ({
  serviceMileages: [],
  setServiceMileages: (payload) => set({ serviceMileages: payload }),
}))

const useStoreMap = create((set) => ({
  map: null,
  setMap: (state) => set({ map: state }),
  hoverCoordinates: null,
  setHoverCoordinates: (state) => set({ hoverCoordinates: state }),
  selectedAddress: null,
  setSelectedAddress: (state) => set({ selectedAddress: state }),
  isAddressLoading: false,
  setAddressLoading: (state) => set({ isAddressLoading: state }),
  isFromMe: false,
  setIsFromMe: (state) => set({ isFromMe: state }),
}))

const useStoreServices = create((set) => ({
  services: null,
  setServices: (payload) => set({ services: payload }),
  selectedService: null,
  setSelectedService: (payload) => set({ selectedService: payload }),
  addService: (payload) => set((state) => ({ services: [...state.services, payload] })),
  updateService: (payload) =>
    set((state) => ({ services: state.services.map((item) => (item.id === payload.id ? payload : item)) })),
  deleteService: (payload) => set((state) => ({ services: state.services.filter((item) => item.id !== payload.id) })),
  isWhat: "insert",
  setIsWhat: (payload) => set({ isWhat: payload }),
}))

const useStoreWorkshops = create((set) => ({
  workshops: null,
  setWorkshops: (payload) => set({ workshops: payload }),
  selectedWorkshop: null,
  setSelectedWorkshop: (payload) => set({ selectedWorkshop: payload }),
  addWorkshop: (payload) => set((state) => ({ workshops: [...state.workshops, payload] })),
  updateWorkshop: (payload) =>
    set((state) => ({ workshops: state.workshops.map((item) => (item.id === payload.id ? payload : item)) })),
  deleteWorkshop: (payload) =>
    set((state) => ({ workshops: state.workshops.filter((item) => item.id !== payload.id) })),
  isWhat: "insert",
  setIsWhat: (payload) => set({ isWhat: payload }),
}))

const useStoreOverAllMaintenanceRates = create((set) => ({
  overAllMaintenanceRates: null,
  setOverAllMaintenanceRates: (payload) => set({ overAllMaintenanceRates: payload }),
  selectedOverAllMaintenanceRates: null,
  setSelectedOverAllMaintenanceRates: (payload) => set({ selectedOverAllMaintenanceRates: payload }),
  addOverAllMaintenanceRates: (payload) => set((state) => ({ overAllMaintenanceRates: [...state.overAllMaintenanceRates, payload] })),
  updateOverAllMaintenanceRates: (payload) =>
    set((state) => ({ overAllMaintenanceRates: state.overAllMaintenanceRates.map((item) => (item.id === payload.id ? payload : item)) })),
  deleteOverAllMaintenanceRates: (payload) =>
    set((state) => ({ overAllMaintenanceRates: state.overAllMaintenanceRates.filter((item) => item.id !== payload.id) })),
  isWhat: "insert",
  setIsWhat: (payload) => set({ isWhat: payload }),
}))

const useStoreDrivers = create((set) => ({
  drivers: null,
  setDrivers: (payload) => set({ drivers: payload }),
  selectedDriver: null,
  setSelectedDriver: (payload) => set({ selectedDriver: payload }),
  addDriver: (payload) => set((state) => ({ drivers: [...state.drivers, payload] })),
  updateDriver: (payload) =>
    set((state) => ({ drivers: state.drivers.map((item) => (item.id === payload.id ? payload : item)) })),
  deleteDriver: (payload) => set((state) => ({ drivers: state.drivers.filter((item) => item.id !== payload.id) })),
  isWhat: "insert",
  setIsWhat: (payload) => set({ isWhat: payload }),
}))

const useStoreUsers = create((set) => ({
  users: null,
  setUsers: (payload) => set({ users: payload }),
  selectedUser: null,
  setSelectedUser: (payload) => set({ selectedUser: payload }),
  addUser: (payload) => set((state) => ({ users: [...state.users, payload] })),
  updateUser: (payload) =>
    set((state) => ({ users: state.users.map((item) => (item.id === payload.id ? payload : item)) })),
  deleteUser: (payload) => set((state) => ({ users: state.users.filter((item) => item.id !== payload.id) })),
  isWhat: "insert",
  setIsWhat: (payload) => set({ isWhat: payload }),
}))

const useStoreReplacementCars = create((set) => ({
  isWhat: null,
  setIsWhat: (payload) => set({ isWhat: payload }),
  replacementCars: null,
  setReplacementCars: (payload) => set({ replacementCars: payload }),

  selectedReplacementCar: null,
  setSelectedReplacementCar: (payload) => set({ selectedReplacementCar: payload }),
  addReplacementCars: (payload) => set((state) => ({ replacementCars: [...state.replacementCars, payload] })),
  updateReplacementCars: (payload) =>
    set((state) => ({
      replacementCars: state.replacementCars.map((item) => (item.id === payload.id ? payload : item)),
    })),
  deleteReplacementCars: (payload) =>
    set((state) => ({ replacementCars: state.replacementCars.filter((item) => item.id !== payload.id) })),
  selectedVehicleCategory: null,
  setSelectedVehicleCategory: (payload) => set({ selectedVehicleCategory: payload }),
  selectedRCRate: null,
  setSelectedRCRate: (payload) => set({ selectedRCRate: payload }),
}))

const useStoreSchedule = create((set) => ({
  schedules: [],
  setSchedules: (payload) => set({ schedules: payload }),
  addSchedule: (payload) => set((state) => ({ schedules: [...state.schedules, payload] })),

  selectedFrom: null,
  setSelectedFrom: (payload) => set({ selectedFrom: payload }),
  selectedTo: null,
  setSelectedTo: (payload) => set({ selectedTo: payload }),
  selectedId: null,
  setSelectedId: (payload) => set({ selectedId: payload }),
}))

const useStoreTypes = create((set) => ({
  userType: null,
  setUserType: (payload) => set({ userType: payload }),
  emirateTypes: null,
  setEmirateTypes: (payload) => set({ emirateTypes: payload }),
  addEmirateTypes: (payload) => set((state) => ({ emirateTypes: [...state.emirateTypes, payload] })),
  updateEmirateTypes: (payload) =>
    set((state) => ({
      emirateTypes: state.emirateTypes.map((item) => (item.id === payload.id ? payload : item)),
    })),
  deleteEmirateTypes: (payload) =>
    set((state) => ({ emirateTypes: state.emirateTypes.filter((item) => item.id !== payload.id) })),

  auhCategoryTypes: null,
  setAuhCategoryTypes: (payload) => {
    payload.sort(function (a, b) {
      if (typeof a.name === "string" || typeof b.name === "string") {
        // if either a or b is a string, sort alphabetically
        return String(a.name).localeCompare(String(b.name))
      } else {
        // otherwise, sort numerically
        return a.name - b.name
      }
    })
    return set({ auhCategoryTypes: payload })
  },
  addAuhCategoryTypes: (payload) =>
    set((state) => {
      const x = [...state.auhCategoryTypes, payload]
      x.sort(function (a, b) {
        if (typeof a === "string" || typeof b === "string") {
          // if either a or b is a string, sort alphabetically
          return String(a.name).localeCompare(String(b.name))
        } else {
          // otherwise, sort numerically
          return a.name - b.name
        }
      })
      return { auhCategoryTypes: x }
    }),
  updateAuhCategoryTypes: (payload) =>
    set((state) => ({
      auhCategoryTypes: state.auhCategoryTypes.map((item) => (item.id === payload.id ? payload : item)),
    })),
  deleteAuhCategoryTypes: (payload) =>
    set((state) => ({ auhCategoryTypes: state.auhCategoryTypes.filter((item) => item.id !== payload.id) })),

  carsCategoryTypes: null,
  setCarsCategoryTypes: (payload) => {
    const sortedArray = payload.sort(customSort);
    return set({ carsCategoryTypes: sortedArray })
  },
  addCarsCategoryTypes: (payload) => set((state) => ({ carsCategoryTypes: [...state.carsCategoryTypes, payload] })),

  carsBodyTypes: null,
  setCarsBodyTypes: (payload) => set({ carsBodyTypes: payload }),
  addCarsBodyTypes: (payload) => set((state) => ({ carsBodyTypes: [...state.carsBodyTypes, payload] })),

  carsBrandTypes: null,
  setCarsBrandTypes: (payload) => {
    payload.sort((a, b) => a.name.localeCompare(b.name));
    return set({ carsBrandTypes: payload })
  },
  statusTypes: null,
  setStatusTypes: (payload) => set({ statusTypes: payload }),
  statusSubTypes: null,
  setStatusSubTypes: (payload) => set({ statusSubTypes: payload }),
  serviceTypes: null,
  setServiceTypes: (payload) => set({ serviceTypes: payload }),
}))

const useStoreSettings = create((set) => ({
  settings: [],
  setSettings: (payload) => set({ settings: payload }),
  updateSetting: (payload) =>
    set((state) => ({
      settings: state.settings.map((item) => (item.id === payload.id ? payload : item)),
    })),
}))

const useStoreImageViewer = create((set) => ({
  pictures: [],
  setPictures: (payload) => set({ pictures: payload }),
  viewerVisible: false,
  setViewerVisible: (payload) => set({ viewerVisible: payload }),
}))

export {
  useStoreImageViewer,
  useStore,
  useStoreWorkshops,
  useStoreOverAllMaintenanceRates,
  useStoreServiceMileages, 
  useStoreDrivers,
  useStoreTypes,
  useStoreMap,
  useStoreReplacementCars,
  useStoreSchedule,
  useStoreServices,
  useStoreAlerts,
  useStoreSettings,
  useStoreUsers,
  useStoreCalendar,
}
