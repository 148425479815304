import React, { useState } from "react"
import { useEffect } from "react"
import AnimatedPage from "../components/AnimatedPage"
import DriverCard, { NewDriverCard } from "../components/drivers/DriverCard"
import FormCreate from "../components/drivers/FormCreate"
import ModalUni from "../components/modals/ModalUni"
import { useStoreDrivers, useStoreUsers } from "../zustand/store"
import { apiUrl } from "../library/constant"

const Drivers = () => {
  const [isAddDriverOpen, setIsAddDriverOpen] = useState(false)
  const drivers = useStoreDrivers((s) => s.drivers)
  const setIsWhat = useStoreDrivers((s) => s.setIsWhat)
  const setSelectedDriver = useStoreDrivers((s) => s.setSelectedDriver)

  const storeUsers = useStoreUsers((s) => s.users)

  const handleOpenAddDriver = (second) => {
    setSelectedDriver(null)
    setIsWhat("insert")
    setIsAddDriverOpen(true)
  }

  return (
    <AnimatedPage title="Drivers">
      {/*  Modals */}
      <ModalUni isVisible={isAddDriverOpen} setIsVisible={setIsAddDriverOpen} title="Add Driver" size={7}>
        <FormCreate setVisibleModal={setIsAddDriverOpen} />
      </ModalUni>
      {/* -------- */}

      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        {/* <div className="flex gap-4">
          <label className="px-4 py-3 text-lg text-gray-400 bg-white rounded-md shadow-md group">
            <input
              type="text"
              className="mr-2 bg-transparent focus:outline-none group-focus-within:text-black"
              placeholder="Service name"
            />
            <i className="cursor-pointer fa-solid fa-search group-focus-within:text-[#F06400]"></i>
          </label>
          <button>
            <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-filter"></i>
          </button>
        </div> */}
        {/* <button
          className="flex items-center justify-center px-8 py-3 text-lg text-white rounded-md shadow-md bg-aqua hover:bg-aqua-dark"
          onClick={handleOpenAddDriver}
        >
          <div className="mr-4">Create Drivers</div>
          <i className="text-sm fa-solid fa-plus-large"></i>
        </button> */}
      </div>
      <div className="grid grid-flow-row grid-cols-1 gap-4 mt-6 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4">
        {/* {drivers?.map((item, key) => {
          return <NewDriverCardx item={item} key={key} setIsAddDriverOpen={setIsAddDriverOpen} isLarge />
        })} */}

        {storeUsers?.map((item, key) => {
          if (item.user_type === 4) {
            return <NewDriverCardx item={item.driver_data} key={key} setIsAddDriverOpen={setIsAddDriverOpen} isLarge />
          }
        })}
      </div>
    </AnimatedPage>
  )
}

export default Drivers

const NewDriverCardx = ({ item, setIsAddDriverOpen, isLarge = false, isFlexCol = true }) => {
  return (
    <div className={`grid grid-cols-3 gap-4 ${isFlexCol ? "flex-col" : ""} card bg-white shadow-lg overflow-hidden `}>
      <img
        src={apiUrl + "/public/" + item?.pic}
        alt="Driver Splash"
        className={`object-cover w-full rounded-md shadow-lg ${isLarge ? "h-48 w-72" : "h-72"}`}
      />
      <div className="flex flex-col col-span-2 py-4">
        <div className="flex justify-between">
          <p className="text-xl font-semibold text-petrol">{item?.name}</p>
          {/* <MenuComponent item={item} setIsAddDriverOpen={setIsAddDriverOpen} /> */}
        </div>
        <p className="text-[#F06400]">{item?.workshop_data?.name}</p>
        <div className="flex flex-col justify-between mt-2">
          <div className="flex items-center gap-2">
            <i className="mr-2 fa-solid fa-phone text-aqua"></i>
            <p className="text-petrol">{item?.contact}</p>
          </div>
          <div className="flex items-center gap-2">
            <i className="mr-2 fa-solid fa-envelope text-aqua"></i>
            <p className="text-right text-petrol">{item?.email}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
