import axios from "axios"
import React from "react"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices } from "../../../zustand/store"
import { useForm } from "react-hook-form"
import TextArea from "../../inputs/TextArea"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const RejectRequest = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const handleSubmitData = useCbOnce(async (data) => {
    const submitData = { id: selectedService.id, reason: data.reason }
    console.log(submitData)
    const response = await axios.post(apiUrl + "/services/reject", submitData, config())
    console.log(response.data)
    setIsVisible(false)
    setSelectedService(response.data)
    updateService(response.data)
  })

  return (
    <form
      className="flex flex-col"
      onSubmit={handleSubmit((data) => {
        handleSubmitData(data)
      })}
    >
      <div className="grid grid-flow-row grid-cols-1 gap-4">
        <TextArea
          register={register}
          name="reason"
          label="Reason For Rejection"
          placeholder="Reason For Rejection"
          type="text"
          error={errors}
          message="Reason For Rejection Required"
          className="min-h-[200px]"
          condition={{ required: true }}
        />
      </div>
      <div className="flex items-center justify-end w-full gap-2">
        {/* <button
          className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
          onClick={() => {
            setIsVisible(false)
          }}
        >
          No
        </button> */}
        <button className="px-6 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600" type="submit">
          Submit
        </button>
      </div>
    </form>
  )
}

export default RejectRequest
