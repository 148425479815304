import React, { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"

import Loading from "../Loading"
import ServiceReportCustomFilterForm from "./ServiceReportCustomFilterForm"

const ServiceReportAllCustomFilter = ({ setIsModalCustomFilterVisible, selectedData, setSelectedData }) => {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <div className="flex flex-col gap-4 mt-4">
        {isLoading ? (
            <div className="flex justify-center py-7">
                <Loading />
            </div>
        ) : (
            <div>
                <ServiceReportCustomFilterForm  
                    selectedData={selectedData} 
                    setSelectedData={setSelectedData} 
                    setIsModalCustomFilterVisible={setIsModalCustomFilterVisible}
                />
            </div>
        )}
    </div>
  )
}

export default ServiceReportAllCustomFilter
