import React, { useEffect, useMemo, useState } from "react"
import Input from "../inputs/Input"
import { useForm } from "react-hook-form"
import { generalInfo, updateFields } from "../../library/variableConstants"
import Select from "../inputs/Select"
import {
  useStoreMap,
  useStoreReplacementCars,
  useStoreSchedule,
  useStoreServices,
  useStoreTypes,
  useStoreServiceMileages
} from "../../zustand/store"
import TextArea from "../inputs/TextArea"
import SelectMap from "../inputs/SelectMap"
import Map from "../map/Map"
import CarItem from "./CarItem"
import dayjs from "dayjs"
import { motion } from "framer-motion"
import { apiUrl, config } from "../../library/constant"
import axios from "axios"
import { toast } from "react-toastify"
import ModalUni from "../modals/ModalUni"
import ServiceDetails from "./forms/ServiceDetails"
import CollectionDetails from "./forms/CollectionDetails"
import ReplacementCarDetails from "./forms/ReplacementCarDetails"
import VehicleReport from "./forms/VehicleReport"
import { fetchAdress } from "../../library/functions"
import Attachments from "./forms/Attachments"
import { customRoundOffTime } from "../../library/testing"

const variants = {
  open: { opacity: 1, height: "100%" },
  closed: { opacity: 1, height: "0px" },
}

const FormUpdate = ({ setIsWhat, setOpenView, service }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const carsCategoryTypes = useStoreTypes((state) => state.carsCategoryTypes)
  const auhCategoryTypes = useStoreTypes((state) => state.auhCategoryTypes)
  const addEmirateSub = useStoreTypes((s) => s.addAuhCategoryTypes)

  const storeReplacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const [replacementCars, setReplacementCars] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  const [modalAddVisible, setModalAddVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const [addCategoryName, setAddCategoryName] = useState("")
  const [addCategoryActual, setAddCategoryActual] = useState("")

  const watcher = watch()

  const selectedFrom = useStoreSchedule((s) => s.selectedFrom)
  const selectedTo = useStoreSchedule((s) => s.selectedTo)
  const setSelectedFrom = useStoreSchedule((s) => s.setSelectedFrom)
  const setSelectedTo = useStoreSchedule((s) => s.setSelectedTo)
  const selectedId = useStoreSchedule((s) => s.selectedId)
  const setSelectedId = useStoreSchedule((s) => s.setSelectedId)
  const setStoreReplacementCars = useStoreReplacementCars((s) => s.setReplacementCars)

  const [selectedRC, setSelectedRC] = useState(null)

  useEffect(() => {
    const make = Number(watcher.replacement_car_make)
    const body = Number(watcher.replacement_car_body)
    if (make && !body) {
      const newReplacementCars = storeReplacementCars.filter((car) => car.brand_data.id === make)
      setReplacementCars(newReplacementCars)
    } else if (make && body) {
      const newReplacementCars = storeReplacementCars.filter(
        (car) => car.brand_data.id === make && car.category_data.id === body
      )
      setReplacementCars(newReplacementCars)
    }
  }, [watcher.replacement_car_make, watcher.replacement_car_body, storeReplacementCars])

  const isNumeric = (value) => {
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    if (typeof value === 'string') {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }
    return false;
  }

  const onSubmit = async (submitData) => {
    if (!selectedId && submitData.rc_needed) {
      toast.error("Please select a replacement car")
      // console.log("TOAST")
      return
    }

    let newDateTime = null
    if (watcher.collection_needed) {
      if (submitData.collection_date && submitData.collection_time) {
        newDateTime = new Date(submitData.collection_date + " " + submitData.collection_time)
      } else {
        setModalLoading(false)
        toast.error("Please fill the Collection Details")
        return
      }
    }

    let workshop = null

    if (submitData.rc_needed) {
      workshop = selectedRC?.workshop ? Number(selectedRC?.workshop) : Number(watcher?.selWorkshop)
    } else {
      workshop = submitData?.workshop ? Number(submitData.workshop) : Number(watcher?.selWorkshop)
    }

    const newSubmitData = {
      ...submitData,
      collection_datetime: newDateTime,
      replacement_car: selectedId ? Number(selectedId) : null,
      auh_category: submitData.auh_category ? Number(submitData.auh_category) : null,
      workshop: workshop,
      collection_needed: watcher.collection_needed ? true : false,
      rc_needed: watcher.rc_needed ? true : false,
      brand: isNumeric(watcher.brand) ? Number(watcher.brand) : 0,
    }

    const { data, status } = await axios.put(apiUrl + "/services", newSubmitData, config())

    let replacement_car_schedule = null

    setModalLoading(true)

    if (selectedFrom && selectedTo && selectedId && submitData.rc_needed && service?.replacement_car_schedule?.id) {
      // Update Schedule
      const submitData2 = {
        id: service.replacement_car_schedule.id,
        start: new Date(selectedFrom).toISOString(),
        end: new Date(selectedTo).toISOString(),
        replacement_car: Number(selectedId),
        service: service.id,
      }

      const { data: data2 } = await axios.put(apiUrl + "/replacement_cars_schedules", submitData2, config())
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)
      replacement_car_schedule = data2

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    } else if (
      selectedFrom &&
      selectedTo &&
      selectedId &&
      submitData.rc_needed &&
      !service?.replacement_car_schedule?.id
    ) {
      // Create Schedule
      const submitData2 = {
        start: new Date(selectedFrom).toISOString(),
        end: new Date(selectedTo).toISOString(),
        replacement_car: Number(selectedId),
        service: service?.id,
      }

      const { data: data2 } = await axios.post(apiUrl + "/replacement_cars_schedules", submitData2, config())
      replacement_car_schedule = data2
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    }

    if (!submitData.rc_needed && service?.replacement_car_schedule?.id) {
      await axios.delete(apiUrl + "/replacement_cars_schedules/" + service.replacement_car_schedule.id, config())
      const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
      setStoreReplacementCars(replacement_cars.data)

      setSelectedId(null)
      setSelectedFrom(null)
      setSelectedTo(null)
    }

    if (status === 200) {
      toast.success("Services Successfully Updated...")
      if (replacement_car_schedule) {
        let xData = {
          ...data,
          replacement_car_schedule: replacement_car_schedule,
        }
        setSelectedService(xData)
        updateService(xData)
      } else {
        setSelectedService(data)
        updateService(data)
        // console.log(data)
      }

      setIsWhat("View")
      setModalLoading(false)
    }
  }

  const handleSubmitCategory = async (second) => {
    const data = {
      emirate: Number(watcher.emirate),
      name: addCategoryName,
      actual: addCategoryActual,
    }
    try {
      const res = await axios.post(apiUrl + "/auh_category_types", data, config())
      addEmirateSub(res.data)
      toast.success("Successfully Category Added.")
      setValue("auh_category", res.data.id)
      setModalAddVisible(false)
    } catch (error) {
      toast.success("Error Adding Category.")
    }
  }

  const filteredCategory = useMemo(() => {
    return auhCategoryTypes.filter((item) => item.emirate === Number(watcher.emirate) && item)
  }, [watcher.emirate, auhCategoryTypes])

  // const filteredReplacementCars = useMemo(() => {
  //   if (!watcher.replacement_car_category) {
  //     return replacementCars
  //   }
  //   return replacementCars?.filter((item) => item.category === Number(watcher.replacement_car_category) && item)
  // }, [replacementCars, watcher.replacement_car_category])

  const filteredReplacementCars = useMemo(() => {
    if (replacementCars) {
      let repCars = [...storeReplacementCars]
      repCars = repCars.sort((a, b) => a?.utilization - b?.utilization)

      if (watcher.workshop) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.workshop) === element.workshop) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      // if (watcher.brand) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (Number(watcher.brand) === element.brand) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      // if (watcher.vmodel) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (watcher?.vmodel?.split(/[\s,._-]+/)[0]?.toUpperCase()?.trim() === element.model?.split(/[\s,._-]+/)[0]?.toUpperCase()?.trim()) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      if (watcher.replacement_car_category) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watcher.replacement_car_category) === Number(element.category)) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      // if (watcher.emirate) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (Number(watcher.emirate) === element.emirate) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      // if (watcher.year) {
      //   let tempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     if (watcher.year === element.year) {
      //       tempCars.push(element)
      //     }
      //   }
      //   repCars = tempCars
      // }

      // if (watcher.client_no) {
      //   let tempCars = []
      //   for (let index = 0; index < storeReplacementCars.length; index++) {
      //     const element = storeReplacementCars[index]
      //     if (element?.locked_to?.includes(String(watcher.client_no))) {
      //       tempCars.push(element)
      //     }
      //   }
      //   // if (tempCars.length !== 0) {
      //   let ntempCars = []
      //   for (let index = 0; index < repCars.length; index++) {
      //     const element = repCars[index]
      //     try {
      //       const lockkss = JSON.parse(element?.locked_to)

      //       if (lockkss.length === 0) {
      //         ntempCars.push(element)
      //       }
      //     } catch (error) {
      //       ntempCars.push(element)
      //     }
      //   }

      //   repCars = [...tempCars, ...ntempCars]
      //   // }
      // }

      return repCars
    } else {
      return []
    }
  }, [
    replacementCars,
    storeReplacementCars,
    // watcher.workshop,
    // watcher.brand,
    // watcher.replacement_car_category,
    // watcher.year,
    // watcher.client_no,
    // watcher.vmodel
  ])

  useEffect(() => {
    if (filteredCategory.length !== 0) {
      for (let index = 0; index < filteredCategory.length; index++) {
        const element = filteredCategory[index]
        if (element.id === Number(service.auh_category)) {
          setValue("auh_category", service.auh_category)
        }
      }
    }
  }, [filteredCategory])

  useEffect(() => {
    // console.log("service update", service)
    setValue("id", service.id)
    setValue("request_no", service.request_no)
    setValue("emirate", service.emirate)
    setValue("emirate_name", service.emirate_data.name)
    setValue("plate", service.plate)
    setValue("auh_category", "")
    setValue("auh_category_name", service.auh_category_data.name)
    setValue("driver_name", service.driver_name)
    setValue("driver_contact", service.driver_contact)
    setValue("driver_email", service.driver_email)
    setValue("request_details", service.request_details)
    setValue("service_type", service.service_type)
    setValue("service_mileage", service.service_mileage)
    setValue("rc_price", service.rc_price)
    setValue("rc_selection_reason", service.rc_selection_reason)
    setValue("mileage", service.mileage)
    setValue("collection_needed", service.collection_needed)
    setValue("rc_needed", service.rc_needed)
    setValue("attachments", service.attachments)
    setValue(
      "collection_date",
      service.collection_datetime ? dayjs(service.collection_datetime).format("YYYY-MM-DD") : ""
    )
    setValue(
      "collection_time",
      service.collection_datetime ? customRoundOffTime(dayjs(service.collection_datetime).format("HH:mm")) : ""
    )
    setValue("collection_location", service.collection_location)
    setValue("collection_state", service.collection_state)
    setValue("collection_country", service.collection_country)
    setValue("collection_coordinates", service.collection_coordinates)
    setValue("workshop", service.workshop_data ? service.workshop_data.id : "")
    setValue("selWorkshop", service.workshop_data ? service.workshop_data.id : "")

    setSelectedId(service.replacement_car)
    setReplacementCars(storeReplacementCars ? [...storeReplacementCars] : [])
    setSelectedCar(service.replacement_car)

    if (service.replacement_car_schedule) {
      setSelectedFrom(dayjs(service.replacement_car_schedule.start).format("YYYY-MM-DD"))
      setSelectedTo(dayjs(service.replacement_car_schedule.end).format("YYYY-MM-DD"))
    }

    if (service.client_api_data && service.client_api_data !== "null") {
      const client = JSON.parse(service.client_api_data)
      setValue("client_api_data", JSON.stringify(client))
      setValue("client_no", client.CLIENT_NO)
      setValue("client_name", client.CLIENT_NAME)
      setValue("client_car_color", client.COLOR)
      setValue("client_car_make", client.MAKE)
      setValue("client_car_model", client.MODEL)
      setValue("client_car_year_model", client.YEAR_MODEL)
      setValue("client_car_lastknown_mileage", client.LAST_KNOWN_MILEAGE)
      setValue("nols_reg_no", client.PLATE_NO)

      for (let index = 0; index < carsCategoryTypes.length; index++) {
        const element = carsCategoryTypes[index]
        if (element.name === client.REPLACEMENT_CATEGORY) {
          // console.log("element", element)
          setValue("replacement_car_category", element.id)
          setValue("replacement_car_category_has", "1")
        }
      }
    }

    setTimeout(() => {
      setValue("vehicle_category", service.vehicle_category)
    }, 2000);
  }, [service])

  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const setAddressLoading = useStoreMap((s) => s.setAddressLoading)
  const setServiceMileages = useStoreServiceMileages((state) => state.setServiceMileages)
  useEffect(() => {
    if (hoverCoordinates) {
      const fetsss = async () => {
        setAddressLoading(true)
        const data = await fetchAdress(`${hoverCoordinates.lng},${hoverCoordinates.lat}`, {
          lat: hoverCoordinates.lat,
          lng: hoverCoordinates.lng,
        })
        if (data?.features.length !== 0) {
          // console.log(data)
          const latlng = {
            lat: data.features[0].center[1],
            lng: data.features[0].center[0],
          }
          setValue("collection_location", data.features[0].place_name_en)
          setValue("collection_coordinates", JSON.stringify(latlng))
        }
        setAddressLoading(false)
      }
      const getMapCountry = async () => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${hoverCoordinates.lat}&lon=${hoverCoordinates.lng}`
          );
          const address = response.data.address
          setValue("collection_state", address && address.state ? address.state.replace(" Emirate", "") : null)
          setValue("collection_country", address ? address?.country : null)
        } catch (error) {
          console.error('Error fetching geocoding data:', error)
        }
      }

      fetsss()
      getMapCountry()
    }

    const getServiceMileages = async () => {
      const serviceMileages = await axios.get(apiUrl + "/service_mileage", config())
      setServiceMileages(serviceMileages.data ?? [])
    }
    getServiceMileages()

  }, [hoverCoordinates, setSelectedAddress])

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setValue("selectedFrom", selectedFrom)
      setValue("selectedTo", selectedTo)
    }
  }, [selectedFrom, selectedTo, setValue])

  useEffect(() => {
    if (replacementCars && selectedId) {
      for (let index = 0; index < replacementCars.length; index++) {
        const replacementCar = replacementCars[index]
        if (replacementCar.id === selectedId) {
          setSelectedRC(replacementCar)
        }
      }
    }
  }, [replacementCars, selectedId])

  return (
    // <pre>{JSON.stringify(service, null, 2)}</pre>
    <div>
      <ModalUni
        isVisible={modalLoading}
        setIsVisible={() => {
          return
        }}
        size={2}
        title={"Submitting Data"}
        hasClose={false}
      ></ModalUni>
      <ModalUni isVisible={modalAddVisible} setIsVisible={setModalAddVisible} size={3} title={"Add Emirate Category"}>
        <div>
          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Category Name</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. R6"
              className="w-full input input-bordered"
              value={addCategoryName}
              onChange={(e) => {
                setAddCategoryName(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Actual</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. 16"
              className="w-full input input-bordered"
              value={addCategoryActual}
              onChange={(e) => {
                setAddCategoryActual(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className="flex justify-end">
            <div className="px-12 btn" onClick={handleSubmitCategory}>
              Submit
            </div>
          </div>
        </div>
      </ModalUni>

      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="px-6 pb-6">
        <ServiceDetails
          errors={errors}
          filteredCategory={filteredCategory}
          register={register}
          requestNo={watcher.request_no}
          setSelectedFrom={setSelectedFrom}
          setSelectedId={setSelectedId}
          setSelectedTo={setSelectedTo}
          setValue={setValue}
          watcher={watcher}
        />

        {watcher.collection_needed ? (
          <CollectionDetails register={register} errors={errors} watcher={watcher} setValue={setValue} />
        ) : (
          <></>
        )}
        {watcher.collection_needed && watcher.rc_needed ? (
          <ReplacementCarDetails
            register={register}
            errors={errors}
            watcher={watcher}
            setValue={setValue}
            filteredReplacementCars={filteredReplacementCars}
            selectedRC={selectedRC}
          />
        ) : (
          <></>
        )}

        {service.lease_vehicle && (
          <VehicleReport
            setValue={setValue}
            lease_vehicle={service.lease_vehicle}
            lease_vehicle_in={service.lease_vehicle_in}
            lease_vehicle_out={service.lease_vehicle_out}
            replacement_vehicle={service.replacement_vehicle}
            replacement_vehicle_in={service.replacement_vehicle_in}
            replacement_vehicle_out={service.replacement_vehicle_out}
            watcher={watcher}
          />
        )}

        <Attachments setValue={setValue} watcher={watcher} />
        <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
          <button
            className="px-12 btn"
            type="button"
            onClick={() => {
              setIsWhat("View")
            }}
          >
            Cancel
          </button>
          <button className="px-12 btn" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormUpdate
