import axios from "axios"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreTypes } from "../../zustand/store"

const AddCategory = ({ setValue, setModalAddVisible }) => {
  const [category, setCategory] = useState("")
  const { addCarsCategoryTypes } = useStoreTypes()

  const handleSubmit = async () => {
    // handleSubmit
    const data = {
      name: category,
    }
    try {
      const res = await axios.post(apiUrl + "/cars_category_types", data, config())
      addCarsCategoryTypes(res.data)
      toast.success("Successfully Category Added.")
      setValue("category", res.data.id)
      setModalAddVisible(false)
    } catch (error) {
      toast.success("Error Adding Category.")
    }
  }

  return (
    <div>
      <div className={"w-full form-control "}>
        <label className="label">
          <span className="label-text">Replacement Car Category</span>
          {/* <span className="label-text-alt">Alt label</span> */}
        </label>
        <input
          type="text"
          placeholder="ex. E"
          className="w-full input input-bordered"
          value={category}
          onChange={(e) => {
            setCategory(e.currentTarget.value)
          }}
        />
        <label className="pt-0 label">
          <span className="label-text-alt"></span>
        </label>
      </div>

      <div className="flex justify-end">
        <div className="px-12 btn" onClick={handleSubmit}>
          Submit
        </div>
      </div>
    </div>
  )
}

export default AddCategory
