import React, { useState, useEffect } from "react"

function RCNumberSearchInput({ onTextChange }) {
  const [text, setText] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => {
      onTextChange(text.toUpperCase())
    }, 500) // debounce time in milliseconds

    return () => {
      clearTimeout(timer)
    }
  }, [text, onTextChange])

  const handleChange = (event) => {
    setText(event.target.value)
  }

  return (
    <label className="px-4 py-3 text-lg text-gray-400 bg-white rounded-md shadow-md group">
      <input
        type="text"
        className="mr-2 bg-transparent focus:outline-none group-focus-within:text-black"
        placeholder="Search RC Number"
        value={text}
        onChange={handleChange}
      />
      <i className="cursor-pointer fa-solid fa-search group-focus-within:text-[#F06400]"></i>
    </label>
  )
}

export default RCNumberSearchInput;
