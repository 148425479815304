import axios from "axios"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices } from "../../../zustand/store"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import dayjs from "dayjs"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const RCCollected = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const selectedService = useStoreServices((s) => s.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const onSubmit = useCbOnce(async (submitData) => {
    // setOpenView(false)
    let newDateTime = null
    if (submitData.date && submitData.time) {
      newDateTime = new Date(submitData.date + " " + submitData.time)
    }
    const newData = {
      id: selectedService.id,
      closed_date: newDateTime,
      closed_remarks: submitData.comment,
    }

    try {
      const { data, status } = await axios.post(apiUrl + "/services/close", newData, config())
      if (status === 200) {
        setIsVisible(false)
        setSelectedService(data)
        updateService(data)
        toast.success("Successfully Service Completed")
      }
    } catch (error) {
      toast.error("There was something wrong with the data submitted.")
    }
  })

  useEffect(() => {
    const newDateTime = new Date()
    setValue("time", dayjs(newDateTime).format("HH:mm"))
    setValue("date", dayjs(newDateTime).format("YYYY-MM-DD"))
  }, [])

  return (
    <div className="w-full border-t">
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="space-y-4">
        <div className="grid w-full grid-cols-2 gap-4">
          <Input
            register={register}
            name="date"
            label="Date"
            placeholder={"Date"}
            type="date"
            condition={{ required: false }}
            error={errors}
          />
          <Input
            register={register}
            name="time"
            label="Time"
            placeholder={"Time"}
            type="time"
            condition={{ required: false }}
            error={errors}
          />
        </div>
        <TextArea
          register={register}
          name="comment"
          label="Comment"
          placeholder={"Comment"}
          type="text"
          error={errors}
          className="min-h-[140px]"
        />
        <div className="flex items-center justify-end w-full gap-2">
          <button
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => {
              setIsVisible(false)
            }}
          >
            Cancel
          </button>
          <button className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default RCCollected
