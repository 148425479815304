import axios from "axios"
import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices, useStoreWorkshops } from "../../../zustand/store"
import Select from "../../inputs/Select"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const TransferWorkshop = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const { workshops } = useStoreWorkshops()
  const watcher = watch()

  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const onSubmit = useCbOnce((data) => {
    const workshop = Number(data.workshop)
    if (Number(selectedService.workshop) === workshop) {
      toast.info("No Changes Made.")
      setIsVisible(false)
    }

    const submitData = {
      id: selectedService.id,
      workshop: workshop,
    }

    console.log(submitData)

    axios.post(apiUrl + "/services/transfer_workshop", submitData, config()).then((res) => {
      setSelectedService(res.data)
      updateService(res.data)
      toast.info("Transfer Initiated.")
    })

    setIsVisible(false)
  })

  const filteredWorkshop = useMemo(() => {
    return workshops.filter((item) => item.id !== selectedService?.workshop)
  }, [workshops, selectedService])

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="flex mb-2">
          <div className="mr-2">Current Workshop: </div>
          <div className="font-bold">{selectedService?.workshop_data?.name}</div>
        </div>
        <Select
          register={register}
          name="workshop"
          label="Workshop"
          placeholder="Select Workshop"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Workshop Required"
          items={filteredWorkshop}
          selectKey="name"
          hasColoring={true}
          colorKey="is_agency"
        />
        <div className="flex items-center justify-end w-full gap-2">
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="button"
            onClick={() => setIsVisible(false)}
          >
            cancel
          </button>
          <button
            className="px-4 py-2 font-medium btn text-white border-none bg-[#F06400] rounded-md hover:bg-orange-600"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default TransferWorkshop
