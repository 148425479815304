import React from "react"
import { useStoreTypes } from "../../zustand/store"
import { useEffect } from "react"
import Card from "../Card"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"

const Roles = () => {
  const { userType, setUserType } = useStoreTypes()

  async function onClickChange(rowId, privilegesId, value) {
    // Find the row with the specified ID
    const data = [...userType]
    const row = data.find((item) => item.id === rowId)

    // Check if the row exists
    if (row) {
      // Update the privileges value at the specified index
      row.privileges[privilegesId] = value
      const { status } = await axios.post(apiUrl + "/user_type", row, config())
      console.log(status) // Display the modified data
      setUserType(data)
    } else {
      console.log("Row not found!")
    }
  }

  return (
    <Card>
      <div className="p-6">
        <div className="text-2xl">User Types Access Configurations</div>
        <div className="flex min-w-full gap-4 mt-6 overflow-auto max-h-96">
          <div className="w-full h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
            <table className="overflow-hidden table-auto whitespace-nowrap">
              <thead>
                <tr>
                  <th className="w-1/4 px-4 border">ID</th>
                  <th className="w-full px-4 border">Role</th>
                  <th className="w-full px-4 border">
                    Create/Edit
                    <br />
                    Request
                  </th>
                  <th className="w-full px-4 border">
                    Delete
                    <br />
                    Request
                  </th>
                  <th className="w-full px-4 border">
                    Change
                    <br />
                    RC
                  </th>
                  <th className="w-full px-4 border">
                    Assign
                    <br />
                    to Driver
                  </th>
                  <th className="w-full px-4 border">
                    Collect
                    <br />
                    Vehicle
                  </th>
                  <th className="w-full px-4 border">
                    Receive
                    <br />
                    Leased
                    <br />
                    Vehicle
                  </th>
                  <th className="w-full px-4 border">
                    Assess
                    <br />
                    Leased
                    <br />
                    Vehicle
                  </th>
                  <th className="w-full px-4 border">
                    Approved
                    <br />
                    Assessment
                  </th>
                  <th className="w-full px-4 border">
                    Complete
                    <br />
                    Job
                    <br />
                    Order
                  </th>
                  <th className="w-full px-4 border">
                    Deliver
                    <br />
                    Leased
                    <br />
                    Vehicle
                  </th>
                  <th className="w-full px-4 border">
                    Transfer
                    <br />
                    Workshop
                  </th>
                  <th className="w-full px-4 border">Settings</th>
                  <th className="w-full px-4 border">
                    User
                    <br />
                    CRUD
                  </th>
                  <th className="w-full px-4 border">
                    Workshop
                    <br />
                    CRUD
                  </th>
                  <th className="w-full px-4 border">
                    Audit
                    <br />
                    Logs
                  </th>
                  <th className="w-full px-4 border">Reports</th>
                  <th className="w-full px-4 border">
                    Replacement
                    <br />
                    Cars
                  </th>
                  <th className="w-full px-4 border">
                    Transfer
                    <br />
                    Ticket
                  </th>
                </tr>
              </thead>
              <tbody>
                {userType.map((item, key) => {
                  return (
                    <tr className="px-4 border" key={key}>
                      <td className="px-4 border">{item.id}</td>
                      <td className="px-4 capitalize border">{item.name}</td>
                      {item.privileges.map((xItem, key) => {
                        return (
                          <td className="px-4 text-center border" key={key}>
                            <input
                              type="checkbox"
                              className="toggle toggle-sm toggle-info"
                              checked={xItem}
                              onChange={() => onClickChange(item.id, key, xItem ? 0 : 1)}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                <tr className="px-4 border">
                  <td></td>
                  <td></td>
                  <td className="flex justify-end px-2 py-1 text-green-500"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Roles
