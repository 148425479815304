import axios from "axios"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreMap, useStoreTypes, useStoreWorkshops } from "../../zustand/store"
import Input from "../inputs/Input"
import Select from "../inputs/Select"
import SelectMap from "../inputs/SelectMap"
import Map from "../map/Map"
import { fetchAdress } from "../../library/functions"
import CheckBox from "../inputs/CheckBox"
import SelectMapEdit from "../inputs/SelectMapEdit"

const FormCreate = ({ setVisibleModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const watcher = watch()

  const emirateTypes = useStoreTypes((state) => state.emirateTypes)
  const addWorkshop = useStoreWorkshops((state) => state.addWorkshop)
  const updateWorkshop = useStoreWorkshops((state) => state.updateWorkshop)
  const isWhat = useStoreWorkshops((state) => state.isWhat)
  const selectedWorkshop = useStoreWorkshops((state) => state.selectedWorkshop)
  const setSelectedWorkshop = useStoreWorkshops((state) => state.setSelectedWorkshop)

  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)

  const handleSubmitData = async (params) => {
    if (isWhat === "insert") {
      const newParams = { ...params }
      console.log(newParams)
      const { data, status } = await axios.post(apiUrl + "/workshops", newParams, config())
      if (status === 200) {
        toast.success("Workshop Successfully added...")
        addWorkshop(data)
        setVisibleModal(false)
      }
    } else if (isWhat === "update") {
      const newParams = { ...params, id: selectedWorkshop.id }
      const { data, status } = await axios.put(apiUrl + "/workshops", newParams, config())
      if (status === 200) {
        toast.success("Workshop Successfully updated...")
        updateWorkshop(data)
        setVisibleModal(false)
      }
    } else {
      toast.error("Workshop confused?...")
    }
  }

  const handleCancel = () => {
    setVisibleModal(false)
    setSelectedWorkshop(null)
  }

  useEffect(() => {
    if (isWhat === "update" && selectedWorkshop) {
      setValue("name", selectedWorkshop.name)
      setValue("address", selectedWorkshop.address)
      setValue("coordinates", selectedWorkshop.coordinates)
      setValue("emirate", selectedWorkshop.emirate)
      setValue("location", selectedWorkshop.location)
      const coordinates = JSON.parse(selectedWorkshop.coordinates)

      setSelectedAddress({ label: selectedWorkshop.address })
      setHoverCoordinates(coordinates)
    } else {
      setSelectedAddress(null)
      setHoverCoordinates(null)
    }
  }, [isWhat, selectedWorkshop])

  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const setAddressLoading = useStoreMap((s) => s.setAddressLoading)
  useEffect(() => {
    if (hoverCoordinates) {
      const fetsss = async () => {
        setAddressLoading(true)
        const data = await fetchAdress(`${hoverCoordinates.lng},${hoverCoordinates.lat}`, {
          lat: hoverCoordinates.lat,
          lng: hoverCoordinates.lng,
        })
        if (data?.features.length !== 0) {
          // console.log(data)
          const latlng = {
            lat: data.features[0].center[1],
            lng: data.features[0].center[0],
          }
          setValue("address", data.features[0].place_name_en)
          setValue("coordinates", JSON.stringify(latlng))

          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${hoverCoordinates.lat}&lon=${hoverCoordinates.lng}`
            );
            const address = response.data.address
            // console.log("address", address)
            // if (address.country_code === 'ae') 
            setValue("location", address && address.state ? address.state.replace(" Emirate", "") : null)
          } catch (error) {
            console.error('Error fetching geocoding data:', error)
          }

          setSelectedAddress({ label: data.features[0].place_name_en, value: { place_id: data.features[0].place_id } })
        }
        setAddressLoading(false)
      }
      fetsss()
    }
  }, [hoverCoordinates])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        handleSubmitData(data)
      })}
    >
      <div className="grid grid-flow-row grid-cols-1">
        <Input
          register={register}
          name="name"
          label="Shop Name"
          placeholder="Workshop Name."
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Shop Name Required"
        />
        <Select
          register={register}
          name="emirate"
          label="Emirate"
          placeholder="Select Emirate"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Emirate Required"
          items={emirateTypes}
          selectKey="name"
        />
        <SelectMap
          register={register}
          name="address"
          nameCoordinates="coordinates"
          label="Address"
          placeholder="Select Address"
          required={true}
          error={errors}
          message="Address Required"
          setValues={setValue}
          watcher={watcher}
        />

        {/* <Input
          register={register}
          name="location"
          label="Location State"
          placeholder="Location State"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Location State Required"
          readOnly={true}
        /> */}
        {watcher?.location && 
          <span className="mb-4 text-xs font-bold">Location State: {watcher?.location}</span>
        }

        <CheckBox id="is_agency" setValue={setValue} value={watcher} title="Is Agency?" />

        <div className="w-full overflow-hidden shadow-md min-w-[100px] h-96 rounded-xl mt-6">
          <Map x="search" />
        </div>
      </div>
      <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
        <div className="px-12 btn" onClick={handleCancel}>
          Cancel
        </div>
        <button className="px-12 btn">{isWhat === "insert" ? "Submit" : "Update"}</button>
      </div>
    </form>
  )
}

export default FormCreate
