import axios from "axios"
import React, { useEffect, useState } from "react"
import { apiUrl, config } from "../../library/constant"
import { useStoreReplacementCars, useStoreSchedule } from "../../zustand/store"

const FormCreate = ({ setVisibleModal }) => {
  const selectedFrom = useStoreSchedule((s) => s.selectedFrom)
  const selectedTo = useStoreSchedule((s) => s.selectedTo)
  const selectedId = useStoreSchedule((s) => s.selectedId)
  const replacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const setReplacementCars = useStoreReplacementCars((s) => s.setReplacementCars)

  const addSchedule = useStoreSchedule((s) => s.addSchedule)

  const [image, setImage] = useState(null)

  const [selectedRC, setSelectedRC] = useState(null)

  useEffect(() => {
    if (replacementCars && selectedId) {
      for (let index = 0; index < replacementCars.length; index++) {
        const replacementCar = replacementCars[index]
        if (replacementCar.id === selectedId) {
          console.log(replacementCar)
          setSelectedRC(replacementCar)
          setImage(apiUrl + "/public/" + replacementCar.pic)
        }
      }
    }
  }, [replacementCars, selectedId])

  const handleSubmit = async () => {
    const submitData = {
      start: new Date(selectedFrom).toISOString(),
      end: new Date(selectedTo).toISOString(),
      replacement_car: Number(selectedId),
    }
    console.log(submitData)
    const { data, status } = await axios.post(apiUrl + "/replacement_cars_schedules", submitData, config())
    console.log(data, status)
    setVisibleModal(false)
    addSchedule(data)
    const replacement_cars = await axios.get(apiUrl + "/replacement_cars", config())
    setReplacementCars(replacement_cars.data)
  }

  return (
    <div className="">
      <div className="mt-6">
        <div className="text-lg font-bold text-gray-500">Replacement Car Details:</div>
      </div>
      <div className="pl-2 text-lg">
        <div>
          <div className="relative object-cover w-full mt-4 overflow-hidden rounded h-36 ring ring-[#F06400] ring-offset-base-100 ring-offset-2 avatar">
            {image ? (
              <img src={image} />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-[#F06400] rounded">
                <i className="flex items-center justify-center w-full h-full text-6xl text-gray-200 fa-solid fa-car-side"></i>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <div className="">Registration No.:</div>
          <div className="font-bold ">{selectedRC?.reg_no}</div>
        </div>
        <div className="grid grid-flow-row grid-cols-2 gap-2 mt-2">
          <div>
            <div>Make:</div>
            <div className="pl-2 font-bold">{selectedRC?.brand_data.name}</div>
          </div>
          <div>
            <div>Model:</div>
            <div className="pl-2 font-bold">{selectedRC?.category_data.name}</div>
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-2 gap-2 mt-2">
          <div>
            <div>Year:</div>
            <div className="pl-2 font-bold">{selectedRC?.year}</div>
          </div>
          <div>
            <div>Color:</div>
            <div className="pl-2 font-bold">{selectedRC?.color}</div>
          </div>
        </div>
      </div>

      <div className="pt-4 mt-6 border-t">
        <div className="text-lg font-bold text-gray-500">Selected Dates: Category</div>
      </div>

      <div className="flex items-center justify-between px-4 mt-2 mb-2 font-bold text-white">
        <div className="w-full px-4 ">
          <div className="flex justify-center py-2 bg-[#F06400] rounded-lg">{selectedFrom}</div>
        </div>
        <div className="font-black text-black">-</div>
        <div className="w-full px-4 ">
          <div className="flex justify-center py-2 bg-[#F06400] rounded-lg ">{selectedTo}</div>
        </div>
      </div>

      <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
        <div className="px-12 btn">Cancel</div>
        <button className="px-12 btn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}

export default FormCreate
