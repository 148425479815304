import React, { useEffect, useMemo } from "react"
import Lottie from "lottie-react"
import todoicon from "../../../assets/todoicon.json"
import inprogress from "../../../assets/inprogress.json"
import delivery from "../../../assets/delivery.json"
import completed from "../../../assets/completed.json"
import cancelled from "../../../assets/cancelled.json"
import { useStoreServices } from "../../../zustand/store"

const Stepper = ({ status, sub_status }) => {
  const selectedService = useStoreServices((s) => s.selectedService)

  const step = useMemo(() => {
    if (status) {
      if (status === 1 || sub_status === 4 || sub_status === 5) {
        return 1
      } else if (sub_status === 6 || sub_status === 7 || sub_status === 8) {
        return 2
      } else if (status === 2) {
        return 3
      } else if (status === 3 || status === 4) {
        return 4
      }
    } else {
      return 1
    }
  }, [status, sub_status])

  useEffect(() => {
    console.log("STEP", step, "STATUSESSS", status, sub_status)
  }, [step])

  return (
    <div>
      <div className="flex items-center">
        <div className="">
          {/* <IconWrapper step={1} currentStep={step} title="Todo">
              <div className="w-16">
                <Lottie animationData={todoicon} loop={true} />
              </div>
            </IconWrapper> */}

          <IconWrapper step={1} currentStep={step} title="Collection">
            <div className="relative flex justify-center w-16 overflow-hidden h-11">
              {/* <div className="absolute w-[4.5rem] mx-2 -top-4 -left-3">
                <Lottie animationData={todoicon} loop={step === 1} />
              </div> */}
              <i className="fa-sharp fa-solid fa-timer text-4xl mt-1 text-white"></i>
            </div>
          </IconWrapper>
        </div>
        <div className="relative w-20">
          <div
            className={`h-5 w-28 -z-10 -left-3 absolute -top-5 ${
              step <= 1 ? "bg-white border-4 border-[#004A5D]" : "bg-[#004A5D]"
            }`}
          ></div>
        </div>
        <div className="">
          <IconWrapper step={2} currentStep={step} title="Job Order">
            <div className="relative flex justify-center w-16 overflow-hidden h-11">
              {/* <div className="absolute w-16 mx-2 -top-2 -left-2">
                <Lottie animationData={inprogress} loop={step === 2} />
              </div> */}
              <i
                className={`fa-sharp fa-solid fa-screwdriver-wrench text-4xl mt-1  ${
                  step >= 2 ? "text-white" : "text-[#004A5D]"
                }`}
              ></i>
            </div>
          </IconWrapper>
        </div>
        {selectedService.collection_needed && (
          <>
            <div className="relative w-20">
              <div
                className={`h-5 w-28 -z-10 -left-3 absolute -top-5 ${
                  step <= 2 ? "bg-white border-4 border-[#004A5D]" : "bg-[#004A5D]"
                }`}
              ></div>
            </div>
            <div className="">
              <IconWrapper step={3} currentStep={step} title="Delivery">
                <div className="relative flex justify-center w-16 overflow-hidden h-11">
                  {/* <div className="absolute w-20 mx-2 -top-6 -left-5">
                    <Lottie animationData={delivery} loop={step === 3} />
                  </div> */}
                  <i
                    className={`fa-sharp fa-solid fa-truck-fast text-4xl mt-1  ${
                      step >= 3 ? "text-white" : "text-[#004A5D]"
                    }`}
                  ></i>
                </div>
              </IconWrapper>
            </div>
          </>
        )}

        <div className="relative w-20">
          <div
            className={`h-5 w-28 -z-10 -left-3 absolute -top-5 ${
              step <= 3 ? "bg-white border-4 border-[#004A5D]" : "bg-[#004A5D]"
            }`}
          ></div>
        </div>
        <div className="">
          <IconWrapper step={4} currentStep={step} title="Completed">
            <div className="relative flex justify-center w-16 overflow-visible h-11">
              {/* <div className="absolute mx-2 w-14 -top-1.5 -left-1">
                <Lottie
                  animationData={sub_status === 11 || sub_status === 13 ? cancelled : completed}
                  loop={step === 4}
                />
              </div> */}
              <i
                className={`fa-sharp fa-solid fa-badge-check text-4xl mt-1  ${
                  step >= 4 ? "text-white" : "text-[#004A5D]"
                }`}
              ></i>
            </div>
          </IconWrapper>
        </div>
      </div>
    </div>
  )
}
export default Stepper

const IconWrapper = ({ children, step, currentStep, title = "none" }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`px-0 py-2 rounded-full ${
          currentStep <= step - 1 ? "bg-white border-4 border-[#004A5D]" : "bg-[#004A5D]"
        }`}
      >
        {children}
      </div>
      <div>{title}</div>
    </div>
  )
}
