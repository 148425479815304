import axios from "axios"
import React, { useState } from "react"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { apiUrl } from "../library/constant"
import VehicleReport from "../components/reports/VehicleReport"

function VehicleReportView() {
  let [searchParams, setSearchParams] = useSearchParams()

  const [selectedService, setSelectedService] = useState(null)
  const [isWhat, setIsWhat] = useState("")

  useEffect(() => {
    if (searchParams.get("f")) {
      console.log(searchParams.get("f"))
      const encryted_data = searchParams.get("f")
      setIsWhat(searchParams.get("what") ?? "out")
      axios
        .post(apiUrl + "/decrypt_data", {
          decode: encryted_data,
        })
        .then((res) => {
          setSelectedService(res.data)
          console.log(res)
        })
    }
  }, [searchParams])

  if (!selectedService) {
    return <div>You Should not be here</div>
  }

  return (
    <div className="flex justify-center mt-4">
      <div className="max-w-7xl card shadow-2xl">
        <VehicleReport service={selectedService} rc={selectedService.replacement_car_data} isWhat={isWhat} />
      </div>
    </div>
  )
}

export default VehicleReportView
