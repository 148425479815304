import { useRef, useState } from "react"
import { Stepper } from "@mantine/core"
import { useForm } from "react-hook-form"
import SelectMap from "../../inputs/SelectMap"
import Map from "../../map/Map"
import SignatureCanvas from "react-signature-canvas"
import MantineDropzone from "./Dropzone"
import { useEffect } from "react"
import { useStore, useStoreDrivers, useStoreServices, useStoreUsers } from "../../../zustand/store"
import { NewDriverCard } from "../../drivers/DriverCard"
import Files from "../../inputs/Files"
import { urltoFile } from "../../../library/functions"
import { apiUrl, config } from "../../../library/constant"
import axios from "axios"
import CarIntent from "../CarIntent"
import dayjs from "dayjs"
import TextArea from "../../inputs/TextArea"
import Input from "../../inputs/Input"
import { toast } from "react-toastify"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const CarCollected = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const watcher = watch()

  const [active, setActive] = useState(0)
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current))
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))

  const loggedUser = useStore((s) => s.loggedUser)
  const drivers = useStoreDrivers((s) => s.drivers)
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const [currentDriver, setCurrentDriver] = useState({})

  const [signaturePoints, setSignaturePoints] = useState([])
  const [signUrl, setSignUrl] = useState("")

  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])

  const [leasedVehicleIn, setLeasedVehicleIn] = useState("{}")
  const [replacementVehicleOut, setReplacementVehicleOut] = useState("{}")

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const { users } = useStoreUsers()

  const [nolsDataRC, setNolsDataRC] = useState({})
  const [nolsDataLV, setNolsDataLV] = useState({})

  useEffect(() => {
    console.log(loggedUser)

    let driverId

    for (let index = 0; index < users.length; index++) {
      const element = users[index]
      if (element.id === selectedService.assignedTo) {
        driverId = element.driver
      }
    }

    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index]
      if (driver.id === driverId) {
        setCurrentDriver(driver)
      }
    }
  }, [loggedUser, drivers])

  const finish = useCbOnce(async () => {
    const currentDriverId = currentDriver?.id ?? loggedUser?.driver

    if (!currentDriverId) {
      toast.error("You Have no Driver Profile")
      return
    }

    const file = await urltoFile(signUrl, "signature.png", "png")
    console.log(file)
    const formData = new FormData()
    formData.append("files", file)
    const res = await axios.post(apiUrl + "/upload", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })

    const uploadedFilename = res.data.filenames[0]
    // const uploadedPictures = uploadFiles ? uploadFiles.map((item) => item.filename) : []

    let lease_vehicle_data
    try {
      lease_vehicle_data = JSON.parse(selectedService.lease_vehicle)
    } catch (error) {
      lease_vehicle_data = {}
    }

    let replacement_vehicle_data
    try {
      replacement_vehicle_data = JSON.parse(selectedService.replacement_vehicle)
    } catch (error) {
      replacement_vehicle_data = {}
    }

    const lease_vehicle = {
      ...lease_vehicle_data,
      date_in: watcher.date_in,
      time_in: watcher.time_in,
      km_in: watcher.km_in,
      fuel_in: watcher.fuel_in,
      description_in: watcher.description_in,
    }

    let replacement_vehicle = {}
    if (selectedService?.rc_needed) {
      replacement_vehicle = {
        ...replacement_vehicle_data,
        date_out: watcher.date_out,
        time_out: watcher.time_out,
        km_out: watcher.km_out,
        fuel_out: watcher.fuel_out,
        description_out: watcher.description_out,
      }
    }

    const xLeasedVehicleIn = JSON.parse(leasedVehicleIn)
    const xReplacementVehicleOut = JSON.parse(replacementVehicleOut)
    const newLeasedVehicleIn = { ...xLeasedVehicleIn, description: watcher.description_in }
    const newReplacementVehicleOut = { ...xReplacementVehicleOut, description: watcher.description_out }

    let submitData = {
      id: selectedService.id,
      collection_driver: Number(currentDriverId),
      collection_signature: uploadedFilename,
      lease_vehicle: JSON.stringify(lease_vehicle),
      lease_vehicle_in: JSON.stringify(newLeasedVehicleIn),
    }

    if (selectedService?.rc_needed) {
      submitData["replacement_vehicle"] = JSON.stringify(replacement_vehicle)
      submitData["replacement_vehicle_out"] = JSON.stringify(newReplacementVehicleOut)
    } else {
      console.log(selectedService.replacement_vehicle)
      submitData["replacement_vehicle"] = selectedService.replacement_vehicle
      submitData["replacement_vehicle_out"] = selectedService.replacement_vehicle_out
    }
    console.log("car_collected", submitData)
    const response = await axios.post(apiUrl + "/services/car_collected", submitData, config())
    console.log("car_collected", response.data)

    setSelectedService(response.data)
    updateService(response.data)
    setIsVisible(false)
  })

  useEffect(() => {
    const newDateTime = new Date()
    setValue("time_in", dayjs(newDateTime).format("HH:mm"))
    setValue("date_in", dayjs(newDateTime).format("YYYY-MM-DD"))
    setValue("time_out", dayjs(newDateTime).format("HH:mm"))
    setValue("date_out", dayjs(newDateTime).format("YYYY-MM-DD"))

    const link = apiUrl + "/rc_history/" + selectedService.id
    console.log(config())
    axios
      .get(link, config())
      .then((res) => {
        if (res.data.length !== 0) {
          console.log(res.data[0].replacement_vehicle_out)
          setLeasedVehicleIn(res.data[0].replacement_vehicle_out)
        }
      })
      .catch((err) => {
        toast.error("Problem fetching History")
      })
  }, [])

  useEffect(() => {
    try {
      console.log(selectedService)
      if (selectedService.client_api_data) {
        const nolslv = JSON.parse(selectedService.client_api_data)
        setNolsDataLV(nolslv)
      }

      if (selectedService?.replacement_car_data?.client_api_data) {
        const nolsrc = JSON.parse(selectedService.replacement_car_data.client_api_data)
        setNolsDataRC(nolsrc)
      }
    } catch (error) {
      console.log(error)
    }
  }, [selectedService])

  return (
    <div className="px-4 space-y-4">
      <div className="">
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Driver Confirmation" radius={"sm"} description="First Step">
            <div className="flex justify-center mt-6">
              {currentDriver?.id ? (
                <NewDriverCard item={currentDriver} isLarge={true} isFlexCol={false} />
              ) : (
                <NewDriverCard item={loggedUser?.driver_data} isLarge={true} isFlexCol={false} />
              )}
            </div>
          </Stepper.Step>

          {/* Leased Vehicle In */}
          <Stepper.Step label="Vehicle Report" radius={"sm"} description={"Second Step"}>
            <CarIntent
              className="border-b"
              title={selectedService.lease_vehicle_in ? "Replacement Vehicle In" : "Leased Vehicle In"}
              title2={
                selectedService.lease_vehicle_in
                  ? `- (${selectedService?.replacement_car_data?.emirate_data?.name}-${nolsDataRC?.PLATE_NO} - ${nolsDataRC?.MODEL})`
                  : `- (${selectedService?.emirate_data?.name}-${nolsDataLV?.PLATE_NO} - ${nolsDataLV?.MODEL})`
              }
              name="Vehicle In"
              id="lease_vehicle_in"
              xSetValue={setLeasedVehicleIn}
              data={leasedVehicleIn ?? null}
              isSetValue={false}
            />
            <form className="grid grid-flow-row grid-cols-3 gap-x-4">
              <Input
                register={register}
                name="date_in"
                label="Date In"
                placeholder="Date In"
                type="date"
                error={errors}
                disabled
              />

              <Input
                register={register}
                name="time_in"
                label="Time In"
                placeholder="Time In"
                type="time"
                error={errors}
                disabled
              />
              <div className="flex gap-x-4">
                <Input register={register} name="km_in" label="Km In" placeholder="Km In" type="text" error={errors} />
                <Input
                  register={register}
                  name="fuel_in"
                  label="Fuel In"
                  placeholder="Fuel In"
                  type="text"
                  error={errors}
                />
              </div>
            </form>
            <div>
              <TextArea
                register={register}
                name="description_in"
                label="Description"
                placeholder={"Description"}
                type="text"
                error={errors}
                className="min-h-[140px]"
              />
            </div>

            {/* Replacement Out */}
            {selectedService?.rc_needed && (
              <>
                <CarIntent
                  className="border-b"
                  title="Replacement Vehicle Out"
                  title2={`- (${selectedService?.replacement_car_data?.emirate_data?.name}-${nolsDataRC?.PLATE_NO} - ${nolsDataRC?.MODEL})`}
                  name="Vehicle Out"
                  id="replacement_vehicle_out"
                  xSetValue={setReplacementVehicleOut}
                  data={replacementVehicleOut ?? null}
                  isSetValue={false}
                />
                <form className="grid grid-flow-row grid-cols-3 gap-x-4">
                  <Input
                    register={register}
                    name="date_out"
                    label="Date Out"
                    placeholder="Date Out"
                    type="date"
                    error={errors}
                    disabled
                  />
                  <Input
                    register={register}
                    name="time_out"
                    label="Time Out"
                    placeholder="Time Out"
                    type="time"
                    error={errors}
                    disabled
                  />
                  <div className="flex gap-x-4">
                    <Input
                      register={register}
                      name="km_out"
                      label="Km Out"
                      placeholder="Km Out"
                      type="text"
                      error={errors}
                    />
                    <Input
                      register={register}
                      name="fuel_out"
                      label="Fuel Out"
                      placeholder="Fuel Out"
                      type="text"
                      error={errors}
                    />
                  </div>
                </form>
                <div>
                  <TextArea
                    register={register}
                    name="description_out"
                    label="Description"
                    placeholder={"Description"}
                    type="text"
                    error={errors}
                    className="min-h-[140px]"
                  />
                </div>
              </>
            )}
          </Stepper.Step>

          <Stepper.Step label={`E-Signature of Client `} radius={"sm"} description="Final Step">
            <div className="">
              <div className="w-full text-center">
                <div className="px-4 py-4 border-t border-gray-400 border-x">
                  I hereby acknowledge the damage / additional damage as described above has been caused by the
                  signatory.
                </div>
                <div className="px-4 py-4 border-t border-gray-400 border-x">
                  " I hereby acknowledge that I have removed my personal belongins & valuable items like Cash, Mobile
                  Phone, Laptop, Camera etc. from the car & I will not claim for any valuables while returning my car or
                  the replacement car. "
                </div>
              </div>
              <Signature value={signaturePoints} setValue={setSignaturePoints} setSignUrl={setSignUrl} />
            </div>
          </Stepper.Step>
          {/* <Stepper.Completed></Stepper.Completed> */}
        </Stepper>
      </div>

      <div className="flex items-center justify-end w-full gap-2">
        {active != 0 && (
          <button
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => prevStep()}
          >
            Back
          </button>
        )}

        {active < 2 ? (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => nextStep()}
          >
            Next
          </button>
        ) : (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => finish()}
            disabled={buttonDisabled}
          >
            Finish
          </button>
        )}
      </div>
    </div>
  )
}

export default CarCollected

const Signature = ({ value, setValue, setSignUrl }) => {
  const sigCanvas = useRef()

  useEffect(() => {
    if (sigCanvas.current || value) {
      sigCanvas.current.fromData(value)
    }
  }, [sigCanvas, value])

  return (
    <div className="relative w-full space-y-2 border border-gray-400">
      {/* TODO: convert signature to image, idk how to do this sir whaha */}
      <button className="absolute px-3 py-2 border rounded-md top-2 right-2" onClick={() => sigCanvas.current.clear()}>
        Clear
      </button>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 800, height: 400, className: "" }}
        ref={sigCanvas}
        onEnd={(e) => {
          const dataPoints = sigCanvas.current.toData()
          setValue(dataPoints)
          setSignUrl(sigCanvas.current.toDataURL("png"))
        }}
      />
    </div>
  )
}
