import { data } from "autoprefixer"
import dayjs from "dayjs"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { useStore, useStoreImageViewer, useStoreMap, useStoreReplacementCars } from "../../zustand/store"
import Map from "../map/Map"
import CarItem from "./CarItem"
import VehicleReportView from "./VehicleReportView"
import { apiUrl } from "../../library/constant"
import { useReactToPrint } from "react-to-print"
import ModalUni from "../modals/ModalUni"

export const DetailField = ({ label, value, className, type = "text", isViewable = true }) => {
  // console.log(value)
  const { setPictures, setViewerVisible, viewerVisible } = useStoreImageViewer()

  const openView = (picture) => {
    if (isViewable) {
      setPictures([picture])
      setViewerVisible(true)
    }
  }

  return (
    <div className={`text-sm ${className === "" ? "w-[40%]" : className}`}>
      <ModalUni isVisible={viewerVisible} setIsVisible={setViewerVisible} size={10} title={""}></ModalUni>
      <div>{label} :</div>
      {type === "text" && <div className={`font-bold text-gray-600 truncate`}>{value}</div>}

      {type === "image" && (
        <img
          onClick={() => openView(apiUrl + "/public/" + value)}
          src={apiUrl + "/public/" + value}
          alt="vsrm-image"
          className={`${isViewable ? "cursor-pointer" : ""}`}
        />
      )}
      {type === "images" && (
        <div className="flex gap-4">
          {value?.map((picture, key) => (
            <img
              onClick={() => openView(apiUrl + "/public/" + picture)}
              key={key}
              src={apiUrl + "/public/" + picture}
              className={`w-20 ${isViewable ? "cursor-pointer" : ""}`}
              alt="vsrm-image"
            />
          ))}
        </div>
      )}

      {type === "files" && (
        <div className="flex gap-4 flex-col">
          <div className="flex gap-4">
            {value?.map((picture, key) => {
              const fext = picture.split(".").pop()
              if (["jpg", "png", "jpeg"].includes(fext)) {
                return (
                  <img
                    onClick={() => openView(apiUrl + "/public/" + picture)}
                    key={key}
                    src={apiUrl + "/public/" + picture}
                    className={`h-20 ${isViewable ? "cursor-pointer" : ""}`}
                    alt="vsrm-image"
                  />
                )
              } else {
                return <React.Fragment key={key} />
              }
            })}
          </div>

          <div className="flex flex-col gap-1">
            {value?.map((picture, key) => {
              const fext = picture.split(".").pop()
              if (!["jpg", "png", "jpeg"].includes(fext)) {
                return (
                  <a
                    href={apiUrl + "/public/" + picture}
                    download="Example-PDF-document"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="link-primary">{picture}</button>
                  </a>
                )
              } else {
                return <React.Fragment key={key} />
              }
            })}
          </div>
        </div>
      )}

      {type === "files-object" && (
        <div className="flex gap-4 flex-col">
          <div className="flex gap-4">
            {value?.map((picture, key) => {
              const fext = picture.filename.split(".").pop()
              if (["jpg", "png", "jpeg"].includes(fext)) {
                return (
                  <img
                    onClick={() => openView(apiUrl + "/public/" + picture.filename)}
                    key={key}
                    src={apiUrl + "/public/" + picture.filename}
                    className={`h-20 ${isViewable ? "cursor-pointer" : ""}`}
                    alt="vsrm-image"
                  />
                )
              } else {
                return <React.Fragment key={key} />
              }
            })}
          </div>

          <div className="flex flex-col gap-1">
            {value?.map((picture, key) => {
              const fext = picture.filename.split(".").pop()
              if (!["jpg", "png", "jpeg"].includes(fext)) {
                return (
                  <a
                    href={apiUrl + "/public/" + picture.filename}
                    download="Example-PDF-document"
                    target="_blank"
                    rel="noreferrer"
                    key={key}
                  >
                    <button className="link-primary">{picture.name}</button>
                  </a>
                )
              } else {
                return <React.Fragment key={key} />
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const FormView = ({ service }) => {
  const storeReplacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)
  const [selectedCar, setSelectedCar] = useState(null)

  const [clientData, setClientData] = useState({})

  const { isVehicleModalOpen } = useStore()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const service_mileage = service.service_type === 1 && service.rc_needed && service.collection_needed ? service.service_mileage : ""
  const location_state = service.service_type === 1 && service.rc_needed && service.collection_needed ? service.collection_state : ""
  const location_country = service.service_type === 1 && service.rc_needed && service.collection_needed ? service.collection_country : ""
  const rc_selection_reason = service.service_type === 1 && service.rc_needed && service.collection_needed ? service.rc_selection_reason : ""
  const rc_price = service.service_type === 1 && service.rc_needed && service.collection_needed ? service.rc_price : ""

  useEffect(() => {
    const car = storeReplacementCars.filter((car) => car.id === service.replacement_car)[0]
    setSelectedCar(car)

    if (service?.client_api_data) {
      setClientData(JSON.parse(service?.client_api_data))
    }
    if (service?.collection_coordinates) {
      const collection_coordinates = JSON.parse(service.collection_coordinates)
      setHoverCoordinates(collection_coordinates)
    } else {
      setHoverCoordinates(null)
    }
  }, [service, storeReplacementCars])

  useEffect(() => {
    console.log("service.lease_vehicle", JSON.parse(service.lease_vehicle))
  }, [service.lease_vehicle])

  return (
    <div
      ref={componentRef}
      className="flex flex-col gap-4 px-4 pb-8 min-h-[70vh] max-h-[80vh] print:max-h-full overflow-y-auto"
    >
      {/*       
      <div className="btn btn-accent print:hidden" onClick={handlePrint}>
        Print
      </div>
      */}
      <div className="grid w-full grid-cols-3 gap-4 pb-4 mt-4 border-b">
        <DetailField label={"Emirates"} value={service.emirate_data.name} />
        <DetailField label={"Registration Number"} value={service.request_no} className="w-full" />
        <DetailField
          label={"Registration Category"}
          value={service?.auh_category_data?.name}
          className={service?.auh_category_data?.name ? "w-full" : "invisible"}
        />
      </div>
      <div className="grid w-full grid-cols-3 gap-4 pb-4 border-b">
        <DetailField label={"Client No."} value={clientData?.CLIENT_NO ?? "No details provided"} />
        <DetailField label={"Client Name"} value={clientData?.CLIENT_NAME ?? "No details provided"} />
        <div></div>
        <DetailField label={"Client Car Make"} value={clientData?.MAKE ?? "No details provided"} />
        <DetailField label={"Client Car Model"} value={clientData?.MODEL ?? "No details provided"} />
        <DetailField label={"Client Car Year Model"} value={clientData?.YEAR_MODEL ?? "No details provided"} />

        <DetailField label={"NOLS Reg No."} value={clientData?.PLATE_NO ?? "No details provided"} />
        <DetailField label={"Client Car Color"} value={clientData?.COLOR ?? "No details provided"} />
        <DetailField
          label={"Client Car Last Known Mileage"}
          value={clientData?.LAST_KNOWN_MILEAGE ?? "No details provided"}
        />
      </div>
      <div className="flex flex-wrap items-center w-full gap-4">
        <DetailField label={"Driver Name"} value={service.driver_name} className="w-[30%]" />
        <DetailField label={"Driver Contact"} value={service.driver_contact} className="w-[30%]" />
        <DetailField label={"Driver Email"} value={service.driver_email} className="w-[30%]" />
      </div>
      <div className="w-full border-b">
        <DetailField
          label={"Description"}
          value={service.request_details ?? "No details provided"}
          className="w-full h-full mb-4"
        />
      </div>
      <div className="flex flex-wrap items-center w-full gap-4">
        <DetailField label={"Service Type"} value={service.service_type_data.name} className="w-[30%]" />
        <DetailField label={"Current Mileage"} value={service.mileage ?? "No details provided"} className="w-[30%]" />
        <DetailField
          label={"Current Mileage Reading"}
          value={service.mileage ?? "No details provided"}
          className="w-[30%]"
        />
      </div>
      {service?.collection_needed && (
        <>
          <div className="flex flex-wrap items-center w-full gap-4">
            <DetailField
              label={"Collection Date"}
              value={
                service.collection_datetime ? (
                  dayjs(service.collection_datetime).format("MMM D, YYYY HH:mm")
                ) : (
                  <div className="text-red-400">Collection Date Not Set</div>
                )
              }
              className="w-[30%]"
            />
            <DetailField
              label={"Collection Time"}
              value={
                service.collection_datetime ? (
                  dayjs(service.collection_datetime).format("HH:mm")
                ) : (
                  <div className="text-red-400">Collection Date Not Set</div>
                )
              }
              className="w-[30%]"
            />
            <DetailField
              label={"Workshop"}
              value={
                service.status_types === 5
                  ? `${service?.workshop_data?.name} => ${service?.transfer_to_data?.name}`
                  : service?.workshop_data?.name ?? "No details provided"
              }
              className={service.status_types === 5 && ` text-red-600 `}
            />
          </div>
          <div className="flex flex-col w-full gap-2 ">
            <DetailField
              label={"Collection Location"}
              value={service.collection_location ?? "No details provided"}
              className="w-full"
            />
            <div className="w-full overflow-hidden shadow-md min-w-[100px] h-96 rounded-xl print:hidden">
              <Map x="search" workshop={service?.workshop_data ?? null} />
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col gap-2">
        <p className="text-sm">Car Replacement Details</p>
        {selectedCar ? (
          <CarItem display={true} item={selectedCar} onClick={() => {}} selectedID={0} service_mileage={service_mileage} location_state={location_state} location_country={location_country} rc_selection_reason={rc_selection_reason} rc_price={rc_price} />
        ) : (
          "No Assigned Replacement Car"
        )}
      </div>

      <div className="grid w-full grid-cols-3 gap-4">
        <DetailField label={"Assigned To"} value={service?.assignedTo_data?.name ?? "No details provided"} />
      </div>

      <div className="grid w-full grid-cols-3 gap-4">
        <DetailField label={"Agent Name"} value={service?.added_by_data?.name ?? "No details provided"} />
        <DetailField
          label={"Collection Driver"}
          value={service?.collection_driver_data?.name ?? "No details provided"}
        />
        <DetailField label={"Delivery Driver"} value={service?.delivery_driver_data?.name ?? "No details provided"} />
      </div>

      <div className="page-break" />

      {!isVehicleModalOpen && (
        <VehicleReportView
          lease_vehicle={service.lease_vehicle}
          lease_vehicle_in={service.lease_vehicle_in}
          lease_vehicle_out={service.lease_vehicle_out}
          replacement_vehicle={service.replacement_vehicle}
          replacement_vehicle_in={service.replacement_vehicle_in}
          replacement_vehicle_out={service.replacement_vehicle_out}
          rc_needed={service?.rc_needed}
          collection_needed={service?.collection_needed}
        />
      )}

      <div>
        {/* <div>Attachments:</div> */}
        <DetailField
          label=" Attachments"
          value={service?.attachments ? JSON.parse(service?.attachments) : null}
          type="files-object"
        />
      </div>

      {/* <pre>{JSON.stringify(service, null, 2)}</pre> */}
    </div>
  )
}

export default FormView
