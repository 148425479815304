import React from "react"

const StatusCard = ({ count, name, className, icon = "file" }) => {
  return (
    <div
      className={
        "bg-white border-l-[12px]  hover:scale-105 cursor-pointer shadow-md rounded-xl overflow-hidden w-full relative text-gray-800 " +
        className
      }
    >
      <div className="px-4 pt-8 pb-4">
        <div className="text-xl font-bold lg:text-4xl">{count}</div>
        <div className="text-sm text-gray-500 lg:text-xl">{name}</div>
      </div>
      <div className="absolute -right-4 top-7">
        <i className={`text-gray-500/50 fa-solid text-8xl md:text-9xl fa-${icon}`}></i>
      </div>
    </div>
  )
}

export default StatusCard
