import { useSearchParams } from "react-router-dom"
import { dayjsx } from "../../library/functions"

export const AlertsItem = ({ item }) => {
  const status =
    Number(item.alert_type) === 1
      ? "New"
      : Number(item.alert_type) === 2
      ? "To Do"
      : Number(item.alert_type) === 3
      ? "Scheduled"
      : Number(item.alert_type) === 4
      ? "Collected"
      : Number(item.alert_type) === 5
      ? "For Assessment"
      : Number(item.alert_type) === 6
      ? "In Progress"
      : Number(item.alert_type) === 7
      ? "For Delivery"
      : Number(item.alert_type) === 8
      ? "Delivering"
      : Number(item.alert_type) === 9
      ? "Cancelled"
      : Number(item.alert_type) === 10
      ? "Rejected"
      : Number(item.alert_type) === 11 && "Delivered"

  let [searchParams, setSearchParams] = useSearchParams()

  const viewTicket = (req_no) => {
    setSearchParams({ service_number: req_no })
  }
  return (
    <div
      className="flex items-center py-3 text-gray-700 cursor-pointer hover:bg-gray-100 hover:text-gray-900"
      onClick={() => viewTicket(item.title)}
    >
      <i className="object-cover mx-1 ml-6 text-2xl rounded-full fa-solid fa-calendar-plus"></i>
      <div className="flex flex-col w-full">
        <p className="mx-2 text-sm font-bold text-gray-600">{item.title}</p>
        <p className="mx-2 text-sm text-gray-600">
          <span className="font-light">{item.message}</span>
        </p>
      </div>
      <div className="flex flex-col items-end justify-start mr-4">
        <p className="px-2 text-xs text-white bg-[#F06400] rounded-full">{status}</p>
        <div className="mt-1 text-xs whitespace-nowrap">{dayjsx(item.added_date).fromNow(false)}</div>
      </div>
    </div>
  )
}
