import axios from "axios"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreSettings } from "../../zustand/store"
import Card from "../Card"
import Input from "../inputs/Input"
import ModalUni from "../modals/ModalUni"
import { useCbOnce } from "../../library/hooks/useCbOne"
import CheckBox from "../inputs/CheckBox"

const SmtpForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const settings = useStoreSettings((s) => s.settings)
  const updateSetting = useStoreSettings((s) => s.updateSetting)
  const [modalTest, setModalTest] = useState(false)
  const [recipient, setRecipient] = useState("edcelmanuel9@gmail.com")
  const [sending, setSending] = useState(false)

  const watcher = watch()

  const getSettingItem = (key) => {
    for (let index = 0; index < settings.length; index++) {
      const element = settings[index]
      if (element.item_key === key) {
        return element
      }
    }
  }

  const handleSubmitData = async (params) => {
    console.log(params)
    const arr = Object.entries(params)
    for (let index = 0; index < arr.length; index++) {
      const key = arr[index][0]
      const param = arr[index][1]
      try {
        const settingItem = getSettingItem(key)
        if (settingItem.type === "bool") {
          settingItem["param"] = param ? 1 : 0
        } else {
          settingItem["param"] = param
        }

        // console.log(settingItem)
        const res = await axios.put(apiUrl + "/settings", settingItem, config())
        updateSetting(res.data)
      } catch (error) {
        toast.error(`Error: there's was a problem updating ${key}`)
      }
    }
    toast.success("Success Updating SMTP.")
  }

  const sendTestMail = async () => {
    try {
      console.log(recipient)
      const res = await axios.post(apiUrl + "/send_mail_test", { recipient })
      console.log(res)

      if (res.data.status === "success") {
        toast.success("Successfully Sent")
      } else {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error("Can't send email. Please try diffirent SMTP configuration")
    }
  }

  const sendTestMail1 = useCbOnce(async (e, setCalled) => {
    console.log("semding")
    try {
      setSending(true)
      console.log(recipient)
      const res = await axios.post(apiUrl + "/send_mail_test", { recipient })
      console.log(res)

      if (res.data.status === "success") {
        toast.success("Successfully Sent")
        setSending(false)
      } else {
        toast.success(res.data.message)
        setSending(false)
      }
      setCalled(false)
    } catch (error) {
      console.log(error)
      toast.error("Can't send email. Please try diffirent SMTP configuration")
      setSending(false)
      setCalled(false)
    }
  })

  useEffect(() => {
    if (settings.length !== 0) {
      let smtps = []
      for (let index = 0; index < settings.length; index++) {
        const element = settings[index]

        if (element.type_key === "mail") {
          if (element.type === "bool") {
            smtps.push(element)
            setValue(element.item_key, Number(element.param))
          } else {
            smtps.push(element)
            setValue(element.item_key, element.param)
          }
        }
      }
    }
  }, [settings, setValue])

  return (
    // mail_username
    // mail_password
    // mail_from
    // mail_from_name
    // mail_port
    // mail_server
    // mail_cert
    // mail_use_credential
    <Card>
      <ModalUni isVisible={modalTest} setIsVisible={setModalTest} title="Test SMTP" size={5}>
        <div className="flex flex-col gap-4 mt-4">
          <input
            type="text"
            placeholder="Enter Recipient"
            className="input input-bordered w-full"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          <button className={`btn btn-accent ${sending ? "disabled" : ""}`} onClick={sendTestMail1} disabled={sending}>
            {sending ? "Please Wait While Sending Test..." : "Send Test"}
          </button>
        </div>
      </ModalUni>
      <form
        className="p-6"
        onSubmit={handleSubmit((data) => {
          handleSubmitData(data)
        })}
      >
        <div className="text-2xl">SMTP Configuration</div>
        <div className="grid grid-flow-row grid-cols-4 gap-4">
          <Input
            register={register}
            name="mail_server"
            label="Mail Server Host"
            placeholder="stmp.google.com"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Server Required"
            className="col-span-2"
          />
          <Input
            register={register}
            name="mail_port"
            label="Mail Server Port"
            placeholder="25"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Server Required"
          />
          <div className="flex items-center h-full mt-4">
            <CheckBox id="mail_cert" setValue={setValue} value={watcher} title="TLS/SSL Connection" />
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-2 gap-4">
          <Input
            register={register}
            name="mail_username"
            label="Username"
            placeholder="Username"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Username Required"
          />
          <Input
            register={register}
            name="mail_password"
            label="Password"
            placeholder="************"
            type="password"
            condition={{ required: true }}
            error={errors}
            message="Mail Password Required"
          />
        </div>

        <div className="grid grid-flow-row grid-cols-2 gap-4">
          <Input
            register={register}
            name="mail_from"
            label="Mail From"
            placeholder="no-reply@leaseplan.com"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Username Required"
          />
          <Input
            register={register}
            name="mail_from_name"
            label="Mail Name"
            placeholder="Lease Plan No-Reply"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Name Required"
          />
        </div>
        <div className="flex">
          <div className="flex items-center h-full mt-12 mr-2">
            <CheckBox id="mail_test_mode" setValue={setValue} value={watcher} title="Test Mode Sending" />
          </div>
          <Input
            register={register}
            name="mail_recipient"
            label="Mail Recipient on Test Mode"
            placeholder="Mail"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Mail Name Required"
          />
        </div>
        <div className="text-red-600 font-bold">
          Warning: Please be aware that when the box is unchecked, the emails will be sent to the client's email
          address.
        </div>
        <div className="flex justify-end w-full gap-4 mt-4">
          {/* <div className="px-12 btn btn-warning">Mail Templates</div> */}
          <div className="px-12 btn btn-success" onClick={() => setModalTest(true)}>
            Test API
          </div>
          <button className="px-12 btn">Update</button>
        </div>
      </form>
    </Card>
  )
}

export default SmtpForm
