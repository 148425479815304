import React from "react"

const Table = ({ columns, data }) => {
  return (
    <div className="">
      {/* zxc */}
      <table className="w-full pr-2 table-auto whitespace-nowrap">
        <thead className="sticky top-0">
          <tr className="text-sm text-[#F06400] border border-[#F06400] ring ring-[#F06400]">
            {columns.map((item, key) => {
              return (
                <th key={key} className="px-3 py-3 text-sm text-left bg-white border border-[#F06400] ring-1 ring-[#F06400]">
                  <p>{item.name}</p>
                  {item.keys.map((item, index) => {
                    return (
                      <p style={{ fontSize: 10, marginBottom: '-5px' }}>{item}</p>
                    )
                  })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => {
            // convert the item to array
            const itemArray = Object.values(item)
            return (
              <tr key={key}>
                <Row key={key} data={itemArray} />
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>  
  )
}

export default Table

const Row = ({ data }) => {
  return data.map((item, key) => {
    return (
      <td className="py-4 pl-3 text-xs border border-[#F06400]" key={key}>
        {item}
      </td>
    )
  })
}
