import React, { useEffect, useState } from "react"
import axios from "axios";
import { motion } from "framer-motion"
import { timeVals, variants } from "../../../library/constant"
import SelectMap from "../../inputs/SelectMap"
import Map from "../../map/Map"
import Input from "../../inputs/Input"
import Select from "../../inputs/Select"
import { useStoreServices } from "../../../zustand/store"
import SelectMapEdit from "../../inputs/SelectMapEdit"

const CollectionDetails = ({ register, errors, watcher, setValue }) => {
  const [isCollectionDetailsVisible, setIsCollectionDetailsVisible] = useState(false)
  const { isWhat, setIsWhat } = useStoreServices()

  const handleCollectionDateChange = (event, value) => {
    setValue("collection_date", value)
    if (event) event.target.blur()
  }

  const handleCollectionTimeChange = (event, value) => {
    setValue("collection_time", value)
    if (event) event.target.blur()
  }

  useEffect(() => {
    if (watcher.collection_date && watcher.collection_time) {
      let newDateTime = new Date(watcher.collection_date + " " + watcher.collection_time)
      console.log(newDateTime.toISOString())
    }
  }, [watcher.collection_time])

  useEffect(() => {
    console.log(isWhat)
  }, [isWhat])

  return (
    <div>
      <div className="flex flex-col mt-6">
        <div
          className="flex justify-between w-full text-xl font-semibold cursor-pointer"
          onClick={() => setIsCollectionDetailsVisible(!isCollectionDetailsVisible)}
        >
          <div className="flex items-center gap-4">
            <div className="text-[#f06400]">Collection Details</div>
          </div>
          <i className="mx-4 fa-solid fa-chevron-down"></i>
        </div>
        <div className="w-full h-2 border-b"></div>
      </div>
      <motion.div
        animate={isCollectionDetailsVisible ? "open" : "closed"}
        variants={variants}
        className="h-0 overflow-hidden"
      >
        <div className="grid grid-flow-row grid-cols-2 gap-4">
          <Input
            register={register}
            name="collection_date"
            label="Collection Date"
            placeholder="Collection Date"
            type="date"
            condition={{ required: true }}
            error={errors}
            message="Collection Date Required"
            onChange={(e) => handleCollectionDateChange(e, e.target.value)}
          />
          {/* <Input
            register={register}
            name="collection_time"
            label="Collection Time"
            placeholder="Collection Time"
            type="time"
            condition={{ required: true }}
            error={errors}
            message="Collection Time Required"
          /> */}
          <Select
            register={register}
            name="collection_time"
            label="Collection Time"
            placeholder="Collection Time"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Collection Time Required"
            items={timeVals}
            selectKey="name"
            onChange={(e) => handleCollectionTimeChange(e, e.target.value)}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-1 gap-4">
          <SelectMap
            register={register}
            name="collection_location"
            nameCoordinates="collection_coordinates"
            label="Collection Location"
            placeholder="Select Collection Location"
            // required={true}
            condition={{ required: true }}
            error={errors}
            message="Collection Location Required"
            setValues={setValue}
            watcher={watcher}
          />
        </div>

        <div className="grid grid-flow-row grid-cols-2 gap-4">
          <Input
            register={register}
            name="collection_state"
            label="Collection Location (State)"
            placeholder="Collection Location (State)"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Collection Location (State) Required"
            readOnly={true}
          />
          <Input
            register={register}
            name="collection_country"
            label="Collection Location (Country)"
            placeholder="Collection Location (Country)"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Collection Location (Country) Required"
            readOnly={true}
          />
        </div>

        <div className="w-full overflow-hidden shadow-md min-w-[100px] h-96 rounded-xl">
          <Map x="search" />
        </div>
      </motion.div>
    </div>
  )
}

export default CollectionDetails
