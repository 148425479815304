import React from "react"
import { Menu, Transition } from "@headlessui/react"
import Tree from "../reports/Tree"
import { useMemo } from "react"
import { jsonToTreeData } from "../../library/functions"
import { useState } from "react"
import { serviceDataSettings } from "./serviceDataSetting"
import { useEffect } from "react"

const DynamicColumnChanger = ({ selectedColumns, setSelectedColumns }) => {
  const procData = useMemo(() => {
    const newData = jsonToTreeData(serviceDataSettings)
    return {
      id: 1,
      name: "Service Request Api Data",
      children: newData,
    }
  }, [serviceDataSettings])

  useEffect(() => {
    const serviceCols = localStorage.getItem("serviceCols")
    if (serviceCols) {
      setSelectedColumns(JSON.parse(serviceCols))
    } else {
      setSelectedColumns([
        {
          id: "service_type",
          key: "service_type",
          keys: [],
          name: "Service type",
        },
        {
          id: "emirate",
          key: "emirate",
          keys: [],
          name: "Emirate",
        },
        {
          id: "plate",
          key: "plate",
          keys: [],
          name: "Plate",
        },
        {
          id: "driver_name",
          key: "driver_name",
          keys: [],
          name: "Driver name",
        },
        {
          id: "collection_datetime",
          key: "collection_datetime",
          keys: [],
          name: "Collection datetime",
        },
      ])
    }
  }, [])

  const serviceReportSettings = useMemo(() => {
    if (selectedColumns?.length !== 0) {
      return {
        id: 15,
        type_key: "report",
        item_key: "services",
        type: "object",
        param: JSON.stringify(selectedColumns),
      }
    }
    return null
  }, [selectedColumns])

  useEffect(() => {
    if (selectedColumns?.length !== 0) {
      const strSelectedCols = JSON.stringify(selectedColumns)
      localStorage.setItem("serviceCols", strSelectedCols)
    } else {
      localStorage.removeItem("serviceCols")
    }
  }, [selectedColumns])

  return (
    <div className="flex items-center justify-center z-[999]">
      <div className="relative inline-block text-left">
        <Menu>
          {({ open }) => (
            <>
              {/* <Menu.Button className="flex items-center justify-center w-12 h-12 border-2 border-[#F06400] rounded-full">
                            <i className="fa-solid fa-user-tie"></i>
                        </Menu.Button> */}

              <Menu.Button className="relative flex items-center justify-center w-8 h-12 rounded-full">
                <div>
                  <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-line-columns"></i>
                </div>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 w-64 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none md:w-96"
                >
                  <div className="overflow-y-auto divide-y divide-gray-100 h-[400px] p-6">
                    <Tree
                      data={procData}
                      setSelectedData={setSelectedColumns}
                      selectedData={selectedColumns}
                      setting={serviceReportSettings}
                    />
                  </div>
                  <div className="flex justify-end p-2">
                    <div className="btn-sm btn">Save</div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}

export default DynamicColumnChanger
