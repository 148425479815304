import GSTC from "gantt-schedule-timeline-calendar"
import React from "react"
import { useEffect } from "react"
import { useStore, useStoreCalendar, useStoreServices, useStoreWorkshops } from "../../zustand/store"
import Calendar from "../calendar/Calendar"
import { DateFilter } from "../schedules/DateFilter"
import { chartData, rowGTSCDataProcessor } from "../schedules/Filters"

const ReplacementSchedules = ({ car, cdate = null, watcher, clientNo = "" }) => {
  const service = useStoreServices((s) => s.selectedService)
  const { setCalendarItems, setCalendarRows, setOldCalendarRows } = useStoreCalendar()
  // const calendar = useStore((s) => s.calendar)

  const uppercaseText = (text) => {
    // console.log("text", text)
    if (text) {
      return text?.toUpperCase()?.trim()
    } else {
      return text
    }
  }

  useEffect(() => {
    let repCars = car

    if (car) {
      // calendar.update("config.chart.items", (s) => {
      //   const newRows = GSTC.api.fromArray([])
      //   return newRows
      // })
      // calendar.update("config.list.rows", (s) => {
      //   const newRows = GSTC.api.fromArray(rowGTSCDataProcessor(repCars))
      //   return newRows
      // })

      // filter by location
      if (watcher?.collection_state) repCars = repCars.filter(item => uppercaseText(item.location) === uppercaseText(watcher?.collection_state))
      // console.log("repCars", repCars)

      setOldCalendarRows(rowGTSCDataProcessor(repCars))
      setCalendarRows(rowGTSCDataProcessor(repCars))

      let ganttSched = []
      for (let index = 0; index < repCars.length; index++) {
        const repCar = repCars[index]
        if (repCar.schedules.length !== 0) {
          let schedules = repCar.schedules
          for (let index = 0; index < schedules.length; index++) {
            const sched = schedules[index]
            const newGanttItem = {
              id: sched.id.toString(),
              row_id: sched.replacement_car,
              start: new Date(sched.start),
              end: new Date(sched.end),
              item: chartData(repCar, sched.service_data ?? null),
            }
            ganttSched.push(newGanttItem)
          }
        }
      }

      if (ganttSched.length !== 0) {
        setCalendarItems(ganttSched)
        // console.log(ganttSched)
      }
    }
  }, [car, watcher?.collection_state])

  return (
    <div className="mt-2">
      <div className="">
        <DateFilter cdate={cdate} clientNo={clientNo} />
        <Calendar selection={true} />
      </div>
    </div>
  )
}

export default ReplacementSchedules
