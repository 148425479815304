// import React from "react"
// import ReactDOM from "react-dom/client"
// import "./index.css"
// import App from "./App"

// const root = ReactDOM.createRoot(document.getElementById("root"))

// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// )

import { StrictMode } from "react"
import { render } from "react-dom"
import "./index.css"
import "./styles.scss"
import App from "./App"
import "leaflet/dist/leaflet.css"
// import "react-tooltip/dist/react-tooltip.css"
import { BrowserRouter } from "react-router-dom"
import CarIntent from "./components/service_request/CarIntent"

const rootElement = document.getElementById("root")
render(
  // <StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </StrictMode>,
  rootElement
)
