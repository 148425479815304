import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useStore } from "../../zustand/store"

const SideBar = () => {
  const userType = useStore((state) => state.userType)
  const privileges = useStore((state) => state.privileges)

  return (
    <div className="flex flex-col items-center h-screen pt-6 text-[#004A5D] border-r border-gray-100 w-14 md:w-36 z-[10000]">
      <div className="mx-2">
        <img src="/logo.png" alt="xx" className="" />
      </div>

      <div className="px-2 border-b border-gray-300">
        <div className="px-2 py-1 mt-5 mb-3 text-xl text-[#F06400] rounded hover:bg-gray-300">
          <i className="fa-solid fa-bars"></i>
        </div>
      </div>

      <div className="flex flex-col px-2 border-b border-gray-300 ">
        <Link to="/">
          <div
            className="px-2 py-1 mt-4 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
            data-tip="Dashboard"
          >
            <i className="fa-solid fa-gauge-max"></i>
          </div>
        </Link>
        <Link to="/service_request">
          <div
            className="px-2 py-1 mb-4 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
            data-tip="Service Request"
          >
            <i className="fa-solid fa-calendar-plus"></i>
          </div>
        </Link>
      </div>

      <div className="flex flex-col px-2 border-b border-gray-300 ">
        {Boolean(privileges[13]) && (
          <Link to="/workshops">
            <div
              className="px-2 py-1 mt-4 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
              data-tip="Workshops"
            >
              <i className="fa-solid fa-shop"></i>
            </div>
          </Link>
        )}
        {(userType === 1 || userType === 3) && (
          <Link to="/drivers">
            <div className="px-2 py-1 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right" data-tip="Drivers">
              <i className="fa-solid fa-id-card"></i>
            </div>
          </Link>
        )}
      </div>

      <div className="flex flex-col items-center px-2">
        {Boolean(privileges[12]) && (
          <Link to="/user_management">
            <div
              className="px-2 py-1 mt-4 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
              data-tip="User Management"
            >
              <i className="fa-solid fa-users"></i>
            </div>
          </Link>
        )}

        {Boolean(privileges[14]) && (
          <Link to="/audit_logs">
            <div
              className="px-2 py-1 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
              data-tip="Audit Logs"
            >
              <i className="fa-solid fa-toilet-paper"></i>
            </div>
          </Link>
        )}
        {Boolean(privileges[15]) && (
          <Link to="/reports/services">
            <div className="px-2 py-1 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right" data-tip="Reports">
              <i className="fa-solid fa-file-chart-column"></i>
            </div>
          </Link>
        )}
        {Boolean(privileges[11]) && (
          <Link to="/settings">
            <div className="px-2 py-1 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right" data-tip="Settings">
              <i className="fa-solid fa-gear"></i>
            </div>
          </Link>
        )}
        {Boolean(privileges[16]) && (
          <div
            className="px-2 py-1 text-xl rounded hover:bg-gray-300 tooltip tooltip-right dropdown dropdown-hover dropdown-end"
            data-tip="Replacement Cars"
          >
            <label tabIndex={0}>
              <i className="fa-solid fa-car-side"></i>
            </label>

            <div tabIndex={0} className="mr-1 dropdown-content menu">
              <i className="fa-solid fa-caret-down"></i>
              <Link to="/replacement_cars/view">
                <div className="px-2 py-1 text-xl rounded hover:bg-gray-300 tooltip tooltip-right" data-tip="View Cars">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
              </Link>
              <Link to="/replacement_cars/schedules">
                <div
                  className="px-2 py-1 mb-3 text-xl rounded hover:bg-gray-300 tooltip tooltip-right"
                  data-tip="Car Schedules"
                >
                  <i className="fa-solid fa-calendar"></i>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SideBar
