import { motion } from "framer-motion"

const animations = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
}

const AnimatedPage = ({ children, withBanner = true, title }) => {
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 1 }}
      className="w-full h-full"
    >
      {withBanner && (
        <div className="relative pt-14">
          {/* min-h-[150px] lg:min-h-[230px] xl:min-h-[260px]   absolute z-10 text-black left-6 lg:left-20 top-20*/}
          {/* <img className="absolute w-full" src="/page_banner.jpg" alt="" /> */}
          <div className="ml-6 text-gray-700">
            <h2 className="text-4xl font-bold">{title}</h2>
            {/* <p>List of all the {title} in the system </p> */}
          </div>
        </div>
      )}
      <div className="p-6">{children}</div>
    </motion.div>
  )
}

export default AnimatedPage
