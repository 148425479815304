import React, { useState } from "react"
import { useStore, useStoreDrivers, useStoreServices, useStoreUsers } from "../../../zustand/store"
import { NewDriverCard } from "../../drivers/DriverCard"
import axios from "axios"
import { apiUrl, config } from "../../../library/constant"
import { toast } from "react-toastify"

const AssignTo = ({ setIsVisible }) => {
  const workshop = useStore((s) => s.workshop)
  const drivers = useStoreDrivers((s) => s.drivers)
  const users = useStoreUsers((s) => s.users)
  const selectedService = useStoreServices((s) => s.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)
  const userType = useStore((state) => state.userType)

  console.log(drivers)

  const handleAssignDriver = async (id) => {
    console.log(id)
    setIsVisible(false)

    const newData = {
      id: selectedService.id,
      workshop: id,
    }
    try {
      const { data, status } = await axios.post(apiUrl + "/services/assign_to", newData, config())
      if (status === 200) {
        setIsVisible(false)
        setSelectedService(data)
        updateService(data)
        toast.success("Successfully Assigned")
      }
    } catch (error) {
      toast.error("There was something wrong with the data submitted.")
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {!selectedService?.workshop && <div>No Workshop Provided</div>}
      {users.map((item, key) => {
        if (item.driver && item.user_type === 4) {
          const driver = drivers.find((obj) => obj.id === item.driver)
          console.log(selectedService.workshop)
          if (selectedService?.workshop !== driver?.workshop && (userType !== 1 || userType !== 2)) {
            return <React.Fragment key={key}></React.Fragment>
          }
          return (
            <div
              key={key}
              className="transition-all ease-out cursor-pointer hover:opacity-50"
              onClick={() => handleAssignDriver(item.id)}
            >
              <NewDriverCard item={driver} isLarge={true} isFlexCol={false} />
            </div>
          )
        }
        return <></>
      })}
    </div>
  )
}

export default AssignTo
