import React from "react"
import AnimatedPage from "../components/AnimatedPage"
import CreateAPI from "../components/settings/CreateAPI"
import Emirates from "../components/settings/Emirates"
import ServiceMileage from "../components/settings/ServiceMileage"
import MapSetting from "../components/settings/MapSetting"
import ServiceWorker from "../components/settings/ServiceWorker"
import SmtpForm from "../components/settings/SmtpForm"
import Roles from "../components/settings/Roles"
import ConvertExcelToJSON from "../components/settings/ConvertExcelToJSON"
import OverallMaintenanceTable from "../components/settings/OverallMaintenanceTable"
import { useStore } from "../zustand/store"
import NoAccess from "./NoAccess"

const Settings = () => {
  const privileges = useStore((state) => state.privileges)

  if (!Boolean(privileges[11])) {
    return <NoAccess />
  }

  return (
    <AnimatedPage title="Settings">
      <div className="flex flex-col gap-6">
        <Emirates />
        {/* <ServiceMileage /> */}
        <Roles />
        <SmtpForm />
        <CreateAPI />
        <MapSetting />
        <ServiceWorker />
        <ConvertExcelToJSON/>
        <OverallMaintenanceTable/>
      </div>
    </AnimatedPage>
  )
}

export default Settings
