// eslint-disable-next-line no-undef
export const serviceDataSettings = {
  emirate: 1,
  plate: "75295",
  emirates_category: 10,
  mileage: null,
  client_api_data: {
    OBJECT_NO: 1283701,
    PLATE_NO: "7529513",
    EMIRATES: "AD",
    CLIENT_NO: 11440,
    CLIENT_NAME: "LeasePlan Emirates Company Cars",
    MAKE: "Toyota",
    MODEL: "Previa,SE,",
    YEAR_MODEL: "2018",
    COLOR: "White",
    LAST_KNOWN_MILEAGE: 108092,
    REPLACEMENT_CATEGORY: "E",
    REPLACEMENT_CARS: "Toyota Yaris or similar",
    DRIVER_NAME: "Pool car Staff Trans",
    DRIVER_EMAIL: "zeenat.rahman@leaseplan.com",
    DRIVER_TELNO: "+971505710626",
  },
  driver_name: "POOL CAR STAFF TRANS",
  driver_contact: "+971505710626",
  driver_email: "zeenat.rahman@leaseplan.com",
  request_details: "zzzzzzzzzz",
  workshop: 5,
  collection_datetime: "2022-12-10T12:48:00+00:00",
  collection_location: "2 10A Street - Al Quoz - Al Quoz 1 - Dubai - United Arab Emirates",
  collection_state: "Dubai",
  collection_country: "United Arab Emirates",
  collection_coordinates: { lat: 25.175953, lng: 55.2517475 },
  service_type: 2,
  service_mileage: '10km/15km',
  added_date: "2022-12-30T12:49:38.652000+00:00",
  updated_date: "2022-12-30T12:59:12.179000+00:00",
  added_by: 2,
}
