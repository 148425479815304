import React, { useEffect } from "react"
import Card from "../Card"
import { useStoreTypes } from "../../zustand/store"
import { useState } from "react"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import { toast } from "react-toastify"

const Emirates = () => {
  return (
    <Card>
      <div className="p-6">
        <div className="text-2xl">Emirates Mapping Configuration</div>
        {/*  overflow-auto max-h-96 */}
        <div className="flex min-w-full gap-4 mt-6">
          <First />
          <Second />
        </div>
        <div className="flex min-w-full gap-4 mt-6">
          <Third />
        </div>
      </div>
    </Card>
  )
}

export default Emirates

const First = () => {
  const emirates = useStoreTypes((s) => s.emirateTypes)
  const addEmirates = useStoreTypes((s) => s.addEmirateTypes)
  const deleteEmirates = useStoreTypes((s) => s.deleteEmirateTypes)
  const updateEmirates = useStoreTypes((s) => s.updateEmirateTypes)

  const addRow = async () => {
    const { data, status } = await axios.post(apiUrl + "/emirate_types", {}, config())

    if (status) {
      addEmirates(data)
      toast.success("Row Has Been Added")
    } else {
      toast.error("There was a problem adding a new row. Please refresh the page thank you.")
    }
  }

  const handleSave = async (id, e) => {
    const xdata = {
      id: id,
      name: e.currentTarget.textContent,
    }

    let selectedEmirate
    for (let index = 0; index < emirates.length; index++) {
      const element = emirates[index]
      if (element.id === id) {
        selectedEmirate = element
      }
    }

    if (selectedEmirate.name.toUpperCase() === xdata.name.toUpperCase()) {
      return null
    }

    const { data, status } = await axios.put(apiUrl + "/emirate_types", xdata, config())

    if (status) {
      updateEmirates(data)
      toast.success("Row Has Been Updated")
    } else {
      toast.error("There was a problem adding a new row. Please refresh the page thank you.")
    }
  }

  const deleteRow = async (item) => {
    const { data, status } = await axios.delete(apiUrl + "/emirate_types/" + item.id, config())

    if (status) {
      deleteEmirates(data)
      toast.success(`Emirate: ${item.name} Successfully Deleted`)
    } else {
      toast.error("If you really need to delete this emirate. Please delete all connected emirates category first")
    }
  }

  return (
    <div className="w-full h-[384px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
      <table className="w-full overflow-hidden table-auto whitespace-nowrap">
        <thead>
          <tr>
            <th className="w-1/4 border " colSpan={5}>
              Emirates Table
            </th>
          </tr>
          <tr>
            <th className="w-1/4 border">ID</th>
            <th className="w-full border">EMIRATES</th>
            <th className="border"></th>
          </tr>
        </thead>
        <tbody>
          {emirates?.map((item, key) => {
            return (
              <tr key={key} className="border">
                <td className="px-4 border">{key + 1}</td>
                <td
                  className="px-4 border"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleSave(item.id, e)}
                >
                  {item?.name}
                </td>
                <td className="">
                  <div className="flex justify-end px-2 py-1 text-red-500">
                    <i className="fa-sharp fa-solid fa-circle-trash" onClick={() => deleteRow(item)} />
                  </div>
                </td>
              </tr>
            )
          })}
          <tr className="px-4 border">
            <td></td>
            <td></td>
            <td className="flex justify-end px-2 py-1 text-green-500">
              <i className="fa-sharp fa-solid fa-circle-plus" onClick={addRow} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const Second = () => {
  const emirates = useStoreTypes((s) => s.emirateTypes)
  const emirates_sub = useStoreTypes((s) => s.auhCategoryTypes)
  const addEmirateSub = useStoreTypes((s) => s.addAuhCategoryTypes)
  const deleteEmirateSub = useStoreTypes((s) => s.deleteAuhCategoryTypes)
  const updateAuhCategoryTypes = useStoreTypes((s) => s.updateAuhCategoryTypes)

  const addRow = async () => {
    const xdata = {
      emirate: 1,
      name: "z",
      actual: "",
    }
    const { data, status } = await axios.post(apiUrl + "/auh_category_types", xdata, config())
    if (status) {
      addEmirateSub(data)
      toast.success("Row Has Been Added")
    } else {
      toast.error("There was a problem adding a new row. Please refresh the page thank you.")
    }
  }

  const handleSave = async (key, id, e, isCategory) => {
    let selectedCategory
    for (let index = 0; index < emirates_sub.length; index++) {
      const element = emirates_sub[index]
      if (element.id === id) {
        selectedCategory = element
      }
    }

    if (selectedCategory[key] === e.currentTarget.textContent && !isCategory) {
      return null
    } else if (selectedCategory.emirate_data.name === e.currentTarget.textContent && isCategory) {
      return null
    }

    selectedCategory[key] = e.currentTarget.textContent.toUpperCase()

    if (isCategory) {
      for (let index = 0; index < emirates.length; index++) {
        const emirate = emirates[index]
        if (emirate.name.toUpperCase() === e.currentTarget.textContent.toUpperCase()) {
          selectedCategory[key] = emirate.id
        }
      }
    }

    axios
      .put(apiUrl + "/auh_category_types", selectedCategory, config())
      .then((res) => {
        updateAuhCategoryTypes(res.data)
      })
      .catch(() => {
        if (isCategory) {
          toast.error("Please Match the Emirates Name.")
        } else {
          toast.error("Something not rigth please refresh the page.")
        }
      })
  }

  const deleteRow = (item) => {
    // deleteEmirateSub(item)
    axios
      .delete(apiUrl + "/auh_category_types/" + item.id, config())
      .then(() => {
        deleteEmirateSub(item)
        toast.success(`Category: ${item.emirate_data.name}-${item.name}-${item.actual} Successfully Deleted`)
      })
      .catch(() => {
        toast.error("If you really need to delete this emirate. Please delete all connected emirates category first")
      })
  }

  return (
    <div className="w-full h-[384px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
      <table className="w-full overflow-hidden table-auto whitespace-nowrap">
        <thead>
          <tr>
            <th className="w-1/4 border " colSpan={5}>
              Emirates Category Table
            </th>
          </tr>
          <tr>
            <th className="w-1/4 border ">ID</th>
            <th className="w-1/4 border ">EMIRATE</th>
            <th className="w-1/4 border ">CATEGORY</th>
            <th className="w-1/4 border ">ACTUAL</th>
            <th className="border "></th>
          </tr>
        </thead>
        <tbody>
          {emirates_sub?.map((item, key) => {
            return (
              <tr key={key} className="border">
                <td className="px-4 border ">{key + 1}</td>
                <td
                  className="px-4 border"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleSave("emirate", item.id, e, true)}
                >
                  {/* {item?.emirate_data?.name} */}
                  {emirates.map((item1) => {
                    if (item1.id === item.emirate) {
                      return item1.name
                    }
                    return ""
                  })}
                </td>
                <td
                  className="px-4 border"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleSave("name", item.id, e, false)}
                >
                  {item?.name}
                </td>
                <td
                  className="px-4 border"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleSave("actual", item.id, e, false)}
                >
                  {item?.actual}
                </td>
                <td className="">
                  <div className="flex justify-end px-2 py-1 text-red-500">
                    <i className="fa-sharp fa-solid fa-circle-trash" onClick={() => deleteRow(item)} />
                  </div>
                </td>
              </tr>
            )
          })}
          <tr className="px-4 border">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="flex justify-end px-2 py-1 text-green-500">
              <i className="fa-sharp fa-solid fa-circle-plus" onClick={addRow} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const Third = () => {

  const [serviceMileages, setServiceMileages] = useState([])

  const addRow = async () => {
    const { status } = await axios.post(apiUrl + "/add_service_mileage", {}, config())

    if (status) {
      getServiceMileage()
      toast.success("Row Has Been Added")
    } else {
      toast.error("There was a problem adding a new row. Please refresh the page thank you.")
    }
  }

  const handleSave = async (id, e) => {
    const xdata = {
      id: id,
      name: e.currentTarget.textContent,
    }

    let selectedServiceMileage
    for (let index = 0; index < serviceMileages.length; index++) {
      const element = serviceMileages[index]
      if (element.id === id) {
        selectedServiceMileage = element
      }
    }

    if (selectedServiceMileage.name?.toUpperCase() === xdata.name?.toUpperCase()) {
      return null
    }

    const { status } = await axios.put(apiUrl + "/service_mileage", xdata, config())

    if (status) {
      getServiceMileage()
      toast.success("Row Has Been Updated")
    } else {
      toast.error("There was a problem adding a new row. Please refresh the page thank you.")
    }
  }

  const deleteRow = async (item) => {
    const { status } = await axios.delete(apiUrl + "/service_mileage/" + item.id, config())

    if (status) {
      getServiceMileage()
      toast.success(`Service Mileage: ${item.name} Successfully Deleted`)
    } else {
      toast.error("Failed to delete")
    }
  }

  const getServiceMileage = async () => {
    const { data } = await axios.get(apiUrl + "/service_mileage", config())
    setServiceMileages(data)
  }

  useEffect(() => {
    getServiceMileage()
  },[])

  return (
    <div className="w-full">
      {/*  h-[230px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100 */}
      <table className="w-[900px] overflow-hidden table-auto whitespace-nowrap">
        <thead>
          <tr>
            <th className="w-1/4 border " colSpan={5}>
              Service Mileages Table
            </th>
          </tr>
          <tr>
            <th className="w-1/4 border">ID</th>
            <th className="w-full border">Mileage</th>
            <th className="border"></th>
          </tr>
        </thead>
        <tbody>
          {serviceMileages?.map((item, key) => {
            return (
              <tr key={key} className="border">
                <td className="px-4 border">{key + 1}</td>
                <td
                  className="px-4 border"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                  onBlur={(e) => handleSave(item.id, e)}
                >
                  {item?.name}
                </td>
                <td className="">
                  <div className="flex justify-end px-2 py-1 text-red-500">
                    <i className="fa-sharp fa-solid fa-circle-trash" onClick={() => deleteRow(item)} />
                  </div>
                </td>
              </tr>
            )
          })}
          <tr className="px-4 border">
            <td></td>
            <td></td>
            <td className="flex justify-end px-2 py-1 text-green-500">
              <i className="fa-sharp fa-solid fa-circle-plus" onClick={addRow} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
