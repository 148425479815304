import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import React from "react"
import { useState } from "react"
import Loading from "../Loading"
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete"
import { useRef } from "react"
import { useStoreMap } from "../../zustand/store"
import { useEffect } from "react"

const SelectMap = ({
  register,
  name,
  nameCoordinates,
  condition = {},
  type = "text",
  placeholder,
  error,
  message = "No Error Message",
  label = "No Label",
  className,
  items,
  selectKey,
  setValues,
  address,
}) => {
  const [value, setValue] = useState("")
  const [hValue, setHValue] = useState("")

  const placeAutoComplete = useRef(null)
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)
  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const selectedAddress = useStoreMap((s) => s.selectedAddress)
  const { isFromMe, setIsFromMe } = useStoreMap()

  const handleOnChange = async (data) => {
    // console.log("data", data)
    setSelectedAddress(data)
    const result = await geocodeByPlaceId(data.value.place_id)
    setValue(data)
    setHoverCoordinates({
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng(),
    })

    const latlng = {
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng(),
    }

    setValues(nameCoordinates, JSON.stringify(latlng))
    setValues(name, data.label)

    setIsFromMe(true)
  }

  useEffect(() => {
    console.log("isFromMe", isFromMe)
    if (selectedAddress && !isFromMe) {
      setHValue(selectedAddress)
    }
  }, [selectedAddress, isFromMe])

  return (
    <div className={"w-full form-control z-[50000] text-base" + className}>
      <label className="label">
        <span className="label-text">{label}</span>
      </label>
      <GooglePlacesAutocomplete
        // apiKey={process.env.REACT_APP_MAP}
        apiKey="AIzaSyD83JQZFzJMQPmW75pm5loFSg9kt3HdCsc"
        debounce={600}
        ref={placeAutoComplete}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["ae"],
          },
        }}
        selectProps={{
          value: isFromMe ? value : hValue,
          onChange: handleOnChange,
          styles: {
            input: (provided) => ({
              ...provided,
              color: "blue",
              padding: "0.5rem",
              fontSize: "1rem",
            }),
            option: (provided) => ({
              ...provided,
              color: "blue",
              //   padding: "2rem",
            }),
            singleValue: (provided) => {
              const transition = "opacity 300ms"
              return {
                ...provided,
                color: "blue",
                transition,
                padding: "0.5rem",
              }
            },
          },
        }}
      />
      <label className="pt-0 label">
        <span className="label-text-alt"></span>
        {error && error[name] && <span className="italic text-red-500 label-text-alt">{message}</span>}
        {/* <span className="label-text-alt">Alt label</span> */}
      </label>
    </div>
  )
}

export default SelectMap
