import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useStore, useStoreDrivers, useStoreServices, useStoreUsers } from "../../../zustand/store"
import { NewDriverCard } from "../../drivers/DriverCard"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import dayjs from "dayjs"
import axios from "axios"
import { apiUrl, config } from "../../../library/constant"
import { toast } from "react-toastify"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const Delivery = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const [currentDriver, setCurrentDriver] = useState({})

  const loggedUser = useStore((s) => s.loggedUser)
  const { users } = useStoreUsers()
  const drivers = useStoreDrivers((s) => s.drivers)
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const onSubmit = useCbOnce(async (submitData) => {
    // setOpenView(false)
    let newDateTime = null
    if (submitData.sched_date) {
      newDateTime = new Date(submitData.sched_date)
    }
    const newData = {
      id: selectedService.id,
      delivery_date: newDateTime,
      delivery_driver: currentDriver.id,
      sampleDate: new Date(),
    }

    console.log(newData)

    try {
      const { data, status } = await axios.post(apiUrl + "/services/deliver", newData, config())
      if (status === 200) {
        setIsVisible(false)
        setSelectedService(data)
        updateService(data)
        toast.success(`${selectedService.request_no} is now out for delivery.`)
      }
    } catch (error) {
      toast.error("There was something wrong with the data submitted.")
    }
  })

  useEffect(() => {
    console.log(loggedUser)

    let driverId

    for (let index = 0; index < users.length; index++) {
      const element = users[index]
      if (element.id === selectedService.assignedTo) {
        driverId = element.driver
      }
    }

    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index]
      if (driver.id === driverId) {
        setCurrentDriver(driver)
      }
    }
    const newDateTime = new Date()
    setValue("sched_date", dayjs(newDateTime).format("YYYY-MM-DD"))
  }, [loggedUser, drivers])

  return (
    <div className="w-full border-t">
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="space-y-4">
        <div className="grid w-full grid-cols-1 gap-4">
          <Input
            register={register}
            name="sched_date"
            label="Delivery date"
            placeholder={"Scheduled date"}
            type="date"
            condition={{ required: false }}
            error={errors}
          />
          <div>Delivery Man</div>
          <NewDriverCard item={currentDriver} isLarge={true} isFlexCol={false} />
        </div>

        <div className="flex items-center justify-end w-full gap-2">
          <div
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => {
              setIsVisible(false)
            }}
          >
            Cancel
          </div>
          <button className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default Delivery
