import Lottie from "lottie-react"
import React from "react"
import loader from "../assets/loader.json"

const LoadingAll = ({ progress = 0 }) => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center justify-center">
        {/* <Lottie animationData={loader} loop={true} /> */}
        <div>Loading Please Wait</div>
        <div>
          <progress className="w-56 h-6 progress progress-warning" value={progress} max="100"></progress>
        </div>
      </div>
    </div>
  )
}

export default LoadingAll
