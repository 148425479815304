import axios from "axios"
import React from "react"
import { useForm } from "react-hook-form"
import { apiUrl } from "../library/constant"
import { useStore } from "../zustand/store"
import { toast } from "react-toastify"

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const setToken = useStore((state) => state.setToken)
  const setLoggedUser = useStore((state) => state.setLoggedUser)
  const setRunningRequestNo = useStore((state) => state.setRunningRequestNo)

  const handleSubmitData = async (data) => {
    // console.log(data)
    axios
      .post(apiUrl + "/login", { ...data, email: "laksdj" })
      .then((res) => {
        // console.log(res.data)

        localStorage.setItem("token", res.data.access_token)
        setToken(res.data.access_token)

        localStorage.setItem("user", JSON.stringify(res.data.data))
        setLoggedUser(res.data.data)

        setRunningRequestNo(res.data.data.running_request_no.request_no)

        toast.success("Login Success")
      })
      .catch((error) => {
        // console.log(JSON.parse(JSON.stringify(error)))
        toast.error("Login Failed")
      })
  }

  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-300">
      <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl">Login To Your Account</div>
        <div className="mt-8">
          <form
            action="#"
            autoComplete="off"
            onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
            })}
          >
            <div className="flex flex-col mb-2">
              <div className="relative flex ">
                <span className="inline-flex items-center px-3 text-sm text-gray-500 bg-white border-t border-b border-l border-gray-300 shadow-sm rounded-l-md">
                  <i className="text-lg fa-solid fa-user"></i>
                  {/* <svg
                    width={15}
                    height={15}
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                  </svg> */}
                </span>
                <input
                  type="text"
                  {...register("username", { required: true })}
                  className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-r-lg shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
                  placeholder="email/username"
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <div className="relative flex ">
                <span className="inline-flex items-center px-3 text-sm text-gray-500 bg-white border-t border-b border-l border-gray-300 shadow-sm rounded-l-md">
                  {/* <svg
                    width={15}
                    height={15}
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                  </svg> */}
                  <i className="text-lg fa-solid fa-lock"></i>
                </span>
                <input
                  type="password"
                  {...register("password", { required: true })}
                  className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-r-lg shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-orange-600 focus:border-transparent"
                  placeholder="Your password"
                />
              </div>
            </div>
            <div className="flex items-center mb-6 -mt-4">
              <div className="flex ml-auto">
                <div className="inline-flex text-xs text-gray-500 sm:text-sm hover:text-gray-700 ">
                  Forgot Your Password?
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <button
                type="submit"
                className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-orange-600 rounded-lg shadow-md hover:bg-orange-700 focus:ring-[#F06400] focus:ring-offset-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className="flex items-center justify-center mt-6">
          <div className="inline-flex items-center text-xs text-center text-gray-500 hover:text-gray-700 ">
            <span className="ml-2">You don't have an account?</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
