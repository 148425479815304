import axios from "axios"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreSettings } from "../../zustand/store"
import Card from "../Card"
import Input from "../inputs/Input"

const MapSetting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const settings = useStoreSettings((s) => s.settings)
  const updateSetting = useStoreSettings((s) => s.updateSetting)

  const getSettingItem = (key) => {
    for (let index = 0; index < settings.length; index++) {
      const element = settings[index]
      if (element.item_key === key) {
        return element
      }
    }
  }

  const handleSubmitData = async (params) => {
    console.log(params)
    const arr = Object.entries(params)
    for (let index = 0; index < arr.length; index++) {
      const key = arr[index][0]
      const param = arr[index][1]

      const settingItem = getSettingItem(key)
      settingItem["param"] = param
      console.log(params, settingItem)

      // console.log(settingItem)
      try {
        const res = await axios.put(apiUrl + "/settings", settingItem, config())
        updateSetting(res.data)
      } catch (error) {
        toast.error(`Error: there's was a problem updating ${key}`)
      }
    }
    toast.success("Success Updating Map Key.")
  }

  useEffect(() => {
    if (settings.length !== 0) {
      let maps = []
      for (let index = 0; index < settings.length; index++) {
        const element = settings[index]

        if (element.type_key === "map") {
          maps.push(element)
          setValue(element.item_key, element.param)
        }
      }
    }
  }, [settings, setValue])

  return (
    <Card>
      <form
        className="p-6"
        onSubmit={handleSubmit((data) => {
          handleSubmitData(data)
        })}
      >
        <div className="text-2xl">Map Configuration</div>
        <div className="grid grid-flow-row grid-cols-1 gap-4">
          <Input
            register={register}
            name="map_api_key"
            label="Map API Key"
            placeholder="AKksdjhlkaj_581ajshASDJK"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Map API Key Required"
          />
        </div>

        <div className="flex justify-end w-full gap-4 mt-4">
          <button className="px-12 btn">Update</button>
        </div>
      </form>
    </Card>
  )
}

export default MapSetting
