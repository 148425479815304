import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  useStore,
  useStoreReplacementCars,
  useStoreTypes,
  useStoreWorkshops,
  useStoreSchedule,
  useStoreCalendar,
} from "../../zustand/store"
import Select from "../inputs/Select"
import GSTC from "gantt-schedule-timeline-calendar"
import { renderToStaticMarkup } from "react-dom/server"
import { apiUrl } from "../../library/constant"
import Input from "../inputs/Input"
import { dayjsx, isJson } from "../../library/functions"

export const Filters = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const workshop = useStore((s) => s.workshop)
  const userType = useStore((s) => s.userType)
  const workshops = useStoreWorkshops((s) => s.workshops)
  const emirates = useStoreTypes((s) => s.emirateTypes)
  const carsBrandTypes = useStoreTypes((s) => s.carsBrandTypes)
  const carsCategoryTypes = useStoreTypes((s) => s.carsCategoryTypes)

  const replacementCars = useStoreReplacementCars((s) => s.replacementCars)

  const { setCalendarItems, setCalendarRows } = useStoreCalendar()

  const watchAllFields = watch()

  const schedules = useStoreSchedule((s) => s.schedules)

  useEffect(() => {
    if (replacementCars) {
      let repCars = [...replacementCars]

      if (watchAllFields.workshop) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watchAllFields.workshop) === element.workshop) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }
      if (watchAllFields.brand) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watchAllFields.brand) === element.brand) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }
      if (watchAllFields.category) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watchAllFields.category) === element.category) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watchAllFields.emirate) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (Number(watchAllFields.emirate) === element.emirate) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (watchAllFields.year) {
        let tempCars = []
        for (let index = 0; index < repCars.length; index++) {
          const element = repCars[index]
          if (watchAllFields.year === element.year) {
            tempCars.push(element)
          }
        }
        repCars = tempCars
      }

      if (userType === 3 || userType === 4) {
        repCars = repCars.filter((s) => s.workshop === workshop)
      }

      setCalendarRows(rowGTSCDataProcessor(repCars))

      let ganttSched = []
      for (let index = 0; index < repCars.length; index++) {
        const repCar = repCars[index]
        if (repCar.schedules.length !== 0) {
          let schedules = repCar.schedules
          for (let index = 0; index < schedules.length; index++) {
            const sched = schedules[index]
            const newGanttItem = {
              id: sched.id.toString(),
              row_id: sched.replacement_car,
              start: new Date(sched.start),
              end: new Date(sched.end),
              item: chartData(repCar, sched.service_data ?? null),
            }
            ganttSched.push(newGanttItem)
          }
        }
      }

      if (ganttSched.length !== 0) {
        setCalendarItems(ganttSched)
        console.log(ganttSched)
      }
    }
  }, [
    replacementCars,
    watchAllFields.workshop,
    watchAllFields.brand,
    watchAllFields.category,
    watchAllFields.emirate,
    watchAllFields.year,
  ])

  return (
    <div>
      <div>Replacement Cars Filters</div>
      <Select
        register={register}
        name="emirate"
        label="Emirates"
        placeholder="Select Emirates"
        type="text"
        items={emirates}
        condition={{ required: false }}
        selectKey="name"
      />
      <Select
        register={register}
        name="workshop"
        label="Workshop"
        placeholder="Select Workshop"
        type="text"
        condition={{ required: false }}
        items={workshops}
        selectKey="name"
      />
      <Select
        register={register}
        name="category"
        label="Category"
        placeholder="Select Category"
        type="text"
        items={carsCategoryTypes}
        selectKey="name"
        condition={{ required: false }}
      />
      <Select
        register={register}
        name="brand"
        label="Make"
        placeholder="Select Make"
        type="text"
        items={carsBrandTypes}
        selectKey="name"
        condition={{ required: false }}
      />
      <Input
        register={register}
        name="year"
        label="Year Model"
        placeholder="Car Year Model"
        type="text"
        error={errors}
        message="Plate Number Required"
      />
    </div>
  )
}

export const rowGTSCDataProcessor = (rows) => {

  const parseJSON = (json) => {
    try {
      return JSON.parse(json)
    } catch (error) {
      return []
    }
  }

  const rowsGTSC = rows.map((row) => {
    return {
      id: row.id,
      workshop_id: row?.workshop, 
      brand_id: row?.brand, 
      brand: row?.brand_data?.name, 
      model: row?.model?.split(/[\s,._-]+/)[0]?.toUpperCase()?.trim(), 
      
      item: rowData(row),
      itemWithWorkshop: rowDataWithWorkshop(row),
      price: row?.price ? parseJSON(row?.price) : [],
      location: row?.location
    }
  })
  return rowsGTSC
}

export const rowData = (row) => {
  return (
    <div className="flex justify-start h-[100px] mb-4">
      <div className="flex items-center mr-4">
        <img
          src={row.pic ? apiUrl + "/public/" + row.pic : "/cars/ferrari.png"}
          alt="ferrari"
          className="w-24 h-auto rounded"
        />
      </div>

      <div className="flex flex-col justify-start pt-1 leading-none h-[100px]">
        <div className="flex font-bold leading-none mt-2" style={{ lineHeight: "1 !important" }}>
          {row.emirate_data.name}-{row.plate}
        </div>
        <div className="text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {/* {row.workshop_data.name} | {row.category_data.name} */}
          {row.category_data.name}
        </div>
        <div className="text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {row.brand_data.name}{row?.model ? ` | ${row?.model}` : ''}

        </div>
        <div className="pt-1 text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          Utilization: {Number(row.utilization).toFixed(2)}% ({dayjsx().format("MMM")})
        </div>
        {/* <div className="pt-1 text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {row.location}
        </div> */}
      </div>
    </div>
  )
}

export const rowDataWithWorkshop = (row) => {
  return (
    <div className="flex justify-start h-[100px] mb-4">
      <div className="flex items-center mr-4">
        <img
          src={row.pic ? apiUrl + "/public/" + row.pic : "/cars/ferrari.png"}
          alt="ferrari"
          className="w-24 h-auto rounded"
        />
      </div>

      <div className="flex flex-col justify-start pt-1 leading-none h-[100px]">
        <div className="flex font-bold leading-none mt-2" style={{ lineHeight: "1 !important" }}>
          {row.emirate_data.name}-{row.plate}
        </div>
        <div className="text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {row.workshop_data.name} | {row.category_data.name}
          {/* {row.category_data.name} */}
        </div>
        <div className="text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {row.brand_data.name}{row?.model ? ` | ${row?.model?.split(/[\s,._-]+/)[0]}` : ''}

        </div>
        <div className="pt-1 text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          Utilization: {Number(row.utilization).toFixed(2)}% ({dayjsx().format("MMM")})
        </div>
        {/* <div className="pt-1 text-xs leading-none text-gray-600" style={{ lineHeight: "1 !important" }}>
          {row.location}
        </div> */}
      </div>
    </div>
  )
}

export const chartData = (rc, service) => {
  // const onClick = (second) => {
  //   alert("zxc")
  //   console.log("first")
  // }
  return (
    <div className="flex h-full px-2 py-1 text-white">
      {/* <img src="/cars/ferrari.png" alt="ferrari" className="h-full" /> */}
      <div className="flex flex-col justify-start h-full pt-2 leading-none">
        <div
          className="font-bold leading-none cursor-pointer h-1/2 "
          // onClick={onClick}
          style={{ lineHeight: "1 !important" }}
        >
          {/* {rc?.emirate_data.name}-{rc?.plate} */}
          <i className="mx-1 fa-solid fa-caret-right"></i>
          {service?.request_no ?? "No Request Connected to this."}
        </div>
        <div
          className="text-xs leading-none h-1/2 whitespace-nowrap"
          style={{
            lineHeight: "1 !important",
          }}
        >
          {rc.workshop_data.name} | {rc.brand_data.name} | {rc.category_data.name} | {rc.year} | {rc.color} |{" "}
          {Number(rc.utilization).toFixed(2)}%
        </div>
      </div>
    </div>
  )
}
