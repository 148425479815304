import React, { useState } from "react"
import {
  createSyntheticFile,
  Dropzone,
  FileItem,
  FullScreenPreview,
  FileItemContainer,
  makeSynthticFileValidate,
} from "@dropzone-ui/react"
import { apiUrl } from "../../library/constant"
import { useEffect } from "react"

const FilesAll = ({ files, setFiles, isWhat, uploadFiles, setUploadFiles, accept = ".png,image/*" }) => {
  const [imageSrc, setImageSrc] = useState(undefined)
  const updateFiles = (incommingFiles) => {
    // console.log("incomming files", incommingFiles)
    setFiles(incommingFiles)
  }
  const onDelete = (id) => {
    if (isWhat === "update") {
      setUploadFiles((files) => files.filter((x) => x.id !== id))
    } else {
      setUploadFiles((files) => files.filter((x) => x.id !== id))
      setFiles(files.filter((x) => x.id !== id))
    }
    console.log(id)
  }
  const handleSee = (imageSource) => {
    setImageSrc(imageSource)
  }
  const handleClean = (files) => {
    // console.log("list cleaned", files)
  }

  const onUploadFinish = (params) => {
    console.log(params)
    setUploadFiles((uploadfiles) => {
      const newUploadedFiles = params.map((uploadedFiles) => {
        const newUploadedFiles = {}
        newUploadedFiles.id = uploadedFiles.id
        newUploadedFiles.filename = uploadedFiles?.serverResponse?.payload?.filename ?? ""
        return newUploadedFiles
      })

      if (!uploadfiles) {
        return newUploadedFiles
      }

      return [...uploadfiles, ...newUploadedFiles]
    })
  }

  useEffect(() => {
    console.log(uploadFiles)
  }, [uploadFiles])

  return (
    <div className="w-full">
      {isWhat === "insert" && (
        <Dropzone
          // style={{ minWidth: "550px" }}
          onChange={updateFiles}
          minHeight="195px"
          onClean={handleClean}
          value={files}
          maxFileSize={104860000}
          label="Drag'n drop Documents Here"
          // accept={accept}
          // accept="*"
          url={`${apiUrl}/uploadzone`}
          uploadOnDrop
          disableScroll
          onUploadFinish={onUploadFinish}
          // header={false}
          view="list"
        >
          {files?.length > 0 &&
            files?.map((file) => (
              <FileItem {...file} key={file.id} onDelete={onDelete} onSee={handleSee} resultOnTooltip preview info hd />
            ))}
        </Dropzone>
      )}

      {isWhat === "update" && (
        <FileItemContainer>
          {uploadFiles &&
            uploadFiles.length > 0 &&
            uploadFiles.map((file, key) => (
              <FileItem
                {...makeSyntheticFiles(`${apiUrl}/public/${file.filename}`)}
                key={file.id}
                onDelete={onDelete}
                onSee={handleSee}
                resultOnTooltip
                preview
                info
                hd
              />
            ))}
        </FileItemContainer>
      )}

      <FullScreenPreview imgSource={imageSrc} openImage={imageSrc} onClose={(e) => handleSee(undefined)} />
    </div>
  )
}

export default FilesAll

const makeSyntheticFiles = (fileUrl) => {
  // get filename from url
  const filename = fileUrl.split("/").pop()

  const fileFromWebUrl = createSyntheticFile(filename, 290000000, "image/jpeg")
  const validateFileFromWebUrl = makeSynthticFileValidate(fileFromWebUrl, true, "success")
  validateFileFromWebUrl.imageUrl = fileUrl

  //set the state
  return validateFileFromWebUrl
}
