import React from "react"
import SelectMap from "../../inputs/SelectMap"
import Map from "../../map/Map"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import { useStoreMap, useStoreServices } from "../../../zustand/store"
import { fetchAdress } from "../../../library/functions"
import { apiUrl, config } from "../../../library/constant"
import axios from "axios"
import { toast } from "react-toastify"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const ChangeLocation = ({ setIsVisible }) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const watcher = watch()
  const selectedService = useStoreServices((s) => s.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const setSelectedAddress = useStoreMap((s) => s.setSelectedAddress)
  const setAddressLoading = useStoreMap((s) => s.setAddressLoading)
  useEffect(() => {
    if (hoverCoordinates) {
      const fetsss = async () => {
        setAddressLoading(true)
        const data = await fetchAdress(`${hoverCoordinates.lng},${hoverCoordinates.lat}`, {
          lat: hoverCoordinates.lat,
          lng: hoverCoordinates.lng,
        })
        if (data?.features.length !== 0) {
          console.log(data)
          const latlng = {
            lat: data.features[0].center[1],
            lng: data.features[0].center[0],
          }
          setValue("collection_location", data.features[0].place_name_en)
          setValue("collection_coordinates", JSON.stringify(latlng))
        }
        setAddressLoading(false)
      }
      fetsss()
    }
  }, [hoverCoordinates, setSelectedAddress])

  const handleSubmit = useCbOnce(async () => {
    if (!watcher.collection_location) {
      toast.warn("Change the location!")
    }
    try {
      const newData = {
        ...watcher,
        id: selectedService.id,
      }
      const { data, status } = await axios.post(apiUrl + "/services/change_location", newData, config())
      if (status === 200) {
        setIsVisible(false)
        setSelectedService(data)
        updateService(data)
        toast.success("Location Successfully Changed")
      }
    } catch (error) {
      toast.error("There was something wrong with the data submitted.")
    }
  })

  return (
    <div>
      <div className="grid grid-flow-row grid-cols-1 gap-4">
        <SelectMap
          register={register}
          name="collection_location"
          nameCoordinates="collection_coordinates"
          label="Collection Location"
          placeholder="Select Collection Location"
          required={true}
          error={errors}
          message="Collection Location Required"
          setValues={setValue}
          watcher={watcher}
        />
      </div>

      <div className="w-full overflow-hidden shadow-md min-w-[100px] h-96 rounded-xl">
        <Map x="search" />
      </div>

      <div className="btn btn-warning bg-orange-600 text-white font-bold w-full mt-4" onClick={handleSubmit}>
        Submit
      </div>
    </div>
  )
}

export default ChangeLocation
