import React from "react"
import { useMemo } from "react"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts"
// const data = [
//   { name: "New", value: 400 },
//   { name: "Approval", value: 300 },
//   { name: "Delivery", value: 300 },
//   { name: "Closed", value: 200 },
// ]

const COLORS = ["#4AAEAE", "#F06400", "#545656", "#309B26"]

const PieChartComponent = ({ data }) => {
  const dataMemo = useMemo(() => {
    return [
      { name: "New", value: data.new },
      { name: "Progress", value: data.progress },
      { name: "Completed", value: data.completed },
      { name: "Todo", value: data.all },
    ]
  }, [data])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie data={dataMemo} innerRadius={50} outerRadius={80} fill="#8884d8" dataKey="value">
          {dataMemo.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartComponent
