import dayjs from "dayjs"
import React from "react"
import "./input.css"

const Input = ({
  register,
  name,
  condition = {},
  type = "text",
  placeholder,
  error,
  message = "No Error Message",
  label = "No Label",
  className,
  disabled = false,
  readOnly = false,
  capitalize = true,
}) => {
  const handleInputChange = (event) => {
    if (capitalize) {
      event.target.value = event.target.value.toUpperCase()
    }
  }
  return (
    <div className={"w-full form-control relative " + className}>
      <label className="label">
        <span className="label-text">{label}</span>
        {/* <span className="label-text-alt">Alt label</span> */}
      </label>
      <input
        {...register(name, condition)}
        type={type}
        placeholder={placeholder}
        className="w-full input input-bordered"
        disabled={disabled}
        readOnly={readOnly}
        // onChange={handleInputChange}
        // min={type === "date" && dayjs(new Date().toISOString()).format("YYYY-MM-DD")}
      />
      <label className="pt-0 label">
        <span className="label-text-alt"></span>
        {error[name] && <span className="italic text-red-500 label-text-alt">{message}</span>}
      </label>
    </div>
  )
}

export default Input
