import React from "react"
import { useMemo } from "react"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useStore, useStoreSchedule, useStoreServices } from "../../zustand/store"

import * as dayjs from "dayjs"
import { toast } from "react-toastify"
import { useState } from "react"
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

const DeliverWarning = () => {
  const { schedules } = useStoreSchedule()
  const userType = useStore((state) => state.userType)
  const workshop = useStore((state) => state.workshop)
  const loggedUser = useStore((state) => state.loggedUser)
  const [selected, setSelected] = useState(0)
  const services = useStoreServices((s) => s.services)

  const filteredSchedules = useMemo(() => {
    const removed10to11statuses = schedules.filter((item) => {
      if (userType === 4) {
        if (item?.service_data?.status_sub_types <= 9 && item.assignedTo === loggedUser?.id) {
          return item
        }
      } else {
        if (item?.service_data?.status_sub_types <= 9) {
          return item
        }
      }
    })

    const newSchedules = []

    for (let index = 0; index < removed10to11statuses.length; index++) {
      const schedule = removed10to11statuses[index]
      const sched_end_date = new Date(schedule.end)
      const now = new Date()
      if (sched_end_date <= now) {
        if (
          sched_end_date.getFullYear() === now.getFullYear() &&
          sched_end_date.getMonth() === now.getMonth() &&
          sched_end_date.getDate() === now.getDate()
        ) {
          console.log(schedule)

          const tomorrow = new Date(now)
          tomorrow.setDate(now.getDate() + 1)

          const schedule1 = schedule
          const schedule1_car = schedule1.replacement_car

          for (let index1 = 0; index1 < removed10to11statuses.length; index1++) {
            const schedule2 = removed10to11statuses[index1]
            const sched_start_date2 = new Date(schedule2.start)

            if (
              sched_start_date2.getFullYear() === tomorrow.getFullYear() &&
              sched_start_date2.getMonth() === tomorrow.getMonth() &&
              sched_start_date2.getDate() === tomorrow.getDate() &&
              schedule1_car === schedule2.replacement_car
            ) {
              newSchedules.push({ date: new Date(schedule2.end), data: [schedule1, schedule2] })
            }
          }
        } else {
          newSchedules.push({ date: new Date(schedule.end), data: [schedule] })
        }
      } else {
        // newSchedules.push([schedule])
      }
    }
    const schedSorted = newSchedules.sort((a, b) => b.date - a.date)
    return schedSorted
  }, [])

  const filteredSchedulesSR = useMemo(() => {
    const xServices = services
    const currentTime = new Date()
    const filteredArrayNewRequest = xServices.filter((obj) => {
      if (userType === 4) {
        if (obj.assignedTo === loggedUser?.id && obj.workshop === workshop) {
          const objDate = new Date(obj.updated_date)
          const timeDifference = currentTime.getTime() - objDate.getTime()
          const minutesDifference = Math.floor(timeDifference / (1000 * 60))
          if (minutesDifference > 30 && obj.status_types === 1) {
            return obj
          }
        }
      } else {
        const objDate = new Date(obj.updated_date)
        const timeDifference = currentTime.getTime() - objDate.getTime()
        const minutesDifference = Math.floor(timeDifference / (1000 * 60))
        if (minutesDifference > 30 && (obj.status_types === 1 || obj.status_sub_types === 10)) {
          return obj
        }
      }
    })
    return filteredArrayNewRequest
  }, [])

  return (
    <div className="flex flex-col gap-4 mt-4">
      <div className="tabs">
        <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => setSelected(0)}>
          Schedule Warning
          <div className="badge badge-warning  bg-red-600 ml-2 font-bold text-white">{filteredSchedules.length}</div>
        </div>
        <div className={`tab tab-lifted ${selected === 1 ? "tab-active" : ""}`} onClick={() => setSelected(1)}>
          Late SR
          <div className="badge badge-warning  bg-red-600 ml-2 font-bold text-white">{filteredSchedulesSR.length}</div>
        </div>
      </div>

      {selected === 0 ? (
        <>
          {filteredSchedules.map((item, key) => {
            const tomorrow = new Date(item.date)
            tomorrow.setDate(item.date.getDate() + 1)

            if (userType === 4 || userType === 3) {
              if (item.data[0]?.service_data?.workshop !== workshop) {
                return <></>
              }
            }

            if (item.data.length === 1) {
              return (
                <div>
                  <Card item={item.data[0].service_data} date={item.date} />
                </div>
              )
            } else {
              return (
                <div className="flex gap-4">
                  <Card item={item.data[0].service_data} date={item.date} />
                  <div className="flex items-center">
                    <i className="text-2xl fa-solid fa-chevron-right"></i>
                  </div>
                  <Card item={item.data[1].service_data} date={tomorrow} isDelivery={false} />
                </div>
              )
            }
          })}
        </>
      ) : (
        <>
          {services && filteredSchedulesSR.length !== 0
            ? filteredSchedulesSR.map((item, key) => {
                return <XXCard item={item} key={key} />
              })
            : "No Tickets Found"}
        </>
      )}
    </div>
  )
}

export default DeliverWarning

const Card = ({ item, date, isDelivery = true }) => {
  let [, setSearchParams] = useSearchParams()
  const userType = useStore((state) => state.userType)
  const loggedUser = useStore((state) => state.loggedUser)
  const viewTicket = (req_no) => {
    if (userType === 4 && item?.assignedTo !== loggedUser?.id) {
      toast.warning("You dont have Access to this Ticket")
    } else {
      console.log(req_no)
      setSearchParams({ service_number: req_no })
    }
  }
  const client_data = item?.client_api_data ? JSON.parse(item?.client_api_data) : {}
  return (
    <div
      className="flex flex-col justify-between w-full py-4 rounded-lg shadow-lg cursor-pointer hover:bg-gray-300/50"
      onClick={() => viewTicket(item.request_no)}
    >
      <div className="ml-4 ">
        <div className="flex justify-between pr-4">
          <div className="text-lg">{item.request_no}</div>
          <div className="text-sm">{dayjs(item.added_date).fromNow("s")} ago</div>
        </div>

        <div className="text-sm">
          {client_data?.PLATE_NO ? client_data?.PLATE_NO + " - " : ""}
          {client_data?.MAKE ? client_data?.MAKE : ""}
        </div>
        <div className="text-sm">
          {isDelivery ? "Delivery Date: " : "Collection Date: "}
          {dayjs(date).format("MM/DD/YYYY")}
          {/* {client_data?.MAKE ? client_data?.MAKE + " - " : ""}
          {dayjs(item.added_date).fromNow("s")} ago */}
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex gap-4 ml-4">
          <div className="flex mt-2 text-xs text-[#F06400]">
            <div className="p-2 bg-green-500 rounded-full"></div>
            <div className="ml-2">{item?.status_types_data?.name}</div>
          </div>
          <div className="flex mt-2 text-xs text-[#F06400]">
            <div className="p-2 bg-[#F06400] rounded-full"></div>
            <div className="ml-2">{item?.status_sub_types_data?.name}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const XXCard = ({ item }) => {
  const userType = useStore((state) => state.userType)
  const loggedUser = useStore((state) => state.loggedUser)
  let [searchParams, setSearchParams] = useSearchParams()
  const viewTicket = (req_no) => {
    if (userType === 4 && item?.assignedTo !== loggedUser?.id) {
      toast.warning("You dont have Access to this Ticket")
    } else {
      console.log(req_no)
      setSearchParams({ service_number: req_no })
    }
  }
  const client_data = item?.client_api_data ? JSON.parse(item?.client_api_data) : {}
  return (
    <div
      className="flex justify-between py-4 rounded-lg shadow-lg cursor-pointer transition-all ease-in hover:translate-x-3"
      onClick={() => viewTicket(item.request_no)}
    >
      <div className="ml-4 ">
        <div className="text-lg">{item.request_no}</div>
        <div className="text-sm">
          {client_data?.PLATE_NO ? client_data?.PLATE_NO + " - " : ""}
          {client_data?.MAKE ? client_data?.MAKE + " - " : ""}
          {client_data?.MODEL ? client_data?.MODEL + " - " : ""}
          {dayjs(item.added_date).fromNow("s")} ago
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-4">
          {/* <div className="flex text-xs text-[#F06400]">
            <div className="p-2 bg-green-500 rounded-full"></div>
            <div className="ml-2">{item?.status_types_data?.name}</div>
          </div> */}
          <div className="flex mt-2 text-xs text-[#F06400]">
            {/* <div className="p-2 bg-[#F06400] rounded-full"></div> */}
            <div className="ml-2">{item?.status_sub_types_data?.name}</div>
          </div>
        </div>
        <div>
          <i className="mx-4 fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
  )
}
