import React, { useRef, useState } from "react"
import { useEffect } from "react"
import axios from "axios";
import { Marker, useMap, useMapEvent } from "react-leaflet"
import { useStoreMap } from "../../zustand/store"
import L from "leaflet"
import EditablePopup from "react-leaflet-editable-popup"

const Picker = () => {
  //   const map = useMap()
  const hoverCoordinates = useStoreMap((s) => s.hoverCoordinates)
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)
  const selectedAddress = useStoreMap((s) => s.selectedAddress)
  const { setIsFromMe } = useStoreMap()

  let popupRef = useRef()

  const map = useMapEvent("click", (e) => {
    setHoverCoordinates(e.latlng)
    setIsFromMe(false)
  })

  useEffect(() => {
    if (hoverCoordinates) {
      map.flyTo(hoverCoordinates)
    }
  })

  return hoverCoordinates ? (
    <Marker
      position={hoverCoordinates}
      icon={greenIcon}
      eventHandlers={{
        click: (e) => {
          console.log(e)
        },
      }}
    >
      {/* <Popup open>{selectedAddress && selectedAddress.label}</Popup> */}
      {/* {selectedAddress && <EditablePopup open>{selectedAddress?.label}</EditablePopup>} */}
    </Marker>
  ) : null
}

export const greenIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export default Picker
