import "./App.css"
import DashboardContainer from "./components/DashboardContainer"

import { AnimatePresence } from "framer-motion"
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router"

import Dashboard from "./routes/Dashboard"
import ServiceRequest from "./routes/ServiceRequest"
import Workshops from "./routes/Workshops"
import Drivers from "./routes/Drivers"
import Schedules from "./routes/replacement_cars/Schedules"
import ViewCars from "./routes/replacement_cars/ViewCars"

import "react-toastify/dist/ReactToastify.css"
import Login from "./routes/Login"
import { useEffect } from "react"
import { apiUrl, config } from "./library/constant"
import axios from "axios"
import { useStore, useStoreImageViewer, useStoreServices, useStoreSettings, useStoreTypes } from "./zustand/store"
import { useState } from "react"
import { io } from "socket.io-client"
import UserManagement from "./routes/UserManagement"
import { QueryClient } from "@tanstack/react-query"
import { QueryClientProvider } from "@tanstack/react-query"
import Settings from "./routes/Settings"
import AuditLogs from "./routes/AuditLogs"
import { ToastContainer } from "react-toastify"
import { useSearchParams } from "react-router-dom"
import View from "./components/service_request/View"
import Tesing from "./routes/Tesing"
import ServicesReports from "./routes/reports/ServicesReports"
import RCPDFReports from "./routes/reports/RCPDFReports"
import Loading from "./components/Loading"
import LoadingAll from "./components/LoadingAll"
import Testing2 from "./routes/Testing2"
import VehicleReportView from "./routes/VehicleReportView"
import AuthVehicleReportView from "./routes/AuthVehicleReportView"
import ImageViewer from "react-simple-image-viewer"

const queryClient = new QueryClient()

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const token = useStore((state) => state.token)
  const setToken = useStore((state) => state.setToken)
  const setLoggedUser = useStore((state) => state.setLoggedUser)
  const setSocket = useStore((state) => state.setSocket)
  const userType = useStoreTypes((s) => s.userType)

  const services = useStoreServices((s) => s.services)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)

  const [openView, setOpenView] = useState(false)
  const [isWhat, setIsWhat] = useState("")

  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const settings = useStoreSettings((s) => s.settings)
  let [searchParams, setSearchParams] = useSearchParams()

  const [isFinishFetching, setIsFinishFetching] = useState(false)

  const { pictures, setViewerVisible, viewerVisible } = useStoreImageViewer()

  useEffect(() => {
    const fetchUser = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios
        .get(apiUrl + "/user", config)
        .then((res) => {
          console.log("Fetched Data", res)
          setIsAuthenticated(true)
          setLoggedUser(res.data.user_data)
          localStorage.setItem("user", JSON.stringify(res.data.user_data))
          setIsFinishFetching(true)
        })
        .catch((err) => {
          console.log(err)
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          setIsAuthenticated(false)
          setIsFinishFetching(true)
          console.log("Error Data", err)
        })
    }
    if (location.pathname !== "/login" && token) {
      fetchUser()
    }
    if (!token && localStorage.getItem("token") !== null) {
      const xTokens = localStorage.getItem("token")
      setToken(xTokens)
      setLoggedUser(JSON.parse(localStorage.getItem("user")))
    }

    if (!localStorage.getItem("token")) {
      const xTokens = localStorage.getItem("token")
      console.log(xTokens)
      setIsAuthenticated(false)
    }
  }, [token, userType])

  // useEffect(() => {
  //   if (!token && isFinishFetching) {
  //     setIsAuthenticated(false)
  //   }
  // }, [isFinishFetching, token]) xxxx

  // useEffect(() => {
  //   const socket = io(apiUrl, { path: "/ws/socket.io" })
  //   setSocket(socket)
  //   socket.on("connect", () => {
  //     // console.log("Connected", socket.id)
  //   })
  //   socket.on("asd", (data) => {
  //     // console.log("asd", data)
  //   })

  //   return () => {
  //     socket.off("connect")
  //     socket.off("asd")
  //     socket.disconnect()
  //     setSocket(null)
  //   }
  // }, [])

  useEffect(() => {
    const service_number = searchParams.get("service_number")
    if (settings.length !== 0) {
      if (services.length !== 0 && service_number) {
        for (let index = 0; index < services.length; index++) {
          const element = services[index]
          if (element.request_no === service_number) {
            setSelectedService(element)
            setOpenView(true)
            setIsWhat("View")
            setSearchParams({ service_number: "" })
          }
        }
      }
    }
  }, [searchParams, services, settings])

  const closeImageViewer = () => {
    setViewerVisible(false)
  }

  if (isAuthenticated === null) {
    return <div></div>
  }

  return (
    <div className="antialiased font-Rampart">
      <ToastContainer
        style={{
          zIndex: 100000000,
        }}
      />
      {!isAuthenticated ? (
        <Routes key={location.pathname} location={location}>
          <Route path="/report" element={<VehicleReportView />} />
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        // <Routes key={location.pathname} location={location}>
        //   <Route path="/login" element={<Login />} />
        // </Routes>
        <QueryClientProvider client={queryClient}>
          <div>
            <View isWhat={isWhat} setIsWhat={setIsWhat} openView={openView} setOpenView={setOpenView} />
          </div>
          {viewerVisible && (
            <ImageViewer
              src={pictures}
              // currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}

          <DashboardContainer>
            <AnimatePresence exitBeforeEnter>
              <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/service_request" element={<ServiceRequest />} />
                <Route path="/workshops" element={<Workshops />} />
                <Route path="/drivers" element={<Drivers />} />
                <Route path="/user_management" element={<UserManagement />} />
                <Route path="/replacement_cars/view" element={<ViewCars />} />
                <Route path="/replacement_cars/schedules" element={<Schedules />} />
                <Route path="/audit_logs" element={<AuditLogs />} />
                <Route path="/reports/services" element={<ServicesReports />} />
                <Route path="/reports/services/rcpdf" element={<RCPDFReports />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/testing" element={<Tesing />} />
                <Route path="/report" element={<VehicleReportView />} />
                <Route path="/report_view" element={<AuthVehicleReportView />} />
              </Routes>
            </AnimatePresence>
          </DashboardContainer>
        </QueryClientProvider>
      )}
    </div>
  )
}

export default App
