import React from "react"
import AlertsComponent from "./AlertsComponent"
import MenuComponent from "./MenuComponent"

const TopNav = () => {
  return (
    <div className="flex items-center justify-end w-full h-20 px-6 border-b border-gray-100 shadow">
      <div className="flex items-center mr-3 space-x-5 text-2xl text-[#F06400]">
        <AlertsComponent />
        <MenuComponent />
      </div>
    </div>
  )
}

export default TopNav
