import React, { useCallback, useEffect, useMemo, useState } from "react"
import Car from "./Car"
import ModalUni from "../modals/ModalUni"
import { useForm } from "react-hook-form"
import Input from "../inputs/Input"
import TextArea from "../inputs/TextArea"
import Files from "../inputs/Files"
import { DetailField } from "./FormView"
import { apiUrl } from "../../library/constant"
import ImageViewer from "react-simple-image-viewer"

const CarIntent = ({
  title = "",
  title2 = "",
  name = "Blank",
  id = "Blank",
  xSetValue = null,
  className = "",
  data = null,
  disabled = false,
  isSetValue = true,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const watcher = watch()
  const [isVisible, setIsVisible] = React.useState(false)
  const [carParts, setCarParts] = React.useState({})

  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])

  const onPartsClick = (id, name) => {
    // check if car part already exists in the list
    if (!id) {
      return null
    }
    const carPart = carParts[id]
    if (carPart) {
      console.log(carPart)
      setValue("car_part_description", carPart.car_part_description)
      let cpics = []
      if (carPart?.car_pics) {
        for (let index = 0; index < carPart?.car_pics?.length; index++) {
          const element = carPart?.car_pics[index]
          console.log(element)
          cpics.push({
            id: index,
            filename: element,
          })
        }
      }
      setUploadFiles(cpics)
      setFiles([])
    } else {
      setUploadFiles([])
      setFiles([])
      setValue("car_part_description", "")
    }
    setIsVisible(true)
    setValue("car_part_id", id)
    setValue("car_part_name", name)
  }

  const btnCancel = () => {
    setIsVisible(false)
  }

  const onSubmit = () => {
    const id = watcher.car_part_id
    const name = watcher.car_part_name
    const description = watcher.car_part_description
    const uploadedPictures = uploadFiles ? uploadFiles?.map((item) => item.filename) : []
    console.log(uploadedPictures)

    // check if car part already exists in the list
    const newCarParts = { ...carParts }
    const carPart = newCarParts[id]
    if (carPart) {
      // update car part
      carPart.car_part_description = description
      carPart.car_pics = uploadedPictures
    } else {
      // add new car part
      newCarParts[id] = {
        car_part_id: id,
        car_part_name: name,
        car_part_description: description,
        car_pics: uploadedPictures,
      }
    }
    console.log(newCarParts)
    setCarParts(newCarParts)
    setIsVisible(false)
  }

  useEffect(() => {
    // console.log("carParts", carParts)
    // console.log(id, carParts)
    if (isSetValue) {
      if (!!xSetValue) {
        xSetValue(id, JSON.stringify(carParts))
      }
    } else {
      xSetValue(JSON.stringify(carParts))
    }
  }, [carParts, id, xSetValue])

  useEffect(() => {
    if (data) {
      const x = JSON.parse(data)
      console.log(x)
      setCarParts(x)
    }
  }, [id])

  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const images = useMemo(() => {
    const newImages = []
    for (let index = 0; index < uploadFiles.length; index++) {
      const element = uploadFiles[index]
      newImages.push(apiUrl + "/public/" + element.filename)
    }
    return newImages
  }, [uploadFiles])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const handleRemovePicture = (id) => {
    let filteredArr = uploadFiles.filter((item) => item.id !== id)
    setUploadFiles(filteredArr)
  }

  return (
    <div className={`p-3 ${className}`}>
      <ModalUni isVisible={isVisible} setIsVisible={setIsVisible} size={5} title={`Car Part: ${watcher.car_part_name}`}>
        {disabled ? (
          <div className="grid grid-flow-row gap-4 mt-4">
            <DetailField label={"Car Part ID"} value={watcher.car_part_id ?? "No Details Provided"} />
            <DetailField label={"Car Part Description"} value={watcher.car_part_description ?? "No Details Provided"} />
            {/* <div>{watcher.car_part_id}</div>
            <div>{watcher.car_part_description}</div> */}
            <div>
              <div className="text-sm">Car Part Pictures :</div>
              <div className="grid grid-flow-row grid-cols-3">
                {images?.map((item, key) => (
                  <img src={item} key={key} alt="vsrm-image" onClick={() => openImageViewer(key)} />
                ))}
                {isViewerOpen && (
                  <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Input
              register={register}
              name="car_part_id"
              label="Car Part ID"
              placeholder="Car Part ID"
              type="text"
              error={errors}
              disabled={true}
            />
            <TextArea
              register={register}
              name="car_part_description"
              label="Car Part Description"
              placeholder="Car Part Description"
              type="text"
              error={errors}
              disabled={true}
            />
            <div className="pb-4 border-b mb">
              <Files
                files={files}
                setFiles={setFiles}
                isWhat="insert"
                uploadFiles={uploadFiles}
                setUploadFiles={setUploadFiles}
              />
            </div>
            <div>
              <div>Uploaded</div>
              <div className="grid grid-flow-row grid-cols-4">
                {uploadFiles?.map((item, key) => {
                  console.log(apiUrl + "/public/", item)
                  return (
                    <div className="relative m-2">
                      <div
                        className="absolute flex items-center justify-center px-2 py-1 text-white bg-red-500 rounded-full hover:cursor-pointer -right-3 -top-3"
                        onClick={() => handleRemovePicture(item.id)}
                      >
                        <i className="fa-solid fa-xmark hover:cursor-pointer"></i>
                      </div>
                      <img
                        src={apiUrl + "/public/" + item.filename}
                        key={key}
                        alt="vsrm-image"
                        onClick={() => openImageViewer(key)}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
              <div className="px-12 btn" onClick={btnCancel}>
                Cancel
              </div>
              <div className="px-12 btn" onClick={onSubmit}>
                Save
              </div>
            </div>
          </div>
        )}
      </ModalUni>
      <div className="text-lg font-semibold">
        {title} {title2}
      </div>
      {/* <div className="flex justify-center mr-48">{name}</div> */}
      <div className="flex">
        <Car onClick={onPartsClick} width="400" height="256" carParts={carParts} />
        <div className="mt-8 ml-5">
          <CheckBox title="Reg Copy" setValue={setCarParts} value={carParts} id="reg_copy" disabled={disabled} />
          <CheckBox title="Spare Tyre" setValue={setCarParts} value={carParts} id="spare_tyre" disabled={disabled} />
          <CheckBox title="Tool Kit" setValue={setCarParts} value={carParts} id="tool_kit" disabled={disabled} />
          <CheckBox title="Radio/Tape/CD" setValue={setCarParts} value={carParts} id="radio" disabled={disabled} />
          <CheckBox title="Fuel Cap" setValue={setCarParts} value={carParts} id="fuel_cap" disabled={disabled} />
          <CheckBox title="Accessories" setValue={setCarParts} value={carParts} id="accessories" disabled={disabled} />
          <CheckBox title="4-Wheel Cap" setValue={setCarParts} value={carParts} id="wheel_cap" disabled={disabled} />
          <CheckBox title="Salik" setValue={setCarParts} value={carParts} id="salik" disabled={disabled} />
        </div>
      </div>
      <div>
        {/* prettify carParts Json  */}
        {/* <pre>{JSON.stringify(carParts, null, 2)}</pre> */}
      </div>
    </div>
  )
}

export default CarIntent

const CheckBox = ({ title = "Title", setValue, value, id, disabled = false }) => {
  const [checked, setChecked] = useState(false)

  const handleCheck = () => {
    if (!disabled) {
      setChecked(!checked)
    }
  }

  useEffect(() => {
    const carParts = { ...value }
    carParts[id] = checked
    setValue(carParts)
  }, [checked])

  useEffect(() => {
    if (value[id]) {
      setChecked(value[id])
    }
  }, [value[id]])

  return (
    <div className="flex items-center ml-3" onClick={handleCheck}>
      <div className="flex items-center justify-center w-4 h-4 rounded ring ring-orange-600">
        {checked && <i className="mt-1 text-sm fa-solid fa-check"></i>}
      </div>
      <div className="flex ml-4 whitespace-nowrap">{title}</div>
    </div>
  )
}
