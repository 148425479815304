import React, { useState } from "react"
import { serviceActions } from "../../library/variableConstants"
import { useStore, useStoreServices } from "../../zustand/store"
import ModalUni from "../modals/ModalUni"
import FormActions from "./ActionModals/FormActions"
import FormUpdate from "./FormUpdate"
import FormView from "./FormView"
import Stepper from "./status/Stepper"
import ServiceActions from "./ServiceActions"
import ServiceActionHistory from "./ServiceActionHistory"
import { useEffect } from "react"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import Tesing from "../../routes/Tesing"
import { useNavigate } from "react-router"
import AuthVehicleReportView from "../../routes/AuthVehicleReportView"

const View = ({ isWhat, setIsWhat, openView, setOpenView }) => {
  const userType = useStore((state) => state.userType)
  const privileges = useStore((state) => state.privileges)
  const selectedService = useStoreServices((state) => state.selectedService)
  const setIsWhatS = useStoreServices((state) => state.setIsWhat)
  const navigate = useNavigate()
  // const setSelectedService = useStoreServices((state) => state.setSelectedService)

  const [isVisible, setIsVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [isVisiblePrintView, setIsVisiblePrintView] = useState(false)

  const setServices = useStoreServices((s) => s.setServices)

  useEffect(() => {
    if (!openView) {
      ;(async () => {
        const services = await axios.get(apiUrl + "/services", config())
        setServices(services.data)
      })()
    }
  }, [openView])

  return (
    <div>
      <ModalUni isVisible={openView} setIsVisible={setOpenView} title="" size={13}>
        <div className="relative flex">
          <ModalUni isVisible={isVisiblePrintView} setIsVisible={setIsVisiblePrintView} title="" size={9}>
            <AuthVehicleReportView />
          </ModalUni>
          <section className="sticky left-0 flex-1 px-2 py-4 space-y-4">
            <div className="flex flex-col items-start gap-4">
              {userType !== 4 && <p className="">Settings</p>}

              {selectedService?.collection_signature ? (
                <div
                  className={`flex items-center text-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 text-gray-500 hover:bg-[#004A5D] hover:text-[#F1F4F9] group cursor-pointer`}
                  onClick={() => setIsVisiblePrintView(true)}
                >
                  <i className={"fa-solid fa-print w-5 h-5 group-hover:text-[#F1F4F9]"} />
                  <p className="truncate">Print View</p>
                </div>
              ) : (
                <></>
              )}

              {Boolean(privileges[0]) && (
                <button
                  className={`flex items-center text-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 ${
                    isWhat === "Edit" ? "bg-[#004A5D] text-[#F1F4F9]" : "text-gray-500"
                  } hover:bg-[#004A5D] hover:text-[#F1F4F9] group`}
                  onClick={() => {
                    setIsWhat("Edit")
                    setIsWhatS("update")
                  }}
                >
                  <i className={"fa-solid fa-pen-to-square w-5 h-5 group-hover:text-[#F1F4F9]"} />
                  <p className="truncate">Edit Request</p>
                </button>
              )}
              {Boolean(privileges[1]) && (
                <button
                  className={`flex items-center text-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 ${
                    isWhat === "Delete" ? "bg-[#004A5D] text-[#F1F4F9]" : "text-gray-500"
                  } hover:bg-[#004A5D] hover:text-[#F1F4F9] group`}
                  onClick={() => {
                    setIsWhat("Delete")
                  }}
                >
                  <i className={"fa-solid fa-trash-can w-5 h-5 group-hover:text-[#F1F4F9]"} />
                  <p className="truncate">Delete Request</p>
                </button>
              )}

              {/* {serviceActions
                .filter((s) => s.link === "Settings")
                .map((action, key) => {
                  if (userType === 4) {
                    return <></>
                  }
                  return selectedService?.status_types === 3 || selectedService?.status_types === 4 ? (
                    <button
                      key={key}
                      className={`flex items-center text-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 cursor-not-allowed ${
                        isWhat === action.action ? "bg-[#004A5D] text-[#F1F4F9]" : "text-gray-500"
                      } hover:bg-[#004A5D] hover:text-[#F1F4F9] group`}
                    >
                      <i className={action.icon + " w-5 h-5 group-hover:text-[#F1F4F9]"} />
                      <p className="truncate">{action.name}</p>
                    </button>
                  ) : (
                    <button
                      key={key}
                      className={`flex items-center text-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 ${
                        isWhat === action.action ? "bg-[#004A5D] text-[#F1F4F9]" : "text-gray-500"
                      } hover:bg-[#004A5D] hover:text-[#F1F4F9] group`}
                      onClick={() => {
                        setIsWhat(action.action)
                        if (action.action === "Delete") {
                          setIsDeleting(true)
                        }
                      }}
                    >
                      <i className={action.icon + " w-5 h-5 group-hover:text-[#F1F4F9]"} />
                      <p className="truncate">{action.name}</p>
                    </button>
                  )
                })} */}
            </div>
            <div className="flex flex-col items-start gap-4">
              <ServiceActions
                setIsVisible={setIsVisible}
                setIsWhat={setIsWhat}
                status={selectedService?.status_types}
                status_sub={selectedService?.status_sub_types}
                collectionNeeded={selectedService?.collection_needed}
                rc_needed={selectedService?.rc_needed}
              />
            </div>
            <div>
              {selectedService?.id && (
                <div>
                  <div>Service History</div>
                  <ServiceActionHistory id={selectedService?.id} />
                </div>
              )}
            </div>
          </section>
          <section className="w-[80%]  border-l">
            <div className="flex justify-between w-full p-2 border-b">
              <div className="gap-2 my-1 font-medium">
                <div className="text-lg">Service Request Information</div>
                <div className="flex flex-col gap-2 mt-2">
                  <div className="flex text-sm text-green-500">
                    <div>
                      <div className="p-2 bg-green-500 rounded-full"></div>
                    </div>
                    <div className="ml-1">{selectedService?.status_types_data.name}</div>
                  </div>
                  <div className="flex text-sm text-[#F06400]">
                    <div>
                      <div className="p-2 bg-[#F06400] rounded-full"></div>
                    </div>
                    <div className="ml-1">{selectedService?.status_sub_types_data.name}</div>
                  </div>
                </div>
              </div>
              <Stepper status={selectedService?.status_types} sub_status={selectedService?.status_sub_types} />
              <button onClick={() => setOpenView(false)} className="mx-4">
                <i className="text-xl fa-regular fa-xmark"></i>
              </button>
            </div>
            {isWhat === "Edit" ? (
              <FormUpdate setIsWhat={setIsWhat} setOpenView={setOpenView} service={selectedService} />
            ) : (
              <FormView service={selectedService} />
              // <Tesing service={selectedService} />
            )}
          </section>
        </div>
      </ModalUni>
      {/* 
      <ModalUni isVisible={isVisible} setIsVisible={setIsVisible} title={isWhat} size={8}>
        <FormActions isWhat={isWhat} setIsVisible={setIsVisible} />
      </ModalUni> 
      */}
      <ModalUni isVisible={isDeleting} setIsVisible={setIsDeleting} title={isWhat} size={6}>
        <div className="flex flex-col gap-6 my-4 margin-auto">
          <div className="">
            <p>
              You are about to delete service request{" "}
              <span className="font-medium text-[#F06400]">{selectedService?.request_no}</span>.
            </p>
            <p className="text-gray-500">
              This action is <span className="font-medium text-red-500">irreversible</span>, please review your action
              before confirming.
            </p>
          </div>

          <div className="flex justify-end w-full gap-2">
            <button
              className="px-4 py-1 text-red-600 border border-red-600 rounded-md"
              onClick={() => setIsDeleting(false)}
            >
              Cancel
            </button>
            <button className="px-4 py-1 text-white bg-red-500 rounded-md hover:bg-red-600">Confirm</button>
          </div>
        </div>
        {/* <pre>{JSON.stringify(selectedService, null, 2)}</pre> */}
      </ModalUni>
    </div>
  )
}

export default View
