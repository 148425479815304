import { Transition } from "@headlessui/react"
import { Dialog } from "@headlessui/react"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import ModalUni from "../modals/ModalUni"
import Form from "./Form"

const FormModal = ({ modal, setModal, selectedUser }) => {
  const setVisible = (visible) => {
    setModal({
      type: modal.type,
      visible: visible,
    })
  }
  return (
    <ModalUni isVisible={modal.visible} setIsVisible={setVisible} title="Add User" size={9}>
      <Form selectedUser={selectedUser} type={modal.type} setVisible={setVisible} />
    </ModalUni>
  )
}

export default FormModal
