import axios from "axios"
import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { apiUrl, config } from "../../library/constant"
import { useStoreAlerts, useStoreReplacementCars, useStore } from "../../zustand/store"
import { dayjsx } from "../../library/functions"
import { toast } from "react-toastify"

const Table = ({ searchText }) => {
  const userType = useStore((state) => state.userType)
  const workshop = useStore((state) => state.workshop)
  const resReplacement_cars = useStoreReplacementCars((s) => s.replacementCars)
  const [replacementCars, setReplacementCars] = useState([])

  const alerts = useStoreAlerts((state) => state.alerts)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const bodyRows = 10

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  useEffect(() => {
    // console.log("workshop", workshop);
    const fetchPagination = async () => {
      if (userType === 3 || userType === 4) {
        const res = await axios.get(
          apiUrl + "/replacement_cars/count?search=" + searchText + "&workshop=" + workshop,
          config()
        )
        let pages = parseInt((res.data - 1) / bodyRows + 1)
        setCount(res.data)
        setTotalPages(pages)
      } else {
        const res = await axios.get(apiUrl + "/replacement_cars/count?search=" + searchText, config())
        let pages = parseInt((res.data - 1) / bodyRows + 1)
        setCount(res.data)
        setTotalPages(pages)
      }
    }
    fetchPagination()
  }, [alerts, resReplacement_cars, searchText, userType, workshop])

  useEffect(() => {
    const fetchData = async () => {
      if (userType === 3 || userType === 4) {
        const res = await axios.get(
          apiUrl +
            `/replacement_cars/workshop/${workshop}?page=` +
            currentPage +
            "&count=" +
            bodyRows +
            "&search=" +
            searchText,
          config()
        )
        setReplacementCars(res.data)
      } else {
        const res = await axios.get(
          apiUrl + "/replacement_cars?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
          config()
        )
        setReplacementCars(res.data)
      }
    }
    fetchData()
  }, [currentPage, alerts, resReplacement_cars, searchText, userType, workshop])

  return (
    <div className="w-full mt-6">
      <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg ">
        <table className="w-full overflow-hidden bg-white rounded-t-lg table-auto whitespace-nowrap">
          <thead className="sticky top-0">
            <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
              <th className="px-3 py-4 text-left">RC NUMBER</th>
              <th className="px-3 py-4 text-left">PLATE NO..</th>
              <th className="px-3 py-4 text-left">MAKE</th>
              <th className="px-3 py-4 text-left">MODEL</th>
              <th className="px-3 py-4 text-left">YEAR</th>
              <th className="px-3 py-4 text-left">COLOR</th>
              <th className="px-3 py-4 text-left">WORKSHOP</th>
              <th className="px-3 py-4 text-left">CATEGORY</th>
              <th className="px-3 py-4 text-left">ADDED</th>
            </tr>
          </thead>
          <tbody>
            {replacementCars.map((item, key) => {
              return (
                <TableRow
                  key={key}
                  item={item}
                />
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
        <div>
          Showing {replacementCars?.length ?? 0} of {count} total record
        </div>
        <div className="flex items-center mt-2 space-x-4">
          <div onClick={prevPage} className="cursor-pointer">
            Previous
          </div>
          <div>
            {currentPage}/{totalPages}
          </div>
          <div onClick={nextPage} className="cursor-pointer">
            Next
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table

const TableRow = ({ item }) => {
  return (
    <tr>
      <td className="py-4 pl-3">
        <div className="flex items-center">
          <div className={`w-3 h-3 mr-1 rounded-full ${item.status ? "bg-green-500" : "bg-red-500"}`}></div>
          <div>{item.reg_no}</div>
        </div>
      </td>
      <td className="py-4 pl-3">{item?.plate}</td>
      <td className="py-4 pl-3">{item?.brand_data?.name}</td>
      <td className="py-4 pl-3">{item?.model}</td>
      <td className="py-4 pl-3">{item?.year}</td>
      <td className="py-4 pl-3">{item?.color}</td>
      <td className="py-4 pl-3">{item?.workshop_data?.name}</td>
      <td className="py-4 pl-3">{item?.category_data?.name}</td>
      <td className="py-4 pl-3">{dayjsx(item.added_date).format("MMM D, YYYY HH:mm")}</td>
    </tr>
  )
}
