import axios from "axios"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices } from "../../../zustand/store"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import { useCbOnce } from "../../../library/hooks/useCbOne"
import dayjs from "dayjs"
import { capitalizeFirstLetter } from "../../../library/functions"

const Approved = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const watcher = watch()

  const selectedService = useStoreServices((s) => s.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const onSubmit = useCbOnce(async (approved) => {
    const newDateTime = new Date(watcher.maintenance_po_date)
    const newData = {
      id: selectedService.id,
      maintenance_po: approved,
      maintenance_po_date: newDateTime,
      maintenance_po_desc: watcher.maintenance_po_desc,
    }
    try {
      const { data, status } = await axios.post(apiUrl + "/services/approved", newData, config())
      if (status === 200) {
        setIsVisible(false)
        setSelectedService(data)
        updateService(data)
        toast.success("Successfully " + capitalizeFirstLetter(approved))
      }
    } catch (error) {
      toast.error("There was something wrong with the data submitted.")
    }
  })

  useEffect(() => {
    const newDateTime = new Date()
    setValue("maintenance_po_date", dayjs(newDateTime).format("YYYY-MM-DD"))
  }, [setValue])

  return (
    <div className="w-full border-t">
      <form className="space-y-4">
        {/* <Input
          register={register}
          name="maintenance_po"
          label="Maintenance PO Number"
          placeholder={"Maintenance PO Number"}
          type="text"
          condition={{ required: true }}
          error={errors}
        /> */}
        <Input
          register={register}
          name="maintenance_po_date"
          label="Date"
          placeholder={"Date"}
          type="date"
          condition={{ required: false }}
          error={errors}
          disabled
        />

        <TextArea
          register={register}
          name="maintenance_po_desc"
          label="Description"
          placeholder={"Description"}
          type="text"
          error={errors}
          className="min-h-[140px]"
        />

        <div className="flex items-center justify-end w-full gap-2">
          <div
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => {
              setIsVisible(false)
            }}
          >
            Cancel
          </div>
          {/* onSubmit(data) */}
          <div
            onClick={() => onSubmit("disapproved")}
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600"
          >
            Disapprove
          </div>
          <div
            onClick={() => onSubmit("approved")}
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600"
          >
            Approve
          </div>
        </div>
      </form>
    </div>
  )
}

export default Approved
