import React, { useState, useEffect, useMemo } from "react"
import axios from "axios"
import { variants, apiUrl, config } from "../../../library/constant"
import { motion } from "framer-motion"
import Select from "../../inputs/Select"
import ReplacementSchedules from "../ReplacementSchedules"
import RgReplacementSchedules from "../RgReplacementSchedules";
import Input from "../../inputs/Input"
import { useStoreTypes, useStoreWorkshops, useStoreReplacementCars } from "../../../zustand/store"
import CarItem from "../CarItem"

const ReplacementCarDetails = ({
  hasDrop = true,
  register,
  errors,
  watcher,
  setValue,
  filteredReplacementCars,
  selectedRC,
}) => {

  const [isReplacementDetailsVisible, setIsReplacementDetailsVisible] = useState(false)
  const carsCategoryTypes = useStoreTypes((state) => state.carsCategoryTypes)
  const workshops = useStoreWorkshops((s) => s.workshops)
  const carsBrandTypes = useStoreTypes((state) => state.carsBrandTypes)
  const replacementCars = useStoreReplacementCars((s) => s.replacementCars)
  const setSelectedVehicleCategory = useStoreReplacementCars((s) => s.setSelectedVehicleCategory)

  // console.log("replacement car length: ", replacementCars.length)

  const [serviceTypeValue, setServiceTypeValue] = useState(null)
  const [haveModel, setHaveModel] = useState(false)
  const [carsModelTypes, setCarsModelTypes] = useState([])
  const [vehicleCategory, setVehicleCategory] = useState([])

  const removeDuplicateNames = (array) => {
    const names = new Set();
    return array.filter(item => {
        if (names.has(item.name)) {
            return false;
        } else {
            names.add(item.name);
            return true;
        }
    });
  }

  const handleCategoryChange = (event, value) => {
    setValue("replacement_car_category", value)
    if (event) event.target.blur()
  }

  const handleVehicleCategoryChange = (event, value) => {
    setValue("vehicle_category", value)
    if (event) event.target.blur()
  }

  const handleWorkshopChange = (event, value) => {
    setValue("workshop", value)
    if (event) event.target.blur()
  }

  const handleMakeChange = (event, value) => {
    setValue("vmodel", "")
    let models = replacementCars.filter(item => Number(item.brand) === Number(value)).map(subItem => {
      return {
          id: subItem.model,
          name: subItem.model.split(/[\s,._-]+/)[0]
      }  
    })

    models = removeDuplicateNames(models)

    models.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    });

    setCarsModelTypes(models)
    setHaveModel(true)
    if (event) event.target.blur()
  }

  const handleModelChange = (event, value) => {
    setValue("vmodel", value)
    if (event) event.target.blur()
  }

  const parsingJSON = (json) => {
    try {
      return JSON.parse(json)
    } catch (error) {
      return []
    }
  }

  const uppercaseText = (text) => {
    // console.log("text", text)
    try {
      return text?.toUpperCase()?.trim()
    } catch (error) {
      return text
    }
  }

  const isNumeric = (value) => {
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    if (typeof value === 'string') {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }
    return false;
  }

  const removeDuplicates = (data) => {
    const uniqueIds = new Set();
    return data.filter(item => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
  }

  const stringToArray = (inputString) => {
    const delimiters = [',', '-', '_', ' ']
    // Create a regular expression from the delimiters
    const regex = new RegExp(`[${delimiters.join('\\')}]`, 'g')
    
    // Split the string using the regex
    return inputString.split(regex)
  }

  const checkServiceMileage = (serviceMileageArray, currentServiceMileage) => {
    const parseServiceMileage = parsingJSON(serviceMileageArray)
    const serviceMileage = parseServiceMileage.filter(item => item?.mileage?.trim() === currentServiceMileage.trim() && isNumeric(item?.price))
    return serviceMileage.length > 0
  }

  const getServiceMileagePrice = (serviceMileageArray) => {
    const parseServiceMileage = parsingJSON(serviceMileageArray)
    const serviceMileage = parseServiceMileage.filter(item => item?.mileage?.trim() === watcher?.service_mileage?.trim() && isNumeric(item?.price))
    return serviceMileage.length > 0 ? serviceMileage[0]?.price : 0
  }

  useEffect(() => {
    setServiceTypeValue(watcher.service_type)
    // if (watcher.brand !== "") handleMakeChange(null, watcher.brand)
    // if (watcher.vmodel !== "") handleModelChange(null, watcher.vmodel)
    
    ;(async () => {
      if (watcher?.service_type == 1) {
        const maintenanceRatesResult = await axios.get(apiUrl + `/overall_maintenance_rates`, config())
        if (maintenanceRatesResult.status === 200) {
          let vehicleCategoryValues = maintenanceRatesResult.data

          // filter by location
          if (watcher?.collection_state) {
            vehicleCategoryValues = vehicleCategoryValues.filter(item => uppercaseText(item.location) === uppercaseText(watcher.collection_state))
          }
          
          // filter by make
          if (watcher?.client_car_make) {
            vehicleCategoryValues = vehicleCategoryValues.filter(item => uppercaseText(item.make) == uppercaseText(watcher.client_car_make))
          }

          // filter by model
          if (watcher?.client_car_model) {
            vehicleCategoryValues = vehicleCategoryValues.filter(item => uppercaseText(stringToArray(item.model)[0]) === uppercaseText(stringToArray(watcher.client_car_model)[0]))
          }

          setSelectedVehicleCategory(vehicleCategoryValues)

          // filter by service mileage
          if (watcher?.service_mileage) {
            vehicleCategoryValues = vehicleCategoryValues.filter(item => checkServiceMileage(item.service_mileage, watcher.service_mileage))
          }

          vehicleCategoryValues = vehicleCategoryValues.map(item => {
            return {
              id: item.model,
              name: item.model,
              workshop: uppercaseText(item.supplier_name),
              price: getServiceMileagePrice(item.service_mileage)
            }
          })

          // sort alphabetically
          vehicleCategoryValues.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
          })

          setVehicleCategory(removeDuplicates(vehicleCategoryValues))
          if (removeDuplicates(vehicleCategoryValues).length === 1) {
            setValue("vehicle_category", removeDuplicates(vehicleCategoryValues)[0].id) 
          } else {
            setValue("vehicle_category", "")
          }
        }
      }
    })()

  }, [watcher?.service_type, watcher?.workshops, watcher?.collection_state, watcher?.client_car_make, watcher?.client_car_model, watcher?.service_mileage, filteredReplacementCars])

  return (
    <div>
      {hasDrop && (
        <div className="flex flex-col mt-6">
          <div
            className="flex justify-between w-full text-xl font-semibold cursor-pointer"
            onClick={() => setIsReplacementDetailsVisible(!isReplacementDetailsVisible)}
          >
            <div className="flex items-center gap-4">
              <div className="text-[#f06400]">Replacement Car Details</div>
            </div>
            <i className="mx-4 fa-solid fa-chevron-down"></i>
          </div>
          <div className="w-full h-2 border-b"></div>
        </div>
      )}

      <motion.div
        animate={hasDrop ? (isReplacementDetailsVisible ? "open" : "closed") : "open"}
        variants={variants}
        className="h-0 overflow-hidden"
      >
        {serviceTypeValue == 1 && 
          <div className="grid grid-flow-row grid-cols-2 gap-x-4 mt-3">
            <Select
              register={register}
              name="vehicle_category"
              label="Select vehicle model that match leased vehicle and sort maintenance rates"
              placeholder="Select Vehicle Model"
              type="text"
              items={vehicleCategory}
              selectKey="name"
              condition={{ required: vehicleCategory.length > 0 ? true : false }}
              message="Vehicle Model Required"
              error={errors}
              onChange={(e) => handleVehicleCategoryChange(e, e.target.value)}
            />
            <span></span>
            {vehicleCategory.length === 0 && 
              <span className="text-xs font-bold text-[#FF0000]">No available rates for {watcher?.client_car_make} {watcher?.client_car_model} {watcher?.service_mileage} service in {watcher?.collection_state}</span>
            }
          </div>
        }
        <div className={`${serviceTypeValue == 1 ? 'mt-3' : 'mt-1'} font-bold`}>Replacement Car Filters</div>
        <div className="grid grid-flow-row grid-cols-2 gap-x-4">
          <Select
            register={register}
            name="replacement_car_category"
            label="Replacement Car Category"
            placeholder="Replacement Car Category"
            type="text"
            // condition={{ required: true }}
            error={errors}
            message="Replacement Car Category Required"
            // className="col-span-2"
            items={carsCategoryTypes}
            selectKey="name"
            onChange={(e) => handleCategoryChange(e, e.target.value)}
            // disabled={watcher.replacement_car_category_has === "1" ? true : false}
          />
          <Select
            register={register}
            name="workshop"
            label="Workshop"
            placeholder="Select Workshop"
            type="text"
            items={workshops.filter(item => uppercaseText(item.location) === uppercaseText(watcher?.collection_state))}
            selectKey="name"
            condition={{ required: false }}
            onChange={(e) => handleWorkshopChange(e, e.target.value)}
          />
          <Select
            register={register}
            name="brand"
            label="Make"
            placeholder="Select Make"
            type="text"
            items={carsBrandTypes}
            selectKey="name"
            onChange={(e) => handleMakeChange(e, e.target.value)}
          />
          {/* {haveModel && 
          <Select
            register={register}
            name="vmodel"
            label="Model"
            placeholder="Select Model"
            type="text"
            items={carsModelTypes}
            selectKey="name"
            onChange={(e) => handleModelChange(e, e.target.value)}
          />
          } */}
          <Input
            register={register}
            name="year"
            label="Year Model"
            placeholder="Car Year Model"
            type="text"
            error={errors}
            message="Plate Number Required"
            onClick={(e) => setValue(e.target.value)}
          />
        </div>

        {serviceTypeValue == 1 ? 
          <RgReplacementSchedules car={filteredReplacementCars} cdate={watcher?.collection_date} watcher={watcher} setValue={setValue} register={register} errors={errors} />
        :
          <ReplacementSchedules car={filteredReplacementCars} cdate={watcher?.collection_date} watcher={watcher} />
        }

        <div className="mt-4">
          <div className="font-bold">Schedule Replacement Car</div>
          <div className="grid grid-cols-2 gap-4">
            <Input
              register={register}
              name="selectedFrom"
              label="Scheduled From"
              placeholder="Scheduled From"
              type="date"
              // condition={{ required: true }}
              error={errors}
              message="Collection Time Required"
              disabled={true}
            />
            <Input
              register={register}
              name="selectedTo"
              label="Scheduled To"
              placeholder="Scheduled To"
              type="date"
              // condition={{ required: true }}
              error={errors}
              message="Collection Time Required"
              disabled={true}
            />
          </div>
          <div className="mb-2 label-text">Selected Car</div>
          {selectedRC ? (
            <CarItem display={true} item={selectedRC} onClick={() => {}} selectedID={0} service_mileage={watcher.service_mileage} location_state={watcher.collection_state} location_country={watcher.collection_country} rc_selection_reason={watcher.rc_selection_reason} rc_price={watcher.rc_price}/>
          ) : (
            <div className="font-bold text-[#FF0000]">No Selected Car</div>
          )}
        </div>
      </motion.div>
    </div>
  )
}

export default ReplacementCarDetails
