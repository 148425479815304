import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"
import * as XLSX from 'xlsx'
import { toast } from "react-toastify"

import { useStoreWorkshops, useStoreTypes } from "../../zustand/store"
import { apiUrl, config, configWithAttachment } from "../../library/constant"

import Card from "../Card"

const ConvertExcelToJSON = () => {

    const workshops = useStoreWorkshops((s) => s.workshops)
    const carsBrandTypes = useStoreTypes((s) => s.carsBrandTypes)

    const fileInputRef = useRef(null)
    const [latestUpload, setLatestUpload] = useState({})
    const [file, setFile] = useState(null)
    const [jsonDisplay, setJsonDisplay] = useState('')
    const [fileName, setFileName] = useState('')
    const [jsonData, setJsonData] = useState([])
    const [mileages, setMileages] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const getMileage = (sheet) => {
        const range = XLSX.utils.decode_range(sheet['!ref'])
        const headers = []
        let headerCount = 0
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_col(col) + '1'
          const cell = sheet[cellAddress]
          const header = cell ? cell.v : `UNKNOWN ${col}`
          if (header.includes("km/")) {
            headers.push({id: headerCount+1, name: header.trim()})
            headerCount++
          }
        }
        return headers
    };

    const convertToJSON = () => {
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
              const fileExtension = file.name.split('.').pop().toLowerCase()
              let json = []

              if (['xls', 'xlsx', 'csv'].includes(fileExtension)) {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: "binary" })
                const sheetName = workbook.SheetNames[0]
                
                // Column headers
                const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
                const headers = getMileage(firstSheet)
                setMileages(headers)

                // validation of column names
                // const requiredColumns = ['supplier', 'supplier_name', 'make', 'model', '', 'location']
                // if (condition) {
                    
                // }

                const worksheet = workbook.Sheets[sheetName]
                json = XLSX.utils.sheet_to_json(worksheet)
              } else {
                toast.warning("Invalid File Format.")
              }

              if (json.length > 0) {
                // Normalize data
                for (let key in json) {
                    const mileage = []
                    for (let subKey in json[key]) {
                        json[key].supplier = json[key].supplier ? json[key].supplier.toString() : ""
                        json[key].supplier_id = workshops.filter(item => item.name.toUpperCase() == json[key].supplier_name?.toUpperCase()?.trim()).length > 0 ? workshops.find(item => item.name.toUpperCase() == json[key].supplier_name?.toUpperCase()?.trim()).id : 0
                        json[key].make_id = carsBrandTypes.filter(item => item.name.toUpperCase() == json[key].make?.toUpperCase()?.trim()).length > 0 ? carsBrandTypes.find(item => item.name.toUpperCase() == json[key].make?.toUpperCase()?.trim()).id : 0
                        if (json[key].hasOwnProperty(subKey) && subKey.includes("km")) {
                            mileage.push({ mileage: subKey.trim(), price: json[key][subKey] })
                            delete json[key][subKey];
                        }
                    }
                    json[key]['service_mileage'] = JSON.stringify(mileage)
                }

                setFileName(file.name)
                setJsonData(json)
                setJsonDisplay(JSON.stringify(json, null, 2))
              }
            };
            reader.readAsBinaryString(file);
        } else {
            toast.warning("Upload excel file first.")
        }
    }

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            const formData = new FormData()
            formData.append('uploadedFile', file)
            formData.append('fileName', fileName)
            formData.append('jsonData', JSON.stringify(jsonData))
            
            const omrResponse = await axios.post(apiUrl + "/overall_maintenance_rates", formData, configWithAttachment())
            // const mResponse = await axios.post(apiUrl + "/service_mileage", mileages, config())

            if (omrResponse?.data?.status === "true") {
                //  && mResponse?.data?.status === "true"
                toast.success(omrResponse?.data?.message)
                setIsSubmitting(false)
                getUploadLogs()

                // clear fields
                setFile(null)
                setFileName('')
                setJsonData([])
                setMileages([])
                setJsonDisplay('')
                if (fileInputRef.current) fileInputRef.current.value = ''

            } else {
                toast.warning(omrResponse?.data?.message)
                setIsSubmitting(false)
            }
          } catch (error) {
            toast.warning("Error Adding.")
            setIsSubmitting(false)
          }
    }

    const formatDate = (dateString) => {
        let date = dateString ? new Date(dateString) : new Date();
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    }

    const getUploadLogs = async () => {
        const res = await axios.get(apiUrl + `/audit_logs/maintenance_rates_upload_logs/latest`, config())
        const uploadLogs = res.data ?? {}
        setLatestUpload(uploadLogs)
    }

    useEffect(() => {
        getUploadLogs()
    }, [mileages])
    

    return (
        <Card>
            <div className="p-6">
                <div className="text-2xl mb-2">Update Overall Maintenance Rates (.csv/.xlsx)</div>
                {latestUpload?.added_date && 
                    <div className="text-sm mb-5 text-[#F44336]">
                        <span className="font-bold">Last Upload</span><br/>
                        <span className="">File Name: {latestUpload?.action_desc}</span><br/>
                        <span className="">Upload Date: {formatDate(latestUpload?.added_date)}</span><br/>
                        <span className="">Upload By: {latestUpload?.added_by_data?.name}</span>
                    </div>
                }
                <input ref={fileInputRef} type="file" accept=".csv,.xls,.xlsx" onChange={e => setFile(e.target.files[0])} /><br/>
                <div className="px-4 py-2 text-white rounded-md btn btn-success btn-sm mt-5 mr-3" onClick={() => convertToJSON()}>
                    Convert and View
                </div>
                {jsonData.length > 0 ? 
                    <div className={`px-4 py-2 text-white rounded-md btn btn-success btn-sm mt-5`} onClick={() => handleSubmit()} disabled={isSubmitting}>
                        {isSubmitting && <i className='fa-solid fa-spinner fa-spin'></i>}
                        Save
                    </div>
                :
                    <button className={`px-4 py-2 text-white rounded-md btn btn-sm mt-5 cursor-not-allowed`} disabled={true}>
                        Save
                    </button>
                }
                <div className={`mt-3 font-semibold ${jsonData.length > 0 ? 'block' : 'hidden'}`}>
                    {jsonData.length} {jsonData.length > 0 ? 'Items' : 'Item'}
                </div>
                <div className={`mt-3 max-h-[300px] overflow-y-auto bg-[#3e3e42] text-white`}>
                    <pre>{jsonDisplay}</pre>
                </div>
            </div>
        </Card>
    );
};

export default ConvertExcelToJSON;