import React from "react"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"

const data = [
  {
    name: "January",
    Toyota: 800,
    Nissan: 400,
    Honda: 400,
    Audi: 400,
  },
  {
    name: "February",
    Toyota: 700,
    Nissan: 398,
    Honda: 210,
    Audi: 400,
  },
  {
    name: "March",
    Toyota: 500,
    Nissan: 800,
    Honda: 290,
    Audi: 400,
  },
  {
    name: "April",
    Toyota: 780,
    Nissan: 908,
    Honda: 700,
    Audi: 400,
  },
  {
    name: "May",
    Toyota: 890,
    Nissan: 800,
    Honda: 181,
    Audi: 400,
  },
  {
    name: "June",
    Toyota: 390,
    Nissan: 800,
    Honda: 500,
    Audi: 400,
  },
  {
    name: "July",
    Toyota: 490,
    Nissan: 300,
    Honda: 100,
    Audi: 400,
  },
]

const BarChartComponent = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={150} height={40} data={data}>
        <XAxis dataKey="name" fontSize={10} />
        <Bar dataKey="Toyota" fill="#00B1AF" />
        <Bar dataKey="Nissan" fill="#F06400" />
        <Bar dataKey="Honda" fill="#009A17" />
        <Bar dataKey="Audi" fill="#ED8B00" />
        <YAxis />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartComponent
