import React from "react"

const TextArea = ({
  register,
  name,
  condition = {},
  placeholder,
  error,
  message = "No Error Message",
  label = "No Label",
  className = "",
}) => {
  return (
    <div className="w-full form-control">
      <label className="label">
        <span className="label-text">{label}</span>
        {/* <span className="label-text-alt">Alt label</span> */}
      </label>
      <textarea
        {...register(name, condition)}
        placeholder={placeholder}
        className={"w-full max-w-full min-w-full textarea input-bordered " + className}
      />
      <label className="pt-0 label">
        <span className="label-text-alt"></span>
        {error[name] && <span className="mt-1 italic text-red-500 label-text-alt">{message}</span>}
        {/* <span className="label-text-alt">Alt label</span> */}
      </label>
    </div>
  )
}

export default TextArea
