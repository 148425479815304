import React, { useState } from "react"
import AnimatedPage from "../components/AnimatedPage"
import Map from "../components/map/Map"
import ModalUni from "../components/modals/ModalUni"
import FormCreate from "../components/workshops/FormCreate"
import Table from "../components/workshops/Table"
import { useStore, useStoreWorkshops } from "../zustand/store"
import DebouceSearchInput from "../components/inputs/DebouceSearchInput"
import NoAccess from "./NoAccess"

const Workshops = () => {
  const [isAddWorkshopOpen, setIsAddWorkshopOpen] = useState(false)
  const setIsWhat = useStoreWorkshops((state) => state.setIsWhat)
  const setSelectedWorkshop = useStoreWorkshops((state) => state.setSelectedWorkshop)
  const [searchText, setSearchText] = useState("")

  const privileges = useStore((state) => state.privileges)

  if (!Boolean(privileges[13])) {
    return <NoAccess />
  }

  const handleOpenNewRequest = (second) => {
    setSelectedWorkshop(null)
    setIsWhat("insert")
    setIsAddWorkshopOpen(true)
  }

  return (
    <AnimatedPage title={"Workshops"}>
      {/*  Modals */}
      <ModalUni isVisible={isAddWorkshopOpen} setIsVisible={setIsAddWorkshopOpen} title="Add Workshop" size={3}>
        <FormCreate setVisibleModal={setIsAddWorkshopOpen} />
      </ModalUni>
      {/* -------- */}

      <div className="grid grid-cols-3 gap-4 lg:flex-row">
        <div className="max-w-5xl  w-full h-[calc(100vh-230px)] overflow-hidden shadow-md rounded-xl mr-6">
          <Map x={"workshop"} />
        </div>

        <div className="col-span-2">
          <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
            <div className="flex gap-4">
              <DebouceSearchInput onTextChange={setSearchText} />
              {/* <button>
                <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-filter"></i>
              </button> */}
            </div>
            <button
              className="flex items-center justify-center px-8 py-3 text-lg text-white rounded-md shadow-md bg-aqua hover:bg-aqua-dark"
              onClick={handleOpenNewRequest}
            >
              <div className="mr-4">Create Workshop</div>
              <i className="text-sm fa-solid fa-plus-large"></i>
            </button>
          </div>
          <Table setIsAddWorkshopOpen={setIsAddWorkshopOpen} searchText={searchText} />
        </div>
      </div>
    </AnimatedPage>
  )
}

export default Workshops
