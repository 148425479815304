import React, { useMemo, useState } from "react"
import dayjs from "dayjs"
import { useEffect } from "react"
import axios from "axios"
import { apiUrl, config } from "../../../library/constant"
import { useStoreReplacementCars, useStoreServices } from "../../../zustand/store"
import { toast } from "react-toastify"
import VehicleReportView from "../VehicleReportView"

const RCHistory = ({ setIsVisible }) => {
  const selectedService = useStoreServices((s) => s.selectedService)

  const [history, setHistory] = useState([])

  useEffect(() => {
    const link = apiUrl + "/rc_history/" + selectedService.id
    console.log(config())
    axios
      .get(link, config())
      .then((res) => {
        console.log(res.data)
        setHistory(res.data)
      })
      .catch((err) => {
        toast.error("Problem fetching History")
      })
  }, [])

  return (
    <div className="grid grid-flow-row gap-2">
      {history.map((item, key) => {
        return <HistoryTag data={item} key={key} />
      })}
    </div>
  )
}

export default RCHistory

const HistoryTag = ({ data }) => {
  const { replacementCars } = useStoreReplacementCars()

  const fromRC = useMemo(() => {
    let rc = {}
    for (let index = 0; index < replacementCars.length; index++) {
      const element = replacementCars[index]
      if (element.id === data?.from_rc_data?.id) {
        rc = element
      }
    }
    return rc
  }, [replacementCars])

  const toRC = useMemo(() => {
    let rc = {}
    for (let index = 0; index < replacementCars.length; index++) {
      const element = replacementCars[index]
      if (element.id === data?.to_rc_data?.id) {
        rc = element
      }
    }
    return rc
  }, [replacementCars])

  return (
    <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md shadow-md">
      <div className={`font-semibold grid grid-flow-col grid-cols-3 w-full`}>
        <div className="flex flex-col">
          <div>{data?.from_rc_data?.reg_no}</div>
          <div className="flex gap-2">
            <div>{fromRC?.brand_data?.name}</div>
            <div>-</div>
            <div>{fromRC?.model}</div>
          </div>
        </div>

        <div className="text-center ">
          <i className=" fa-solid fa-right-long"></i>
        </div>
        <div className="flex flex-col">
          <div>{data?.to_rc_data?.reg_no}</div>
          <div className="flex gap-2">
            <div>{toRC?.brand_data?.name}</div>
            <div>-</div>
            <div>{toRC?.model}</div>
          </div>
        </div>
      </div>
      <div className="text-sm">Change by: {data?.added_by_data?.name}</div>
      <div className="flex justify-between text-sm">
        <div>{dayjs(data.added_date).format("MMM D, YYYY")}</div>
        <div>{dayjs(data.added_date).format("HH:mm")}</div>
      </div>
      <VehicleReportView
        lease_vehicle={""}
        lease_vehicle_in={""}
        lease_vehicle_out={""}
        replacement_vehicle={data?.replacement_vehicle ?? ""}
        replacement_vehicle_in={data?.replacement_vehicle_in ?? ""}
        replacement_vehicle_out={data?.replacement_vehicle_out ?? ""}
        rc_needed={true}
        collection_needed={false}
      />
    </div>
  )
}
