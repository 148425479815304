import axios from "axios"
import React from "react"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices } from "../../../zustand/store"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const TransferReceivedLV = ({ setIsVisible }) => {
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const handleSubmit = useCbOnce(async () => {
    const response = await axios.post(apiUrl + "/services/transfer_delivered", { id: selectedService.id }, config())
    console.log("TransferReceivedLV", selectedService.transfer_to)
    setIsVisible(false)
    setSelectedService(response.data)
    updateService(response.data)
  })

  return (
    <div>
      <div className="text-2xl">Is Lease Vehicle Received?</div>
      <div className="flex items-center justify-end w-full gap-2">
        <button
          className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
          onClick={() => {
            setIsVisible(false)
          }}
        >
          No
        </button>
        <button
          className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600"
          onClick={handleSubmit}
        >
          Yes
        </button>
      </div>
    </div>
  )
}

export default TransferReceivedLV
