import axios from "axios"
import React, { useState, useEffect } from "react"
import { HashLoader } from "react-spinners"
import { apiUrl, config } from "../../library/constant"
import { dayjsx } from "../../library/functions"

const Table = ({ searchText='' }) => {
  const [auditLogs, setAuditLogs] = useState([])
  const bodyRows = 10
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  useEffect(() => {
    const fetchPagination = async () => {
      const res = await axios.get(
        apiUrl + "/audit_logs/count?search=" + searchText + "&page=" + currentPage,
        config()
      )
      let pages = parseInt((res.data - 1) / bodyRows + 1)
      setCount(res.data)
      setTotalPages(pages)
    }
    fetchPagination()
  }, [auditLogs, currentPage, searchText])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(apiUrl + "/audit_logs?search=" + searchText + "&page=" + currentPage + "&count=" + bodyRows, config())
      // console.log(res.data)
      setAuditLogs(res.data.rows)
      // setTotalPages(res.data.totalPages)
      setIsLoading(false)
    }
    fetchData()
  }, [currentPage, searchText])

  return (
    <>
      {isLoading ? 
        <div className="mt-10">
          <center>
            <HashLoader color="#f97316" loading={true} size={80}/>
          </center>
        </div>
      :
        <div>
          <div className="min-w-full overflow-auto rounded-t-lg shadow-lg">
            <table className="w-full overflow-hidden rounded-t-lg table-auto whitespace-nowrap">
              <thead className="sticky top-0">
                <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
                  <th className="px-3 py-4 text-left">ID</th>
                  <th className="px-3 py-4 text-left">MODULE</th>
                  <th className="px-3 py-4 text-left">MODULE_ID</th>
                  <th className="px-3 py-4 text-left">ACTION_TYPE</th>
                  <th className="py-4 pl-3 text-left">ACTION_DESCRIPTION</th>
                  <th className="py-4 pl-3 text-left">ACTION_BY</th>
                  <th className="py-4 pl-3 text-left">ACTION_DATE</th>
                </tr>
              </thead>
              <tbody>
                {auditLogs.map((item, key) => {
                  return <TableRow item={item} key={key} />
                })}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
            <div>
              Showing {auditLogs?.length ?? 0} of {count} total record
            </div>
            <div className="flex items-center mt-2 space-x-4">
              <div onClick={prevPage} className="cursor-pointer">
                Previous
              </div>
              <div>
                {currentPage}/{totalPages}
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                Next
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Table

const TableRow = ({ item }) => {
  return (
    <tr>
      <td className="py-4 pl-3">{item?.id}</td>
      <td className="py-4 pl-3">{item?.from_table}</td>
      <td className="py-4 pl-3">{item?.table_id}</td>
      <td className="py-4 pl-3">{item?.action_type}</td>
      <td className="py-4 pl-3">
        <HtmlRenderer data={item?.action_desc} user={item?.added_by_data} />
      </td>
      <td className="py-4 pl-3">{item?.added_by_data?.name}</td>
      <td className="py-4 pl-3">{dayjsx(item?.added_date).format("MMM D, YYYY HH:mm")}</td>
    </tr>
  )
}

const HtmlRenderer = ({ data, user }) => {
  return <div dangerouslySetInnerHTML={{ __html: data ? data.replace("@user", user?.username) : "" }} />
}
