import React, { useEffect, useState } from "react"
import AnimatedPage from "../../components/AnimatedPage"
import Calendar from "../../components/calendar/Calendar"
import Card from "../../components/Card"
import ModalUni from "../../components/modals/ModalUni"
import { Filters } from "../../components/schedules/Filters"
import { useStore, useStoreReplacementCars, useStoreSchedule, useStoreServices } from "../../zustand/store"
import { DateFilter } from "../../components/schedules/DateFilter"
import FormCreate from "../../components/schedules/FormCreate"

const Schedules = () => {
  const [isNewReplacementCar, setIsNewReplacementCar] = useState(false)

  const selectedFrom = useStoreSchedule((s) => s.selectedFrom)
  const selectedTo = useStoreSchedule((s) => s.selectedTo)
  const { setSelectedService } = useStoreServices()

  const handleOpenNewRequest = (second) => {
    setIsNewReplacementCar(true)
  }

  useEffect(() => {
    setSelectedService(null)
  }, [])

  return (
    <AnimatedPage title="Replacement Cars Schedules">
      <ModalUni isVisible={isNewReplacementCar} setIsVisible={setIsNewReplacementCar} title="Add RC Schedule" size={3}>
        <FormCreate setVisibleModal={setIsNewReplacementCar} />
      </ModalUni>
      {/* -------- */}
      <div className="flex flex-col w-full h-full">
        <div className="flex h-full gap-4 pt-6">
          <div className="w-full max-w-xs">
            <Card className="px-4 py-4 mb-6">
              <Filters />
            </Card>
            {selectedFrom && selectedTo && (
              <Card className="px-4 py-4">
                <div>Selected Dates</div>
                <div className="flex items-center justify-between px-4 mt-2 mb-2 font-bold text-white">
                  <div className="w-full px-1">
                    <div className="flex justify-center py-2 bg-[#F06400] rounded-lg">{selectedFrom}</div>
                  </div>
                  <div className="font-black text-black">-</div>
                  <div className="w-full px-1">
                    <div className="flex justify-center py-2 bg-[#F06400] rounded-lg ">{selectedTo}</div>
                  </div>
                </div>
                <div
                  className="flex items-center justify-center px-4 py-1 text-lg text-white bg-[#F06400] rounded btn-sm btn-warning"
                  onClick={handleOpenNewRequest}
                >
                  <div className="mr-2">Add Schedule</div>
                  <i className="text-sm fa-solid fa-plus-large"></i>
                </div>
              </Card>
            )}
          </div>
          {/* <Card className="px-4 py-4 mb-6"></Card> */}

          <Card className="w-full px-4 pt-4">
            <DateFilter />
            <div className="h-[calc(100vh-380px)] min-h-[100px] mb-4">
              <Calendar className="max-w-[calc(100vw-500px)]" selection={false} />
            </div>
          </Card>
        </div>
      </div>
    </AnimatedPage>
  )
}

export default Schedules
