import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { DetailField } from "./FormView"
import CarIntent from "./CarIntent"

const VehicleReportView = ({
  //   setValue,
  lease_vehicle_in = null,
  lease_vehicle_out = null,
  replacement_vehicle_out = null,
  replacement_vehicle_in = null,
  lease_vehicle = null,
  replacement_vehicle = null,
  rc_needed = false,
  collection_needed = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    console.log(lease_vehicle_in)
  }, [lease_vehicle_in])

  return (
    <div>
      <div>
        <div className="px-6 mt-4">
          <div className="grid grid-cols-2 ">
            {collection_needed && (
              <>
                <CarIntent
                  className="border-t border-l border-r"
                  title="Leased Vehicle In"
                  name="Vehicle In"
                  id="lease_vehicle_in"
                  data={lease_vehicle_in}
                  disabled={true}
                />
                <CarIntent
                  className="border-t border-r"
                  title="Leased Vehicle Out"
                  name="Vehicle Out"
                  id="lease_vehicle_out"
                  data={lease_vehicle_out}
                  disabled={true}
                />
              </>
            )}
            {rc_needed && (
              <>
                <CarIntent
                  className="border-t border-b border-l border-r"
                  title="Replacement Vehicle Out"
                  name="Vehicle Out"
                  id="replacement_vehicle_out"
                  data={replacement_vehicle_out}
                  disabled={true}
                />
                <CarIntent
                  className="border-t border-b border-r"
                  title="Replacement Vehicle In"
                  name="Vehicle In"
                  id="replacement_vehicle_in"
                  data={replacement_vehicle_in}
                  disabled={true}
                />
              </>
            )}
          </div>
          {collection_needed && (
            <div>
              <div className="mt-4 text-lg font-bold">Leased Vehicle - IN</div>
              <LVInputs id="lease_vehicle" data={lease_vehicle} />
            </div>
          )}
          {rc_needed && (
            <div>
              <div className="mt-4 text-lg font-bold">Replacement Vehicle - OUT</div>
              <LVInputs id="replacement_vehicle" isReverse={true} data={replacement_vehicle} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VehicleReportView

export const LVInputs = ({ xSetValue, id, isReverse = false, data = null }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const watcher = watch()

  useEffect(() => {
    if (data) {
      const x = JSON.parse(data)
      console.log(x)
      setValue("fleet_no", x.fleet_no)
      setValue("type", x.type)
      setValue("reg_no", x.reg_no)
      setValue("date_in", x.date_in)
      setValue("time_in", x.time_in)
      setValue("km_in", x.km_in)
      setValue("fuel_in", x.fuel_in)
      setValue("date_out", x.date_out)
      setValue("time_out", x.time_out)
      setValue("km_out", x.km_out)
      setValue("fuel_out", x.fuel_out)
    }
  }, [data])

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-x-4">
      {/* <Input register={register} name="fleet_no" label="Fleet No." placeholder="Fleet No." type="text" error={errors} />
      <Input register={register} name="type" label="Type" placeholder="Type" type="text" error={errors} />
      <Input register={register} name="reg_no" label="Reg No." placeholder="Reg No." type="text" error={errors} /> */}
      <div className={`${isReverse ? "flex-col-reverse" : "flex-col"} flex col-span-3`}>
        {/* IN */}
        <div className="grid grid-flow-row grid-cols-3 gap-x-4">
          <DetailField label={"Date In"} value={watcher.date_in ?? "No details provided"} />
          <DetailField label={"Time In"} value={watcher.time_in ?? "No details provided"} />
          <div className="flex gap-x-4">
            <DetailField label={"Km In"} value={watcher.km_in ?? "No details provided"} />
            <DetailField label={"Fuel In"} value={watcher.fuel_in ?? "No details provided"} />
          </div>
        </div>

        {/* OUT */}
        <div className="grid grid-flow-row grid-cols-3 gap-x-4">
          <DetailField label={"Date Out"} value={watcher.date_out ?? "No details provided"} />
          <DetailField label={"Time Out"} value={watcher.time_out ?? "No details provided"} />
          <div className="flex gap-x-4">
            <DetailField label={"Km Out"} value={watcher.km_out ?? "No details provided"} />
            <DetailField label={"Fuel Out"} value={watcher.fuel_out ?? "No details provided"} />
          </div>
        </div>
        {/* <div className="grid grid-flow-row grid-cols-3 gap-x-4">
          <Input
            register={register}
            name="date_out"
            label="Date Out"
            placeholder="Date Out"
            type="date"
            error={errors}
          />
          <Input
            register={register}
            name="time_out"
            label="Time Out"
            placeholder="Time Out"
            type="time"
            error={errors}
          />
          <div className="flex gap-x-4">
            <Input register={register} name="km_out" label="Km Out" placeholder="Km Out" type="text" error={errors} />
            <Input
              register={register}
              name="fuel_out"
              label="Fuel Out"
              placeholder="Fuel Out"
              type="text"
              error={errors}
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}
