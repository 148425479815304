import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreReplacementCars, useStoreTypes, useStoreWorkshops } from "../../zustand/store"
import Files from "../inputs/Files"
import Input from "../inputs/Input"
import Select from "../inputs/Select"
import { pad } from "../../library/functions"
import ModalUni from "../modals/ModalUni"
import AddCategory from "./AddCategory"
import AddBody from "./AddBody"

const FormCreate = ({ setVisibleModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const emirateTypes = useStoreTypes((s) => s.emirateTypes)
  const auhCategoryTypes = useStoreTypes((state) => state.auhCategoryTypes)
  const carsCategoryTypes = useStoreTypes((s) => s.carsCategoryTypes)
  const carsBodyTypes = useStoreTypes((s) => s.carsBodyTypes)
  const carsBrandTypes = useStoreTypes((s) => s.carsBrandTypes)
  const setCarsBrandTypes = useStoreTypes((s) => s.setCarsBrandTypes)
  const workshops = useStoreWorkshops((s) => s.workshops)

  const isWhat = useStoreReplacementCars((s) => s.isWhat)
  const addReplacementCars = useStoreReplacementCars((s) => s.addReplacementCars)
  const updateReplacementCars = useStoreReplacementCars((s) => s.updateReplacementCars)
  const selectedReplacementCar = useStoreReplacementCars((s) => s.selectedReplacementCar)
  const addEmirateSub = useStoreTypes((s) => s.addAuhCategoryTypes)

  // console.log("test")
  // console.log(selectedReplacementCar)

  const replacementCars = useStoreReplacementCars((s) => s.replacementCars)

  const pictureRef = useRef(null)
  const [image, setImage] = useState(null)
  const [files, setFiles] = useState(null)
  const [uploadFiles, setUploadFiles] = useState(null)
  const [isFetchingDriver, setIsFetchingDriver] = useState(false)
  const watcher = watch()

  const [locks, setLocks] = useState([])
  const [modalAddCategoryVisible, setModalAddCategoryVisible] = useState(false)
  const [modalAddBodyVisible, setModalAddBodyVisible] = useState(false)
  const [modalAddVisible, setModalAddVisible] = useState(false)
  const [addCategoryName, setAddCategoryName] = useState("")
  const [addCategoryActual, setAddCategoryActual] = useState("")

  const handleSubmitData = async (params) => {
    const photos = uploadFiles ? uploadFiles.map((item) => item.filename) : []
    if (isWhat === "insert") {
      const newParams = { ...params, pics: photos }
      const { data, status } = await axios.post(apiUrl + "/replacement_cars", newParams, config())
      console.log(data)
      if (status === 200) {
        if (data?.error) {
          toast.error("Chassis existed to the Database.")
        } else {
          toast.success("RC Successfully added...")
          addReplacementCars(data)
          setVisibleModal(false)
        }
      }
    } else if (isWhat === "update") {
      console.log({ ...params, pics: photos })
      const newParams = { ...params, pics: photos, id: selectedReplacementCar.id }
      const { data, status } = await axios.put(apiUrl + "/replacement_cars", newParams, config())

      if (status === 200) {
        console.log(data)
        toast.success("Replacement Car Successfully updated...")
        updateReplacementCars(data)
        setVisibleModal(false)
      }
    } else {
      toast.error("Workshop confused?...")
    }
  }

  const btnCancel = () => {
    setVisibleModal(false)
  }

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      //   setImage(URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append("files", event.target.files[0])
      const res = await axios.post(apiUrl + "/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      console.log(res.data)
      setValue("pic", res.data.filenames[0])
      setImage(apiUrl + "/public/" + res.data.filenames[0])
    }
  }

  const handleUploadPicture = () => {
    pictureRef.current.click()
  }

  const handleBrandChange = (event) => {
    const brandId = event.target.value
    setValue("make", carsBrandTypes.filter(item => item.id == brandId).length > 0 ? carsBrandTypes.find(item => item.id == brandId).name : null)
  }

  const fetchDriver = () => {
    const category = watcher.auh_category
    let category_name = ""
    for (let index = 0; index < auhCategoryTypes.length; index++) {
      const element = auhCategoryTypes[index]
      if (element.id === Number(category)) {
        category_name = element.actual
        console.log(element)
      }
    }

    console.log("category", category_name)

    if (watcher.plate === "") {
      toast.warning("Please Fill the Plate Number")
      return null
    }

    if (category_name === "") {
      toast.warning("Please Select Emirates Category")
      return null
    }

    const link = apiUrl + "/fetch_client/" + watcher.plate + category_name
    setIsFetchingDriver(true)
    axios
      .get(link, config())
      .then(async (res) => {
        const clients = res.data
        if (clients.length !== 0 && Array.isArray(clients)) {
          console.log(clients[0])
          const client = clients[0]
          toast.success("Client Found!")
          setValue("client_api_data", JSON.stringify(client).toUpperCase())

          for (let index = 0; index < carsCategoryTypes.length; index++) {
            const element = carsCategoryTypes[index]
            if (element.name === client.REPLACEMENT_CATEGORY) {
              setValue("category", element.id)
            }
          }
          let brandFound = false
          for (let index = 0; index < carsBrandTypes.length; index++) {
            const element = carsBrandTypes[index]
            if (element.name === client.MAKE) {
              setValue("brand", element.id)
              setValue("make", carsBrandTypes.filter(carBrand => carBrand.id == element.id).length > 0 ? carsBrandTypes.find(carBrand => carBrand.id == element.id).name : null)
              brandFound = true
            }
          }

          if (!brandFound) {
            const { data: carbrand } = await axios.post(apiUrl + "/cars_brand_types", { name: client.MAKE }, config())
            console.log(carbrand)
            const newBrands = [...carsBrandTypes, carbrand]
            setCarsBrandTypes(newBrands)

            setValue("brand", carbrand.id)
            setValue("make", carsBrandTypes.filter(carb => carb.id == carbrand.id).length > 0 ? carsBrandTypes.find(carb => carb.id == carbrand.id).name : null)
          }

          setValue("model", client.MODEL)
          setValue("year", client.YEAR_MODEL)
          setValue("color", client.COLOR)
          setValue("mileage", client.LAST_KNOWN_MILEAGE)
          setValue("chassis_no", client.CHASSIS_NUMBER)

          let counts = 1
          for (let index = 0; index < replacementCars.length; index++) {
            const replacementCar = replacementCars[index]
            const reg_no = replacementCar.reg_no

            if (reg_no.includes(client.CLIENT_NO)) {
              counts += 1
            }
          }

          setValue("reg_no", "RC-" + client.CLIENT_NO + "-" + pad(counts, 3))
        } else {
          toast.success("Client Not Found!")
          setValue("model", "")
          setValue("year", "")
          setValue("color", "")
          setValue("mileage", "")
          setValue("reg_no", "")
          setValue("category", "")
          setValue("brand", "")
          setValue("make", "")
        }
        setIsFetchingDriver(false)
      })
      .catch(() => {
        setIsFetchingDriver(false)
        toast.success("Client Not Found!")
      })
  }

  useEffect(() => {
    console.log("carsBrandTypes", carsBrandTypes)
    const fetchData = async () => {
      if (isWhat === "update" && selectedReplacementCar) {
        setValue("reg_no", selectedReplacementCar.reg_no)
        setValue("chassis_no", selectedReplacementCar.chassis_no)
        setValue("emirate", selectedReplacementCar.emirate)
        setValue("auh_category", "")
        setValue("plate", selectedReplacementCar.plate)
        setValue("category", selectedReplacementCar.category)
        setValue("body_type", selectedReplacementCar.body_type)
        setValue("brand", selectedReplacementCar.brand)
        setValue("make", selectedReplacementCar.make || (carsBrandTypes.filter(c => c.id == selectedReplacementCar.brand).length > 0 ? carsBrandTypes.find(c => c.id == selectedReplacementCar.brand).name : null))
        setValue("model", selectedReplacementCar.model)
        setValue("year", selectedReplacementCar.year)
        setValue("color", selectedReplacementCar.color)
        setValue("mileage", selectedReplacementCar.mileage)
        setValue("daily_rate", selectedReplacementCar.daily_rate)
        setValue("workshop", "")
        setValue("status", selectedReplacementCar.status)
        setValue("locked_to", selectedReplacementCar.locked_to)
        setValue("pic", selectedReplacementCar.pic)
        setValue("pics", selectedReplacementCar.pics)
        setLocks(JSON.parse(selectedReplacementCar.locked_to))
        if (selectedReplacementCar.pic) {
          setImage(apiUrl + "/public/" + selectedReplacementCar.pic)
        }
        if (selectedReplacementCar.pics) {
          setUploadFiles(selectedReplacementCar.pics.map((item, key) => ({ filename: item, id: key })))
        }
      }
    }
    fetchData()
  }, [isWhat, selectedReplacementCar, setValue])

  const filteredCategory = useMemo(() => {
    // console.log(watcher.emirate)
    return auhCategoryTypes.filter((item) => item.emirate === Number(watcher.emirate) && item)
  }, [watcher.emirate, auhCategoryTypes])

  const handleSubmitCategory = async (second) => {
    const data = {
      emirate: Number(watcher.emirate),
      name: addCategoryName,
      actual: addCategoryActual,
    }
    try {
      const res = await axios.post(apiUrl + "/auh_category_types", data, config())
      addEmirateSub(res.data)
      toast.success("Successfully Category Added.")
      setValue("auh_category", res.data.id)
      setModalAddVisible(false)
    } catch (error) {
      toast.success("Error Adding Category.")
    }
  }

  useEffect(() => {
    if (filteredCategory.length !== 0 && isWhat !== "insert") {
      for (let index = 0; index < filteredCategory.length; index++) {
        const element = filteredCategory[index]
        if (element.id === Number(selectedReplacementCar.auh_category)) {
          setValue("auh_category", selectedReplacementCar.auh_category)
        }
      }
    }
  }, [filteredCategory])

  // useEffect(() => {
  //   if (filteredWorkshops.length !== 0 && isWhat !== "insert") {
  //     for (let index = 0; index < filteredWorkshops.length; index++) {
  //       const element = filteredWorkshops[index]
  //       if (element.id === Number(selectedReplacementCar.workshop)) {
  //         setValue("workshop", selectedReplacementCar.workshop)
  //       }
  //     }
  //   }
  // }, [filteredWorkshops]) KAPAREHAS LANG TO SA BABA

  useEffect(() => {
    if (workshops.length !== 0 && isWhat !== "insert") {
      for (let index = 0; index < workshops.length; index++) {
        const element = workshops[index]
        if (element.id === Number(selectedReplacementCar.workshop)) {
          setValue("workshop", selectedReplacementCar.workshop)
        }
      }
    }
  }, [workshops])

  useEffect(() => {
    setValue("locked_to", JSON.stringify(locks))
  }, [locks])

  useEffect(() => {
    const category = watcher.auh_category
    if (category === "add") {
      if (Number(watcher.emirate) !== 0) {
        setModalAddVisible(true)
      } else if (Number(watcher.emirate) === 0) {
        toast.warning("Please select Emirate first.")
        setValue("auh_category", "")
      }
    }
  }, [watcher.auh_category])

  useEffect(() => {
    const category = watcher.category
    if (category === "add") {
      setModalAddCategoryVisible(true)
    }
  }, [watcher.category])

  useEffect(() => {
    const body_type = watcher.body_type
    if (body_type === "add") {
      setModalAddBodyVisible(true)
    }
  }, [watcher.body_type])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        handleSubmitData(data)
      })}
    >
      <ModalUni
        isVisible={modalAddCategoryVisible}
        setIsVisible={setModalAddCategoryVisible}
        size={3}
        title={"Add Replacement Car Category"}
      >
        <AddCategory setValue={setValue} setModalAddVisible={setModalAddCategoryVisible} />
      </ModalUni>

      <ModalUni isVisible={modalAddBodyVisible} setIsVisible={setModalAddBodyVisible} size={3} title={"Add Body Type"}>
        <AddBody setValue={setValue} setModalAddVisible={setModalAddBodyVisible} />
      </ModalUni>

      <ModalUni isVisible={modalAddVisible} setIsVisible={setModalAddVisible} size={3} title={"Add Emirate Category"}>
        <div>
          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Category Name</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. R6"
              className="w-full input input-bordered"
              value={addCategoryName}
              onChange={(e) => {
                setAddCategoryName(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className={"w-full form-control "}>
            <label className="label">
              <span className="label-text">Actual</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              type="text"
              placeholder="ex. 16"
              className="w-full input input-bordered"
              value={addCategoryActual}
              onChange={(e) => {
                setAddCategoryActual(e.currentTarget.value)
              }}
            />
            <label className="pt-0 label">
              <span className="label-text-alt"></span>
            </label>
          </div>

          <div className="flex justify-end">
            <div className="px-12 btn" onClick={handleSubmitCategory}>
              Submit
            </div>
          </div>
        </div>
      </ModalUni>

      <div className="grid grid-flow-row grid-cols-4 gap-4">
        <Select
          register={register}
          name="emirate"
          label="Emirate"
          placeholder="Select Emirate"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Emirate Required"
          items={emirateTypes}
          selectKey="name"
        />
        <Input
          register={register}
          name="plate"
          label="Plate No."
          placeholder="Plate Number"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Plate Number Required"
        />
        <Select
          register={register}
          name="auh_category"
          label="Registration Category"
          placeholder="Select Emirate Category"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="AUH Category Required"
          items={filteredCategory}
          selectKey="name"
          hasAddItem={true}
        />

        <div className="flex items-start">
          {isFetchingDriver ? (
            <div className="flex items-center px-3 py-3 text-white bg-[#F06400] rounded-lg cursor-not-allowed mt-9">
              <div>
                <i className="fa-solid fa-spinner-third animate-spin"></i>
                <span className="mb-0.5 ml-2 ">Fetching Details</span>
              </div>
            </div>
          ) : (
            <div
              className="flex items-center px-3 py-3 text-white bg-[#F06400] rounded-lg cursor-pointer mt-9"
              onClick={fetchDriver}
            >
              <div>
                <i className="fa-solid fa-eye"></i>
                <span className="mb-0.5 ml-2 ">Get Details</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-flow-row grid-cols-3 gap-4">
        <Input
          register={register}
          name="reg_no"
          label="Car Replacement No."
          placeholder="Registration Number"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Registration No. Required"
          // disabled={true}
        />
        <Select
          register={register}
          name="category"
          label="Replacement Car Category"
          placeholder="Select Category"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Category Required"
          items={carsCategoryTypes}
          selectKey="name"
          hasAddItem={true}
        />
        <Input
          register={register}
          name="chassis_no"
          label="Chassis Number"
          placeholder="Chassis Number"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Chassis Number Required"
        />
      </div>

      <div className="grid grid-flow-row grid-cols-3 gap-4">
        <Select
          register={register}
          name="brand"
          label="Make"
          placeholder="Select Make"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Brand Required"
          items={carsBrandTypes}
          selectKey="name"
          onChange={(event) => handleBrandChange(event)}
        />
        <Input
          register={register}
          name="model"
          label="Model"
          placeholder="Model"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Model Required"
        />
        <Input
          register={register}
          name="year"
          label="Year"
          placeholder="Year"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Year Required"
        />
      </div>

      <div className="grid grid-flow-row grid-cols-3 gap-4">
        <Select
          register={register}
          name="body_type"
          label="Body Type"
          placeholder="Body Type"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Category Required"
          items={carsBodyTypes}
          selectKey="name"
          hasAddItem={true}
        />
        <Input
          register={register}
          name="color"
          label="Car Color"
          placeholder="Car Color"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Car Color Required"
        />
        <Input
          register={register}
          name="mileage"
          label="Mileage"
          placeholder="Mileage"
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Mileage Required"
        />
      </div>
      <div className="grid grid-flow-row grid-cols-2 gap-4 mb-0">
        <div className="flex flex-col w-full pb-5 mt-1">
          <div className="mb-2">Display Picture</div>
          <div className="relative object-cover w-full h-56 overflow-hidden rounded ring ring-[#F06400] ring-offset-base-100 ring-offset-2 avatar">
            <div
              className="absolute top-0 z-50 flex items-center justify-center w-full h-full transition-all ease-in-out opacity-0 cursor-pointer hover:bg-black/50 hover:opacity-100"
              onClick={handleUploadPicture}
            >
              <div className="flex items-center justify-center h-full text-white">Upload</div>
              <input
                type="file"
                ref={pictureRef}
                className="hidden"
                onChange={onImageChange}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
            {image ? (
              <img src={image} />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-[#F06400] rounded">
                <i className="flex items-center justify-center w-full h-full text-6xl text-gray-200 fa-solid fa-car-side"></i>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-1 ">
          <Input
            register={register}
            name="daily_rate"
            label="Daily Rate"
            placeholder="Daily Rate"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Daily Rate Required"
          />
          <Select
            register={register}
            name="workshop"
            label="Workshop"
            placeholder="Select Workshop"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Workshop Required"
            items={workshops}
            selectKey="name"
          />
          <Select
            register={register}
            name="status"
            label="Car Status"
            placeholder="Select Status"
            type="text"
            condition={{ required: true }}
            error={errors}
            message="Status Required"
            items={[
              { id: 1, name: "Active" },
              { id: 0, name: "Inacctive" },
              // { id: 2, name: "Terminated" },
            ]}
            selectKey="name"
          />
          {/* <Input
            register={register}
            name="locked_to"
            label="Locked to Client"
            placeholder="Client No ex. 11440"
            type="text"
            error={errors}
            message="Mileage Required"
          /> */}
        </div>
      </div>

      <div className="grid grid-flow-row grid-cols-1 gap-4 mb-3">
        <div className="grid grid-flow-row grid-cols-1 ">
          <div className={"w-full form-control relative "}>
            <label className="label">
              <span className="flex items-end label-text">
                Locked to Client <div className="ml-1 text-xs italic font-bold">*Press Enter to add.</div>
              </span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <div className="flex flex-wrap">
              {locks.map((item, kik) => (
                <div className="px-2 m-0.5 text-white bg-blue-800 rounded-full text-sm">
                  {item}{" "}
                  <i
                    className="fa-sharp fa-solid fa-circle-xmark text-xs text-red-500 cursor-pointer"
                    onClick={() => {
                      const nLocks = []
                      for (let index = 0; index < locks.length; index++) {
                        const element = locks[index]
                        if (kik !== index) {
                          nLocks.push(element)
                        }
                      }
                      setLocks(nLocks)
                    }}
                  ></i>
                </div>
              ))}
            </div>
            <input
              {...register("locks")}
              type="text"
              placeholder="Client No ex. 11440"
              className="w-full input input-bordered"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  event.preventDefault()
                  const xTags = [...locks, event.currentTarget.value]
                  setLocks(xTags)
                  setValue("locks", "")
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="mb-1">Gallery</div>
      <Files
        files={files}
        setFiles={setFiles}
        isWhat={isWhat}
        uploadFiles={uploadFiles}
        setUploadFiles={setUploadFiles}
      />
      <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
        <div className="px-12 btn" onClick={btnCancel}>
          Cancel
        </div>
        <button className="px-12 btn">
          {isWhat === "insert" ? "Submit" : isWhat === "update" ? "Update" : "Confused"}
        </button>
      </div>
    </form>
  )
}

export default FormCreate
