import axios from "axios"
import * as dayjs from "dayjs"
import { geocodeByLatLng } from "react-google-places-autocomplete"
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%dm",
    y: "a year",
    yy: "%dy",
  },
})

const dayjsx = dayjs

function capitalizeWords(string) {
  string = string.toLowerCase()
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

function ConvertToJson(str) {
  try {
    const data_json = JSON.parse(str)
    return data_json
  } catch (e) {
    return false
  }
}

async function urltoFile(url, filename, mimeType) {
  return await fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType })
    })
}

const jsonToTreeData = (serviceData) => {
  const newData = []
  const keys = Object.keys(serviceData)

  for (let index = 0; index < keys.length; index++) {
    const element = keys[index]
    newData.push(addDataNode(index, element))
    const children = serviceData[element]
    // check if children is object
    if (typeof children === "object" && children !== null) {
      const childrenKeys = Object.keys(children)
      for (let index = 0; index < childrenKeys.length; index++) {
        const element = childrenKeys[index]
        newData[newData.length - 1].children.push(addDataNode(index, element))

        const childrenChildren = children[element]
        if (typeof childrenChildren === "object" && childrenChildren !== null) {
          const childrenChildrenKeys = Object.keys(childrenChildren)
          for (let index = 0; index < childrenChildrenKeys.length; index++) {
            const element = childrenChildrenKeys[index]
            newData[newData.length - 1].children[newData[newData.length - 1].children.length - 1].children.push(
              addDataNode(index, element)
            )

            const childrenChildrenChildren = childrenChildren[element]
            if (typeof childrenChildrenChildren === "object" && childrenChildrenChildren !== null) {
              const childrenChildrenChildrenKeys = Object.keys(childrenChildrenChildren)
              for (let index = 0; index < childrenChildrenChildrenKeys.length; index++) {
                const element = childrenChildrenChildrenKeys[index]
                newData[newData.length - 1].children[newData[newData.length - 1].children.length - 1].children[
                  newData[newData.length - 1].children[newData[newData.length - 1].children.length - 1].children
                    .length - 1
                ].children.push(addDataNode(index, element))
              }
            }
          }
        }
      }
    }
  }

  function addDataNode(id, name, children = []) {
    return {
      id,
      name,
      children,
    }
  }

  return newData
}

const fetchAdress = async (address, cords) => {
  // const token = "pk.eyJ1IjoiYmVucGFpZ2UiLCJhIjoiY2pxd3M2bW1lMDA2cTQzbW1ydzdyaG1kZSJ9.UknF8VENFi1_YhdgaSyJvA"
  // // https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=ae&limit=10&proximity=ip&types=poi&language=en&access_token=${token}
  // const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=ae&limit=7&proximity=ip&types=poi&language=en&access_token=${token}`
  // const { data } = await axios.get(url)
  // geocodeByLatLng({ lat: cords.lat, lng: cords.lng })
  // .then(results => console.log(results,results[0]))
  // .catch(error => console.error(error));
  try {
    const data = await geocodeByLatLng({ lat: cords.lat, lng: cords.lng })

    const newData = {
      features: [
        {
          place_name_en: data[0].formatted_address,
          center: [cords.lng, cords.lat],
          place_id: data[0].place_id,
        },
      ],
    }
    return newData
  } catch (error) {
    return null
  }
}

export const pad = (num, size) => {
  let s = num + ""
  while (s.length < size) s = "0" + s
  return s
}

function getMessage(id, with_collection, need_to_assign) {
  if (id === 1 && with_collection === false) {
    return "Awaiting For Ticket Action"
  } else if (id === 3 && with_collection === true && need_to_assign === true) {
    return "Assign to Driver"
  } else if ((id === 3 || id === 2) && with_collection === true && need_to_assign === false) {
    return "Collect Vehicle"
  } else if (id === 4 && with_collection === true) {
    return "Receive Vehicle"
  } else if (id === 5 && with_collection === true) {
    return "Set For Assessment"
  } else if ((id === 6 && with_collection === true) || (id === 2 && with_collection === false)) {
    return "Assess Vehicle"
  } else if (id === 7 && with_collection === true) {
    return "Approve Job Order"
  } else if (id === 7 && with_collection === false) {
    return "Approve Request"
  } else if (id === 8 && with_collection === true) {
    return "Complete Job Order"
  } else if (id === 8 && with_collection === false) {
    return "Complete Request"
  } else if ((id === 9 || id === 13) && need_to_assign === true) {
    return "Assign to Driver"
  } else if ((id === 9 || id === 13) && need_to_assign === false) {
    return "Deliver Vehicle"
  } else if (id === 10) {
    return "Awaiting For Client Decision"
  } else if (id === 12) {
    return "Close Ticket"
  } else if (id === 13) {
    return "Receive Vehicle"
  } else if (id === 17) {
    return "Collect RC from Client"
  } else if (id === 18) {
    return "Receive Replaced RC"
  } else if (id === 15) {
    return "Transfer Leased Vehicle"
  } else if (id === 16) {
    return "Recieve Transfered Leased Vehicle"
  } else {
    return "Message not found"
  }
}

function getMessagedItem(id, with_collection, need_to_assign, item, selected) {
  if (id === 7 && selected === 37) {
    // console.log(item)
  }

  if (id === 1 && with_collection === false && selected === 31) {
    return item
  } else if (id === 3 && with_collection === true && need_to_assign === true && selected === 32) {
    return item
  } else if ((id === 3 || id === 2) && with_collection === true && need_to_assign === false && selected === 33) {
    return item
  } else if (id === 4 && with_collection === true && selected === 34) {
    return item
  } else if (id === 5 && with_collection === true && selected === 35) {
    return item
  } else if (
    (id === 6 && with_collection === true && selected === 36) ||
    (id === 2 && with_collection === false && selected === 36)
  ) {
    return item
  } else if (id === 7 && with_collection === true && selected === 37) {
    return item
  } else if (id === 7 && with_collection === false && selected === 38) {
    return item
  } else if (id === 8 && with_collection === true && selected === 39) {
    return item
  } else if (id === 8 && with_collection === false && selected === 40) {
    return item
  } else if ((id === 9 || id === 13) && need_to_assign === true && selected === 41) {
    return item
  } else if ((id === 9 || id === 13) && need_to_assign === false && selected === 42) {
    return item
  } else if (id === 10 && selected === 43) {
    return item
  } else if (id === 12 && selected === 44) {
    return item
  } else if (id === 13 && selected === 45) {
    return item
  } else if (id === 17 && selected === 46) {
    return item
  } else if (id === 18 && selected === 47) {
    return item
  } else if (id === 15 && selected === 48) {
    return item
  } else if (id === 16 && selected === 49) {
    return item
  } else if (id === 12 && selected === 50) {
    return item
  } else {
    return
  }
}

function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase()
}

function isNumber(c) {
  return !isNaN(parseFloat(c)) && isFinite(c)
}

function customSort(a, b) {
  const nameA = isNaN(a.name) ? a.name : Number(a.name);
  const nameB = isNaN(b.name) ? b.name : Number(b.name);

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export {
  customSort,
  isLetter,
  isNumber,
  ConvertToJson,
  isJson,
  dayjsx,
  capitalizeWords,
  capitalizeFirstLetter,
  urltoFile,
  jsonToTreeData,
  fetchAdress,
  getMessage,
  getMessagedItem,
}

