import axios from "axios"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { apiUrl, config } from "../../../library/constant"
import { useStoreServices } from "../../../zustand/store"
import Input from "../../inputs/Input"
import TextArea from "../../inputs/TextArea"
import { useCbOnce } from "../../../library/hooks/useCbOne"
import Files from "../../inputs/Files"
import { useState } from "react"
import FilesAll from "../../inputs/FilesAll"

const ForAssessment = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const selectedService = useStoreServices((s) => s.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])

  const onSubmit = useCbOnce(async (submitData) => {
    console.log(submitData)
    const newDateTime = new Date(submitData.date)
    const uploadedPictures = uploadFiles
      ? uploadFiles.map((item, key) => ({ filename: item.filename, name: files[key]?.file?.name }))
      : []

    const newData = {
      id: selectedService.id,
      job_description: submitData.job_order_description,
      target_date_complete: newDateTime,
      assessment_pics: JSON.stringify(uploadedPictures),
    }

    // console.log(newData)
    const { data, status } = await axios.post(apiUrl + "/services/assessment", newData, config())
    setIsVisible(false)
    setSelectedService(data)
    updateService(data)
  })

  useEffect(() => {
    console.log("files", files)
  }, [files])

  return (
    <div className="w-full border-t">
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="space-y-4">
        <Input
          register={register}
          name="date"
          label="Target Completion Date"
          placeholder={"Target Completion Date"}
          type="date"
          condition={{ required: true }}
          error={errors}
          message="Date Require"
        />
        <TextArea
          register={register}
          name="job_order_description"
          label="Job Order Description"
          placeholder={"Job Order Description"}
          type="text"
          error={errors}
          className="min-h-[140px]"
        />
        <div className="">
          <FilesAll
            files={files}
            setFiles={setFiles}
            isWhat="insert"
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        </div>
        <div className="flex items-center justify-end w-full gap-2">
          <button
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => {
              setIsVisible(false)
            }}
          >
            Cancel
          </button>
          <button className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-orange-600">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ForAssessment
