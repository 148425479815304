import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { apiUrl, config } from "../../library/constant"
import { useStoreServices } from "../../zustand/store"
import dayjs from "dayjs"
import ModalUni from "../modals/ModalUni"
import { DetailField } from "./FormView"

const ServiceActionHistory = ({ id }) => {
  const [history, setHistory] = useState([])
  const selectedService = useStoreServices((s) => s.selectedService)

  const [collect, setCollect] = useState(false)
  const [assess, setAssess] = useState(false)
  const [approved, setApproved] = useState(false)
  const [serviceCompleted, setServiceCompleted] = useState(false)
  const [delivery, setDelivery] = useState(false)
  const [delivered, setDelivered] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [rejected, setRejected] = useState(false)

  const openModalActionFinder = (action) => {
    switch (action) {
      case "update_4":
        setCollect(true)
        return "Collected"
      case "update_7":
        setAssess(true)
        return "Assessed"
      case "update_8":
        setApproved(true)
        return "Approved"
      case "update_9":
        setServiceCompleted(true)
        return "Job Finished"
      case "update_10":
        // setDelivery(true)
        return "Out For Delivery"
      case "update_11":
        return "Cancelled"
      case "update_12":
        setDelivered(true)
        return "Delivered"
      case "update_13":
        setRejected(true)
        return "Rejected"
      case "update_14":
        return "Completed"
      // case "update_20":
      //   return "Workshop Transfer"
      default:
        return ""
    }
  }

  const actionFinder = (action) => {
    switch (action) {
      case "update_4":
        const s = {
          title: "Collected",
          icon: "",
          clickable: true,
        }
        return s
      case "update_5":
        return "Received"
      case "update_6":
        return "Set For Assessment"
      case "update_7":
        const s1 = {
          title: "Assessed",
          icon: "",
          clickable: true,
        }
        return s1
      case "update_8":
        const s2 = {
          title: "Approved",
          icon: "",
          clickable: true,
        }
        return s2
      case "update_9":
        const s3 = {
          title: "Job Finished",
          icon: "",
          clickable: true,
        }
        return s3
      case "update_10":
        const s4 = {
          title: "Out For Delivery",
          icon: "",
          clickable: false,
        }
        return s4
      case "update_11":
        return "Cancelled"
      case "update_12":
        const s5 = {
          title: "Delivered",
          icon: "",
          clickable: true,
        }
        return s5
      case "update_13":
        const s6 = {
          title: "Rejected",
          icon: "",
          clickable: true,
        }
        return s6
      case "update_14":
        return "Completed"
      // case "update_20":
      //   return "Workshop Transfer"
      default:
        return ""
    }
  }

  useEffect(() => {
    axios.get(apiUrl + "/audit_logs/service/" + id, config()).then((res) => {
      const data = res.data
      const processedData = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]

        const action = actionFinder(element?.action_type ?? "")

        if (action) {
          if (typeof action === "object" && Object.prototype.toString.call(action) === "[object Object]") {
            const story = {
              user: element?.added_by_data?.name ?? "No Name",
              action: action.title,
              action_type: element?.action_type,
              date: element?.added_date,
              clickable: action.clickable,
            }
            processedData.push(story)
          } else {
            const story = {
              user: element?.added_by_data?.name ?? "No Name",
              action: action,
              action_type: element?.action_type,
              date: element?.added_date,
              clickable: false,
            }
            processedData.push(story)
          }
        }
      }
      setHistory(processedData)
    })
  }, [id, selectedService])

  const lsv = useMemo(() => {
    try {
      const lsv = JSON.parse(selectedService.lease_vehicle)
      return lsv
    } catch (error) {
      return {}
    }
  }, [selectedService])

  return (
    <div className="mt-2 space-y-2">
      <ModalUni isVisible={collect} setIsVisible={setCollect} size={4} title={"Vehicle Collection"}>
        <DetailField
          label="Collection Driver"
          value={selectedService?.collection_driver_data?.name ?? "No details provided"}
        />
        <DetailField label="Client's Driver Name" value={selectedService?.driver_name ?? "No details provided"} />
        <DetailField
          label="Client's Driver Signature"
          value={selectedService?.collection_signature ?? "No details provided"}
          type="image"
          isViewable={false}
        />
        <DetailField
          label="Client ID"
          value={selectedService?.collection_driver_id ? selectedService?.collection_driver_id : []}
          type="images"
        />
      </ModalUni>
      <ModalUni isVisible={assess} setIsVisible={setAssess} size={8} title={"Assessment"}>
        <div className="flex flex-col gap-2 mt-4">
          <DetailField
            label="Target Completion Date"
            value={
              selectedService?.target_date_complete
                ? dayjs(selectedService?.target_date_complete).format("YYYY-MM-DD")
                : "No details provided"
            }
          />
          <DetailField
            label="Assessment Description"
            value={selectedService?.job_description ?? "No details provided"}
          />
          <DetailField
            label="Assessment Attachments"
            value={
              selectedService?.assessment_pics ? JSON.parse(selectedService?.assessment_pics) : "No details provided"
            }
            type="files-object"
          />
        </div>
      </ModalUni>
      <ModalUni isVisible={approved} setIsVisible={setApproved} size={8} title={"Approved Job Order"}>
        <div className="flex flex-col gap-2 mt-4">
          <DetailField label="Maintenance PO" value={selectedService?.maintenance_po ?? "No details provided"} />
          <DetailField
            label="Maintenance PO Date"
            value={selectedService?.maintenance_po_date ?? "No details provided"}
          />
          <DetailField label="Description" value={selectedService?.maintenance_po_desc ?? "No details provided"} />
        </div>
      </ModalUni>
      <ModalUni isVisible={serviceCompleted} setIsVisible={setServiceCompleted} size={8} title={"Completed Job Order"}>
        <div className="flex flex-col gap-2 mt-4">
          <DetailField label="Completion Date" value={selectedService?.job_date_completed ?? "No details provided"} />
          <DetailField
            label="Description"
            value={selectedService?.job_date_completed_remarks ?? "No details provided"}
          />
        </div>
      </ModalUni>
      <ModalUni isVisible={delivery} setIsVisible={setDelivery} size={8} title={"Deliver Vehicle"}>
        <div className="mt-4">{/* <Delivery setIsVisible={setDelivery} /> */}</div>
      </ModalUni>
      <ModalUni isVisible={delivered} setIsVisible={setDelivered} size={4} title={"Delivered Details"}>
        <DetailField
          label="Delivery Driver"
          value={selectedService?.delivery_driver_data?.name ?? "No details provided"}
        />
        <DetailField label="Client's Driver Name" value={selectedService?.driver_name ?? "No details provided"} />
        <DetailField
          label="Client's Driver Signature"
          value={selectedService?.delivery_signature ?? "No details provided"}
          type="image"
          isViewable={false}
        />
        <DetailField
          label="Delivery Pictures"
          value={selectedService?.delivery_pics ?? "No details provided"}
          type="images"
        />
      </ModalUni>
      <ModalUni isVisible={completed} setIsVisible={setCompleted} size={8} title={"Job Completion"}>
        <div className="mt-4">{/* <RCCollected setIsVisible={setCompleted} /> */}</div>
      </ModalUni>

      {/* <ModalUni isVisible={cancel} setIsVisible={setCancel} size={8} title={"Service Request Cancellation"}>
        <div className="mt-4">
          <CancelRequest setIsVisible={setCancel} />
        </div>
      </ModalUni>
      <ModalUni isVisible={reject} setIsVisible={setReject} size={8} title={"Reject Request"}>
        <div className="mt-4">
          <RejectRequest setIsVisible={setReject} />
        </div>
      </ModalUni> */}

      <ModalUni isVisible={rejected} setIsVisible={setRejected} size={8} title={"Rejected"}>
        <div className="flex flex-col gap-2 mt-4">
          {/* <DetailField label="Completion Date" value={selectedService?.job_date_completed ?? "No details provided"} /> */}
          <DetailField label="Description" value={selectedService?.reject_reason ?? "No details provided"} />
        </div>
      </ModalUni>

      {history.map((item, key) => {
        let nitem = { ...item }
        if (item.action_type === "update_4") {
          const combinedDateTime = `${lsv?.date_in} ${lsv?.time_in}`
          nitem.date = combinedDateTime
        } else if (item.action_type === "update_12") {
          const combinedDateTime = `${lsv?.date_out} ${lsv?.time_out}`
          nitem.date = combinedDateTime
        }
        return (
          <div
            onClick={() => {
              console.log(nitem.action_type)
              openModalActionFinder(nitem.action_type)
            }}
            key={key}
            className={` ${nitem?.clickable ? "cursor-pointer" : ""} `}
          >
            <HistoryTag data={nitem} />
          </div>
        )
      })}
    </div>
  )
}

const HistoryTag = ({ data }) => {
  return (
    <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md shadow-md">
      <div className={`font-semibold ${data?.clickable ? "text-[#F06400]" : ""} `}>
        {data.action} {data?.clickable ? <i className="fa-solid fa-hand-pointer"></i> : ""}
      </div>
      <div className="flex justify-between text-sm">
        <div>{dayjs(data.date).format("MMM D, YYYY")}</div>
        <div>{dayjs(data.date).format("HH:mm")}</div>
      </div>
    </div>
  )
}

export default ServiceActionHistory
