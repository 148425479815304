import React, { useEffect, useMemo, useState } from "react"
import ModalUni from "../modals/ModalUni"
import CarCollected from "./ActionModals/CarCollected"
import Delivered from "./ActionModals/Delivered"
import Delivery from "./ActionModals/Delivery"
import ForAssessment from "./ActionModals/ForAssessment"
import RCCollected from "./ActionModals/RCCollected"
import ServiceCompleted from "./ActionModals/ServiceCompleted"
import Received from "./ActionModals/Received"
import SetForAssesment from "./ActionModals/SetForAssesment"
import CancelRequest from "./ActionModals/CancelRequest"
import Approved from "./ActionModals/Approved"
import RejectRequest from "./ActionModals/RejectRequest"
import TransferWorkshop from "./ActionModals/TransferWorkshop"
import ChangeRC from "./ActionModals/ChangeRC"
import { useStore, useStoreServices } from "../../zustand/store"
import RCHistory from "./ActionModals/RCHistory"
import WorkshopHistory from "./ActionModals/WorkshopHistory"
import TransferLeaseVehicle from "./ActionModals/TransferLeaseVehicle"
import TransferReceivedLV from "./ActionModals/TransferReceivedLV"
import { apiUrl, config } from "../../library/constant"
import axios from "axios"
import AssignTo from "./ActionModals/AssignTo"
import TransferTicket from "./ActionModals/TransferTicket"
import TransferTicketHistory from "./ActionModals/TransferTicketHistory"
import ChangeLocation from "./ActionModals/ChangeLocation"

const ServiceActions = ({ status, status_sub, setIsWhat, setIsVisible, collectionNeeded, rc_needed }) => {
  const privileges = useStore((state) => state.privileges)

  const [collect, setCollect] = useState(false)
  const [received, setReceived] = useState(false)
  const [forAssessment, setForAssessment] = useState(false)
  const [assess, setAssess] = useState(false)
  const [approved, setApproved] = useState(false)
  const [serviceCompleted, setServiceCompleted] = useState(false)
  const [delivery, setDelivery] = useState(false)
  const [delivered, setDelivered] = useState(false)
  const [completed, setCompleted] = useState(false)

  const [cancel, setCancel] = useState(false)
  const [reject, setReject] = useState(false)

  const [transfer, setTransfer] = useState(false)
  const [transferLV, setTransferLV] = useState(false)
  const [receiveLV, setReceiveLV] = useState(false)

  const [change, setChange] = useState(false)
  const [changeLocation, setChangeLocation] = useState(false)
  const [transferHistory, setTransferHistory] = useState(false)
  const [rcHistory, setRCHistory] = useState(false)

  const { setVehicleModalOpen } = useStore()
  const { selectedService } = useStoreServices()

  const [workshopHistory, setWorkshopHistory] = useState(0)
  const [rcHistoryC, setRCHistoryC] = useState(0)

  const [transferTicket, setTransferTicket] = useState(false)
  const [transferTicketHistory, setTransferTicketHistory] = useState(false)

  const [assignTo, setAssignTo] = useState(false)

  useEffect(() => {
    if (collect) {
      setVehicleModalOpen(true)
    } else {
      setVehicleModalOpen(false)
    }
  }, [collect])

  useEffect(() => {
    if (delivered) {
      setVehicleModalOpen(true)
    } else {
      setVehicleModalOpen(false)
    }
  }, [delivered])

  useEffect(() => {
    ;(async () => {
      const link = apiUrl + "/workshop_history/" + selectedService.id
      const { data: workshop } = await axios.get(link, config())
      if (workshop?.length) {
        setWorkshopHistory(workshop.length)
      } else {
        setWorkshopHistory(0)
      }
    })()
  }, [selectedService])

  useEffect(() => {
    ;(async () => {
      const link = apiUrl + "/rc_history/" + selectedService.id
      const { data: rc } = await axios.get(link, config())
      if (rc?.length) {
        setRCHistoryC(rc.length)
      } else {
        setRCHistoryC(0)
      }
    })()
  }, [selectedService])

  return (
    <>
      <ModalUni isVisible={collect} setIsVisible={setCollect} size={10} title={"Collect Vehicle Description"}>
        <div className="mt-4">
          <CarCollected setIsVisible={setCollect} />
        </div>
      </ModalUni>
      <ModalUni isVisible={received} setIsVisible={setReceived} size={4} title={"Leased Vehicle Received"}>
        <div className="mt-4">
          <Received setIsVisible={setReceived} />
        </div>
      </ModalUni>
      <ModalUni isVisible={forAssessment} setIsVisible={setForAssessment} size={3} title={`Set "For Assessment"`}>
        <div className="mt-4">
          <SetForAssesment setIsVisible={setForAssessment} />
        </div>
      </ModalUni>
      <ModalUni isVisible={assess} setIsVisible={setAssess} size={8} title={"Assessment"}>
        <div className="mt-4">
          <ForAssessment setIsVisible={setAssess} />
        </div>
      </ModalUni>
      <ModalUni isVisible={approved} setIsVisible={setApproved} size={8} title={"Approve Job Order"}>
        <div className="mt-4">
          <Approved setIsVisible={setApproved} />
        </div>
      </ModalUni>
      <ModalUni isVisible={serviceCompleted} setIsVisible={setServiceCompleted} size={8} title={"Complete Job Order"}>
        <div className="mt-4">
          <ServiceCompleted setIsVisible={setServiceCompleted} />
        </div>
      </ModalUni>
      <ModalUni isVisible={delivery} setIsVisible={setDelivery} size={8} title={"Deliver Vehicle"}>
        <div className="mt-4">
          <Delivery setIsVisible={setDelivery} />
        </div>
      </ModalUni>
      <ModalUni isVisible={delivered} setIsVisible={setDelivered} size={8} title={"Client Accept Delivery"}>
        <div className="mt-4">
          <Delivered setIsVisible={setDelivered} />
        </div>
      </ModalUni>
      <ModalUni isVisible={completed} setIsVisible={setCompleted} size={8} title={"Job Completion"}>
        <div className="mt-4">
          <RCCollected setIsVisible={setCompleted} />
        </div>
      </ModalUni>

      <ModalUni isVisible={cancel} setIsVisible={setCancel} size={8} title={"Service Request Cancellation"}>
        <div className="mt-4">
          <CancelRequest setIsVisible={setCancel} />
        </div>
      </ModalUni>

      <ModalUni isVisible={reject} setIsVisible={setReject} size={8} title={"Reject Request"}>
        <div className="mt-4">
          <RejectRequest setIsVisible={setReject} />
        </div>
      </ModalUni>

      <ModalUni isVisible={transfer} setIsVisible={setTransfer} size={4} title={"Transfer Workshop"}>
        <div className="mt-4">
          <TransferWorkshop setIsVisible={setTransfer} />
        </div>
      </ModalUni>

      <ModalUni isVisible={transferTicket} setIsVisible={setTransferTicket} size={4} title={"Transfer Ticket"}>
        <div className="mt-4">
          {/* <TransferWorkshop setIsVisible={setTransferTicket} /> */}
          <TransferTicket setIsVisible={setTransferTicket} />
        </div>
      </ModalUni>

      <ModalUni isVisible={changeLocation} setIsVisible={setChangeLocation} size={4} title={"Transfer Ticket"}>
        <div className="mt-4">
          <ChangeLocation setIsVisible={setChangeLocation} />
        </div>
      </ModalUni>

      <ModalUni
        isVisible={transferTicketHistory}
        setIsVisible={setTransferTicketHistory}
        size={4}
        title={"Agent History"}
      >
        <div className="mt-4">
          <TransferTicketHistory setIsVisible={setTransferTicketHistory} />
        </div>
      </ModalUni>

      <ModalUni isVisible={change} setIsVisible={setChange} size={10} title={"Replacement Car Details"}>
        <div className="mt-4">
          <ChangeRC setIsVisible={setChange} />
        </div>
      </ModalUni>

      <ModalUni isVisible={receiveLV} setIsVisible={setReceiveLV} size={4} title={"Leased Vehicle Returned"}>
        <div className="mt-4">
          <TransferReceivedLV setIsVisible={setReceiveLV} />
        </div>
      </ModalUni>

      <ModalUni isVisible={transferLV} setIsVisible={setTransferLV} size={10} title={"Replacement Car Details"}>
        <div className="mt-4">
          <TransferLeaseVehicle setIsVisible={setTransferLV} />
        </div>
      </ModalUni>

      <ModalUni isVisible={transferHistory} setIsVisible={setTransferHistory} size={3} title={"Workshop History"}>
        <div className="mt-4">
          <WorkshopHistory setIsVisible={setTransferHistory} />
        </div>
      </ModalUni>

      <ModalUni isVisible={rcHistory} setIsVisible={setRCHistory} size={10} title={"Replacement Car History"}>
        <div className="mt-4">
          <RCHistory setIsVisible={setRCHistory} />
        </div>
      </ModalUni>

      <ModalUni isVisible={assignTo} setIsVisible={setAssignTo} size={6} title={"Assign to Driver"}>
        <div className="mt-4">
          <AssignTo setIsVisible={setAssignTo} />
        </div>
      </ModalUni>

      {/* <p>Admin Actions</p> */}
      {Boolean(privileges[10]) && (
        <button
          className={`flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group`}
          onClick={() => {
            if (status === 1 || status === 2 || status === 3) {
              setTransferTicket(true)
            }
          }}
        >
          <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Transfer Ticket</p>
        </button>
      )}

      {Boolean(privileges[10]) && (
        <button
          className={`flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group`}
          onClick={() => {
            if (status === 1 || status === 2 || status === 3) {
              setTransferTicketHistory(true)
            }
          }}
        >
          <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Agent History</p>
        </button>
      )}

      {Boolean(privileges[10]) && status === 2 && (
        <button
          className={`flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group ${
            status === 4 || status === 5 ? "cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (status === 1 || status === 2 || status === 3) {
              setTransfer(true)
            }
          }}
        >
          <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Transfer Workshop</p>
        </button>
      )}

      {Boolean(privileges[3]) && (status === 1 || status_sub === 9) && (
        <div
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => setAssignTo(true)}
        >
          <i className={`fa-solid fa-handshake w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Assign To Driver</p>
        </div>
      )}

      {workshopHistory !== 0 && (
        <button
          className={`flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group ${
            status === 4 ? "cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (status !== 4) {
              setTransferHistory(true)
            }
          }}
        >
          <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Workshop History</p>
        </button>
      )}

      {Boolean(privileges[2]) && rc_needed && status !== 1 && selectedService.replacement_car && (
        <button
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => {
            setChange(true)
          }}
        >
          <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Change RC</p>
        </button>
      )}

      {rc_needed ? (
        rcHistoryC ? (
          <button
            className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
            onClick={() => {
              setRCHistory(true)
            }}
          >
            <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
            <p className="truncate">RC History</p>
          </button>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      {status !== 4 ? <p>Service Actions</p> : <div></div>}

      {(status === 1 || status_sub === 9) && (
        <button
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => {
            setChangeLocation(true)
          }}
        >
          <i className={` fa-solid fa-location-dot w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Change Location</p>
        </button>
      )}

      {selectedService?.assignedTo && (
        <>
          {Boolean(privileges[4]) &&
            selectedService.assignedTo &&
            (status === 1 || status_sub === 17) &&
            collectionNeeded && (
              <button
                className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
                onClick={() => {
                  setCollect(true)
                }}
              >
                <i className={`fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
                <p className="truncate">{status_sub === 17 ? "Collect RC" : "Collect Vehicle"}</p>
              </button>
            )}
        </>
      )}

      {Boolean(privileges[5]) && (status_sub === 4 || status_sub === 18 || status_sub === 13) && (
        <button
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => {
            setReceived(true)
          }}
        >
          <i className={` fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">{status_sub === 18 ? "Receive RC" : "Receive Vehicle"}</p>
        </button>
      )}

      {Boolean(privileges[5]) && (
        <>
          {status_sub === 5 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setForAssessment(true)
              }}
            >
              <i className={` fa-solid fa-car-garage w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Set "For Assessment"</p>
            </button>
          )}
        </>
      )}

      {/* 5 = "Received" && 6 = "For Assessment" */}
      {Boolean(privileges[6]) && (
        <>
          {(!collectionNeeded && status === 1) || status_sub === 6 ? (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setAssess(true)
              }}
            >
              <i className={`fa-solid fa-clipboard w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Assess Vehicle</p>
            </button>
          ) : (
            <></>
          )}
        </>
      )}

      {Boolean(privileges[7]) && (
        <>
          {status_sub === 7 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setApproved(true)
              }}
            >
              <i className={`fa-solid fa-clipboard-check w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Approve Job Order</p>
            </button>
          )}
        </>
      )}

      {Boolean(privileges[8]) && (
        <>
          {status_sub === 8 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setServiceCompleted(true)
              }}
            >
              <i className={`fa-solid fa-car-wrench w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Complete Job Order</p>
            </button>
          )}
        </>
      )}

      {Boolean(privileges[9]) && selectedService.assignedTo && collectionNeeded && status_sub === 9 && (
        <button
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => {
            setDelivery(true)
          }}
        >
          <i className={`fa-solid fa-truck w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Deliver Vehicle</p>
        </button>
      )}

      {Boolean(privileges[9]) && status_sub === 10 && (
        <>
          <button
            className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
            onClick={() => {
              setDelivered(true)
            }}
          >
            <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
            <p className="truncate">Accept Vehicle</p>
          </button>

          <button
            className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
            onClick={() => {
              setReject(true)
            }}
          >
            <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
            <p className="truncate">Reject Deliver</p>
          </button>
        </>
      )}

      {Boolean(privileges[8]) && status_sub === 12 && (
        <button
          className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
          onClick={() => {
            setCompleted(true)
          }}
        >
          <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
          <p className="truncate">Complete</p>
        </button>
      )}

      {Boolean(privileges[0]) && (
        <>
          {status === 1 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setCancel(true)
              }}
            >
              <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Cancel Request</p>
            </button>
          )}

          {status_sub === 15 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setTransferLV(true)
              }}
            >
              <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Transfer Lease Vehicle</p>
            </button>
          )}

          {status_sub === 16 && (
            <button
              className="flex items-center justify-start gap-2 bg-[#F1F4F9] focus:outline-none outline-none rounded-md px-4 w-full py-1 hover:bg-[#004A5D] text-gray-500 hover:text-[#F1F4F9] group"
              onClick={() => {
                setReceiveLV(true)
              }}
            >
              <i className={` fa-solid fa-check-to-slot w-5 h-5 group-hover:text-[#F1F4F9]`} />
              <p className="truncate">Receive Lease Vehicle</p>
            </button>
          )}
        </>
      )}
    </>
  )
}

export default ServiceActions
