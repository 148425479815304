import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreDrivers, useStoreWorkshops } from "../../zustand/store"
import Files from "../inputs/Files"
import Input from "../inputs/Input"
import Select from "../inputs/Select"

const FormCreate = ({ setVisibleModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const workshops = useStoreWorkshops((s) => s.workshops)
  const isWhat = useStoreDrivers((s) => s.isWhat)
  const setSelectedDriver = useStoreDrivers((s) => s.setSelectedDriver)
  const selectedDriver = useStoreDrivers((s) => s.selectedDriver)
  const addDriver = useStoreDrivers((s) => s.addDriver)
  const updateDriver = useStoreDrivers((s) => s.updateDriver)

  const [files, setFiles] = useState(undefined)
  const [image, setImage] = useState(null)
  const pictureRef = useRef(null)

  const handleSubmitData = async (params) => {
    if (isWhat === "insert") {
      const newParams = { ...params }
      const { data, status } = await axios.post(apiUrl + "/drivers", newParams, config())
      if (status === 200) {
        toast.success("Driver Successfully Added...")
        addDriver(data)
        setVisibleModal(false)
      }
    } else if (isWhat === "update") {
      const newParams = { ...params, id: selectedDriver.id }
      const { data, status } = await axios.put(apiUrl + "/drivers", newParams, config())
      if (status === 200) {
        toast.success("Driver Successfully Updated...")
        console.log(data)
        updateDriver(data)
        setVisibleModal(false)
      }
    } else {
      toast.error("error occured me is 😕")
    }
  }

  const handleCancel = () => {
    setVisibleModal(false)
    setSelectedDriver(null)
  }

  const handleUploadPicture = () => {
    pictureRef.current.click()
  }

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      //   setImage(URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append("files", event.target.files[0])
      const res = await axios.post(apiUrl + "/upload", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      console.log(res.data)
      setValue("pic", res.data.filenames[0])
      setImage(apiUrl + "/public/" + res.data.filenames[0])
    }
  }

  useEffect(() => {
    if (isWhat === "update" && selectedDriver) {
      setValue("name", selectedDriver.name)
      setValue("address", selectedDriver.address)
      setValue("contact", selectedDriver.contact)
      setValue("email", selectedDriver.email)
      setValue("workshop", selectedDriver.workshop)
      if (selectedDriver?.pic) {
        setValue("pic", selectedDriver.pic)
        setImage(apiUrl + "/public/" + selectedDriver.pic)
      } else {
        setImage(null)
      }
    }
  }, [isWhat, selectedDriver])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        handleSubmitData(data)
      })}
    >
      <div>
        <div className="flex justify-center w-full mt-6 avatar">
          <div className="relative w-32 rounded-full ring ring-[#F06400] ring-offset-base-100 ring-offset-2">
            <div
              className="absolute top-0 z-50 flex items-center justify-center w-full h-full transition-all ease-in-out opacity-0 cursor-pointer hover:bg-black/50 hover:opacity-100"
              onClick={handleUploadPicture}
            >
              <div className="text-white">Upload</div>
              <input
                type="file"
                ref={pictureRef}
                className="hidden"
                onChange={onImageChange}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
            {image ? (
              <img src={image} />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-[#F06400]">
                <i className="text-6xl text-gray-200 fa-solid fa-user-bounty-hunter"></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <Input
        register={register}
        name="name"
        label="Driver Names"
        placeholder="Driver Name."
        type="text"
        condition={{ required: true }}
        error={errors}
        message="Driver Name."
      />
      <Input
        register={register}
        name="address"
        label="Driver Address"
        placeholder="Driver Address."
        type="text"
        condition={{ required: true }}
        error={errors}
        message="Driver Address."
      />
      <div className="grid grid-flow-row grid-cols-2 gap-4">
        <Input
          register={register}
          name="contact"
          label="Driver Contact"
          placeholder="Driver Contact."
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Driver Contact."
        />
        <Input
          register={register}
          name="email"
          label="Driver Email"
          placeholder="Driver Email."
          type="text"
          condition={{ required: true }}
          error={errors}
          message="Driver Email."
        />
      </div>

      <Select
        register={register}
        name="workshop"
        label="Workshop"
        placeholder="Select Workshop"
        condition={{ required: true }}
        error={errors}
        message="Workshop Required"
        items={workshops}
        selectKey="name"
      />
      {/* <Files files={files} setFiles={setFiles} /> */}
      <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
        <div className="px-12 btn" onClick={handleCancel}>
          Cancel
        </div>
        <button className="px-12 btn">{isWhat === "insert" ? "Submit" : "Update"}</button>
      </div>
    </form>
  )
}

export default FormCreate
