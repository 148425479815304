import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"

// import { useStoreOverAllMaintenanceRates } from "../../zustand/store"
import DebouceSearchInput from "../inputs/DebouceSearchInput"
import Card from "../Card"

const OverallMaintenanceTable = () => {

    // const resOverAllMaintenanceRates = useStoreOverAllMaintenanceRates((state) => state.overAllMaintenanceRates)
    // const setSelectedOverAllMaintenanceRates = useStoreOverAllMaintenanceRates((state) => state.setSelectedOverAllMaintenanceRates)

    const bodyRows = 10
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)

    const [overallMaintenanceRates, setOverallMaintenanceRates] = useState([])

    const nextPage = (params) => {
        if (currentPage === totalPages) return null
    
        setCurrentPage((state) => {
          state += 1
          return state
        })
    }
    
    const prevPage = (params) => {
        if (currentPage === 1) return null
    
        setCurrentPage((state) => {
          state -= 1
          return state
        })
    }

    useEffect(() => {
        const fetchPagination = async () => {
          const res = await axios.get(apiUrl + "/overall_maintenance_rates/count?search=" + searchText, config())
          let pages = parseInt((res.data - 1) / bodyRows + 1)
          setCount(res.data)
          setTotalPages(pages)
        }
        fetchPagination()
    }, [searchText])
    
      useEffect(() => {
        const fetchData = async () => {
          const res = await axios.get(
            apiUrl + "/overall_maintenance_rates/data_table?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
            config()
          )
          setOverallMaintenanceRates(res.data)
        }
        fetchData()
      }, [currentPage, searchText])

    return (
        <Card>
            <div className="w-full mt-6 p-6">
                <DebouceSearchInput onTextChange={setSearchText} />
                <div className="w-full mt-6">
                    <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg max-h-200">
                        <table className="w-full overflow-hidden rounded-t-lg table-auto whitespace-nowrap">
                        <thead className="sticky top-0">
                            <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
                            <th className="px-3 py-4 text-left">SUPPLIER NAME</th>
                            <th className="px-3 py-4 text-left">MAKE</th>
                            <th className="px-3 py-4 text-left">MODEL</th>
                            <th className="px-3 py-4 text-left">SERVICE MILEAGE</th>
                            <th className="px-3 py-4 text-left">LOCATION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {overallMaintenanceRates.map((item, key) => {
                            return (
                                <TableRow
                                    key={key}
                                    item={item}
                                />
                            )
                            })}
                        </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
                        <div>
                        Showing {overallMaintenanceRates?.length ?? 0} of {count} total record
                        </div>
                        <div className="flex items-center mt-2 space-x-4">
                        <div onClick={prevPage} className="cursor-pointer">
                            Previous
                        </div>
                        <div>
                            {currentPage}/{totalPages}
                        </div>
                        <div onClick={nextPage} className="cursor-pointer">
                            Next
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

const parsingJSON = (json) => {
    try {
      return JSON.parse(json)
    } catch (error) {
      return []
    }
}
const TableRow = ({ item }) => {

    const serviceMileage = parsingJSON(item.service_mileage)

    return (
      <tr>
        <td className={`py-4 pl-3`}>{item?.supplier_name}</td>
        <td className={`py-4 pl-3`}>{item?.make}</td>
        <td className={`py-4 pl-3`}>{item?.model}</td>
        <td className={`py-4 pl-3`}>
            <ol>
                {serviceMileage.map((item, index) => (
                    <li key={index}>{item?.mileage} - {item?.price}</li>
                ))}
            </ol>
        </td>
        <td className={`py-4 pl-3`}>{item?.location}</td>
      </tr>
    )
  }

export default OverallMaintenanceTable