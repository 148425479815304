import React, { useEffect, useMemo, useState } from "react"
import * as dayjs from "dayjs"
import { HashLoader } from "react-spinners"
import { useStore, useStoreAlerts, useStoreServices } from "../../zustand/store"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import View from "./View"
import ModalUni from "../modals/ModalUni"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var updateLocale = require("dayjs/plugin/updateLocale")
dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
})

export const theEquivament = [
  {
    id: "emirate",
    key: "name",
    keys: ["emirate_data"],
    name: "Emirate",
  },
  {
    id: "plate",
    key: "plate",
    keys: [],
    name: "Plate",
  },
  {
    id: "emirates_category",
    key: "actual",
    keys: ["auh_category_data"],
    name: "Emirates category",
  },
  {
    id: "mileage",
    key: "mileage",
    keys: [],
    name: "Mileage",
  },
  {
    id: "client_api_data.PLATE_NO",
    key: "PLATE_NO",
    keys: ["client_api_data"],
    name: "PLATE NO",
  },
  {
    id: "client_api_data.OBJECT_NO",
    key: "OBJECT_NO",
    keys: ["client_api_data"],
    name: "OBJECT NO",
  },
  {
    id: "client_api_data.EMIRATES",
    key: "EMIRATES",
    keys: ["client_api_data"],
    name: "EMIRATES",
  },
  {
    id: "client_api_data.CLIENT_NO",
    key: "CLIENT_NO",
    keys: ["client_api_data"],
    name: "CLIENT NO",
  },
  {
    id: "client_api_data.CLIENT_NAME",
    key: "CLIENT_NAME",
    keys: ["client_api_data"],
    name: "CLIENT NAME",
  },
  {
    id: "client_api_data.MAKE",
    key: "MAKE",
    keys: ["client_api_data"],
    name: "MAKE",
  },
  {
    id: "client_api_data.MODEL",
    key: "MODEL",
    keys: ["client_api_data"],
    name: "MODEL",
  },
  {
    id: "client_api_data.YEAR_MODEL",
    key: "YEAR_MODEL",
    keys: ["client_api_data"],
    name: "YEAR MODEL",
  },
  {
    id: "client_api_data.COLOR",
    key: "COLOR",
    keys: ["client_api_data"],
    name: "COLOR",
  },
  {
    id: "client_api_data.LAST_KNOWN_MILEAGE",
    key: "LAST_KNOWN_MILEAGE",
    keys: ["client_api_data"],
    name: "LAST KNOWN MILEAGE",
  },
  {
    id: "client_api_data.REPLACEMENT_CATEGORY",
    key: "REPLACEMENT_CATEGORY",
    keys: ["client_api_data"],
    name: "REPLACEMENT CATEGORY",
  },
  {
    id: "client_api_data.REPLACEMENT_CARS",
    key: "REPLACEMENT_CARS",
    keys: ["client_api_data"],
    name: "REPLACEMENT CARS",
  },
  {
    id: "client_api_data.DRIVER_NAME",
    key: "DRIVER_NAME",
    keys: ["client_api_data"],
    name: "DRIVER NAME",
  },
  {
    id: "client_api_data.DRIVER_EMAIL",
    key: "DRIVER_EMAIL",
    keys: ["client_api_data"],
    name: "DRIVER EMAIL",
  },
  {
    id: "client_api_data.DRIVER_TELNO",
    key: "DRIVER_TELNO",
    keys: ["client_api_data"],
    name: "DRIVER TELNO",
  },
  {
    id: "driver_name",
    key: "driver_name",
    keys: [],
    name: "Driver name",
  },
  {
    id: "driver_contact",
    key: "driver_contact",
    keys: [],
    name: "Driver contact",
  },
  {
    id: "driver_email",
    key: "driver_email",
    keys: [],
    name: "Driver email",
  },
  {
    id: "request_details",
    key: "request_details",
    keys: [],
    name: "Request details",
  },
  {
    id: "workshop",
    key: "name",
    keys: ["workshop_data"],
    name: "Workshop",
  },
  {
    id: "collection_datetime",
    key: "collection_datetime",
    keys: [],
    name: "Collection datetime",
    type: "date",
  },
  {
    id: "collection_location",
    key: "collection_location",
    keys: [],
    name: "Collection location",
  },
  {
    id: "collection_coordinates.lat",
    key: "lat",
    keys: ["collection_coordinates"],
    name: "Lat",
  },
  {
    id: "collection_coordinates.lng",
    key: "lng",
    keys: ["collection_coordinates"],
    name: "Lng",
  },
  {
    id: "service_type",
    key: "name",
    keys: ["service_type_data"],
    name: "Service type",
  },
  {
    id: "added_date",
    key: "added_date",
    keys: [],
    name: "Added date",
    type: "date",
  },
  {
    id: "added_by",
    key: "name",
    keys: ["added_by_data"],
    name: "Added by",
  },
  {
    id: "updated_by",
    key: "name",
    keys: ["updated_by_data"],
    name: "Updated by",
  },
]

const Table = ({ searchText, selectedColumns }) => {
  // const services = useStoreServices((state) => state.services)
  // const setServices = useStoreServices((state) => state.setServices)
  const [services, setServices] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [openView, setOpenView] = useState(false)
  const [isWhat, setIsWhat] = useState("")
  const selectedService = useStoreServices((state) => state.selectedService)
  const runningRequestNo = useStore((s) => s.runningRequestNo)
  const setSelectedService = useStoreServices((state) => state.setSelectedService)
  const loggedUser = useStore((state) => state.loggedUser)
  const userType = useStore((state) => state.userType)
  const workshop = useStore((state) => state.workshop)

  const [count, setCount] = useState(0)
  const alerts = useStoreAlerts((state) => state.alerts)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const bodyRows = 10

  useEffect(() => {
    const fetchPagination = async () => {
      const res = await axios.get(apiUrl + "/services/count?search=" + searchText, config())
      let pages = parseInt((res.data - 1) / bodyRows + 1)
      setCount(res.data)
      setTotalPages(pages)
    }
    fetchPagination()
  }, [alerts, searchText])

  useEffect(() => {
    const fetchData = async () => {
      if (userType === 1) {
        const res = await axios.get(
          apiUrl + "/services?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
          config()
        )
        setServices(res.data)
      } else if (userType === 2) {
        const res = await axios.get(
          apiUrl +
            `/services/user/${loggedUser.id}?page=` +
            currentPage +
            "&count=" +
            bodyRows +
            "&search=" +
            searchText,
          config()
        )
        setServices(res.data)
      } else if (userType === 3 || userType === 4) {
        if (userType === 4) {
          const res = await axios.get(
            apiUrl +
              `/services/workshop/${workshop}?page=` +
              currentPage +
              "&count=" +
              bodyRows +
              "&search=" +
              searchText +
              "&usertype=" +
              userType +
              "&assigned=" +
              loggedUser.id,
            config()
          )
          setCount(res.data ? res.data.length : 0)
          setServices(res.data ?? [])
        } else {
          const res = await axios.get(
            apiUrl +
              `/services/workshop/${workshop}?page=` +
              currentPage +
              "&count=" +
              bodyRows +
              "&search=" +
              searchText,
            config()
          )
          setCount(res.data ? res.data.length : 0)
          setServices(res.data ?? [])
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [currentPage, alerts, loggedUser, userType, workshop, runningRequestNo, selectedService, searchText])

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  const data = useMemo(() => {
    const convertedTableData = [...services]
    const tableData = []

    for (let index = 0; index < convertedTableData.length; index++) {
      let tableRow = { ...convertedTableData[index] }
      try {
        tableRow.client_api_data = JSON.parse(tableRow.client_api_data)
        tableRow.collection_coordinates = JSON.parse(tableRow.collection_coordinates)
      } catch (error) {}

      const newTableRow = {}

      const servicex = { ...tableRow }
      servicex.client_api_data = JSON.stringify(servicex.client_api_data)
      servicex.collection_coordinates = JSON.stringify(servicex.collection_coordinates)
      newTableRow.service = servicex
      newTableRow.request_nox = tableRow.request_no
      newTableRow.added_datex = tableRow.added_date
      newTableRow.stats = tableRow.status_types_data?.name
      newTableRow.sub_stats = tableRow.status_sub_types_data?.name

      for (let index = 0; index < selectedColumns.length; index++) {
        const columnx = selectedColumns[index]
        const column = theEquivament.find((obj) => obj.id === columnx.id)
        if (column.keys.length === 0) {
          try {
            if (column.type === "date") {
              if (tableRow[column.key]) {
                newTableRow[column.id] = dayjs(tableRow[column.key]).format("MMM D, YYYY HH:mm")
              } else {
                newTableRow[column.id] = ""
              }
            } else {
              newTableRow[column.id] = tableRow[column.key]
            }
          } catch (error) {
            newTableRow[column.id] = ""
          }
        }
        if (column.keys.length === 1) {
          try {
            newTableRow[column.id] = tableRow[column.keys[0]][column.key]
          } catch (error) {
            newTableRow[column.id] = ""
          }
        }
      }

      tableData.push(newTableRow)
    }
    return tableData
  }, [selectedColumns, services])

  return (
    <div className="">
      <View isWhat={isWhat} setIsWhat={setIsWhat} openView={openView} setOpenView={setOpenView} />
      {isLoading ? 
        <div className="mt-10">
          <center>
            <HashLoader color="#f97316" loading={true} size={80}/>
          </center>
        </div>
      :
        <>
          <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg max-h-[calc(100vh-360px)]">
            <table className="w-full overflow-hidden bg-white table-auto whitespace-nowrap">
              <thead className="sticky top-0">
                <tr className="text-sm text-[#F06400] ring ring-[#F06400] bg-white">
                  {/* from-[#F06400] via-orange-300 to-[#F06400] */}
                  <th className="py-4 pl-3 pr-3 text-left">SERVICE NUMBER</th>

                  {selectedColumns.map((item, key) => {
                    return (
                      <th className="py-4 pl-3 pr-3 text-left" key={key}>
                        {item.name}
                      </th>
                    )
                  })}
                  {/* <th className="py-4 pl-3 pr-4 text-left">ACTION</th> */}
                  <th className="py-4 pl-3 pr-3 text-left">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {services &&
                  data.map((service, key) => {
                    const itemArray = Object.values(service)
                    return (
                      <TableRow
                        key={key}
                        setIsWhat={setIsWhat}
                        service={service}
                        setSelectedService={setSelectedService}
                        setOpenView={setOpenView}
                        data={itemArray}
                      />
                    )
                  })}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
            <div>
              Showing {services ? data?.length : 0} of {count} total record
            </div>
            <div className="flex items-center mt-2 space-x-4">
              <div onClick={prevPage} className="cursor-pointer">
                Previous
              </div>
              <div>
                {currentPage}/{totalPages}
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                Next
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Table

const TableRow = ({ setIsWhat, service, setSelectedService, setOpenView, data }) => {
  const [modalLoading, setModalLoading] = useState(false)
  return (
    <tr
      onClick={() => {
        // setSelectedService(service.service)
        // setOpenView(true)
        // setIsWhat("View")
        setModalLoading(true)
        axios
          .get(apiUrl + "/services/" + service.service.id, config())
          .then((res) => {
            setSelectedService(res.data)
            setOpenView(true)
            setIsWhat("View")
            setModalLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setModalLoading(false)
          })
      }}
      className="cursor-pointer hover:bg-orange-50"
    >
      <ModalUni
        isVisible={modalLoading}
        setIsVisible={() => {
          return
        }}
        size={2}
        title={"Fetching Data"}
        hasClose={false}
      ></ModalUni>
      <td className="py-4 pl-3">
        <div className="flex flex-col">
          <div>{service.request_nox}</div>
          <div className="text-xs">{dayjs(service.added_datex).fromNow("s")} ago</div>
        </div>
      </td>

      {data.map((item, key) => {
        if (key <= 4) {
          return null
        }
        return (
          <td className="py-4 pl-3 text-xs" key={key}>
            {item}
          </td>
        )
      })}

      <td className="py-3 pl-3 ">
        <div className="space-y-1">
          <div className="flex text-xs text-green-500">
            <div className="p-2 bg-green-500 rounded-full"></div>
            <div className="ml-1">{service.stats}</div>
          </div>
          <div className="flex text-xs text-[#F06400]">
            <div className="p-2 bg-[#F06400] rounded-full"></div>
            <div className="ml-1">{service.sub_stats}</div>
          </div>
        </div>
      </td>
    </tr>
  )
}
