import React, { useState } from "react"
import AnimatedPage from "../../components/AnimatedPage"
import ModalUni from "../../components/modals/ModalUni"
import FormCreate from "../../components/replacement_cars/FormCreate"
import Table from "../../components/replacement_cars/Table"
import { useStoreReplacementCars, useStoreTypes } from "../../zustand/store"
import DebouceSearchInput from "../../components/inputs/DebouceSearchInput"
import DebouceDropdown from "../../components/inputs/DebouceDropdown"

const ViewCars = () => {
  const [isNewReplacementCar, setIsNewReplacementCar] = useState(false)
  const isWhat = useStoreReplacementCars((s) => s.isWhat)
  const setIsWhat = useStoreReplacementCars((s) => s.setIsWhat)
  const carsCategoryTypes = useStoreTypes((s) => s.carsCategoryTypes)
  const [searchText, setSearchText] = useState("")
  const [category, setCategory] = useState(0)

  const handleOpenNewRequest = (second) => {
    setIsWhat("insert")
    setIsNewReplacementCar(true)
  }

  return (
    <AnimatedPage title="Replacement Cars">
      {/*  Modals */}
      <ModalUni
        isVisible={isNewReplacementCar}
        setIsVisible={setIsNewReplacementCar}
        title={`${isWhat === "insert" ? "Add" : "Edit"} Replacement Cars`}
        size={8}
      >
        <FormCreate setVisibleModal={setIsNewReplacementCar} />
      </ModalUni>
      {/* -------- */}
      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        <div className="flex gap-4">
          <DebouceSearchInput onTextChange={setSearchText} />
          <DebouceDropdown label={'Category'} item={carsCategoryTypes} onTextChange={setCategory} />
          {/* <button>
            <i className="px-3 py-2 text-2xl text-white bg-[#F06400] rounded-md fa-solid fa-filter"></i>
          </button> */}
        </div>
        <button
          className="flex items-center justify-center px-8 py-3 text-lg text-white rounded-md shadow-md bg-aqua hover:bg-aqua-dark"
          onClick={handleOpenNewRequest}
        >
          <div className="mr-4">Create Replacement Cars</div>
          <i className="text-sm fa-solid fa-plus-large"></i>
        </button>
      </div>
      <Table setModalOpen={setIsNewReplacementCar} searchText={searchText} categoryFilter={category} />
    </AnimatedPage>
  )
}

export default ViewCars
