import React from "react"
import { apiUrl } from "../../library/constant"

const CarItem = ({ display = false, selectedID, item, onClick, service_mileage, location_state, location_country, rc_selection_reason, rc_price }) => {

  const isSelected = selectedID === item.id

  const isNumeric = (value) => {
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    if (typeof value === 'string') {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }
    return false;
  }

  return (
    <div
      className={`flex w-full h-full overflow-auto bg-white rounded-lg ${
        display ? "shadow-md border" : `hover:bg-orange-300 ${isSelected && "ring-4 ring-[#F06400]"}`
      }  `}
      onClick={() => {
        if (!display) {
          onClick(item.id)
        }
      }}
    >
      <img src={item.pic ? apiUrl + "/public/" + item.pic : "/cars/ferrari.png"} alt="car" className="w-40" />
      <div className="flex flex-col justify-between w-full px-4 py-4">
        <div className="text-lg font-bold">
          {/* {item?.reg_no} */}
          {item?.emirate_data?.name}-{item?.plate}
        </div>
        <div className="grid grid-flow-row grid-cols-3">
          <div className="whitespace-nowrap">Make: {item?.brand_data.name}</div>
          <div className="whitespace-nowrap">Model: {item?.model}</div>
          <div className="whitespace-nowrap">Year: {item?.year}</div>
        </div>
        <div className="grid grid-flow-row grid-cols-3">
          <div className="whitespace-nowrap">Color: {item?.color}</div>
          <div className="whitespace-nowrap">Workshop: {item?.workshop_data.name}</div>
          <div className="whitespace-nowrap">Utilization: {Number(item?.utilization).toFixed(2)}%</div>
        </div>
        {(service_mileage || rc_price || location_state || location_country) && 
          <div className="grid grid-flow-row grid-cols-3">
            {service_mileage && 
              <div className="whitespace-nowrap">Service Mileage: {service_mileage}</div>
            }
            {isNumeric(rc_price) && 
              <div className="whitespace-nowrap">Maintenance Rate: {rc_price}</div>
            }
            {(location_state || location_country) && 
              <div className="whitespace-nowrap">Location: {location_state && `${location_state}, `}{location_country}</div>
            }
          </div>
        }
        {rc_selection_reason && 
          <div className="grid grid-flow-row grid-cols-1">
            <div className="whitespace-nowrap">Selection Reason: {rc_selection_reason}</div>
          </div>
        }
      </div>
    </div>
  )
}

export default CarItem
