import { useEffect, useState } from "react"

const CheckBox = ({ title = "Title", setValue, id, value, className }) => {
  const handleCheck = () => {
    setValue(id, !value[id])
  }

  return (
    <div className={`flex items-center ml-3 ${className} `} onClick={handleCheck}>
      <div className="flex items-center justify-center w-4 h-4 rounded ring ring-orange-600">
        {Boolean(value[id]) && <i className="mt-1 text-sm fa-solid fa-check"></i>}
      </div>
      <div className="flex ml-4 whitespace-nowrap">{title}</div>
    </div>
  )
}

export default CheckBox
