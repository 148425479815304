import React, { useEffect, useState } from "react"

import Loading from "../Loading"
import { toast } from "react-toastify"

const ServiceReportCustomFilter = ({ setIsModalCustomFilterVisible, selectedCustomColumn, selectedData, setSelectedData }) => {

  // console.log("selectedData", selectedData)
  selectedCustomColumn = selectedCustomColumn.replace("Service Request Api Data.", "")
  const [isLoading, setIsLoading] = useState(true)
  const selectedColumn = selectedData.find(item => item.id === selectedCustomColumn)
  const selectedIndex = selectedData.findIndex(item => item.id === selectedCustomColumn)

  const prevValues = []
  for (const key in selectedData) {
    if (selectedData[key].id === selectedCustomColumn && selectedData[key].customFilter?.length > 0) {
      for (const subKey in selectedData[key].customFilter) {
        prevValues.push({operator: selectedData[key].customFilter[subKey].operator, logicalOp: selectedData[key].customFilter[subKey].logicalOp, value: selectedData[key].customFilter[subKey].value})
      }
    }
  }
  const [fields, setFields] = useState(prevValues.length > 0 ? prevValues : [{ operator: '', logicalOp: '', value: '' }]);

  const handleAddFieldSet = () => {
    setFields([...fields, { operator: '', logicalOp: '', value: '' }]);
  };

  const handleClearFieldSet = () => {
    Array.from(document.getElementsByClassName('filter-dropdown')).forEach((element) => {
      element.value = element.options[0].value;
    });
    Array.from(document.getElementsByClassName('filter-field')).forEach((element) => {
      element.value = '';
    });
    setFields([{ operator: '', logicalOp: '', value: '' }]);
  }

  const handleRemoveFieldSet = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleDropdownChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, operator: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleTextChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleLogicalOpChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, logicalOp: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };
  
  const handleOperatorRequired = (index) => {
    // fields.length > 1
    const valueField = document.getElementById(`filter-value-${index}`)
    if (fields.length > 1) {
      return true
    } else {
      if (valueField?.value && valueField?.value != "") {
        return true
      } else {
        return false
      }
    }
  }

  const handleSubmitFilter = (event) => {
    event.preventDefault();
    if (fields[0].operator == '' && fields[0].value == '') {
      const newSelectedData = selectedData
      selectedColumn.customFilter = []
      newSelectedData[selectedIndex] = selectedColumn
      setSelectedData(newSelectedData)
    } else {
      const newSelectedData = selectedData
      selectedColumn.customFilter = fields
      newSelectedData[selectedIndex] = selectedColumn
      setSelectedData(newSelectedData)
    }
    setIsModalCustomFilterVisible(false)
    toast.success("Successfully Saved.")
  }

  useEffect(() => {
    // console.log("selectedColumn", selectedColumn)
    setIsLoading(false)
  }, [fields])

  return (
    <div className="flex flex-col gap-4 mt-4">
        {isLoading ? (
            <div className="flex justify-center py-7">
                <Loading />
            </div>
        ) : (
            <div>
                {/* {selectedColumn.customFilter.length > 0 && selectedColumn.customFilter[0]?.name} */}
                <form onSubmit={handleSubmitFilter}>
                  <div className="pt-3 pb-7">
                    <h3 className="text-[23px] font-bold mb-[10px]">
                      {selectedColumn?.id}
                    </h3>
                    {/* <div className="w-full mb-[10px] hidden">
                      <button type="button" className="px-12 mr-3 btn btn-success">
                          AND
                      </button>
                      <button type="button" className="px-12 btn">
                          OR
                      </button>
                    </div> */}
                    {fields.map((field, index) => (
                      <div key={index} className="w-full mb-[10px]">
                        {index > 0 && 
                          <div>
                            <select
                              value={field.logicalOp}
                              onChange={(e) => handleLogicalOpChange(index, e)}
                              className="filter-dropdown input input-bordered mb-[10px]"
                              id={`filter-logicalOp-${index}`}
                              required={fields.length > 1}
                            >
                              <option value="">Select an option</option>
                              <option value="&&">AND</option>
                              <option value="||">OR</option>
                            </select>
                          </div>
                        }
                        <div className={"form-inline"}>
                          <select
                            value={field.operator}
                            onChange={(e) => handleDropdownChange(index, e)}
                            className="filter-dropdown input input-bordered mr-[10px]"
                            id={`filter-operator-${index}`}
                            required={handleOperatorRequired(index)}
                          >
                            <option value="">Select an option</option>
                            <option value="==">equals to</option>
                            <option value="!=">not equals to</option>
                          </select>
                          <input
                            className="filter-field input input-bordered mr-[13px]"
                            id={`filter-value-${index}`}
                            type="text"
                            value={field.value}
                            onChange={(e) => handleTextChange(index, e)}
                            placeholder={``}
                          />
                          {index > 0 ? 
                            <button type="button" onClick={() => handleRemoveFieldSet(index)}>
                              <i className="fa-solid fa-trash"/>
                            </button>
                          : 
                            <button type="button" onClick={() => handleClearFieldSet()}>
                              <i className="fa-solid fa-undo"/> Clear All
                            </button>
                          }
                        </div>
                      </div>
                    ))}
                    {/* <div className={"w-full form-control"}>
                    </div> */}
                    <button type="button" onClick={handleAddFieldSet}>
                      <i className="fa-solid fa-plus"/> Add Filter
                    </button>
                  </div>
                  <div className="flex justify-end">
                      <button type="submit" className="px-12 btn">
                          Save
                      </button>
                  </div>
                </form>
            </div>
        )}
    </div>
  )
}

export default ServiceReportCustomFilter
