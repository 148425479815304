import { useRef, useState } from "react"
import { Stepper } from "@mantine/core"
import { useForm } from "react-hook-form"
import Input from "../../inputs/Input"
import SelectMap from "../../inputs/SelectMap"
import Map from "../../map/Map"
import SignatureCanvas from "react-signature-canvas"
import MantineDropzone from "./Dropzone"
import { useEffect } from "react"
import { useStore, useStoreDrivers, useStoreServices } from "../../../zustand/store"
import { NewDriverCard } from "../../drivers/DriverCard"
import Files from "../../inputs/Files"
import { urltoFile } from "../../../library/functions"
import { apiUrl, config } from "../../../library/constant"
import axios from "axios"
import CarIntent from "../CarIntent"
import dayjs from "dayjs"
import TextArea from "../../inputs/TextArea"
import { useCbOnce } from "../../../library/hooks/useCbOne"

const Delivered = ({ setIsVisible }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const watcher = watch()

  const [active, setActive] = useState(0)
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current))
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))

  const loggedUser = useStore((s) => s.loggedUser)
  const drivers = useStoreDrivers((s) => s.drivers)
  const selectedService = useStoreServices((state) => state.selectedService)
  const setSelectedService = useStoreServices((s) => s.setSelectedService)
  const updateService = useStoreServices((s) => s.updateService)

  const [leasedVehicleOut, setLeasedVehicleOut] = useState(selectedService.lease_vehicle_in ?? "{}")
  const [replacementVehicleIn, setReplacementVehicleIn] = useState(selectedService.replacement_vehicle_out ?? "{}")

  const [currentDriver, setCurrentDriver] = useState({})

  const [signaturePoints, setSignaturePoints] = useState([])
  const [signUrl, setSignUrl] = useState("")

  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])

  const [nolsDataRC, setNolsDataRC] = useState({})
  const [nolsDataLV, setNolsDataLV] = useState({})

  useEffect(() => {
    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index]
      if (loggedUser.driver === driver.id) {
        setCurrentDriver(driver)
      }
    }
  }, [loggedUser, drivers])

  const finish = useCbOnce(async () => {
    const file = await urltoFile(signUrl, "signature.png", "png")
    console.log(file)
    const formData = new FormData()
    formData.append("files", file)
    const res = await axios.post(apiUrl + "/upload", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })

    const uploadedFilename = res.data.filenames[0]
    const uploadedPictures = uploadFiles ? uploadFiles.map((item) => item.filename) : []

    let replacement_vehicle_data = {}
    try {
      replacement_vehicle_data = JSON.parse(selectedService.replacement_vehicle)
    } catch (error) {
      replacement_vehicle_data = {}
    }

    let lease_vehicle_data = {}
    try {
      lease_vehicle_data = JSON.parse(selectedService.lease_vehicle)
    } catch (error) {
      lease_vehicle_data = {}
    }

    let replacement_vehicle = {}

    if (selectedService?.rc_needed) {
      replacement_vehicle = {
        ...replacement_vehicle_data,
        date_in: watcher.date_in,
        time_in: watcher.time_in,
        km_in: watcher.km_in,
        fuel_in: watcher.fuel_in,
      }
    }

    const lease_vehicle = {
      ...lease_vehicle_data,
      date_out: watcher.date_out,
      time_out: watcher.time_out,
      km_out: watcher.km_out,
      fuel_out: watcher.fuel_out,
      description_out: watcher.description_out,
    }

    const xLeasedVehicleOut = JSON.parse(leasedVehicleOut)
    const xReplacementVehicleIn = JSON.parse(replacementVehicleIn)
    const newLeasedVehicleOut = { ...xLeasedVehicleOut, description: watcher.description_in }
    const newReplacementVehicleIn = { ...xReplacementVehicleIn, description: watcher.description_out }

    const submitData = {
      id: selectedService.id,
      delivery_signature: uploadedFilename,
      delivery_pics: uploadedPictures,
      lease_vehicle: JSON.stringify(lease_vehicle),
      lease_vehicle_out: JSON.stringify(newLeasedVehicleOut),
      replacement_vehicle: JSON.stringify(replacement_vehicle),
      replacement_vehicle_in: JSON.stringify(newReplacementVehicleIn),
    }
    const response = await axios.post(apiUrl + "/services/delivered", submitData, config())

    setSelectedService(response.data)
    updateService(response.data)
    setIsVisible(false)
  })

  useEffect(() => {
    const newDateTime = new Date()
    setValue("time_in", dayjs(newDateTime).format("HH:mm"))
    setValue("date_in", dayjs(newDateTime).format("YYYY-MM-DD"))
    setValue("time_out", dayjs(newDateTime).format("HH:mm"))
    setValue("date_out", dayjs(newDateTime).format("YYYY-MM-DD"))
  }, [])

  useEffect(() => {
    try {
      const LeasedVehicle = JSON.parse(selectedService.lease_vehicle)
      setValue("km_in", LeasedVehicle.km_in)
      setValue("fuel_in", LeasedVehicle.fuel_in)
      setValue("km_out", LeasedVehicle.km_in)
      setValue("fuel_out", LeasedVehicle.fuel_in)

      console.log(selectedService)

      if (selectedService?.client_api_data) {
        const nolslv = JSON.parse(selectedService.client_api_data)
        setNolsDataLV(nolslv)
      }

      if (selectedService?.replacement_car_data?.client_api_data) {
        const nolsrc = JSON.parse(selectedService.replacement_car_data.client_api_data)
        setNolsDataRC(nolsrc)
      }
    } catch (error) {
      console.log(error)
    }
  }, [selectedService])

  return (
    <div className="px-4 space-y-4">
      <form className="">
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Vehicle Report" radius={"sm"} description="First Step">
            <CarIntent
              className="border-b"
              title="Leased Vehicle Out"
              title2={`(${selectedService?.emirate_data?.name}-${nolsDataLV?.PLATE_NO} - ${nolsDataLV?.MODEL})`}
              name="Vehicle Out"
              id="lease_vehicle_out"
              xSetValue={setLeasedVehicleOut}
              data={leasedVehicleOut ?? null}
              isSetValue={false}
            />
            {/* IN */}
            <div className="grid grid-flow-row grid-cols-3 gap-x-4">
              <Input
                register={register}
                name="date_out"
                label="Date Out"
                placeholder="Date Out"
                type="date"
                error={errors}
                disabled
              />
              <Input
                register={register}
                name="time_out"
                label="Time Out"
                placeholder="Time Out"
                type="time"
                error={errors}
                disabled
              />
              <div className="flex gap-x-4">
                <Input
                  register={register}
                  name="km_out"
                  label="Km Out"
                  placeholder="Km Out"
                  type="text"
                  error={errors}
                />
                <Input
                  register={register}
                  name="fuel_out"
                  label="Fuel Out"
                  placeholder="Fuel Out"
                  type="text"
                  error={errors}
                />
              </div>
            </div>
            <div>
              <TextArea
                register={register}
                name="description_out"
                label="Description"
                placeholder={"Description"}
                type="text"
                error={errors}
                className="min-h-[140px]"
              />
            </div>

            {/* Replacement Out */}
            {selectedService?.rc_needed && (
              <>
                <CarIntent
                  className="border-b"
                  title="Replacement Vehicle In"
                  title2={`(${selectedService?.replacement_car_data?.emirate_data?.name}-${nolsDataRC.PLATE_NO} - ${nolsDataRC.MODEL})`}
                  name="Vehicle In"
                  id="replacement_vehicle_in"
                  xSetValue={setReplacementVehicleIn}
                  data={replacementVehicleIn ?? null}
                  isSetValue={false}
                />
                <div className="grid grid-flow-row grid-cols-3 gap-x-4">
                  <Input
                    register={register}
                    name="date_in"
                    label="Date In"
                    placeholder="Date In"
                    type="date"
                    error={errors}
                    disabled
                  />
                  <Input
                    register={register}
                    name="time_in"
                    label="Time In"
                    placeholder="Time In"
                    type="time"
                    error={errors}
                    disabled
                  />
                  <div className="flex gap-x-4">
                    <Input
                      register={register}
                      name="km_in"
                      label="Km In"
                      placeholder="Km In"
                      type="text"
                      error={errors}
                    />
                    <Input
                      register={register}
                      name="fuel_in"
                      label="Fuel In"
                      placeholder="Fuel In"
                      type="text"
                      error={errors}
                    />
                  </div>
                </div>
                <div>
                  <TextArea
                    register={register}
                    name="description_in"
                    label="Description"
                    placeholder={"Description"}
                    type="text"
                    error={errors}
                    className="min-h-[140px]"
                  />
                </div>
              </>
            )}
          </Stepper.Step>

          <Stepper.Step label="Delivery Pictures" radius={"sm"} description="Second Step">
            <div className="">
              <Files
                files={files}
                setFiles={setFiles}
                isWhat="insert"
                uploadFiles={uploadFiles}
                setUploadFiles={setUploadFiles}
              />
            </div>
          </Stepper.Step>
          <Stepper.Step label="E-Signature of Client" radius={"sm"} description="Final Step">
            <Signature value={signaturePoints} setValue={setSignaturePoints} setSignUrl={setSignUrl} />
          </Stepper.Step>
        </Stepper>
      </form>

      <div className="flex items-center justify-end w-full gap-2">
        {active != 0 && (
          <button
            className="px-4 py-2 font-medium text-[#F06400] border border-[#F06400] rounded-md"
            onClick={() => prevStep()}
          >
            Back
          </button>
        )}

        {active < 2 ? (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => nextStep()}
          >
            Next
          </button>
        ) : (
          <button
            className="px-4 py-2 font-medium text-white bg-[#F06400] rounded-md hover:bg-[#F06400]"
            onClick={() => finish()}
          >
            Finish
          </button>
        )}
      </div>
    </div>
  )
}

export default Delivered

const Signature = ({ value, setValue, setSignUrl }) => {
  const sigCanvas = useRef()

  useEffect(() => {
    if (sigCanvas.current || value) {
      sigCanvas.current.fromData(value)
    }
  }, [sigCanvas, value])

  return (
    <div className="relative w-full space-y-2 border">
      {/* TODO: convert signature to image, idk how to do this sir whaha */}
      <button className="absolute px-3 py-2 border rounded-md top-2 right-2" onClick={() => sigCanvas.current.clear()}>
        Clear
      </button>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 800, height: 400, className: "" }}
        ref={sigCanvas}
        onEnd={(e) => {
          const dataPoints = sigCanvas.current.toData()
          setValue(dataPoints)
          setSignUrl(sigCanvas.current.toDataURL("png"))
        }}
      />
    </div>
  )
}
