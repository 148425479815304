import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import React, { useState } from "react"
import AnimatedPage from "../components/AnimatedPage"
import FormModal from "../components/user_management/FormModal"
import UsersTable from "../components/user_management/UsersTable"
import { apiUrl, config } from "../library/constant"
import { useStore, useStoreUsers } from "../zustand/store"
import { useEffect } from "react"
import DebouceSearchInput from "../components/inputs/DebouceSearchInput"
import NoAccess from "./NoAccess"

const UserManagement = () => {
  const [modal, setModal] = useState({
    type: "",
    visible: false,
  })

  const [searchText, setSearchText] = useState("")
  const [selectedUser, setSelectedUser] = useState(null)
  const privileges = useStore((state) => state.privileges)

  if (!Boolean(privileges[12])) {
    return <NoAccess />
  }

  return (
    <AnimatedPage title={"User Management"}>
      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        <div className="flex gap-4">
          <DebouceSearchInput onTextChange={setSearchText} />
        </div>
        <button
          className="flex items-center justify-center px-8 py-3 text-lg text-white rounded-md shadow-md bg-aqua hover:bg-aqua-dark"
          onClick={() => {
            setModal({
              type: "create",
              visible: true,
            })
          }}
        >
          <div className="mr-4">Create Users</div>
          <i className="text-sm fa-solid fa-plus-large"></i>
        </button>
      </div>

      <UsersTable setModal={setModal} setSelectedUser={setSelectedUser} searchText={searchText} />
      <FormModal modal={modal} setModal={setModal} selectedUser={selectedUser} />
    </AnimatedPage>
  )
}

export default UserManagement
