import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { apiUrl, config } from "../../library/constant"
import { useStoreAlerts, useStoreMap, useStoreWorkshops } from "../../zustand/store"
import ModalUni from "../modals/ModalUni"
import Cars from "./Cars"
import Drivers from "./Drivers"

const Table = ({ setIsAddWorkshopOpen, searchText }) => {
  const resWorkshops = useStoreWorkshops((state) => state.workshops)
  const setSelectedWorkshop = useStoreWorkshops((state) => state.setSelectedWorkshop)
  const selectedWorkshop = useStoreWorkshops((state) => state.selectedWorkshop)
  const deleteWorkshop = useStoreWorkshops((state) => state.deleteWorkshop)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCarsOpen, setModalCarsOpen] = useState(false)
  const [modalDriversOpen, setModalDriversOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)

  const [workshops, setWorkshops] = useState([])

  const alerts = useStoreAlerts((state) => state.alerts)

  const bodyRows = 10

  const handleDelete = (item) => {
    setModalIsOpen(true)
    setSelectedWorkshop(item)
  }

  const handleConfirmDelete = async () => {
    const { data, status } = await axios.delete(apiUrl + "/workshops/" + selectedWorkshop.id, config())
    console.log(status)

    if (status === 200) {
      toast.success("Workshop Successfully Deleted...")
      deleteWorkshop(data)
      setModalIsOpen(false)
    }
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    setSelectedWorkshop(null)
  }

  const nextPage = (params) => {
    if (currentPage === totalPages) return null

    setCurrentPage((state) => {
      state += 1
      return state
    })
  }

  const prevPage = (params) => {
    if (currentPage === 1) return null

    setCurrentPage((state) => {
      state -= 1
      return state
    })
  }

  useEffect(() => {
    const fetchPagination = async () => {
      const res = await axios.get(apiUrl + "/workshops/count?search=" + searchText, config())
      let pages = parseInt((res.data - 1) / bodyRows + 1)
      setCount(res.data)
      setTotalPages(pages)
    }
    fetchPagination()
  }, [alerts, resWorkshops, searchText])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        apiUrl + "/workshops?page=" + currentPage + "&count=" + bodyRows + "&search=" + searchText,
        config()
      )
      setWorkshops(res.data)
    }
    fetchData()
  }, [currentPage, alerts, resWorkshops, searchText])

  return (
    <div className="w-full mt-6">
      <ModalUni isVisible={modalIsOpen} setIsVisible={setModalIsOpen} title="You sure to delete?" size={3}>
        <div className="flex mt-2 space-x-1">
          <div>Workshop:</div>
          <div className="font-semibold">{selectedWorkshop?.name}</div>
        </div>
        <div className="flex justify-end pt-4 mt-4 space-x-2 border-t">
          <button className="px-12 btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="px-12 btn" onClick={handleConfirmDelete}>
            Delete
          </button>
        </div>
      </ModalUni>

      <ModalUni isVisible={modalCarsOpen} setIsVisible={setModalCarsOpen} title="Workshop Cars" size={9}>
        <Cars />
      </ModalUni>
      <ModalUni isVisible={modalDriversOpen} setIsVisible={setModalDriversOpen} title="Workshop Drivers" size={6}>
        <Drivers />
      </ModalUni>

      {/* <div className="w-full overflow-x-auto overflow-y-scroll max-h-96 scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100"> */}
      <div className="min-w-full mt-6 overflow-auto rounded-t-lg shadow-lg max-h-96">
        <table className="w-full overflow-hidden rounded-t-lg table-auto whitespace-nowrap">
          <thead className="sticky top-0">
            <tr className="text-sm text-[#F06400] ring ring-[#F06400]">
              <th className="px-3 py-4 text-left">EMIRATE</th>
              <th className="px-3 py-4 text-left">WORKSHOP NAME</th>
              <th className="px-3 py-4 text-left">LOCATION</th>
              <th className="px-3 py-4 text-left">ADDRESS</th>
              <th className="px-3 py-4 text-left">CARS</th>
              <th className="px-3 py-4 text-left">DRIVERS</th>
              <th className="py-4 pl-3 text-left">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {workshops.map((item, key) => {
              return (
                <TableRow
                  key={key}
                  item={item}
                  setIsAddWorkshopOpen={setIsAddWorkshopOpen}
                  handleDelete={() => handleDelete(item)}
                  setOpenCars={setModalCarsOpen}
                  setOpenDrivers={setModalDriversOpen}
                />
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col mt-6 mr-4 md:flex-row md:items-center md:justify-between">
        <div>
          Showing {workshops?.length ?? 0} of {count} total record
        </div>
        <div className="flex items-center mt-2 space-x-4">
          <div onClick={prevPage} className="cursor-pointer">
            Previous
          </div>
          <div>
            {currentPage}/{totalPages}
          </div>
          <div onClick={nextPage} className="cursor-pointer">
            Next
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table

const TableRow = ({ item, setIsAddWorkshopOpen, handleDelete, setOpenCars, setOpenDrivers }) => {
  const setHoverCoordinates = useStoreMap((s) => s.setHoverCoordinates)
  const setIsWhat = useStoreWorkshops((state) => state.setIsWhat)
  const setSelectedWorkshop = useStoreWorkshops((state) => state.setSelectedWorkshop)
  const rc_count = item?.replacement_cars?.length ? item?.replacement_cars?.length : 0
  const drivers_count = item?.drivers?.length ? item?.drivers?.length : 0

  const handleEdit = () => {
    setIsWhat("update")
    setSelectedWorkshop(item)
    setIsAddWorkshopOpen(true)
  }
  return (
    <tr>
      <td className={`py-4 pl-3`}>{item?.emirate_data?.name ?? "No Emirate"}</td>
      <td className={`py-4 pl-3 ${item?.is_agency ? "text-[#db240c]" : ""} `}>{item?.name}</td>
      <td className={`py-4 pl-3 ${item?.is_agency ? "text-[#db240c]" : ""} `}>{item?.location}</td>
      <td
        className="max-w-lg py-4 pl-3 overflow-hidden text-blue-400 cursor-pointer text-ellipsis"
        onClick={() => {
          setHoverCoordinates(JSON.parse(item?.coordinates))
          console.log(JSON.parse(item?.coordinates))
        }}
      >
        <i className="ml-2 fa-solid fa-location-arrow"></i> {item?.address}
        {/* <i class="fa-solid fa-location-arrow"></i> */}
      </td>
      <td
        className="py-4 pl-3 text-blue-400 cursor-pointer"
        onClick={() => {
          setSelectedWorkshop(item)
          setOpenCars(true)
        }}
      >
        {rc_count} <i className="ml-2 fa-solid fa-magnifying-glass"></i>
      </td>
      <td
        className="py-4 pl-3 text-blue-400 cursor-pointer"
        onClick={() => {
          setSelectedWorkshop(item)
          setOpenDrivers(true)
        }}
      >
        {drivers_count} <i className="ml-2 fa-solid fa-magnifying-glass"></i>
      </td>
      <td className="flex items-center py-4 pl-3 space-x-2">
        <div onClick={handleEdit} className="flex items-center text-blue-400 cursor-pointer">
          <i className="ml-2 fa-solid fa-pen-to-square"></i>
        </div>
        <div onClick={handleDelete} className="flex items-center text-red-400 cursor-pointer">
          <i className="ml-2 fa-solid fa-trash"></i>
        </div>
      </td>
    </tr>
  )
}
